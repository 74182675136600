import { useState, useRef, useEffect } from "react";
import { API } from "aws-amplify";
import { Box, Stack, Button, Typography, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export default function ContactUsForConsultation({ buttonOnly }) {
  const [openForm, setOpenForm] = useState(false);

  return (
    <>
      {buttonOnly ? (
        <Box sx={{ flexShrink: 0, width: 160 }}>
          {!openForm && (
            <Button
              onClick={() => {
                setOpenForm(true);
                // scrollTo.current.scrollIntoView({ behavior: "smooth" });
              }}
              variant="contained"
            >
              Message us
            </Button>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            height: 100,
            m: 5,
            display: "flex",
            gap: 5,
            alignItems: "center",
            // justifyContent: "space-between",
            bgcolor: "purple",
            p: 5,
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              sx={{
                alignSelf: "center",
                color: "#fff",
                fontSize: 20,
                fontWeight: "light",
                // flexGrow: 1,
              }}
            >
              Interested in learning more? Contact our representative today to
              schedule a consultation and discuss how Hankeno can meet your
              unique needs and goals
            </Typography>
          </Box>

          <Box sx={{ flexShrink: 0, width: 160 }}>
            {!openForm && (
              <Button
                onClick={() => {
                  setOpenForm(true);
                  // scrollTo.current.scrollIntoView({ behavior: "smooth" });
                }}
                variant="contained"
              >
                Message us
              </Button>
            )}
          </Box>
        </Box>
      )}

      {openForm && <Form onClose={() => setOpenForm(false)} />}
    </>
  );
}

function Form({ onClose }) {
  let preventBlur = false;
  const formRef = useRef(null);
  const inputRef = useRef(null);
  const scrollRef = useRef(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  // const scrollToRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, []);

  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  async function onSubmit(e) {
    setLoading(true);
    e.preventDefault();

    const formData = new FormData(formRef.current);
    const data = Object.fromEntries(formData.entries());
    const email = data.email?.trim();
    const name = data.name?.trim();
    const subject = data.subject?.trim();
    const message = data.message?.trim();

    try {
      await API.post("iSupplyAPI", "/public", {
        body: {
          operation: "SEND_INQUIRY",
          data: {
            email,
            name,
            subject,
            message,
          },
        },
      });
      setSent(true);
    } catch (err) {
      console.log({ error3333: err });
    }
    setLoading(false);
  }

  // console.log({ preventBlur });

  const onBlur = (event) => {
    if (preventBlur) return;
    const { name, value } = event.target;

    switch (name) {
      case "email":
        if (!isValidEmail.test(value)) {
          setErrors({ email: "Please enter a valid email" });
        } else {
          setErrors((prev) => ({ ...prev, email: null }));
        }
        break;
      case "name":
        if (value.trim()?.length < 2 || value.trim()?.length > 20) {
          setErrors({ name: "Please enter a valid name" });
        } else {
          setErrors((prev) => ({ ...prev, name: null }));
        }
        break;
      case "subject":
        if (value.trim()?.length < 2 || value.trim()?.length > 100) {
          setErrors({ subject: "Please enter a valid subject" });
        } else {
          setErrors((prev) => ({ ...prev, subject: null }));
        }
        break;
      case "message":
        if (value.trim()?.length < 5) {
          setErrors({
            message: "Please enter a message with at least 5 characters",
          });
        } else {
          setErrors((prev) => ({ ...prev, message: null }));
        }
        break;
      default:
        break;
    }
  };

  const onChange = (event) => {
    let { name, value } = event.target;

    if (!value.trim()?.length) {
      event.target.value = "";
    }

    switch (name) {
      case "email":
        if (!isValidEmail.test(value)) {
          // setErrors({ email: "Please enter a valid email" });
        } else {
          setErrors((prev) => ({ ...prev, email: null }));
        }
        break;
      case "name":
        if (value.trim()?.length < 2 || value.trim()?.length > 20) {
          // setErrors({ name: "Please enter a valid name" });
        } else {
          setErrors((prev) => ({ ...prev, name: null }));
        }
        break;
      case "subject":
        if (value.trim()?.length < 2 || value.trim()?.length > 100) {
          // setErrors({ subject: "Please enter a valid subject" });
        } else {
          setErrors((prev) => ({ ...prev, subject: null }));
        }
        break;
      case "message":
        if (value.trim()?.length < 5) {
          // setErrors({
          //   message: "Please enter a message with at least 5 characters",
          // });
        } else {
          setErrors((prev) => ({ ...prev, message: null }));
        }
        break;
      default:
        break;
    }
  };

  if (sent) {
    return <SuccessfulMessage onClose={onClose} />;
  }

  return (
    <Box
      ref={formRef}
      component="form"
      autoComplete="off"
      sx={{
        bgcolor: "#f1f1f1",
        m: 5,
        p: 5,
        mt: 0,
        flexDirection: "column",

        display: "flex",
        gap: 2,
      }}
      onSubmit={onSubmit}
    >
      <TextField
        inputRef={inputRef}
        error={!!errors.name}
        onChange={onChange}
        onBlur={onBlur}
        helperText={errors.name}
        sx={{ width: 400 }}
        required
        name="name"
        label="Name"
        defaultValue=""
        variant="standard"
        placeholder="Please enter your name"
      />
      <TextField
        sx={{ width: 400 }}
        onChange={onChange}
        error={!!errors.email}
        onBlur={onBlur}
        helperText={errors.email}
        required
        name="email"
        label="Email"
        defaultValue=""
        variant="standard"
        placeholder="Please enter your email"
      />
      <TextField
        required
        onChange={onChange}
        error={!!errors.subject}
        onBlur={onBlur}
        helperText={errors.subject}
        name="subject"
        label="Subject"
        defaultValue=""
        variant="standard"
        placeholder="Please enter the subject"
      />
      <TextField
        onChange={onChange}
        error={!!errors.message}
        onBlur={onBlur}
        helperText={errors.message}
        multiline
        rows={8}
        required
        name="message"
        label="Message"
        defaultValue=""
        placeholder="Please enter the message"
      />

      <Stack ref={scrollRef} spacing={5} direction="row" alignSelf="center">
        <Button
          sx={{ alignSelf: "center" }}
          onMouseDown={(e) => {
            preventBlur = true;

            // e.preventDefault();
          }}
          onClick={(e) => {
            // preventBlur = true;
            setErrors({});
            onClose();
            preventBlur = false;
          }}
          variant="contained"
        >
          Close
        </Button>
        <LoadingButton
          loading={loading}
          sx={{ alignSelf: "center" }}
          variant="contained"
          type="submit"
          disabled={Object.values(errors)?.filter(Boolean).length > 0}
        >
          Send
        </LoadingButton>
      </Stack>
    </Box>
  );
}

function SuccessfulMessage({ onClose }) {
  return (
    <Box
      sx={{
        bgcolor: "#f1f1f1",
        m: 5,
        p: 5,
        mt: 0,
        flexDirection: "column",
        display: "flex",
        gap: 5,
      }}
    >
      <Typography sx={{ color: "green", fontSize: 24 }}>
        Thank you for your inquiry! Your message has been sent successfully.
        We'll be in touch with you shortly.
      </Typography>

      <Button
        onClick={onClose}
        sx={{ alignSelf: "center" }}
        variant="contained"
      >
        close
      </Button>
    </Box>
  );
}
