import { useEffect, useMemo } from "react";
import {
  Stack,
  TextField,
  Chip,
  Autocomplete,
  Typography,
  Checkbox,
  ListItemText,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { useQuery, gql, useApolloClient } from "@apollo/client";
import filterOutSameItemInArray from "../../../../../lib/filterOutSameItemInArray";
import { mailEntityRelationByEntityRelation } from "./query";
import onCreateMailRelation from "../../../entities/onCreateMailRelation";

const entitiyUserRelationByEntityID = /* GraphQL */ `
  query EntitiyUserRelationByEntityID(
    $entityID: ID!
    $definerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityUserRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entitiyUserRelationByEntityID(
      entityID: $entityID
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        title
        shownFn
        shownLn
        shownMn
        statusCode
        isEmailVerified
        userID
        user {
          id
          fn
          ln
          mn
          name @client
          email
          sub
        }
        entityID
        definerID
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
const relationsByEntity1 = /* GraphQL */ `
  query RelationsByEntity1(
    $entity1ID: ID!
    $entity2ID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityRelationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationsByEntity1(
      entity1ID: $entity1ID
      entity2ID: $entity2ID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        relation
        definerID
        entity1ID
        entity2ID
        alias
        customerCode
        vendorCode
        statusCode
        type
        paymentTerms
        incoTerms {
          terms
          condition
          entityUserRelationID
        }

        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

const relationsByEntity1AndRelatioin = /* GraphQL */ `
  query RelationsByEntity1AndRelatioin(
    $entity1ID: ID!
    $relation: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityRelationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationsByEntity1AndRelatioin(
      entity1ID: $entity1ID
      relation: $relation
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        relation
        definerID
        entity1ID
        entity2ID
        entity2 {
          id
          alias
          name
          # entityUserRelations(filter: { role: { eq: "main_contact" } }) {
          #   items {
          #     id
          #     role
          #     user {
          #       id
          #       name @client
          #       fn
          #       ln
          #       mn
          #       email
          #       sub
          #       phoneNumber
          #       address
          #     }
          #   }
          # }
        }
        alias
        customerCode
        vendorCode
        statusCode
        type
        paymentTerms

        __typename
      }
      nextToken
      __typename
    }
  }
`;

const relationsByEntity2 = /* GraphQL */ `
  query RelationsByEntity2(
    $entity2ID: ID!
    $relation: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityRelationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationsByEntity2(
      entity2ID: $entity2ID
      relation: $relation
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        relation
        definerID
        entity1ID
        entity1 {
          id
          name
          alias
          abbr @client
          entityUserRelations(filter: { role: { eq: "buyer" } }) {
            items {
              id
              user {
                id
                name @client
                fn
                ln
                mn
                email
              }
            }
          }
        }
        entity2ID
        alias
        customerCode
        vendorCode
        logisticsAccountNumber
        logisticsTrackingTemplate
        statusCode
        type
        paymentTerms
        serviceFeeRate
        minCharge
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export default function Step2SelectEntity({
  handleChange,
  setFieldValue,
  handleBlur,
  values,
  setErrors,
}) {
  const { POItem, step } = values;

  useEffect(() => {
    if (step !== 2) {
      setFieldValue("step", 2);
    }
  }, [setFieldValue, step]);

  useEffect(() => {
    [...POItem].forEach((x) => {
      if (x.emailDelivery === undefined) {
        x.emailDelivery = true;
      }
      setFieldValue("POItem", POItem);
    });
  }, [POItem, step, setFieldValue]);

  const parts = useMemo(
    () =>
      filterOutSameItemInArray(
        POItem.map((x) => ({ ...x, materialID: x.material?.id })),
        "materialID"
      ),
    // filterOutSameItemInArray(POItem.map((x) => x.material)?.flat(), "id"),
    [POItem]
  );

  const vendors = parts
    .map((x) => ({
      ...x.vendor,
      sales: x.sales,
      customer: x.customer,
      buyer: x.buyer,
      incoTerms: x.incoTerms,
      paymentTerms: x.paymentTerms,
      forwarder: x.forwarder,
      forwarderContact: x.forwarderContact,
      paymentAgent: x.paymentAgent,
      vendorCode: x.vendorCode,
      emailDelivery: x.emailDelivery,
      forwarderOptions: x.forwarderOptions,
      incoTermOptions: x.incoTermOptions,
      paymentTermOptions: x.paymentTermOptions,
      buyerOptions: x.buyerOptions,
      emailRecipients: x.emailRecipients,
    }))
    .flat();

  const vendorOptions = filterOutSameItemInArray(vendors, "id");

  // useEffect(() => {
  //   if (step !== 2) {
  //     setFieldValue("step", 2);
  //   }
  // }, [setFieldValue, step]);

  return (
    <Stack>
      <Stack spacing={5}>
        {vendorOptions?.map((item) => {
          return (
            <ItemByVendor
              key={item.id}
              item={item}
              values={values}
              parts={parts}
              setFieldValue={setFieldValue}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}

const ItemByVendor = ({ item, setFieldValue, values, parts }) => {
  const client = useApolloClient();

  const {
    forwarderOptions,
    incoTermOptions,
    paymentTermOptions,
    vendorCode,
    emailDelivery,
    buyerOptions,
    emailRecipients,
    sales,
  } = item || {};


  const partsByVendor = parts.filter((x) => x.vendor?.id === item.id);

  // // // console.log({ item, parts, partsByVendor });

  const { POItem, definerID } = values;

  // GET SALES OPTIONS
  const { data: entitiyUserRelationByEntityIDData } = useQuery(
    gql(entitiyUserRelationByEntityID),
    {
      variables: {
        entityID: item.id,
        definerID: { eq: definerID },
        filter: { role: { eq: "sales" } },
      },
    }
  );
  const salesOptions = useMemo(
    () =>
      entitiyUserRelationByEntityIDData?.entitiyUserRelationByEntityID?.items?.map(
        (x) => ({
          ...x.user,
          entityID: x.entityID,
        })
      ),
    [entitiyUserRelationByEntityIDData?.entitiyUserRelationByEntityID?.items]
  );

  // GET CUSTOMER OPTIONS FOR VENDOR

  const { data: relationsByEntity2Data } = useQuery(gql(relationsByEntity2), {
    variables: {
      entity2ID: item?.id,
      relation: { eq: "CUSTOMER-SUPPLIER" },
      filter: {
        definerID: { eq: definerID },
      },
      skip: !item?.id,
    },
  });
  const customerOptions = filterOutSameItemInArray(
    relationsByEntity2Data?.relationsByEntity2?.items?.map((x) => x.entity1),
    "id"
  );

  // GET BUYER OPTIONS FOR CUSTOMER

  // const buyerOptions = item.customer?.entityUserRelations?.items?.map(
  //   (x) => x.user
  // );

  //GET FORWARDER OPTIONS FOR CUSTOMER
  // const { data: relationsByEntity1Forwarder } = useQuery(
  //   gql(relationsByEntity1AndRelatioin),
  //   {
  //     variables: {
  //       entity1ID: item.customer?.id,
  //       relation: { eq: "PRINCIPAL-FORWARDER" },
  //     },
  //     skip: !item.customer?.id,
  //   }
  // );
  // const forwarderOptions =
  //   relationsByEntity1Forwarder?.relationsByEntity1AndRelatioin?.items?.map(
  //     (x) => x.entity2
  //   );

  // GET PAYMENT TERMS AND INCO TERMS OPTIONS

  // const { data: relationsByEntity1Data } = useQuery(gql(relationsByEntity1), {
  //   variables: {
  //     entity1ID: item.customer?.id,
  //     entity2ID: { eq: item.id },
  //     filter: { relation: { eq: "CUSTOMER-SUPPLIER" } },
  //   },
  //   skip: !item.customer?.id || !item.id,
  // });

  // const entityRelation = relationsByEntity1Data?.relationsByEntity1?.items?.[0];

  // const {
  //   incoTerms: incoTermOptions,
  //   paymentTerms: paymentTermOptions,
  //   vendorCode,
  // } = entityRelation || {};

  // console.log({ customer: item.customer, entity2ID: item });

  // useEffect(() => {
  //   if (item.customer && entityRelation && !item.vendorCode) {
  //     const {
  //       incoTerms: incoTermOptions,
  //       paymentTerms: paymentTermOptions,
  //       vendorCode,
  //     } = entityRelation || {};

  //     setFieldValue(
  //       "POItem",
  //       POItem.map((x) => {
  //         return {
  //           ...x,
  //           // incoTerms:
  //           //   x.vendor?.id === item.id
  //           //     ? incoTermOptions?.length === 1
  //           //       ? incoTermOptions[0]
  //           //       : null
  //           //     : x.incoTerms,
  //           // paymentTerms:
  //           //   x.vendor?.id === item.id
  //           //     ? paymentTermOptions?.length === 1
  //           //       ? paymentTermOptions[0]
  //           //       : null
  //           //     : x.paymentTerms,
  //           vendorCode: x.vendor?.id === item.id ? vendorCode : x.vendorCode,
  //           // buyer:
  //           //   x.vendor?.id === item.id && buyerOptions?.length === 1
  //           //     ? buyerOptions[0]
  //           //     : x.buyer,
  //         };
  //       })
  //     );
  //   }
  // }, [entityRelation, POItem, item, buyerOptions, setFieldValue]);

  useEffect(() => {
    if (salesOptions?.length === 1 && !item?.sales) {
      setFieldValue(
        "POItem",
        POItem.map((x) => {
          // const material = x.material.map((y) => ({
          //   ...y,
          //   sales: y.vendor?.id === item.id ? salesOptions[0] : y.sales,
          // }));

          return {
            ...x,
            sales: x.vendor?.id === item.id ? salesOptions[0] : x.sales,
          };
        })
      );
    }
  }, [salesOptions, POItem, item.id, item.sales, setFieldValue]);

  // useEffect(() => {
  //   async function func() {

  //     console.log({ emailDelivery, item, POItem })
  //     if (emailDelivery === undefined) {
  //       // const {data: relationsByEntity1Data} = await client.query({})

  //       setFieldValue(
  //         "POItem",
  //         POItem.map((x) => {
  //           return {
  //             ...x,
  //             emailDelivery: x.vendor?.id === item.id ? true : false,
  //           };
  //         })
  //       );
  //     }
  //   }

  //   func();
  // }, [salesOptions, POItem, emailDelivery, item.id, item.sales, setFieldValue]);

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <Typography variant="h6">{item.name}</Typography>
        <Typography variant="body2">Vendor Code: {vendorCode}</Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!emailDelivery}
                onChange={(e) => {
                  setFieldValue(
                    "POItem",
                    POItem.map((x) => {
                      // const material = x.material.map((y) => ({
                      //   ...y,
                      //   emailDelivery:
                      //     y.vendor?.id === item.id
                      //       ? e.target.checked
                      //       : y.emailDelivery,
                      // }));

                      return {
                        ...x,
                        emailDelivery:
                          x.vendor?.id === item.id
                            ? e.target.checked
                            : x.emailDelivery,
                      };
                    })
                  );
                }}
              />
            }
            label="email delivery?"
          />
        </FormGroup>
        {emailDelivery &&
          emailRecipients?.map((x) => (
            <Typography key={x.name} variant="body2">
              {x.name} {x.email}
            </Typography>
          ))}
      </Stack>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Stack direction="row" spacing={1}>
          {partsByVendor?.map((x) => (
            <Chip
              key={x.material.id}
              siza="small"
              label={`${x.material?.mfr} ${x.material?.mpn}`}
              color="primary"
              variant="contained"
            />
          ))}
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Autocomplete
          sx={{ width: 500 }}
          options={salesOptions || []}
          // multiple
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.name}
          // disableCloseOnSelect
          value={item?.sales || null}
          onChange={(e, v) => {
            setFieldValue(
              "POItem",
              POItem.map((x) => {
                // const material = x.material.map((y) => ({
                //   ...y,
                //   sales: y.vendor?.id === item.id ? v : y.sales,
                // }));

                return { ...x, sales: x.vendor?.id === item.id ? v : x.sales };
              })
            );
          }}
          // getOptionDisabled={(option) => {
          //   return option.contacts.length === 0;
          // }}
          renderOption={(props, option, { selected }) => {
            return (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                <ListItemText
                  primary={option?.name}
                  // secondary={option?.entity1?.alias || option?.entity1?.name}
                />
              </li>
            );
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                autoComplete="off"
                label="Please select sales"
                required
                InputProps={{
                  ...params.InputProps,
                  // type: "search",
                }}
              />
            );
          }}
        />
        <Autocomplete
          sx={{ width: 500 }}
          options={incoTermOptions || []}
          isOptionEqualToValue={(option, value) =>
            option?.terms === value?.terms
          }
          getOptionLabel={(option) => option?.terms || ""}
          // disableCloseOnSelect
          value={item?.incoTerms || null}
          onChange={(e, v) => {
            setFieldValue(
              "POItem",
              POItem.map((x) => {
                // const material = x.material.map((y) => ({
                //   ...y,
                //   incoTerms: y.vendor?.id === item.id ? v : y.incoTerms,
                // }));

                return {
                  ...x,
                  incoTerms: x.vendor?.id === item.id ? v : x.incoTerms,
                };
              })
            );
          }}
          // getOptionDisabled={(option) => {
          //   return option.contacts.length === 0;
          // }}
          renderOption={(props, option, { selected }) => {
            return (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                <ListItemText
                  primary={option?.terms}
                  // secondary={option?.entity1?.alias || option?.entity1?.name}
                />
              </li>
            );
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                autoComplete="off"
                label="Please select inco terms"
                required
                InputProps={{
                  ...params.InputProps,
                  // type: "search",
                }}
              />
            );
          }}
        />

        <FormControl>
          <InputLabel id="PaymentTerms-select-label">PaymentTerms</InputLabel>
          <Select
            // disabled={!item.customer?.id}

            sx={{ width: 500 }}
            labelId="PaymentTerms-select-label"
            id="PaymentTerms-select"
            value={item.paymentTerms || ""}
            label="PaymentTerms"
            onChange={(e) => {
              setFieldValue(
                "POItem",
                POItem.map((x) => {
                  // const material = x.material.map((y) => ({
                  //   ...y,
                  //   paymentTerms:
                  //     y.vendor?.id === item.id
                  //       ? e.target.value
                  //       : y.paymentTerms,
                  // }));

                  return {
                    ...x,
                    paymentTerms:
                      x.vendor?.id === item.id
                        ? e.target.value
                        : x.paymentTerms,
                  };
                })
              );
            }}
          >
            {paymentTermOptions?.map((x) => (
              <MenuItem key={x} value={x}>
                {x}
                {/* {paymentTerms.find((y) => y.value === x).label} */}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>

      <Stack direction="row" spacing={2}>
        <Autocomplete
          // disabled={!sales}
          sx={{ width: 500 }}
          options={customerOptions || []}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.alias || option.name}
          // disableCloseOnSelect
          value={item?.customer || null}
          onChange={async (e, v) => {
            console.log({ v });

            if (!v) {
              setFieldValue(
                "POItem",
                POItem.map((x) => {
                  return {
                    ...x,
                    customer: null,
                  };
                })
              );
              return;
            }

            const buyerOptions = v?.entityUserRelations?.items?.map(
              (x) => x.user
            );

            const { data: relationsByEntity1Forwarder } = await client.query({
              query: gql(relationsByEntity1AndRelatioin),
              variables: {
                entity1ID: v?.id,
                relation: { eq: "PRINCIPAL-FORWARDER" },
              },
              skip: !v,
            });

            const forwarderOptions =
              relationsByEntity1Forwarder?.relationsByEntity1AndRelatioin?.items?.map(
                (x) => x.entity2
              );

            const { data: relationsByEntity1Data } = await client.query({
              query: gql(relationsByEntity1),
              variables: {
                entity1ID: v?.id,
                entity2ID: { eq: item.id },
                filter: { relation: { eq: "CUSTOMER-SUPPLIER" } },
              },
              skip: !item.id || !v,
            });

            const entityRelation =
              relationsByEntity1Data?.relationsByEntity1?.items?.[0];

            const {
              incoTerms: incoTermOptions,
              paymentTerms: paymentTermOptions,
              vendorCode,
              id,
            } = entityRelation || {};

            let reecipients;
            if (id) {
              const { data: mailEntityRelationByEntityRelationData } =
                await client.query({
                  query: gql(mailEntityRelationByEntityRelation),
                  variables: {
                    entityRelationID: id,
                    filter: { type: { eq: "SEND_PO" } },
                  },
                  skip: !id,
                });

              reecipients =
                mailEntityRelationByEntityRelationData?.mailEntityRelationByEntityRelation?.items?.map(
                  (x) => ({ name: x.user?.name, email: x.user?.email })
                );

              // console.log({ reecipients })
              // console.log({ sales })

              if (!reecipients.find((x) => x.email === sales?.email)) {
                await onCreateMailRelation({
                  client,
                  values: {
                    userID: sales?.id,
                    entityRelationID: id,
                    type: "SEND_PO",
                  },
                });
                reecipients.push({ email: sales?.email, name: sales?.name });
              }
            }

            setFieldValue(
              "POItem",
              POItem.map((x) => {
                // const material = x.material.map((y) => ({
                //   ...y,
                //   customer: y.vendor?.id === item.id ? v : y.customer,
                // }));

                return {
                  ...x,
                  incoTermOptions,
                  paymentTermOptions,
                  vendorCode,
                  customer: x.vendor?.id === item?.id ? v : x.customer,
                  forwarderOptions,
                  incoTerms:
                    incoTermOptions?.length === 1 ? incoTermOptions[0] : null,
                  paymentTerms:
                    paymentTermOptions?.length === 1
                      ? paymentTermOptions[0]
                      : null,
                  buyer: buyerOptions?.length === 1 ? buyerOptions[0] : null,
                  buyerOptions,
                  emailRecipients:
                    x.vendor?.id === item?.id ? reecipients : x?.emailRecipients,
                };
              })
            );
          }}
          // getOptionDisabled={(option) => {
          //   return option.contacts.length === 0;
          // }}
          renderOption={(props, option, { selected }) => {
            return (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                <ListItemText
                  primary={option?.alias || option?.name}
                  // secondary={option?.entity1?.alias || option?.entity1?.name}
                />
              </li>
            );
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                autoComplete="off"
                label="Please select entity"
                required
                InputProps={{
                  ...params.InputProps,
                  // type: "search",
                }}
              />
            );
          }}
        />
        <Autocomplete
          sx={{ width: 500 }}
          options={forwarderOptions || []}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.alias || option.name}
          // disableCloseOnSelect
          value={item?.forwarder || null}
          onChange={(e, v) => {
            setFieldValue(
              "POItem",
              POItem.map((x) => {
                // const material = x.material.map((y) => ({
                //   ...y,
                //   forwarder: y.vendor?.id === item.id ? v : y.forwarder,
                // }));

                return {
                  ...x,
                  forwarder: x.vendor?.id === item.id ? v : x.forwarder,
                };
              })
            );
          }}
          // getOptionDisabled={(option) => {
          //   return option.contacts.length === 0;
          // }}
          renderOption={(props, option, { selected }) => {
            return (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                <ListItemText
                  primary={option?.alias || option?.name}
                  // secondary={option?.entity1?.alias || option?.entity1?.name}
                />
              </li>
            );
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                autoComplete="off"
                label="Please select forwarder"
                // required
                InputProps={{
                  ...params.InputProps,
                  // type: "search",
                }}
              />
            );
          }}
        />
        <Autocomplete
          disabled={!item.customer?.id}
          sx={{ width: 500 }}
          options={buyerOptions || []}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.name}
          // disableCloseOnSelect
          value={item?.buyer || null}
          onChange={(e, v) => {
            setFieldValue(
              "POItem",
              POItem.map((x) => {
                // const material = x.material.map((y) => ({
                //   ...y,
                //   buyer: y.vendor?.id === item.id ? v : y.buyer,
                // }));

                return { ...x, buyer: x.vendor?.id === item.id ? v : x.buyer };
              })
            );
          }}
          // getOptionDisabled={(option) => {
          //   return option.contacts.length === 0;
          // }}
          renderOption={(props, option, { selected }) => {
            return (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                <ListItemText
                  primary={option?.alias || option?.name}
                  // secondary={option?.entity1?.alias || option?.entity1?.name}
                />
              </li>
            );
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                autoComplete="off"
                label="Please select buyer"
                required
                InputProps={{
                  ...params.InputProps,
                  // type: "search",
                }}
              />
            );
          }}
        />
      </Stack>
    </Stack>
  );
};
