import React from "react";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Stack, Button } from "@mui/material";
import CreateForm from "./components/CreateForm";
import FileZone from "../../../component/FileZone";
import Item from "./components/Item";
import DataGridComp from "../../../component/DataGrid";
import Notes from "../../../component/Notes";
import Updates from "../../../component/Updates";
import getFilteredItemsByUserRole from "../../../function/getFilteredItemsByUserRole";
import { offerStatus } from "../../../settings/formFieldEnum";
import { getOffer, offerByDefiner } from "./query";
import onDelete from "./onDelete";
import { userEntityRelationVar } from "../../../client/cache";
import SendQuoteToCTM from "./components/SendQuoteToCtm";

function ItemView({ lineData: { id }, onClose }) {
  const { entityID } = useReactiveVar(userEntityRelationVar);

  let { data } = useQuery(gql(getOffer), { variables: { id } });

  data = data?.getOffer;

  if (!data) return null;

  data = { ...data, definerID: entityID };

  const storagePath = `${entityID}/pricing/${data?.number}`;

  return (
    <Stack
      justifyContent="space-between"
      sx={{ width: 1500, height: 900, padding: 2 }}
    >
      <Stack spacing={5}>
        <Item data={data} onClose={onClose} />
        <SendQuoteToCTM number={data?.number} definerID={entityID} />
        <FileZone storagePath={storagePath} />
        <Notes itemID={id} />
        <Updates itemID={id} />
      </Stack>

      <Button
        variant="contained"
        sx={{ alignSelf: "center", justifySelf: "flex-end" }}
        onClick={onClose}
      >
        back
      </Button>
    </Stack>
  );
}

function Comp({ entityType, userRole, isAuthenticated, entityID }) {
  const isAdmin = userRole?.includes("admin");

  const userEntityRelation = useReactiveVar(userEntityRelationVar);
  const { subsidiaryIDs } = userEntityRelation;

  const IDs = [entityID, ...subsidiaryIDs];

  const queryVariables = {
    definerID: entityID,
    limit: 5000,
    filter: {
      or: IDs.map((id) => ({
        vendorID: { eq: id },
      })),
    },
  };

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 140,
      userRole: ["admin", "sales"],
    },
    {
      field: "number",
      headerName: "Number",
      width: 140,
      userRole: ["admin", "sales"],
    },
    {
      field: "itemNumber",
      headerName: "Item",
      width: 140,
      userRole: ["admin", "sales"],
    },
    {
      field: "customer",
      headerName: "Customer",
      width: 180,
      userRole: ["admin", "sales"],
      // renderCell: ({ row, ...props }) =>
      //   row?.customer?.alias || row?.customer?.name,
    },
    {
      field: "buyer",
      headerName: "Buyer",
      width: 180,
      userRole: ["admin"],
      // renderCell: ({ row, ...props }) => row?.buyer?.name,
    },
    {
      field: "vendor",
      headerName: "Vendor",
      width: 180,
      userRole: ["admin"],
      // renderCell: ({ row, ...props }) =>
      //   row?.vendor?.alias || row?.vendor?.name,
    },
    {
      field: "sales",
      headerName: "Sales",
      width: 180,
      userRole: ["admin"],
      // renderCell: ({ row, ...props }) => row?.sales?.name,
    },
    {
      field: "code",
      headerName: "Code",
      width: 180,
      userRole: ["admin", "sales"],
      // renderCell: ({ row, ...props }) => row?.materialEntityRelation?.code,
    },
    {
      field: "mfr",
      headerName: "MFR",
      width: 180,
      userRole: ["admin", "sales"],
      // renderCell: ({ row, ...props }) => row?.material?.mfr,
    },
    {
      field: "mpn",
      headerName: "MPN",
      width: 180,
      userRole: ["admin", "sales"],
      // renderCell: ({ row, ...props }) => row?.material?.mpn,
    },
    {
      field: "price",
      headerName: "Price",
      width: 100,
      userRole: ["admin", "sales"],
    },
    {
      field: "currency",
      headerName: "Currency",
      width: 100,
      userRole: ["admin", "sales"],
    },

    {
      field: "leadtime",
      headerName: "LT",
      width: 120,
      userRole: ["admin", "sales"],
    },

    {
      field: "status",
      headerName: "Status",
      width: 200,
      userRole: ["admin", "sales"],
      //   renderCell: ({ row, ...props }) =>
      //     offerStatus.find((x) => x.code === row.statusCode)?.status,
    },
    {
      field: "incoTerms",
      headerName: "IncoTerms",
      width: 200,
      userRole: ["admin", "sales"],
      // renderCell: ({ row, ...props }) => row.incoTerms?.terms,
    },
    {
      field: "paymentTerms",
      headerName: "PaymentTerms",
      width: 200,
      userRole: ["admin", "sales"],
    },
    {
      field: "note",
      headerName: "Note",
      width: 400,
      userRole: ["admin", "sales"],
    },
  ];
  const dropdownMenu = [
    {
      label: "Delete",
      userRole: ["admin"],
      onClick: async ({ client, selectionModel, setSelectionModel }) => {
        await onDelete({
          selectionModel,
          setSelectionModel,
          client,
        });
      },
    },
  ];

  const filteredColumns = getFilteredItemsByUserRole(columns, userRole);

  const filteredDropdownMenu = getFilteredItemsByUserRole(
    dropdownMenu,
    userRole
  );

  const prepareItemsFunc = (items ) => {
    return items.map((x) => ({
      ...x,
      incoTerms: x.incoTerms?.terms,
      customer: x.customer?.alias || x.customer?.name,
      vendor: x.vendor?.alias || x.vendor?.name,
      buyer: x.buyer?.name,
      mfr: x.material?.mfr,
      mpn: x.material?.mpn,
      code: x.materialEntityRelation?.code,
      sales: x.sales?.name,
      status: offerStatus.find((y) => y.code === x.statusCode)?.status,
    }));
  };

  const sortBy = (arr) => {
    arr.sort((a, b) => (a.itemNumber > b.itemNumber ? 1 : -1));
    arr.sort((a, b) => (a.number > b.number ? -1 : 1));

    return arr;
  };
  const filterAttrs = [
    { label: "buyer", value: "buyer", type: "date" },
    {
      label: "code",
      value: "code",
      type: "string",
    },
    { label: "mfr", value: "mfr", type: "string" },
    { label: "mpn", value: "mpn", type: "string" },

    { label: "customer", value: "customer", type: "string" },
  ];

  return (
    <DataGridComp
      query={offerByDefiner}
      queryVariables={queryVariables}
      sortBy={sortBy}
      onClickAddRoute={isAdmin ? "new" : null}
      columns={filteredColumns}
      DetailsDialog={isAdmin ? ItemView : null}
      userRole={userRole}
      checkboxSelection={isAdmin ? true : null}
      getDropdownMenu={filteredDropdownMenu}
      prepareItemsFunc={prepareItemsFunc}
      filterAttrs={filterAttrs}
    />
  );
}

const quotation = {
  path: "pricing",

  sideNav: {
    group: 3,
    order: 20,
    label: "Pricing",
    entityType: ["200", "300"],
    allowedRole: ["admin", "sales"],
    auth: ["authenticated"],
  },
  route: {
    index: {
      element: null,
      Comp,
      allowedRole: "all",
      entityType: "all",
      auth: ["authenticated"],
    },
    new: {
      element: <CreateForm />,
      allowedRole: ["admin", "sales"],
      entityType: "all",
      auth: ["authenticated"],
    },
  },
};

export default quotation;
