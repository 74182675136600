import { useFormikContext, Field, useField } from "formik";
import { TextField, Stack, Box } from "@mui/material";
import FieldErrorMessage from "./FieldErrorMessage";

export default function FormikField({
  label,
  id,
  name,
  width,
  type,
  required,
  validate,
  disabled,
  InputProps,
  size,
  disableUnderline,
  onChange,
  onBlur,
  checkError,
  select,
  children,
  endAdornment,
}) {
  let fontSize = 14;

  switch (size) {
    case "sm":
      fontSize = 10;
      break;
    case "lg":
      fontSize = 20;
      break;

    default:
      break;
  }

  const [field, meta, helpers] = useField({ name, type });

  const { setValue, setTouched, setError } = helpers;

  let value = field.value;

  let defaultWidth = 300;
  if (type === "date") {
    defaultWidth = 140;
  }

  // if (typeof value === "object" && Object.keys(value)?.length === 0)
  //   value = '';
  // console.log("999999999999999999999");
  // console.log({ value, meta, field, name });

  return (
    <Stack spacing={1}>
      <Field
        id={id}
        children={children}
        select={select}
        sx={{ width: width || defaultWidth }}
        required={required}
        disabled={disabled}
        component={TextField}
        type={type}
        label={label}
        validate={validate}
        name={name}
        InputLabelProps={{ ...(type === "date" && { shrink: true }) }}
        InputProps={{
          ...InputProps,
          endAdornment,
          style: { fontSize },
          disableUnderline,
        }}
        variant="standard"
        onChange={(e) => {
          setValue(e.target.value);
          onChange && onChange(e.target.value);
        }}
        // onBlur={handleBlur}

        onBlur={async (e) => {
          //ATTENTION  setting the second argument of the setTouched to false not to trigger validation.
          // see here: https://github.com/jaredpalmer/formik/issues/374

          setTouched(true, false);
          checkError && checkError(setError, e.target.value);
          onBlur && onBlur(e.target.value);
        }}
        value={value !== undefined && value !== null ? value : ""}
      />
      <Box sx={{ height: "5px" }}>
        <FieldErrorMessage name={name} meta={meta} />
      </Box>
    </Stack>
  );
}
