export default function getFilteredItemsByUserRole(items, role) {
  if (!items) return null

  const filetered = items.filter(
    (x) => x.userRole === 'all' || x.userRole?.some((y) => role?.includes(y)),
  )

  // console.log({ items, filetered, role })

  return filetered
}
