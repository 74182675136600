import { useCallback } from "react";
import { useApolloClient } from "@apollo/client";
import { Hub } from "aws-amplify";
import { Stack, Typography, Button } from "@mui/material";
import onDeleteRelation from "../../onDeleteRelation";
import AddOrEditRelationship from "./AddOrEditRelationship";
import MailRelation from "./MailRelation";

export default function Relation({ data, onClose }) {
  const { id, definerID, incoTerms, paymentTerms } = data || {};

  const onAddBtnClick = useCallback(() => {
    Hub.dispatch("dialogMonitor", {
      event: "showDetails",
      data: {
        Component: AddOrEditRelationship,
        lineData: {
          id,
          definerID,
          entityID: id,
          incoTermsOptions: incoTerms,
          paymentTermsOptions: paymentTerms,
          editType: "create",
        },
      },
    });
  }, [id, definerID, incoTerms, paymentTerms]);

  const entities = [
    ...(data?.relationsAsEntity1?.items || []),
    ...(data?.relationsAsEntity2?.items || []),
  ];

  entities.sort((a, b) => (a.entity2.abbr > b.entity2.abbr ? 1 : -1));

  // console.log({ entities });

  // debugger;

  return (
    <Stack
      spacing={2}
      justifyContent="space-between"
      sx={{ minHeight: 120 }}
      bgcolor="#f1f1f1"
      borderRadius={1}
      padding={2}
    >
      <Stack spacing={3}>
        <Typography variant="h6" sx={{ alignSelf: "center" }}>
          Entity Relations
        </Typography>

        {entities.map((item) => (
          <RelationLine
            key={item.id}
            lineData={{
              ...item,
              definerID,
              entityID: id,
              incoTermsOptions: incoTerms,
              paymentTermsOptions: paymentTerms,
            }}
            onClose={onClose}
          />
        ))}
      </Stack>

      <Button
        variant="contained"
        sx={{ alignSelf: "center" }}
        onClick={onAddBtnClick}
      >
        add
      </Button>
    </Stack>
  );
}

const RelationLine = ({ lineData, onClose }) => {
  const {
    id,
    entity1,
    entity2,
    relation,
    paymentTerms,
    incoTerms,
    vendorCode,
    definerID,
    serviceFeeRate,
    minCharge,
    logisticsAccountNumber,
    logisticsTrackingTemplate,
    type,
  } = lineData;
  const client = useApolloClient();

  // console.log({ lineData })

  if (incoTerms?.terms === "DAP HONGKONG") {
    console.log({ incoTerms });
  }

  function handleDelete() {
    onDeleteRelation({ values: { id: lineData?.id }, client });
  }

  const handleEdit = useCallback(() => {
    Hub.dispatch("dialogMonitor", {
      event: "showDetails",
      data: {
        Component: AddOrEditRelationship,
        lineData: {
          ...lineData,
          editType: "edit",
        },
      },
    });
  }, [lineData]);

  return (
    <Stack direction="row" spacing={2}>
      <Stack spacing={1}>
        <Stack direction="row" bgcolor="purple" color="#fff" spacing={1} p={1}>
          <Typography width={200}>{relation.split("-")[0]} </Typography>
          <Typography width={200}>{relation.split("-")[1]} </Typography>
        </Stack>
        <Stack direction="row" spacing={1} p={1}>
          <Typography width={200}>{entity1?.abbr} </Typography>
          <Typography width={200}>{entity2?.abbr} </Typography>
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          // bgcolor="red"
          sx={{ width: 900 }}
          // width="100%"
        >
          {relation === "CUSTOMER-SUPPLIER" && (
            <Stack direction="row" spacing={2}>
              <Typography width={200}> Vendor Code: {vendorCode}</Typography>
              <Typography width={300}>
                Payment Terms: {paymentTerms?.join(", ")}
              </Typography>
              <Typography width={240}>
                Inco Terms: {incoTerms?.map((x) => x.terms)?.join(", ")}
              </Typography>
            </Stack>
          )}
          {relation === "PRINCIPAL-FORWARDER" && (
            <Stack spacing={1}>
              <Stack direction="row" spacing={2}>
                <Typography width={200}> Vendor Code: {vendorCode}</Typography>
                <Typography width={300}>
                  Payment Terms: {paymentTerms?.join(", ")}
                </Typography>
                <Typography width={240}>
                  Inco Terms: {incoTerms?.map((x) => x.terms)?.join(", ")}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography width={200}>
                  Service Fee Rate: {serviceFeeRate}
                </Typography>
                <Typography width={300}>Min Charge: {minCharge}</Typography>
              </Stack>
            </Stack>
          )}
          {relation === "PRINCIPAL-CONTRACTOR" && (
            <>
              <Typography>type: {type}</Typography>
              {(logisticsAccountNumber || logisticsTrackingTemplate) && (
                <Stack spacing={2}>
                  <Typography>
                    logisticsAccountNumber: {logisticsAccountNumber}
                  </Typography>
                  <Typography>
                    logisticsTrackingTemplate:
                    {logisticsTrackingTemplate}
                  </Typography>
                </Stack>
              )}
              {serviceFeeRate && (
                <Stack spacing={2}>
                  <Typography>Rate: {serviceFeeRate}</Typography>
                </Stack>
              )}
            </>
          )}

          <Stack direction="row" spacing={2} width={120}>
            <Button onClick={handleEdit}>edit</Button>
            <Button onClick={handleDelete}>delete</Button>
          </Stack>
        </Stack>
        <MailRelation relationID={id} definerID={definerID} />
      </Stack>
    </Stack>
  );
};
