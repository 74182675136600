import { gql } from "@apollo/client";
import { receivingAndShippingByMaterialAndTime } from "../query";

export default async function haneleRunCheck({
  materialID,
  inventoryCheckTimeStamp,
  inventoryCheckQuantity,
  materialInv,
  client,
  definerID
}) {
  const { data: receivingAndShippingByMaterialAndTimeData } =
    await client.query({
      query: gql(receivingAndShippingByMaterialAndTime),
      variables: {
        materialID,
        // timeStamp: { gt: inventoryCheckTimeStamp },
      },
    });

  const receivingAndShippings =
    receivingAndShippingByMaterialAndTimeData?.receivingAndShippingByMaterialAndTime?.items?.filter(
      (x) => x.timeStamp > inventoryCheckTimeStamp
    );
  const shippings = receivingAndShippings?.filter((x) => x.type === "OUTGOING");

  const receivings = receivingAndShippings?.filter(
    (x) => x.type === "INCOMING"
  );

  const receivedQtySinceLastCheck = receivings?.reduce((a, v) => a + v.qty, 0);
  const shippedQtySinceLastCheck = shippings?.reduce((a, v) => a + v.qty, 0);
  const calculatedCurrentInventory =
    inventoryCheckQuantity +
    receivedQtySinceLastCheck -
    shippedQtySinceLastCheck;

    // console.log({
    //   calculatedCurrentInventory,
    //   materialInv,
    //   shippedQtySinceLastCheck,
    //   receivedQtySinceLastCheck,
    // });

  if (calculatedCurrentInventory !== materialInv) {
    return "mismatch";
  } else {
    return "match";
  }
}
