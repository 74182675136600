import { Box, Stack, Button } from "@mui/material";
import Tree from "./TreeView";

export default function ViewByMaterial({ setIsViewByMaterial }) {
  return (
    <Box width="100%" height="100%">
      <Stack direction="row" px={2} justifyContent="space-between">
        <p></p>
        <Button
          size="small"
          sx={{ height: "40px" }}
          variant="contained"
          onClick={() => {
            setIsViewByMaterial(false);
          }}
        >
          Table View
        </Button>
      </Stack>

      <Tree />
    </Box>
  );
}
