import { gql, useQuery, useApolloClient, useReactiveVar } from "@apollo/client";
import { Stack, Button, Typography } from "@mui/material";

import WarehouseIcon from "@mui/icons-material/Warehouse";
import {
  runInventoryCheckVar,
  userEntityRelationVar,
} from "../../../client/cache";
import DataGridComp from "../../../component/DataGrid";
import Notes from "../../../component/Notes";
import Updates from "../../../component/Updates";
import FileZone from "../../../component/FileZone";
import History from "./components/History";
import haneleRunCheck from "./functions/haneleRunCheck";

import getFilteredItemsByUserRole from "../../../function/getFilteredItemsByUserRole";
import {
  getInventoryHistory,
  InventoryByDefiner,
  offerByMaterial,
} from "./query";
import onDelete from "./onDelete";
import CreateForm from "./components/CreateForm";
import Item from "./components/Item";

// const FormArray = [
//   {
//     Item: MaterialItem,
//     name: "materials",
//   },
// ];

function ItemView({ lineData, onClose, userRole }) {
  const { entityID } = useReactiveVar(userEntityRelationVar);

  let { data } = useQuery(gql(getInventoryHistory), {
    variables: { id: lineData?.id },
  });

  data = data?.getInventoryHistory;

  // console.log({ data });

  if (!data) return null;

  const storagePath = `${entityID}/inventory/${lineData?.id}`;

  return (
    <Stack
      justifyContent="space-between"
      sx={{ minWidth: 800, minHeight: 400, padding: 2 }}
    >
      <Stack spacing={5}>
        <Item data={data} onClose={onClose} userRole={userRole} />
        <History data={data} />
        <FileZone storagePath={storagePath} />
        <Notes itemID={lineData?.id} />
        <Updates itemID={lineData?.id} />
      </Stack>

      <Button
        variant="contained"
        sx={{ alignSelf: "center", justifySelf: "flex-end" }}
        onClick={onClose}
      >
        back
      </Button>
    </Stack>
  );
}

function Comp({ entityType, userRole, isAuthenticated, entityID }) {
  const client = useApolloClient();
  const runInventoryCheck = useReactiveVar(runInventoryCheckVar);
  const { subsidiaryIDs } = useReactiveVar(userEntityRelationVar);

  // const navigate = useNavigate();

  const IDs = [entityID, ...subsidiaryIDs];

  // console.log({ IDs });

  const isAdmin = userRole?.includes("admin");

  const columns = [
    {
      field: "mfr",
      headerName: "mfr",
      width: 150,
      userRole: ["admin", "sales", "warehouse"],
    },
    {
      field: "mpn",
      headerName: "mpn",
      width: 280,
      userRole: ["admin", "sales", "warehouse"],
    },
    {
      field: "unit",
      headerName: "unit",
      width: 50,
      userRole: ["admin", "sales", "warehouse"],
    },

    {
      field: "warehouse",
      headerName: "warehouse",
      width: 100,
      userRole: ["admin", "sales", "warehouse"],
    },
    {
      field: "currentInv",
      headerName: "当前数量",
      width: 100,
      userRole: ["admin", "sales", "warehouse"],
    },

    {
      field: "creator",
      headerName: "creator",
      width: 100,
      userRole: ["admin", "sales", "warehouse"],
    },

    {
      field: "timeStamp",
      headerName: "timeStamp",
      width: 160,
      userRole: ["admin"],
    },

    {
      field: "date",
      headerName: "盘点日期",
      width: 100,
      userRole: ["admin", "sales", "warehouse"],
    },
    {
      field: "quantity",
      headerName: "盘点数量",
      width: 100,
      userRole: ["admin", "sales", "warehouse"],
    },
    {
      field: "price",
      headerName: "price",
      width: 120,
      userRole: ["admin"],
    },
    {
      field: "currency",
      headerName: "currency",
      width: 120,
      userRole: ["admin"],
    },
    {
      field: "value",
      headerName: "value",
      width: 120,
      userRole: ["admin"],
    },
    {
      field: "code",
      headerName: "code",
      width: 300,
      userRole: ["admin", "sales", "warehouse"],
    },
    ...(runInventoryCheck
      ? [
          {
            field: "alert",
            headerName: "Check Result",
            width: 100,
            userRole: ["admin", "sales", "warehouse"],

            renderCell: (params) => {
              return (
                <Typography
                  color={params.value === "mismatch" ? "red" : "green"}
                >
                  {params.value}
                </Typography>
              );
            },
          },
        ]
      : []),
  ];

  const dropdownMenu = null;

  const filteredColumns = getFilteredItemsByUserRole(columns, userRole);

  const filteredDropdownMenu = getFilteredItemsByUserRole(
    dropdownMenu,
    userRole
  );

  if (!entityID) return null;
  const queryVariables = { definerID: entityID };

  const topRightButtons = [
    {
      comp: ({ rows, setRows, client }) => (
        <Button
          sx={{ color: runInventoryCheck ? "red" : "primary" }}
          onClick={async () => {
            runInventoryCheckVar(!runInventoryCheck);
          }}
        >
          <WarehouseIcon /> Run Chedk
        </Button>
      ),
      userRole: ["admin"],
    },
  ];

  const filteredTopRightButtons = getFilteredItemsByUserRole(
    topRightButtons,
    userRole
  );

  const prepareItemsFunc = async (items ) => {
    return await Promise.all(
      items.map(async (x) => {
        // console.log({ xxxxx: x });

        let price;
        let currency;
        let value;
        let alert;

        if (runInventoryCheck) {
          alert = await haneleRunCheck({
            materialID: x.materialID,
            inventoryCheckTimeStamp: x.timeStamp,
            inventoryCheckQuantity: x.quantity,
            materialInv: x.material.inventory,
            client,
            definerID: entityID,
          });
        }

        if (isAdmin) {
          const { data: offerData } = await client.query({
            query: gql(offerByMaterial),
            variables: {
              materialID: x.materialID,
              filter: {
                and: {
                  statusCode: { ne: "900" },
                  or: IDs.map((x) => ({ vendorID: { eq: x } })),
                },
              },
            },
          });

          price = offerData?.offerByMaterial?.items?.[0]?.price;
          currency = offerData?.offerByMaterial?.items?.[0]?.currency;
          value = (price * x.quantity).toFixed(2) * 1;
        }

        return {
          ...x,
          mpn: x.material.mpn,
          currentInv: x.material.inventory,
          mfr: x.material.mfr,
          unit: x.material.unit,
          creator: x.creator?.name,
          code: [
            ...new Set(
              x.material?.materialEntityRelations?.items?.map((x) => x.code)
            ),
          ]?.join(", "),
          price,
          currency,
          value: isNaN(value) ? "" : value,
          alert,
        };
      })
    );
  };

  const sortBy = (arr) => {
    // console.log(333333)
    arr.sort((a, b) => (a.date > b.date ? -1 : 1));
    arr.sort((a, b) =>
      a.alert === "mismatch" && b.alert !== "mismatch" ? -1 : 1
    );
    // arr.sort((a, b) => (a.alert && !b.alert ? 1 : -1));

    return arr;
  };
  const filterAttrs = [
    { label: "mfr", value: "mfr", type: "string" },
    {
      label: "mpn",
      value: "mpn",
      type: "string",
    },
    { label: "gcas", value: "code", type: "string" },
    { label: "warehouse", value: "warehouse", type: "string" },
  ];

  return (
    <DataGridComp
      query={InventoryByDefiner}
      queryVariables={queryVariables}
      onDelete={onDelete}
      onClickAddRoute="new"
      DetailsDialog={ItemView}
      userRole={userRole}
      sortBy={sortBy}
      columns={filteredColumns}
      checkboxSelection
      getDropdownMenu={filteredDropdownMenu}
      prepareItemsFunc={prepareItemsFunc}
      ToolbarInsert={ToolbarInsert}
      topRightButtons={filteredTopRightButtons}
      filterAttrs={filterAttrs}
    />
  );
}

const inventory = {
  path: "inventory",

  sideNav: {
    group: 1,
    order: 60,
    label: "Inventory",
    entityType: "all",
    allowedRole: ["admin", "sales", "warehouse"],
    auth: ["authenticated"],
  },
  route: {
    index: {
      element: null,
      Comp,
      allowedRole: "all",
      entityType: "all",
      auth: ["authenticated"],
    },
    new: {
      element: <CreateForm />,
      allowedRole: ["admin", "sales", "warehouse"],
      entityType: "all",
      auth: ["authenticated"],
    },
  },
};

function ToolbarInsert({ selectionModel }) {
  return !selectionModel?.length ? null : (
    <Stack
      direction="row"
      spacing={2}
      color="secondary.main"
      sx={{ position: "relative", top: -7 }}
    >
      <Typography variant="title" sx={{ fontWeight: 500 }}>
        SELECTED VALUE:
      </Typography>
      <Typography variant="title" sx={{ fontWeight: 500 }}>
        {selectionModel.reduce((a, v) => a + (v?.value || 0), 0).toFixed(3) * 1}
      </Typography>
    </Stack>
  );
}

export default inventory;
