import { useState } from 'react'

import { LoadingButton } from '@mui/lab'
import { API } from 'aws-amplify'

export default function BraunPLBtn({ shippingItems, date, number }) {
  const [isLoading, setIsLoading] = useState(false)

  const meta = {
    date,
    shippingNumber: number,
    hknVendorCode: '20625219',
    vendorPhoneNumber: '021-50595180',
    customerName: '博朗(上海)有限公司',
    to: 'steven.lu@hankeno.com',
    vendorAlias: 'SHJH',
    vendorName: '上海汉肯诺电子科技有限公司',
    driver: '司威峰',
    driverPhoneNumber: '18717751966',
    cc: null,
    bcc: null,
    isPreview: true,
   
  }

  shippingItems.sort((a, b) => (a.itemNumber * 1 > b.itemNumber * 1 ? 1 : -1))

  async function onClick() {
    setIsLoading(true)

    try {
      const apidata = { meta, items: shippingItems }

      await API.post('iSupplyAPI', '/restricted', {
        body: {
          operation: 'SEND_BSL_SHIPPINGLIST',
          data: apidata,
        },
      })
    } catch (err) {
      console.log({ error3333: err })
    }
    setIsLoading(false)
  }

  return (
    <LoadingButton variant='contained' loading={isLoading} onClick={onClick}>
      BRAUN PL
    </LoadingButton>
  )
}
