import { Stack, Typography } from "@mui/material";

export default function Summary({ setFieldValue, values }) {
  let {
    statusCode,
    name,
    alias,
    paymentTerms,
    incoTerms,
    relations,
    contacts,
  } = values;
  console.log({ incoTerms });

  paymentTerms = paymentTerms?.filter((x) => typeof x === "string");
  incoTerms = incoTerms?.filter((x) => !!x.terms);
  const newContactsFromIncoTerms = incoTerms
    ?.filter((x) => !x.entityUserRelation)
    .map((x) => ({ ...x, role: ["WAREHOUSE"] }));

  console.log({ newContactsFromIncoTerms });
  console.log({ contacts });
  console.log({ incoTerms, paymentTerms });

  console.log({
    combined: [...(contacts || []), ...(newContactsFromIncoTerms || [])],
  });

  return (
    <Stack spacing={5} alignItems="flex-start">
      <Typography sx={{ alignSelf: "center" }} variant="h5">
        Summary
      </Typography>

      <Stack direction="row" spacing={2}>
        <Typography sx={{ alignSelf: "center" }} variant="body1">
          Name: {name}
        </Typography>
        <Typography sx={{ alignSelf: "center" }} variant="body1">
          Alias: {alias}
        </Typography>
        <Typography sx={{ alignSelf: "center" }} variant="body1">
          StatusCode: {statusCode}
        </Typography>
      </Stack>
      {paymentTerms?.length > 0 && (
        <Stack direction="row" spacing={5}>
          <Typography sx={{ alignSelf: "center" }} variant="body1">
            Payment Terms:
          </Typography>
          <Stack direction="row" spacing={2}>
            {paymentTerms.map((x) => (
              <Typography key={x} variant="body1">
                {x}
              </Typography>
            ))}
          </Stack>
        </Stack>
      )}
      {incoTerms?.length > 0 && (
        <Stack direction="row" spacing={5}>
          <Typography sx={{ alignSelf: "center" }} variant="body1">
            Inco Terms:
          </Typography>
          <Stack direction="row" spacing={2}>
            {incoTerms.map((x) => (
              <Typography key={x.terms} variant="body1">
                {x.terms}-{x.entityUserRelation?.name || `${x.fn}, ${x.ln}`}
              </Typography>
            ))}
          </Stack>
        </Stack>
      )}
      {relations?.length > 0 && (
        <Stack spacing={2}>
          <Typography variant="h4">Relations</Typography>

          {relations.map((x, i) => (
            <Stack key={i}>
              <Stack direction="row" spacing={2}>
                <Typography>{x.relationType}</Typography>
                <Typography>
                  {x.relationType?.split("-")[0]} :{" "}
                  {x.thisEntityIs === x.relationType?.split("-")[0]
                    ? alias || name
                    : x.theOtherEntity?.alias || x.theOtherEntity?.name}
                </Typography>
                <Typography>
                  {x.relationType?.split("-")[1]}:{" "}
                  {x.thisEntityIs === x.relationType?.split("-")[1]
                    ? alias || name
                    : x.theOtherEntity?.alias || x.theOtherEntity?.name}
                </Typography>
                {x.vendorCode && (
                  <Typography>Vendor Code: {x.vendorCode}</Typography>
                )}
              </Stack>

              {x.paymentTerms?.length > 0 && (
                <Stack direction="row" spacing={2}>
                  <Typography variant="body1">Payment Terms</Typography>
                  {x.paymentTerms.map((y) => (
                    <Typography key={y}> {y} </Typography>
                  ))}
                </Stack>
              )}
              {x.incoTerms?.length > 0 && (
                <Stack direction="row" spacing={2}>
                  <Typography variant="body1">Inco Terms</Typography>
                  {x.incoTerms.map((y) => {
                    const termsFound = incoTerms?.find(
                      (x) => x.refID === y.refID
                    );

                    return (
                      <Typography key={y.refID || y.terms}>
                        {termsFound
                          ? `${termsFound?.terms}-
                        ${termsFound?.entityUserRelation?.name} ||
                          ${termsFound?.fn}, ${termsFound?.ln}}`
                          : y.terms}
                      </Typography>
                    );
                  })}
                </Stack>
              )}
            </Stack>
          ))}
        </Stack>
      )}
      {[...(contacts || []), ...(newContactsFromIncoTerms || [])]?.length >
        0 && (
        // <Stack direction="row" spacing={5}>
        <Stack spacing={2}>
          <Typography variant="h4">Contacts:</Typography>

          {[...(contacts || []), ...(newContactsFromIncoTerms || [])].map(
            (x, i) => (
              <Stack key={i} direction="row" spacing={2}>
                <Typography variant="body1">
                  {x.fn} {x.ln}
                </Typography>
                <Typography variant="body1">{x.email}</Typography>
                <Typography variant="body1">{x.phoneNumber}</Typography>
                <Typography variant="body1">{x.address}</Typography>
                <Typography ariant="body1">{x.role?.join(", ")}</Typography>
              </Stack>
            )
          )}
        </Stack>
        // </Stack>
      )}
    </Stack>
  );
}
