import { useApolloClient, useQuery, gql } from "@apollo/client";
import { useEffect } from "react";
import * as Yup from "yup";
import { Stack, Typography } from "@mui/material";
import FormikForm, {
  FormikField,
  FormikSelect,
  FormikAutocomplete,
} from "../../../../component/formikForm";
import onDeletePO from "../onDelete";
import onUpdate from "../onUpdate";
import { currencies, POStatusCode } from "../../../../settings/formFieldEnum";

import {
  getEntityUserRelation,
  relationsByEntity1AndRelatioin,
} from "../query";

const validationSchema = Yup.object().shape({
  price: Yup.number().min(0).required("Please enter Price"),
  priceCur: Yup.string().required("Please select Currency"),
});

export default function Item({ data, onClose }) {
  const client = useApolloClient();

  const onDeleteBtnClick = async () => {
    await onDeletePO({ values: data, client });
    onClose();
  };

  const onSubmit = async ({ values, client }) => {
    await onUpdate({
      values,
      client,
    });
    onClose && onClose();
  };

  return (
    <FormikForm
      initialValues={{ ...data, isEditing: false }}
      validationSchema={validationSchema}
      FormMainFields={EditFields}
      FormArray={null}
      handleCancel={({ setFieldValue, setValues }) => {
        setValues(data);
        setFieldValue("isEditing", false);
      }}
      saveButtonLabel="update"
      onDelete={onDeleteBtnClick}
      onSubmit={onSubmit}
    />
  );
}

const EditFields = ({ values, setFieldValue }) => {
  const {
    number,
    itemNumber,
    customer,
    vendor,
    material,
    materialEntityRelation,
    isEditing,
    definerID,
  } = values;

  const client = useApolloClient();

  const { data: relationsByEntity1AndRelatioinData } = useQuery(
    gql(relationsByEntity1AndRelatioin),
    {
      variables: {
        entity1ID: customer?.id,
        relation: { eq: "CUSTOMER-SUPPLIER" },
      },
      skip: !definerID,
    }
  );

  let incoTermsOptions =
    relationsByEntity1AndRelatioinData?.relationsByEntity1AndRelatioin
      ?.items?.[0].incoTerms;

  useEffect(() => {
    async function func() {
      if (!incoTermsOptions || incoTermsOptions.length === 0) return;

      const options = await Promise.all(
        incoTermsOptions?.map(async (x) => {
          const { data } = await client.query({
            query: gql(getEntityUserRelation),
            variables: { id: x.entityUserRelationID },
          });

          //           {relationid: '22eb250e-2f15-4661-892a-a05abf198b1f'}relationid: "22eb250e-2f15-4661-892a-a05abf198b1f"[[Prototype]]: Object
          // Step0ChooseItems.js:75 {relationid: '419269ab-7637-4e55-8a74-597770906204'}
          // Step0ChooseItems.js:75 {relationid: '06c25c69-0c54-4a2f-a4a3-fb33dd49c158'}

          const { user, entity } = data?.getEntityUserRelation || {};
          const { name, phoneNumber, address, email } = user || {};
          const { name: entityName } = entity || {};
          return {
            relationID: x.entityUserRelationID,
            terms: x.terms,
            entityName,
            contactName: name,
            phoneNumber,
            address,
            email,
          };
        })
      );

      setFieldValue("incoTermsOptions", options);

      if (options?.length === 1) {
        setFieldValue("incoTerms", options[0]);
      }
    }

    func();
  }, [incoTermsOptions, customer?.id, vendor?.id, setFieldValue, client]);

  return (
    <Stack spacing={2} width={1200}>
      <Stack direction="row" spacing={5}>
        <Typography> CUSTMER: {customer?.name || customer?.alias}</Typography>
        <Typography> VENDOR: {vendor?.name || vendor?.alias}</Typography>
      </Stack>

      <Stack direction="row" spacing={5}>
        {materialEntityRelation && (
          <Typography> CODE: {materialEntityRelation?.code}</Typography>
        )}

        <Typography> MFR: {material?.mfr}</Typography>
        <Typography> MPN: {material?.mpn}</Typography>

        <FormikAutocomplete
          name="incoTerms"
          label="Incoterms"
          options={values.incoTermsOptions || []}
          getOptionLabel={(option) => option.terms}
          required
          getPrimaryText={(option) => option.terms}
          getSecondaryText={(option) => option.contactName}
          listItemPrimaryField={null}
          listItemSecondaryField={null}
          disabled={!isEditing}
          width={600}
          isOptionEqualToValue={(option, value) => option.terms === value.terms}
          // onChange={() => {
          //   setFieldValue("POItems", [{ itemNumber: "00010", findBy: "code" }]);

          //   setFieldValue("vendor", null);
          //   setFieldValue("incoTerms", null);
          //   setFieldValue("paymentTerms", null);
          // }}
          getOptionDisabled={null}
        />
      </Stack>
      <Stack direction="row" spacing={5}>
        <FormikField
          width={180}
          name="number"
          label="PO Number"
          disabled={!isEditing}
        />
        <FormikField
          width={180}
          name="itemNumber"
          label="Item Number"
          disabled={!isEditing}
        />
      </Stack>

      <Stack direction="row" spacing={2} alignItems="flex-end">
        <FormikField
          width={120}
          name="date"
          label="date"
          type="date"
          disabled={!isEditing}
        />
        <FormikSelect
          label="Currency"
          required
          name="priceCur"
          options={currencies}
          labelKey="label"
          valueKey="value"
          width={120}
          disabled={!isEditing}
        />
        <FormikField
          width={120}
          name="price"
          label="Price"
          type="number"
          disabled={!isEditing}
        />
        <FormikField
          width={180}
          name="qty"
          label="Qty"
          type="number"
          disabled={!isEditing}
        />
        <FormikField
          width={180}
          name="openQty"
          label="Open Qty"
          type="number"
          disabled={!isEditing}
        />
        <FormikField
          width={200}
          name="CRD"
          label="CRD"
          type="date"
          disabled={!isEditing}
        />
        <FormikSelect
          label="statusCode"
          name="statusCode"
          width={200}
          options={POStatusCode}
          labelKey="label"
          valueKey="value"
          disabled={!isEditing}
        />
      </Stack>
    </Stack>
  );
};
