import { gql } from "@apollo/client";
import emitSnackbar from "../emitSnackbar";
// import comparePropertyBetweenObjects from "../../lib/comparePropertyBetweenObjects";
import crossCheckArray from "../../lib/crossCheckArray";
import isArrayIdentical from "../../lib/isArrayIdentical";
import { onCreate } from "../onCreate";
import * as schema from "../../graphql/schema.json";

export { default as onUpdateEntity } from "./onUpdateEntity";
export { default as onUpdateInvite } from "./onUpdateInvite";
export { default as onUpdateSupplier } from "./onUpdateSupplier";
export { default as onUpdateExternalCoworker } from "./onUpdateExternalCoworker";
export { default as onUpdatePart } from "./onUpdatePart";
export { default as onUpdateMaterial } from "./onUpdateMaterial";
export { default as onUpdateRFQ } from "./onUpdateRFQ";

const createNote = /* GraphQL */ `
  mutation CreateNote(
    $input: CreateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    createNote(input: $input, condition: $condition) {
      id
      type
      itemID
      message
      createdBy
      groupToRead
      groupToEdit
      groupToDelete
      createdAt
      updatedAt
    }
  }
`;

export async function onUpdate({
  type,
  values,
  mutation,
  query,
  onUpdate,
  fieldsToUpdate,
  client,
  getInitialValue,
  refetchQueries,
}) {
  // GET THE LATEST UPDATED ITEM

  let initialValues;

  if (getInitialValue) {
    initialValues = await getInitialValue();
  } else {
    const { data } = await client.query({
      query: gql(query),
      variables: { id: values.id },
    });

    const dataProperty = Object.keys(data)[0];
    initialValues = data[dataProperty];
  }

  // console.log({ initialValueInUpdate: initialValues });

  // debugger;

  const updatedFields = {};
  const updatesToCreate = [];

  await Promise.all(
    fieldsToUpdate.map(async (x) => {
      if (values[x.field] === undefined) return;

      // if (x.field === "paymentDueDate") {
      //   console.log({ xxx: x });
      //   console.log({ values });
      //   console.log("fieldValue", values[x.field]);

      //   debugger;
      // }

      const typeInSchema = schema.default.data.__schema.types.find(
        (y) => y.name === type
      );
      // console.log({ typeInSchema });

      const fieldInSchema = typeInSchema?.fields?.find(
        (z) => z.name === x.field
      );
      // console.log({ fieldInSchema });
      // debugger

      const isFieldModel = fieldInSchema?.type?.name?.startsWith("Model");

      // console.log({ isFieldModel });

      // IF FIELD IS NOT ARRAY

      if (!Array.isArray(values[x.field])) {
        if (values[x.field] !== initialValues[x.field] && fieldInSchema) {
          // ADD TO UPDATE FIELD
          updatedFields[x.field] = values[x.field];

          // ADD TO CREATE UPDATES

          if (x.createUpdate) {
            const message = `${x.field} changed from ${
              initialValues[x.field]
            } to ${values[x.field]}`;
            // alert(message);

            updatesToCreate.push(message);
          }
        }
      } else {
        if (isArrayIdentical(values[x.field], initialValues[x.field])) {
          console.log("array is identical");
          return;
        }

        // alert("starting check array");
        await crossCheckArray({
          originalArr: initialValues[x.field],
          newArr: values[x.field],
          funcAdd: async (addedItem) => {
            // console.log("added");
            // debugger;
            if (x.funcAdd) {
              await x.funcAdd({ addedItem, updatesToCreate });
            } else if (x.createUpdate) {
              // console.log("create update");
              // debugger;
              const message = `${x.field} ${
                addedItem[x.key] || addedItem
              } was added`;
              updatesToCreate.push(message);
            }
          },
          funcDelete: async (deletedItem) => {
            // console.log({ deletedItem, key: x.key });
            // debugger;
            if (x.funcDelete) {
              await x.funcDelete({ deletedItem, updatesToCreate });
            } else if (x.createUpdate) {
              const message = `${x.field} ${
                deletedItem[x.key] || deletedItem
              } was removed`;
              updatesToCreate.push(message);
            }
          },
          funcCheckUpdate: async ({ currentItem }) => {
            if (x.funcCheckUpdate) {
              await x.funcCheckUpdate({
                currentItem,
                initialValues,
                updatesToCreate,
              });
            }
          },
          checkIsItemInNewArrayExisting: x.checkIsItemInNewArrayExisting,
          checkIsItemInOldArrayRemaining: x.checkIsItemInOldArrayRemaining,
        });

        if (fieldInSchema && !isFieldModel) {
          updatedFields[x.field] = values[x.field];
        }
      }
    })
  );

  // RUN UPDATE
  let res;

  if (Object.keys(updatedFields)?.length > 0) {
    const inputForUpdate = {
      id: values.id,
      ...updatedFields,
    };

    res = await client.mutate({
      mutation: gql(mutation),
      variables: { input: inputForUpdate },
      update(cache, { data }) {
        if (onUpdate) {
          onUpdate(cache, data);
        }
      },
    });
  }

  // console.log({ res });
  // debugger;

  // CREATE UPDATE FOR EVERY UPDATE

  await Promise.all(
    updatesToCreate.map(async (x) => {
      const input = { itemID: initialValues?.id, type: "UPDATE", message: x };

      const NoteFragment = /* GraphQL */ `
        fragment NewNote on Note {
          id
        }
      `;
      await onCreate({
        input,
        mutation: createNote,
        fragment: NoteFragment,
        updateCacheFields: null,
        client,
        // refetchQueries: "GetOffer",
      });
    })
  );

  // REFETCH QUERY

  if (refetchQueries) {
    await client.refetchQueries({ include: [refetchQueries] });
  }

  emitSnackbar({
    message: "Data updated successfully",
    duration: 3000,
  });
  return res;
}
