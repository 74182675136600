import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { CircularProgress } from "@mui/material";

export default function UpdateEmailVerification({ action, passcode }) {
  const navigate = useNavigate();

  useEffect(() => {
    async function func() {
      if (passcode) {
        try {
          await Auth.verifyCurrentUserAttributeSubmit("email", passcode);
          action.onSuccess();
        } catch (e) {
          action.onFail(e);
        }
      } else {
        navigate("/", { replace: true });
      }
    }
    func();
  }, [navigate, passcode, action]);

  // return <CircularProgress color="secondary" />;
  return null;
}
