import { useFormikContext } from "formik";

import { Stack, IconButton, Typography } from "@mui/material";
import { RemoveCircle as RemoveCircleIcon } from "@mui/icons-material";

export default function FormikArrayRemoveItemButton({
  onClick,
  name,
  removeBtnColor,
  size,
  disabled,
}) {
  const { values } = useFormikContext();

  const { isEditing } = values || {};

  let iconSize = "medium";
  let fontVariant = "body1";

  switch (size) {
    case "sm":
      iconSize = "small";
      fontVariant = "body2";

      break;
    case "lg":
      iconSize = "large";
      fontVariant = "h6";

      break;

    default:
      break;
  }

  if (!isEditing) return null;

  return (
    <IconButton aria-label="remove" disabled={disabled} onClick={onClick}>
      <Stack direction="row" spacing={1} alignItems="center">
        <RemoveCircleIcon
          sx={{ color: disabled ? "gray" : removeBtnColor }}
          fontSize={iconSize}
        />
        <Typography
          color={disabled ? "gray" : removeBtnColor}
          variant={fontVariant}
        >
          Remove
          {/* {name?.split("][")?.slice(-1).pop().replace("]", "")} */}
        </Typography>
      </Stack>
    </IconButton>
  );
}
