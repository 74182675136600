import { useReactiveVar } from "@apollo/client";
import {
  Typography,
  Grid,
  Box,
  Card,
  CardActionArea,
  Badge,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CreateEntityStepForm from "../modules/entityModules/entities/components/CreateEntityStepForm";
import { userVar, userEntityRelationVar } from "../client/cache";

import filterOutSameItemInArray from "../lib/filterOutSameItemInArray";

export default function Dashboard() {
  const navigate = useNavigate();
  const user = useReactiveVar(userVar);
  const userEntityRelation = useReactiveVar(userEntityRelationVar);

  const {
    id,
    groups,
    receivedMessages,
    invites,
    offersReceived,
    entityUserRelations,
  } = user || {};


  let filteredRelations = entityUserRelations?.filter(
    (x) => x.definerID === x.entityID
  );

  filteredRelations = filterOutSameItemInArray(filteredRelations, "entityID");


  if (!user?.id) return null;
  let isSystemAdmin = "PENDING";

  if (groups?.length > 0) {
    isSystemAdmin = groups?.includes("admin");
  }

  return (
    <Box
      sx={
        {
          // width:'100%'
          // bgcolor: "red",
          // position: "relative",
          // top: 100,
        }
      }
    >
      {filteredRelations?.length > 0 ? (
        <Grid container spacing={0}>
          {filteredRelations?.map((x) => {
            const rfqs = offersReceived.filter(
              (y) => y.customer?.id === x.entity?.id && y.salesID === user?.id
            );

            console.log({ rfqs });
            return (
              <Grid
                key={x.id}
                item
                xs={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 3,
                }}
              >
                <Card
                  bgcolor="#fff"
                  onClick={() => {
                    navigate(`./${x.entity?.id}`);
                  }}
                >
                  <CardActionArea>
                    <Box
                      sx={{
                        width: 200,
                        height: 200,
                        borderRadius: 2,
                        bgcolor: "#f0f0f0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Badge
                        variant="dot"
                        color="error"
                        invisible={!rfqs.length === 0}
                        sx={{}}
                      >
                        <Typography variant="h5" color="secondary">
                          {x.entity?.alias || x.entity?.name}
                        </Typography>
                      </Badge>
                    </Box>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <>
          {!isSystemAdmin && isSystemAdmin !== "PENDING" ? (
            <>
              <Typography variant="h3">
                YOU MAY NEED TO REBUILD THIS FORM
              </Typography>
              <CreateEntityStepForm />
            </>
          ) : (
            <p>dashboard</p>
          )}
        </>
      )}
    </Box>
  );
}
