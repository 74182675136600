export const deleteLog = /* GraphQL */ `
  mutation DeleteLog(
    $input: DeleteLogInput!
    $condition: ModelLogConditionInput
  ) {
    deleteLog(input: $input, condition: $condition) {
      id
      timeString
      city
      country
      hostname
      loc
      org
      postal
      region
      timezone
      message
      type
      ip
      userID

      entityGroup
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const listLogs = /* GraphQL */ `
  query ListLogs(
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timeString
        city
        country
        hostname
        loc
        org
        postal
        region
        timezone
        message
        type
        ip
        userID
        user {
          id
          email
          fn
          mn
          ln
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
