/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_mobile_analytics_app_id": "daa75abb375e43b7b2cb736f4f5df46e",
    "aws_mobile_analytics_app_region": "us-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "daa75abb375e43b7b2cb736f4f5df46e",
            "region": "us-west-2"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://fqyjlv7c21.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        },
        {
            "name": "iSupplyAPI",
            "endpoint": "https://bdnajsoib0.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://tmeeobneyjfw3oujxw5ktbyepi.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-west-2:5dd241c0-83f7-4404-82b1-6c82ea6f1cc6",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_W94NCkceq",
    "aws_user_pools_web_client_id": "pd5k0eb07274rh28jg8vqn6vi",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "supply97979cd3881146cda54655200016497b134337-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
