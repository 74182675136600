import { onCreate } from "../../../function/onCreate";
import { createOffer, fragment } from "./query";

export async function onCreateInquiry({ client, values, statusCode }) {
  const {
    date,
    number,
    itemNumber,
    materialID,
    materialEntityRelationID,
    salesID,
    vendorID,
    customerID,
    definerID,
    TP,
    TPCurrency,
    EAU,
    application,
    inquiryValidDays,
    price,
    currency,
  } = values;

  const input = {
    date,
    number,
    itemNumber,
    materialID,
    materialEntityRelationID,
    salesID,
    vendorID,
    customerID,
    definerID,
    TP,
    TPCurrency,
    EAU,
    application,
    statusCode: statusCode || "100",
    inquiryValidDays,
    price,
    currency,
  };

  // console.log({ input });

  // debugger;

  const { data: createOfferData, errors: createOfferErrors } = await onCreate({
    input,
    mutation: createOffer,
    fragment,
    updateCacheFields: ["offerByDefiner"],
    client,
    refetchQueries: [],
  });
  // console.log({ createOfferData, createOfferErrors });
}
export default async function onCreateOffer({ client, values }) {
  const {
    date,
    quotes,
    buyer,
    sales,
    number,
    customer,
    vendor,
    entityID,
    incoTerms,
    paymentTerms,
  } = values;

  if (!quotes || quotes?.length === 0) return;

  await Promise.all(
    quotes.map(
      async ({
        currency,
        itemNumber,
        leadtime,
        material: { id, materialID, relationID },
        price,
        validDays,
        note,
      }) => {
        const input = {
          date,
          materialID: id || materialID,
          materialEntityRelationID: relationID,
          number,
          itemNumber,
          salesID: sales.id,
          buyerID: buyer.id,
          vendorID: vendor.id,
          customerID: customer?.id,
          definerID: entityID,
          currency,
          price,
          leadtime,
          validDays,
          paymentTerms,
          incoTerms,
          statusCode: "050",
          note,
        };

        // console.log({ input });
        // debugger;

        const { data: createOfferData, errors: createOfferErrors } =
          await onCreate({
            input,
            mutation: createOffer,
            fragment,
            updateCacheFields: ["offerByDefiner"],
            client,
            refetchQueries: [],
          });
      }
    )
  );
}
