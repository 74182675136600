import { useEffect, useState } from "react";
import {
  Stack,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Typography,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { gql, useQuery } from "@apollo/client";
import { useFormikContext } from "formik";

import {
  FormikField,
  FormikCheckbox,
  FormikAutocomplete,
  FormikArrayRemoveItemButton,
} from "../../../../../../component/formikForm";
import filterOutSameItemInArray from "../../../../../../lib/filterOutSameItemInArray";

const entitiyUserRelationByDefiner = /* GraphQL */ `
  query EntitiyUserRelationByDefiner(
    $definerID: ID!
    $entityID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityUserRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entitiyUserRelationByDefiner(
      definerID: $definerID
      entityID: $entityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        title
        shownFn
        shownLn
        shownMn
        statusCode
        isEmailVerified
        userID
        user {
          id
          fn
          ln
          mn
          name @client
        }
        entityID
        entity {
          id
          alias
          name
        }
        definerID
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export default function IncoTermsForEntityCreation({
  index,
  remove,
  arrayFieldName,
  removeBtnColor,
  size,
}) {
  // const entityID = params?.entityID;

  const { values, setFieldValue } = useFormikContext();
  const item = values?.[arrayFieldName]?.[index];

  const { hasExternalReceiver, refID, entityUserRelation } = item || {};

  console.log({ item });
  let { data } = useQuery(gql(entitiyUserRelationByDefiner), {
    variables: {
      definerID: values.definerID,
    },
  });

  let entityUserRelations =
    data?.entitiyUserRelationByDefiner?.items?.map((x) => ({
      ...x.user,
      userID: x.user.id,
      id: x.id,
      entity: x.entity?.alias || x.entity?.name,
    })) || [];

  entityUserRelations = filterOutSameItemInArray(entityUserRelations, "userID");

  useEffect(() => {
    if (!refID) {
      setFieldValue(`[${arrayFieldName}][${index}][refID]`, uuidv4());
    }

    if (!entityUserRelation?.id) {
      setFieldValue(`[${arrayFieldName}][${index}][userID]`, uuidv4());
      setFieldValue(
        `[${arrayFieldName}][${index}][entityUserRelationID]`,
        uuidv4()
      );
    } else {
      setFieldValue(
        `[${arrayFieldName}][${index}][userID]`,
        entityUserRelation?.userID
      );
      setFieldValue(
        `[${arrayFieldName}][${index}][entityUserRelationID]`,
        entityUserRelation?.id
      );
    }
  }, [
    arrayFieldName,
    index,
    entityUserRelation?.id,
    entityUserRelation?.userID,
    refID,
    setFieldValue,
  ]);

  return (
    <Stack direction="row" width={1800}>
      <Stack>
        <FormikCheckbox
          label="Does this inco terms apply to external receiver?"
          // name="hasSpecifiedIncoTerms"
          name={`[${arrayFieldName}][${index}][hasExternalReceiver]`}
        />
        <Stack
          // direction="row"
          spacing={2}
          // alignItems="flex-end"
          // sx={{ height: 200, bgcolor: "red" }}
        >
          <FormikField
            name={`[${arrayFieldName}][${index}][terms]`}
            label="IncoTerms"
            size={size}
          />

          {hasExternalReceiver ? (
            <Stack>
              <FormikAutocomplete
                width={500}
                name={`[${arrayFieldName}][${index}][entityUserRelation]`}
                label="entityUserRelation"
                required
                options={entityUserRelations || []}
                // options={[]}
                getOptionLabel={(option) => {
                  const item = entityUserRelations?.find(
                    (x) => x.id === option.id
                  );
                  return item ? item.name + "@" + item.entity : "";
                }}
                getPrimaryText={(option) => option.name + "@" + option.entity}
              />
            </Stack>
          ) : (
            <Stack>
              <Typography>Receiver</Typography>
              <Stack direction="row" spacing={2}>
                <FormikField
                  name={`[${arrayFieldName}][${index}][fn]`}
                  label="First Name"
                  size={size}
                  width={200}
                />
                <FormikField
                  name={`[${arrayFieldName}][${index}][ln]`}
                  label="Last Name"
                  size={size}
                  width={200}
                />
                <FormikField
                  name={`[${arrayFieldName}][${index}][address]`}
                  label="Address"
                  size={size}
                  width={600}
                />
                <FormikField
                  name={`[${arrayFieldName}][${index}][phoneNumber]`}
                  label="phoneNumber"
                  size={size}
                  width={200}
                />
                <FormikField
                  name={`[${arrayFieldName}][${index}][email]`}
                  label="email"
                  size={size}
                  width={300}
                />
              </Stack>
            </Stack>
          )}

          {/* <FormikAutocomplete
        width={500}
        name={`[${arrayFieldName}][${index}]`}
        label="entityUserRelation"
        required
        options={incoTerms || []}
        disabled={!incoTerms || incoTerms.length === 0}
        isOptionEqualToValue={(option, value) => option?.terms === value?.terms}
        getOptionLabel={(option) => option?.terms || ""}
        getPrimaryText={(option) => option?.terms}
        getOptionDisabled={(option) =>
          values.incoTerms.some((x) => x?.terms === option?.terms)
        }
      /> */}
        </Stack>
      </Stack>
      <FormikArrayRemoveItemButton
        onClick={() => remove(index)}
        name={arrayFieldName}
        removeBtnColor={removeBtnColor}
        size={size}
      />
    </Stack>
  );
}
