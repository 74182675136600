import { I18n } from "aws-amplify";

export function customizedI18nGet(label) {
  return I18n.get(label?.toLowerCase());
}

const mapping = {
  "sign in": {
    "en-us": "Log In",
    zh_cn: "注册",
  },
  "sign up": {
    "en-us": "Registration",
    zh_cn: "登录",
  },
  playground: {
    "en-us": "Trial & Error",
    zh_cn: "测试",
  },

  home: {
    "en-us": "Home",
    zh_cn: "首页",
  },
  about: {
    "en-us": "About",
    zh_cn: "关于",
  },
  contacts: {
    "en-us": "Contacts",
    zh_cn: "联系人",
  },
  materials: {
    "en-us": "Materials",
    zh_cn: "物料",
  },
  "incoming invoices": {
    "en-us": "Incoming Invoices",
    zh_cn: "进项发票",
  },
  billing: {
    "en-us": "Billing",
    zh_cn: "销项发票",
  },
  pricing: {
    "en-us": "Pricing",
    zh_cn: "报价",
  },
  costs: {
    "en-us": "Costs",
    zh_cn: "进价",
  },
  inventory: {
    "en-us": "Inventory",
    zh_cn: "库存",
  },
  receivings: {
    "en-us": "Receivings",
    zh_cn: "收货",
  },
  shippings: {
    "en-us": "Shippings",
    zh_cn: "发货",
  },
  entities: {
    "en-us": "Entities",
    zh_cn: "往来企业",
  },
  "outgoing pos": {
    "en-us": "Outgoing POs",
    zh_cn: "采购订单",
  },
  "incoming pos": {
    "en-us": "Incoming POs",
    zh_cn: "客户订单",
  },
  eta: {
    "en-us": "ETA",
    zh_cn: "交期",
  },
  "outbound payment": {
    "en-us": "Outbound Payment",
    zh_cn: "付款",
  },
  "inbound payment": {
    "en-us": "Inbound Payment",
    zh_cn: "收款",
  },
  "batch import": {
    "en-us": "Batch Import",
    zh_cn: "批量导入",
  },
  filters: {
    "en-us": "Filters",
    zh_cn: "筛选",
  },
  submit: {
    "en-us": "Submit",
    zh_cn: "提交",
  },
  cancel: {
    "en-us": "Cancel",
    zh_cn: "取消",
  },
  log: {
    "en-us": "Log",
    zh_cn: "日志",
  },
};

const dictionary = Object.entries(mapping).reduce((a, v) => {
  //   console.log({ a, v });

  const label = v[0];
  const langs = Object.keys(v[1]);
  //   console.log({ langs });
  langs.forEach((lang) => {
    if (a[lang]) {
      a[lang][label] = v[1][lang];
    } else {
      a[lang] = {
        [label]: v[1][lang],
      };
    }
  });

  return a;
}, {});

export default dictionary;
