import React from "react";
import { useQuery, gql, useApolloClient } from "@apollo/client";
import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, Typography, Stack } from "@mui/material";
import { onDelete } from "../function/onDelete";

const query = gql`
  query NotesByItemAndType(
    $itemID: ID!
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notesByItemAndType(
      itemID: $itemID
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        itemID
        message
        createdBy
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        date @client
      }
      nextToken
    }
  }
`;

const deleteNote = /* GraphQL */ `
  mutation DeleteNote(
    $input: DeleteNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    deleteNote(input: $input, condition: $condition) {
      id
      itemID
      message
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export default function Update({ itemID }) {
  const client = useApolloClient();

  let { data } = useQuery(
    gql`
      ${query}
    `,
    {
      variables: { itemID, type: { eq: "UPDATE" } },
      fetchPolicy: "network-only",
    }
  );

  data = data?.notesByItemAndType?.items;

  if (!itemID) return null;

  return (
    <Stack spacing={1}>
      <Typography p={1} variant="h6" bgcolor='yellow'>UPDATES</Typography>

      <Stack direction="column" spacing={2}>
        {data?.map((x, i) => (
          <Stack key={i} alignItems="center" direction="row">
            <IconButton
              onClick={async () => {
                try {
                  await onDelete({
                    input: { id: x.id },
                    mutation: deleteNote,
                    // updateCacheFields: ["listEntities"],
                    client,
                  });
                } catch (err) {
                  console.log("error deleting note", err);
                }
              }}
            >
              <ClearIcon />
            </IconButton>
            <MessageItem item={x} />
          </Stack>
        ))}
        {(!data || data.length === 0) && <Typography p={2}>no update</Typography>}
      </Stack>
    </Stack>
  );
}

function MessageItem({ item }) {
  return (
    <Stack
      width="100%"
      direction="row"
      justifyContent="space-between"
      bgcolor="grey.200"
      p={1}
      borderRadius={1}
      spacing={3}
    >
      <Typography sx={{ fontStyle: "italic" }} variant="body1">
        {item.message}
      </Typography>

      <Stack direction="row">
        <Typography width={100} sx={{ fontStyle: "italic" }} variant="body2">
          {item.date}
        </Typography>
        <Typography width={200} sx={{ fontStyle: "italic" }} variant="body2">
          {item.createdBy}
        </Typography>
      </Stack>
    </Stack>
  );
}
