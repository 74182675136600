import { onUpdate } from '../../../function/onUpdate'
import { getReceivingAndShipping, updateReceivingAndShipping } from './query'

export default async function onUpdateShipping({ values, client }) {
  const fieldsToUpdate = [
    { field: 'courierID', createUpdate: false },
    { field: 'trackingNumber', createUpdate: true },
    { field: 'packingDetails', createUpdate: false },
    { field: 'itemNumber', createUpdate: false },
    { field: 'number', createUpdate: false },
    { field: "qty", createUpdate: true },
    // { field: "openQty", createUpdate: true },
  ]

  return await onUpdate({
    type: 'ReceivingAndShipping',
    values,
    mutation: updateReceivingAndShipping,
    query: getReceivingAndShipping,
    getInitialValue: null,
    fieldsToUpdate,
    client,
    refetchQueries: [""],
  })
}
