import { gql } from "@apollo/client";
import { onCreate } from "../../../function/onCreate";
import { createInvoice, getPO } from "./query";
import { vatRate } from "../../../settings/formFieldEnum";

export async function onCreateInvoiceItem({ client, values }) {
  const fragment = /* GraphQL */ `
    fragment NewInvoice on Invoice {
      id
    }
  `;

  if (values.poID) {
    const { data } = await client.query({
      query: gql(getPO),
      variables: { id: values.poID },
    });
    const PO = data?.getPO;

    const { invoice, qty: POQty } = PO || {};

    const billedQty =
      invoice.items?.reduce((acc, cur) => acc + cur.qty, 0) || 0;
    if (values.qty + billedQty === POQty) {
      await client.mutate({
        mutation: gql(/* GraphQL */ `
          mutation UpdatePO(
            $input: UpdatePOInput!
            $condition: ModelPOConditionInput
          ) {
            updatePO(input: $input, condition: $condition) {
              id
              number
              itemNumber
              qty
              openQty
              CRD
              __typename
            }
          }
        `),
        variables: {
          input: {
            id: values.poID,
            billingStatus: "CLOSED",
          },
        },
      });
    }
  }

  return await onCreate({
    input: values,
    mutation: createInvoice,
    fragment,
    updateCacheFields: [],
    client,
    refetchQueries: [],
  });
}

export default async function onCreateInvoices({ client, values, type }) {
  if (type !== "OUTGOING" && type !== "INBOUND")
    return alert('type must be "OUTGOING" or "INBOUND"');
  const {
    date,
    batch,
    billingEntityID,
    issuerID,
    category,
    definerID,
    invoiceItems,
  } = values;

  if (!invoiceItems || invoiceItems?.length === 0) return;

  await Promise.all(
    invoiceItems.map(
      async (
        {
          number,
          itemNumber,
          shippingID,
          poID,
          qty,
          currency,
          price,
          desc,
          paymentDueDate,
          taxRate,
          // paymentStatus,
        },
        i
      ) => {
        let txRate = taxRate;
        if (category === "VAT") {
          txRate = vatRate;
        }
        const values = {
          number,
          itemNumber,
          category,
          date,
          batch,
          type,
          qty: qty * 1,
          price,
          currency,
          poID,
          desc,
          paymentDueDate,
          definerID,
          billingEntityID,
          issuerID,
          shippingID,
          paymentStatus: "UNPAID",
          taxRate: txRate,
        };

        // console.log({ values });
        // debugger;

        await onCreateInvoiceItem({ client, values });
      }
    )
  );
  await client.refetchQueries({
    include: ["InvoiceByType", "POByDefinerID"],
  });
}
