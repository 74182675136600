import { onDelete } from "../../../function/onDelete";
import { deleteLog } from "./query";

export default async function onDeleteLog({
  selectionModel,
  setSelectionModel,
  client,
  values,
}) {
  if (selectionModel) {
    await Promise.all(
      selectionModel?.map(async (items) => {
        await deleteSingleItem(items, client);
      })
    );
    setSelectionModel && setSelectionModel([]);
  } else if (values) {
    await deleteSingleItem(values, client);
  }

  // await client.refetchQueries({ include: ["ListLogs"] });
}

async function deleteSingleItem(item, client) {
  const { id } = item || {};

  await onDelete({
    input: { id },
    mutation: deleteLog,
    // updateCacheFields: [""],
    client,
    // relations: [
    //   {
    //     query: entitiyUserRelationByUserID,
    //     mutation: deleteEntityUserRelation,
    //     variables: { userID: id },
    //   },
    // ],
    storagePath: id,
  });
}
