import { gql, useReactiveVar } from "@apollo/client";
import { useState } from "react";
import { Storage } from "aws-amplify";
import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import {
  materialEntityRelationByCode,
  EntitybyAlias,
  POByNumber,
  materialByMPN,
} from "../query";

import { userEntityRelationVar } from "../../../../client/cache";

import onCreateSPO from "../../SPOs/onCreate";

export const TemplateLabel = ({ rows }) => {
  const { entityID } = useReactiveVar(userEntityRelationVar);

  const [isLoading, setIsloading] = useState(false);
  const filename = "spo.csv";
  const path = `${entityID}/template/${filename}`;

  return (
    <Stack
      direction="row"
      spacing={2}
      // display="flex"
      // justifyContent="center"
      alignItems="center"
      // sx={{ alignItems: "center" }}
    >
      <Typography variant="h6">Template: </Typography>
      <Typography variant="p">{filename}</Typography>

      <LoadingButton
        loading={isLoading}
        size="small"
        onClick={async () => {
          setIsloading(true);
          try {
            const fetchedURL = await Storage.get(path, {
              download: false,
              validateObjectExistence: true,
              // level: 'protected',
            });
            window.location.href = fetchedURL;

            // console.log({window})

            // const downloadFolder = window.downloads.getDownloadFolder();

            // console.log({ downloadFolder });
            // debugger;
          } catch (err) {
            console.log({ err444: err });
          }
          setIsloading(false);
        }}
        variant="contained"
      >
        download
      </LoadingButton>
    </Stack>
  );
};

export const headerIdentifier = [
  { label: "DATE", isRequired: true },
  { label: "NUMBER", isRequired: true },
  { label: "CUSTOMERID", isRequired: false },
  { label: "vendorID", isRequired: false },
  { label: "VENDOR", isRequired: true },
  { label: "materialID", isRequired: false },
  { label: "materialRelationID", isRequired: false },
  { label: "FORWARDERID", isRequired: false },
  { label: "GCAS", isRequired: false },
  { label: "MFR", isRequired: true },
  { label: "MPN", isRequired: true },
  { label: "QTY", isRequired: true },
  { label: "OPEN QTY", isRequired: true },
  { label: "PRICE", isRequired: true },
  { label: "CURRENCY", isRequired: true },
  { label: "CRD", isRequired: false },
  { label: "INCOTERMS", isRequired: false },
  { label: "PAYMENTTERMS", isRequired: false },
  { label: "BILLINGSTATUS", isRequired: false },
  { label: "PAYMENTSTATUS", isRequired: false },

  // { label: "STATUSCODE", isRequired: false },
];

export async function onCreate({ rows, definerID, client }) {
  await Promise.all(
    rows.map(async (row) => {
      const {
        BILLINGSTATUS,
        PAYMENTSTATUS,
        materialID,
        materialRelationID,
        CUSTOMERID,
        vendorID,
        DATE,
        NUMBER,
        PRICE,
        CURRENCY,
        // IMPORTAGENTID,
        QTY,
        FORWARDERID,
        CRD,
      } = row;

      const values = {
        date: new Date(DATE).toISOString("en-US").substring(0, 10),
        materialID,
        materialEntityRelationID: materialRelationID?.trim(),
        billingStatus: BILLINGSTATUS?.trim() || undefined,
        paymentStatus: PAYMENTSTATUS?.trim() || undefined,
        CRD: new Date(CRD).toISOString("en-US").substring(0, 10),
        // importAgentID: IMPORTAGENTID?.trim(),
        forwarderID: FORWARDERID?.trim() || undefined,
        number: NUMBER?.trim(),
        itemNumber: "00010",
        customerID: CUSTOMERID?.trim(),
        vendorID,
        definerID,
        price: PRICE * 1,
        priceCur: CURRENCY?.trim(),
        qty: QTY * 1,
        openQty: row["OPEN QTY"] * 1,
      };

      // console.log({ values });
      // debugger;

      await onCreateSPO({ client, values });
    })
  );
}

export async function validator({ rows, definerID, client }) {
  const newarr = await Promise.all(
    rows?.map(async (row) => {
      let ERROR = [];

      const { MPN, MFR, GCAS, VENDOR, NUMBER } = row;

      let { data: POByNumberData } = await client.query({
        query: gql(POByNumber),
        variables: { number: NUMBER?.trim() },
      });
      POByNumberData = POByNumberData?.POByNumber?.items?.[0];

      if (POByNumberData) {
        ERROR.push({
          attr: "NUMBER",
          isMajor: true,
          msg: "PO already exists",
        });
        return { ...row, ERROR };
      }

      let { data: entitybyAliasData } = await client.query({
        query: gql(EntitybyAlias),
        variables: { alias: VENDOR },
      });
      entitybyAliasData = entitybyAliasData?.EntitybyAlias?.items?.[0];

      if (!entitybyAliasData) {
        ERROR.push({
          attr: "VENDOR",
          isMajor: true,
          msg: "Entity not found",
        });
        return { ...row, ERROR };
      }

      const { id: vendorID } = entitybyAliasData;

      let materialID, materialRelationID;

      if (GCAS) {
        const { data: materialEntityRelationByCodeData } = await client.query({
          query: gql(materialEntityRelationByCode),
          variables: {
            code: GCAS?.trim(),
            filter: { definerID: { eq: definerID } },
          },
        });
        const relationFound =
          materialEntityRelationByCodeData?.materialEntityRelationByCode?.items.find(
            (x) => x.material?.mpn === MPN && x.material?.mfr === MFR
          );

        // console.log({ materialsFound });

        if (!relationFound) {
          ERROR.push({
            attr: "MPN",
            isMajor: true,
            msg: "Material not found",
          });
          return { ...row, ERROR };
        }
        materialID = relationFound.materialID;
        materialRelationID = relationFound.id;
      } else {
        const { data: materialByMPNData } = await client.query({
          query: gql(materialByMPN),
          variables: {
            mpn: MPN?.trim(),
            mfr: { eq: MFR?.trim() },
            filter: { definerID: { eq: definerID } },
          },
        });
        const materialFound = materialByMPNData?.materialByMPN?.items?.[0];

        // console.log({ materialsFound });

        if (!materialFound) {
          ERROR.push({
            attr: "MPN",
            isMajor: true,
            msg: "Material not found",
          });
          return { ...row, ERROR };
        }
        materialID = materialFound.id;
      }

      row.materialID = materialID;
      row.materialRelationID = materialRelationID;
      row.vendorID = vendorID;

      return { ...row, ERROR };
    })
  );

  return newarr;
}
