import { Stack, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import {
  FormikField,
  FormikArrayRemoveItemButton,
  FormikRadioGroup,
} from "../../../../../../component/formikForm";
import ChartView from "../../../../../../component/ChartView";
import { POByNumber } from "../../../../SPOs/query";

export default function InvoicePaymentItem({
  index,
  remove,
  arrayFieldName,
  removeBtnColor,
  size,
}) {
  const { values, setFieldValue } = useFormikContext();

  const { paymentType, invoiceItems } = values;
  const { id, po } = invoiceItems[index] || {};

  if (paymentType !== "INVOICE") return null;

  return (
    <Stack>
      <Stack direction="row" spacing={2} alignItems="flex-end">
        <FormikField
          width={240}
          name={`[${arrayFieldName}][${index}][number]`}
          label="Number"
          disabled
          // type="number"
        />
        <FormikField
          width={80}
          name={`[${arrayFieldName}][${index}][itemNumber]`}
          label="itemNumber"
          disabled
          // type="number"
        />
        <FormikField
          width={180}
          name={`[${arrayFieldName}][${index}][desc]`}
          label="Desc"
          disabled
          // type="number"
        />
        <FormikField
          width={120}
          name={`[${arrayFieldName}][${index}][qty]`}
          label="Qty"
          disabled
          // type="number"
        />
        <FormikField
          width={120}
          name={`[${arrayFieldName}][${index}][price]`}
          label="Price"
          disabled
          // type="number"
        />
        <FormikField
          width={120}
          name={`[${arrayFieldName}][${index}][amount]`}
          label="Amount"
          type="number"
          disabled
        />

        <FormikField
          width={120}
          name={`[${arrayFieldName}][${index}][paidAmount]`}
          label="paidAmount"
          type="number"
        />

        <FormikArrayRemoveItemButton
          onClick={() => remove(index)}
          name={arrayFieldName}
          removeBtnColor={removeBtnColor}
          size={size}
        />
      </Stack>
      <ChartView
        query={POByNumber}
        variables={{ number: po?.number }}
        currentID={po?.id}
      />
    </Stack>
  );
}
