import { onUpdate } from "../../../function/onUpdate";
import {
  updateMaterialEntityRelation,
  getMaterialEntityRelation,
} from "./query";

export default async function onUpdateRelation({ client, values }) {
  const fieldsToUpdate = [
    { field: "entityID", createUpdate: true },
    { field: "code", createUpdate: true },
    { field: "statusCode", createUpdate: true },
  ];

  await onUpdate({
    type: "MaterialEntityRelation",
    values,
    mutation: updateMaterialEntityRelation,
    query: getMaterialEntityRelation,
    getInitialValue: null,
    fieldsToUpdate,
    client,
    refetchQueries: ["GetMaterial"],
  });
}
