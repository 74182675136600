import { onUpdate } from '../../../function/onUpdate'
import { getMaterial, updateMaterial } from './query'

export default async function onUpdateMaterial({ values, client }) {
  console.log({ values })
  const coo = values.coo?.map(
    (item) => item?.label?.toUpperCase() || item?.toUpperCase(),
  )

  const fieldsToUpdate = [
    { field: 'mpn', createUpdate: true },
    { field: 'mfr', createUpdate: true },
    { field: 'desc', createUpdate: true },
    { field: 'mpq', createUpdate: true },
    { field: 'unit', createUpdate: true },
    { field: 'coo', createUpdate: false },
    { field: 'isDeleted', createUpdate: true },
    { field: 'inventory', createUpdate: true },
    { field: 'importDutyCN', createUpdate: true },
    { field: 'specifications', createUpdate: false },
  ]

  await onUpdate({
    type: 'Material',
    values: { ...values, coo },
    mutation: updateMaterial,
    query: getMaterial,
    // getInitialValue,
    fieldsToUpdate,
    client,
    refetchQueries: [],
  })
}
