export const invoiceByType = /* GraphQL */ `
  query InvoiceByType(
    $type: String!
    $definerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoiceByType(
      type: $type
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        category
        type
        number
        itemNumber
        no @client
        date
        price
        qty
        currency
        note
        desc
        poID
        po {
          id
          customer {
            id
            name
            alias
            abbr @client
          }
          vendor {
            id
            name
            alias
            abbr @client
          }
        }
        paymentDueDate
        billingEntityID
        shippingID
        paymentStatus
        deductionDate
        definerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const relationsByEntity1AndRelatioin = /* GraphQL */ `
  query RelationsByEntity1AndRelatioin(
    $entity1ID: ID!
    $relation: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityRelationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationsByEntity1AndRelatioin(
      entity1ID: $entity1ID
      relation: $relation
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        relation
        definerID
        entity1ID
        entity2ID
        entity2 {
          id
          name
          alias
          abbr @client
        }
        alias
        customerCode
        vendorCode
        logisticsAccountNumber
        logisticsTrackingTemplate
        statusCode
        type
        paymentTerms
        serviceFeeRate
        minCharge
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
