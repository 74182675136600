export const materialByMPN = /* GraphQL */ `
  query MaterialByMPN(
    $mpn: String!
    $mfr: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMaterialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    materialByMPN(
      mpn: $mpn
      mfr: $mfr
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        definerID
        mpn
        mfr

        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const createNote = /* GraphQL */ `
  mutation CreateNote(
    $input: CreateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    createNote(input: $input, condition: $condition) {
      id
      type
      itemID
      message
      createdBy
      groupToRead
      groupToEdit
      groupToDelete
      createdAt
      updatedAt
    }
  }
`;

export const materialEntityRelationByCode = /* GraphQL */ `
  query MaterialEntityRelationByCode(
    $code: String!
    $entityID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMaterialEntityRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    materialEntityRelationByCode(
      code: $code
      entityID: $entityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityID
        entity {
          id
          alias
          name
        }
        materialID
        material {
          id
          mfr
          mpn
        }
        code
        statusCode
        desc
        category
        pdt
        inventoryAlignment
        groupToRead
        groupToEdit
        groupToDelete
        definerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const offerByNumber = /* GraphQL */ `
  query OfferByNumber(
    $number: String!
    $itemNumber: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    offerByNumber(
      number: $number
      itemNumber: $itemNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        number
        itemNumber
        statusCode
        materialID
        materialEntityRelationID
        salesID
        buyerID
        vendorID
        customerID
        definerID
        price
        currency
        inquiryValidDays
        leadtime
        validDays
        paymentTerms
        TP
        TPCurrency
        EAU
        application
        projectName
        endCustomer
        version
        latestVersion
        note
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const POByNumber = /* GraphQL */ `
  query POByNumber(
    $number: String!
    $itemNumber: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPOFilterInput
    $limit: Int
    $nextToken: String
  ) {
    POByNumber(
      number: $number
      itemNumber: $itemNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        itemNumber
        date
        offerID
        customerID
        vendorID
        definerID
        materialID
        materialEntityRelationID
        materialEntityRelation {
          id
          code
        }
        buyerID
        salesID
        qty
        openQty
        price
        priceCur
        paymentTerms
        billingEntityID
        CRD
        shippingStatus
        billingStatus
        paymentStatus
        importAgentID
        paymentAgentID
        forwarderID
        groupToRead
        groupToEdit
        groupToDelete
        version
        latestVersion
        statusCode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const EntitybyAlias = /* GraphQL */ `
  query EntitybyAlias(
    $alias: String!
    $sortDirection: ModelSortDirection
    $filter: ModelEntityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EntitybyAlias(
      alias: $alias
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isVerified
        name
        alias
        type
        businessType
        statusCode
        paymentTerms
        groupToRead
        groupToEdit
        groupToDelete
        definerID
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: AWSEmail!
    $sub: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sub: $sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        email
        statusCode
        fn
        mn
        ln
        phoneNumber
        address
        groupToRead
        groupToEdit
        groupToDelete
        owner
        definerID
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;


export const POByDefinerID = /* GraphQL */ `
  query POByDefinerID(
    $definerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPOFilterInput
    $limit: Int
    $nextToken: String
  ) {
    POByDefinerID(
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amount @client
        statusCode
        number
        itemNumber
        date
        offerID
        customerID
      
        vendorID
       
        definerID
        materialID
        material {
          id
          mfr
          mpn
        }
        buyerID
        salesID
        materialEntityRelationID
        materialEntityRelation {
          id
          code
        }
        qty
        openQty
        price
        priceCur
        paymentTerms
       
        billingEntityID
        CRD
        shippingStatus
        billingStatus
        paymentStatus
        importAgentID
        paymentAgentID
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`