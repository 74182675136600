import { useEffect, useMemo } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Stack, Button, Typography, Chip } from '@mui/material'
import { useFormikContext, useField } from 'formik'
import {
  FormikField,
  FormikSelect,
  FormikAutocomplete,
  FormikArrayRemoveItemButton,
  FormikRadioGroup,
} from '../../../../../../component/formikForm'
import { currencies } from '../../../../../../settings/formFieldEnum'
import filterOutSameItemInArray from '../../../../../../lib/filterOutSameItemInArray'
import mergeArrayAndCombineQty from '../../../../../../lib/mergeArrayAndCombineQty'

export default function ReceivingItemSubArray({
  index,
  remove,
  arrayFieldName,
  removeBtnColor,
  size,
}) {
  const { values, setFieldValue } = useFormikContext()

  let parentLine = arrayFieldName?.split('][')

  let poped = parentLine?.pop()

  parentLine = parentLine.map((x) => x.replace('[', ''))
  parentLine = parentLine.map((x) => `[${x}]`)
  parentLine = parentLine.join('')

  const [parentLineField, meta, helpers] = useField({ name: parentLine })

  const parentLineQty = parentLineField.value?.qty * 1
  const availableInvoices = parentLineField.value?.availableInvoices
  // console.log({ availableInvoices });

  const thisLineName = `${arrayFieldName}[${index}]`

  const [thisLineField] = useField({ name: thisLineName })

  const thisLineQty = thisLineField.value?.qty * 1

  const thisLineOpenQty = thisLineField.value?.openQty * 1

  const thisLinePIID = thisLineField.value?.piID

  // console.log({ parentLineQty, thisLineQty, thisLineOpenQty });

  // const [field, meta, helpers] = useField({ name: parentLine });
  // let value = parentLineField.value;
  // const lineQty = value.qty * 1;

  return (
    <Stack spacing={2}>
      <Stack
        direction='row'
        spacing={2}
        alignItems='flex-end'
        justifyContent='space-between'>
        <FormikField
          width={180}
          name={`[${arrayFieldName}][${index}][itemNumber]`}
          label='Item Number'
          disabled
        />
        <FormikField
          width={180}
          name={`[${arrayFieldName}][${index}][openQty]`}
          label='Open Qty'
          disabled
        />

        <FormikField
          width={180}
          name={`[${arrayFieldName}][${index}][receivedQty]`}
          label='Receive Qty'
          type='number'
          // disabled
        />
        {availableInvoices && availableInvoices.length > 0 && (
          <Stack>
            <Typography sx={{ alignSelf: 'center' }}>
              Please select invoice
            </Typography>

            <Stack direction='row' spacing={2}>
              {availableInvoices.map(({ id, currency, price, qty, date }) => (
                <Chip
                  key={id}
                  {...(id === thisLinePIID && { color: 'secondary' })}
                  label={`${date}-${currency}${price} * ${qty}`}
                  onClick={() => {
                    setFieldValue(`[${arrayFieldName}][${index}][piID]`, id)
                  }}
                  onDelete={() => {
                    setFieldValue(`[${arrayFieldName}][${index}][piID]`, null)
                  }}
                />
              ))}
            </Stack>
          </Stack>
        )}

        {/* <FormikField
          width={200}
          name={`[${arrayFieldName}][${index}][CRD]`}
          label="CRD"
          type="date"
        /> */}
        {/* <FormikArrayRemoveItemButton
          onClick={() => remove(index)}
          name={arrayFieldName}
          removeBtnColor={removeBtnColor}
          size={size}
        /> */}
      </Stack>
    </Stack>
  )
}
