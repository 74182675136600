import { onDelete } from "../../../function/onDelete";
import { deletePayment } from "./query";
import updateInvoicePaymentStatusFromArray from "../../../function/utilityAPI/updateInvoicePaymentStatusFromArray";

export default async function onDeletePayment({
  selectionModel,
  setSelectionModel,
  client,
  values,
}) {
//DON'T CHANGE SEQUENCE
  await updateInvoicePaymentStatusFromArray({
    client,
    arr: selectionModel || [values],
    type: "deduct",
  });


  if (selectionModel) {
    await Promise.all(
      selectionModel?.map(async (items) => {
        await deleteSingleItem(items, client);
      })
    );
    setSelectionModel && setSelectionModel([]);
  } else if (values) {
    await deleteSingleItem(values, client);
  }


}

async function deleteSingleItem(item, client) {
  // console.log({ item, client });
  const { id, number } = item || {};

  await onDelete({
    input: { id },
    mutation: deletePayment,
    // updateCacheFields: ["relationsByCustomerID"],
    client,
    storagePath: number,
  });
}
