// import { useState, useEffect } from "react";
// import usePrevious from "../../function/hook/usePrevious";
import { useFormikContext, useField } from "formik";
import {
  TextField,
  Select,
  Stack,
  Box,
  Autocomplete,
  Checkbox,
  ListItemText,
} from "@mui/material";
import FieldErrorMessage from "./FieldErrorMessage";
import isObjectOrFunction from "../../lib/isObjectOrFunction";

export default function FormikAutocomplete({
  label,
  name,
  width,
  type,
  options,
  multiple,
  freeSolo,
  required,
  getOptionDisabled,
  disableCloseOnSelect,
  noOptionsText,
  getOptionLabel,
  listItemPrimaryField,
  listItemSecondaryField,
  getPrimaryText,
  getSecondaryText,
  disabled,
  onChange,
  isOptionEqualToValue,
  // value,
}) {
  const [field, meta, helpers] = useField({ name, type });

  const { setValue, setTouched, setError } = helpers;

  // console.log({ setValue });

  // const [value, setValue] = useState("");

  const fieldValue = field.value;

  // const fieldValue = field.value || (multiple ? [] : null);

  // const previousValue = usePrevious(fieldValue);
  // const currentValue = fieldValue;

  // useEffect(() => {
  //   // console.log({ name, previousValue, currentValue });
  //   if (fieldValue !== previousValue) {
  //     // console.log({ name, currentValue });
  //     setValue(fieldValue || "");
  //     // setTouched(true);
  //   }
  // }, [fieldValue, setValue, name, previousValue]);

  let value = fieldValue != null ? fieldValue : multiple ? [] : null;

  // console.log({ fieldValue });

  // console.log({ value });

  if (
    typeof value === "object" &&
    !Array.isArray(value) &&
    value !== null &&
    Object.keys(value).length === 0
  ) {
    value = null;
  }

  return (
    <Stack spacing={1}>
      <Autocomplete
        required
        multiple={multiple}
        id={name}
        disabled={disabled}
        name={name}
        freeSolo={freeSolo}
        disableCloseOnSelect={disableCloseOnSelect || multiple}
        getOptionDisabled={getOptionDisabled}
        // value={fieldValue || (multiple ? [] : null)}
        value={value}
        options={options || []}
        onChange={async (e, v) => {
          // console.log({ e, v, name });
          // debugger;
          setValue(v || null);
          onChange && (await onChange(e, v));
          // setFieldValue(name, v || "");
        }}
        onInputChange={(event, value, reason) => {
          // console.log({ name, value, reason });

          const option = options?.find(
            (x) =>
              x?.label?.toLowerCase() === value?.toLowerCase() ||
              x?.name?.toLowerCase() === value?.toLowerCase()
          );

          // ATTENTION THINK ABOUT SETFIELDVALUE OR NOT (CAUSED INFINTE LOOP TO CONTACT)!

          if (option) {
            // console.log({ fieldValue, option });
            // debugger;
            // setFieldValue(name, option);
          } else {
            // ATTENTION ONLY SETFIELDVALUE ON FREESOLO!!! OTHERWISE IT MIGHT CAUSE INFINITE LOOP!!

            // debugger

            if (freeSolo) {
              // console.log({name,value})
              // setFieldValue(name, value);
              setValue(value);
            }
            // setFieldValue(name, "");
            // setFieldValue(name, value);
          }

          // !multiple &&
          // freeSolo &&
          //   defaultHandleChange(
          //     null,
          //     trimmedFieldValue(optionAttr || fieldName, value)
          //   );
        }}
        onBlur={() => {
          setTouched();
        }}
        noOptionsText={noOptionsText}
        getOptionLabel={(option) => {
          // console.log({ option });
          if (isObjectOrFunction(option)) {
            if (Object.keys(option) === 0) {
              return "";
            } else {
              if (getOptionLabel) {
                return getOptionLabel(option);
              } else {
                return option || "UNKNOWN";
              }
            }
          } else {
            return option || "UNKNOWN";
          }
        }}
        sx={{ width: width || 500 }}
        isOptionEqualToValue={(option, value) => {
          return isOptionEqualToValue
            ? isOptionEqualToValue(option, value)
            : option.id && value.id
            ? option.id === value.id
            : option === value;
        }}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props}>
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              <ListItemText
                primary={
                  listItemPrimaryField
                    ? option?.[listItemPrimaryField]
                    : getPrimaryText
                    ? getPrimaryText(option)
                    : option?.name || option?.label || option || "UNKNOWN"
                }
                secondary={
                  listItemSecondaryField
                    ? option?.[listItemSecondaryField]
                    : getSecondaryText
                    ? getSecondaryText(option)
                    : null
                }
                // {...(listItemSecondaryField && {
                //   secondary: option?.[listItemSecondaryField],
                // })}
              />
            </li>
          );
        }}
        renderInput={(params) => {
          // console.log({ params });
          // console.log({ id: params.id, value: params.inputProps.value });
          // debugger;
          return (
            <TextField
              {...params}
              autoComplete="off"
              label={label}
              required={required}
              InputProps={{
                ...params.InputProps,
                // type: "search",
              }}
            />
          );
        }}
      />
      <Box sx={{ height: "5px" }}>
        <FieldErrorMessage name={name} meta={meta} />
      </Box>
    </Stack>
  );
}
