import { useState } from "react";
import { API, Hub } from "aws-amplify";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import {
  Stack,
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel,
  Typography,
  MenuItem,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import FileZone from "../../../component/FileZone";
import Notes from "../../../component/Notes";
import Updates from "../../../component/Updates";
import Item from "./components/Item";
import DataGridComp from "../../../component/DataGrid";
import getFilteredItemsByUserRole from "../../../function/getFilteredItemsByUserRole";
import CreateInboundInvoiceStepForm from "./components/CreateInboundInvoiceStepForm";
import { getInvoice, invoiceByType } from "./query";
import { POByNumber } from "../SPOs/query";
import onDeleteInvoice from "../billing/onDelete";
import ChartView from "../../../component/ChartView";
import { invoiceStatusCode } from "../../../settings/formFieldEnum";
import { userEntityRelationVar } from "../../../client/cache";

function ItemView({ lineData: { id }, onClose, userRole }) {
  const { entityID } = useReactiveVar(userEntityRelationVar);

  let { data } = useQuery(gql(getInvoice), {
    variables: { id },
  });
  data = data?.getInvoice;

  if (!data) return null;

  const storagePath = `${entityID}/inboundInvoices/${data?.number}`;

  const po = data?.po || {};

  const { date, number, itemNumber, vendor, customer } = po;

  return (
    <Stack
      justifyContent="space-between"
      sx={{ width: 1500, height: 900, padding: 2 }}
    >
      <Stack spacing={5}>
        <Item
          data={{ ...data, definerID: entityID }}
          userRole={userRole}
          onClose={onClose}
        />

        <ChartView currentID={id} query={POByNumber} variables={{ number }} />

        <FileZone storagePath={storagePath} />
        <Notes itemID={id} />
        <Updates itemID={id} />
      </Stack>

      <Button
        variant="contained"
        sx={{ alignSelf: "center", justifySelf: "flex-end" }}
        onClick={onClose}
      >
        back
      </Button>
    </Stack>
  );
}

function Comp({ entityType, userRole, isAuthenticated, entityID }) {
  const isAdmin = userRole?.includes("admin");

  const queryVariables = {
    type: "INBOUND",
    definerID: { eq: entityID },
  };
  const columns = [
    {
      field: "paymentDueDate",
      headerName: "PaymentDueDate",
      width: 150,
      userRole: ["admin"],
      renderCell: ({ row, ...props }) => {
        return (
          <Typography
            {...(new Date(row.paymentDueDate).getTime() < Date.now() &&
              row.paymentStatus !== "PAID" && {
                sx: { bgcolor: "red", color: "white" },
              })}
          >
            {row?.paymentDueDate}
          </Typography>
        );
      },
    },
    {
      field: "deductionDate",
      headerName: "抵扣日期",
      width: 150,
      userRole: ["admin", "sales", "accounting"],
    },
    {
      field: "status",
      headerName: "status",
      width: 150,
      userRole: ["admin"],
    },
    {
      field: "paymentStatus",
      headerName: "paymentStatus",
      width: 150,
      userRole: ["admin", "sales", "accounting"],
    },
    {
      field: "category",
      headerName: "Category",
      width: 120,
      userRole: ["admin", "sales", "accounting"],
    },
    {
      field: "taxRate",
      headerName: "taxRate",
      width: 120,
      userRole: ["admin", "sales", "accounting"],
    },
    {
      field: "forwarderPONumber",
      headerName: "forwarderPONumber",
      width: 150,
      userRole: ["admin", "sales", "accounting"],
    },
    {
      field: "date",
      headerName: "Date",
      width: 180,
      userRole: ["admin", "sales", "accounting"],
    },

    {
      field: "no",
      headerName: "Number",
      width: 240,
      userRole: ["admin", "sales", "accounting"],
    },

    {
      field: "customer",
      headerName: "Customer",
      width: 180,
      userRole: ["admin", "sales", "accounting"],
    },
    {
      field: "vendor",
      headerName: "vendor",
      width: 180,
      userRole: ["admin", "sales", "accounting"],
    },
    {
      field: "poNumber",
      headerName: "PO Number",
      width: 240,
      userRole: ["admin", "sales", "accounting"],
    },
    {
      field: "code",
      headerName: "Code",
      width: 150,
      userRole: ["admin", "sales", "accounting"],
    },

    {
      field: "mfr",
      headerName: "MFR",
      width: 180,
      userRole: ["admin", "sales", "accounting"],
    },
    {
      field: "mpn",
      headerName: "MPN",
      width: 180,
      userRole: ["admin", "sales", "accounting"],
    },
    {
      field: "qty",
      headerName: "Qty",
      width: 150,
      userRole: ["admin", "sales", "accounting"],
    },
    {
      field: "unit",
      headerName: "Unit",
      width: 150,
      userRole: ["admin", "sales", "accounting"],
    },
    {
      field: "price",
      headerName: "price",
      width: 150,
      userRole: ["admin", "sales", "accounting"],
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      userRole: ["admin", "sales", "accounting"],
    },
    {
      field: "desc",
      headerName: "Desc",
      width: 300,
      userRole: ["admin", "sales", "accounting"],
    },
  ];
  const dropdownMenu = [
    {
      label: "Delete",
      userRole: ["admin"],
      onClick: async ({ client, selectionModel, setSelectionModel }) => {
        await onDeleteInvoice({
          selectionModel,
          setSelectionModel,
          client,
        });
      },
    },
    {
      label: "Generate Forwarder PO",
      userRole: ["admin"],
      onClick: async ({ client, selectionModel, setSelectionModel }) => {
        if (selectionModel.some((x) => x.forwarderPO)) {
          return alert("Some of the selected items already have fowarder PO");
        }
        Hub.dispatch("dialogMonitor", {
          event: "forwarderPOGen",
          data: {
            selectionModel,
            setSelectionModel,
            definerID: entityID,
            // attrs: ["price", "priceCur", "paymentTerms"],
            // model:'CPO'
          },
        });
        // await onDeletePO({
        //   selectionModel,
        //   setSelectionModel,
        //   client,
        // });
      },
    },
    {
      label: "Batch Update",
      userRole: ["admin", "accounting"],
      onClick: async ({ client, selectionModel, setSelectionModel }) => {
        Hub.dispatch("dialogMonitor", {
          event: "batchUpdate",
          data: {
            selectionModel,
            setSelectionModel,
            attrs: [
              { label: "deductionDate", type: "date" },

              ...(isAdmin
                ? [
                    { label: "number" },
                    { label: "paymentDueDate", type: "date" },
                    { label: "paymentStatus" },
                  ]
                : []),
            ],
            model: "Invoice",
          },
        });
        // await onDeletePO({
        //   selectionModel,
        //   setSelectionModel,
        //   client,
        // });
      },
    },
  ];

  const filteredColumns = getFilteredItemsByUserRole(columns, userRole);

  const filteredDropdownMenu = getFilteredItemsByUserRole(
    dropdownMenu,
    userRole
  );

  const prepareItemsFunc = (items) => {
    // console.log({ items });
    return items.map((x) => {
      return {
        ...x,
        poNumber: x.po?.no,
        customer: x.po?.customer?.abbr || x.billingEntity?.abbr,
        vendor: x.po?.vendor?.abbr || x.issuer?.abbr,
        code: x.po?.materialEntityRelation?.code,
        mfr: x.po?.material?.mfr,
        mpn: x.po?.material?.mpn,
        unit: x.po?.material?.unit,
        forwarderPONumber: x.forwarderPO?.no,
        status: invoiceStatusCode.find((y) => y.value == x.statusCode)?.label,
      };
    });
  };

  const toolbarFieldAddon = [
    {
      comp: null,
      element: <FXRateField />,
      userRole: ["admin"],
    },
  ];

  const filteredToolbarFieldAddon = getFilteredItemsByUserRole(
    toolbarFieldAddon,
    userRole
  );

  const sortBy = (arr) => {
    arr.sort((a, b) => (a.itemNumber * 1 < b.itemNumber * 1 ? -1 : 1));

    arr.sort((a, b) => (a.number < b.number ? 1 : -1));

    arr.sort((a, b) => (new Date(a.date) < new Date(b.date) ? 1 : -1));

    return arr;
  };
  const filterAttrs = [
    { label: "category", value: "category", type: "string" },
    { label: "date", value: "date", type: "date" },
    { label: "code", value: "code", type: "string" },
    { label: "deductionDate", value: "deductionDate", type: "date" },
    { label: "desc", value: "desc", type: "string" },
    { label: "currency", value: "currency", type: "string" },
    { label: "forwarderPONumber", value: "forwarderPONumber", type: "string" },
    { label: "mfr", value: "mfr", type: "string" },
    {
      label: "mpn",
      value: "mpn",
      type: "string",
    },
    { label: "number", value: "number", type: "string" },
    { label: "paymentStatus", value: "paymentStatus", type: "string" },
    { label: "poNumber", value: "poNumber", type: "string" },
    { label: "vendor", value: "vendor", type: "string" },
  ];
  return (
    <DataGridComp
      query={invoiceByType}
      queryVariables={queryVariables}
      sortBy={sortBy}
      onClickAddRoute="new"
      columns={filteredColumns}
      // DetailsDialog={isAdmin ? ItemView : null}
      DetailsDialog={ItemView}
      userRole={userRole}
      checkboxSelection
      getDropdownMenu={filteredDropdownMenu}
      prepareItemsFunc={prepareItemsFunc}
      ToolbarInsert={ToolbarInsert}
      filterAttrs={filterAttrs}
      toolbarFieldAddon={filteredToolbarFieldAddon}
    />
  );
}

const incoming_invoices = {
  path: "incoming_invoices",

  sideNav: {
    group: 2,
    order: 50,
    label: "Incoming Invoices",
    entityType: ["100", "300"],
    allowedRole: ["admin", "buyer", "accounting"],
    auth: ["authenticated"],
  },
  route: {
    index: {
      element: null,
      Comp,
      allowedRole: ["admin", "buyer", "accounting"],
      entityType: "all",
      auth: ["authenticated"],
    },
    new: {
      element: <CreateInboundInvoiceStepForm />,
      allowedRole: ["admin", "buyer", "accounting"],
      entityType: "all",
      auth: ["authenticated"],
    },
  },
};

function ToolbarInsert({ selectionModel }) {
  return !selectionModel?.length ? null : (
    <Stack
      direction="row"
      spacing={2}
      color="secondary.main"
      sx={{ position: "relative", top: -7 }}
    >
      <Typography variant="title" sx={{ fontWeight: 500 }}>
        SELECTED QTY:{" "}
        {selectionModel.reduce((a, v) => a + (v?.qty || 0), 0).toFixed(3) * 1}
      </Typography>

      <Typography variant="title" sx={{ fontWeight: 500 }}>
        AMOUNT:{" "}
        {selectionModel
          .reduce((a, v) => a + (v?.qty * v?.price || 0), 0)
          .toFixed(3) * 1}
      </Typography>

      <Typography variant="title" sx={{ fontWeight: 500 }}>
        TAX:{" "}
        {selectionModel
          .reduce((a, v) => a + (v?.qty * v?.price * v.taxRate || 0), 0)
          .toFixed(3) * 1}
      </Typography>

      <Typography variant="title" sx={{ fontWeight: 500 }}>
        INCLUDE TAX:{" "}
        {selectionModel
          .reduce(
            (a, v) => a + (v?.qty * v?.price * (1 + v?.taxRate || 0) || 0),
            0
          )
          .toFixed(3) * 1}
      </Typography>
    </Stack>
  );
}

export default incoming_invoices;

function FXRateField() {
  const currentDate = new Date();
  let chinaTime = currentDate.toLocaleString("zh-CN", {
    timeZone: "Asia/Shanghai",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false, // Use 24-hour format
  });

  chinaTime = chinaTime.substring(0, 10).replace(/\//g, "-");

  const [fxRate, setFxRate] = useState("");
  const [fetching, setFetching] = useState(false);
  const [currency, setCurrency] = useState("美元");
  const [date, setDate] = useState(chinaTime);

  // console.log({ date, currency, fxRate });
  // useEffect(() => {
  //   async function func() {
  //     setFxRate(data?.getFXRate);
  //   }
  // }, []);

  async function getFXRate() {
    setFetching(true);
    try {
      const res = await API.post("iSupplyAPI", "/restricted", {
        body: {
          operation: "GET_FX_RATE",
          data: {
            currency,
            date,
            time: "09:30:00",
          },
        },
      });
      // console.log({ res });
      if (res * 1) {
        setFxRate(res * 1);
      }
    } catch (err) {
      console.log({ error3333: err });
    }
    setFetching(false);
  }

  return (
    <>
      <Stack direction="row" gap={2}>
        <FormControl>
          <TextField
            type="date"
            size="small"
            sx={{ width: 150 }}
            value={date}
            label="Date"
            onChange={(e) => {
              setDate(e.target.value);
            }}
          />
        </FormControl>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Currency</InputLabel>
          <Select
            sx={{ width: 100 }}
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={currency}
            label="Currency"
            onChange={(e) => {
              setCurrency(e.target.value);
            }}
          >
            <MenuItem value="美元">USD</MenuItem>
            <MenuItem value={"欧元"}>EUR</MenuItem>
          </Select>
        </FormControl>
        <LoadingButton
          sx={{ flexShrink: 0 }}
          variant="contained"
          size="small"
          loading={fetching}
          onClick={async () => {
            await getFXRate();
          }}
        >
          Get FX Rate
        </LoadingButton>
      </Stack>
      <TextField
        variant="standard"
        label="FX Rate"
        size="small"
        value={fxRate}
        type="number"
        sx={{ width: 100, flexShrink: 0 }}
        InputProps={{
          sx: { height: 40 },
          inputProps: { min: 0, max: 30 },
        }}
        onChange={(e) => {
          setFxRate(e.target.value);
        }}
      />
    </>
  );
}
