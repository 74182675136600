export const getOffer = /* GraphQL */ `
  query GetOffer($id: ID!) {
    getOffer(id: $id) {
      id
      date
      number
      itemNumber
      statusCode
      materialID
      material {
        id
        definerID
        mpn
        mfr
        coo
        desc
      }
      materialEntityRelationID
      materialEntityRelation {
        id
        entityID
        materialID
        code
      }
      salesID
      sales {
        id
        email
        fn
        mn
        ln
        name @client
      }
      buyerID
      buyer {
        id
        email
        fn
        mn
        ln
        name @client
      }
      vendorID
      vendor {
        id
        name
        alias
      }
      customerID
      customer {
        id
        name
        alias
      }
      definerID

      price
      moq
      currency
      leadtime
      validDays
      incoTerms {
        terms
        condition
        entityUserRelationID
      }

      paymentTerms
      TP
      TPCurrency
      EAU
      application
      projectName
      endCustomer
      version
      latestVersion
      note
      groupToRead
      groupToEdit
      groupToDelete
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const updateOffer = /* GraphQL */ `
  mutation UpdateOffer(
    $input: UpdateOfferInput!
    $condition: ModelOfferConditionInput
  ) {
    updateOffer(input: $input, condition: $condition) {
      id
      date
      number
      itemNumber
      materialID
      materialEntityRelationID
      statusCode
      salesID
      buyerID
      vendorID
      customerID
      price
      moq
      currency
      leadtime
      validDays
      incoTerms {
        terms
        condition
        entityUserRelationID
      }
      paymentTerms
      TP
      TPCurrency
      EAU
      note
      application
      projectName
      endCustomer
      version
      latestVersion
      createdAt
      updatedAt
    }
  }
`;

export const deleteOffer = /* GraphQL */ `
  mutation DeleteOffer(
    $input: DeleteOfferInput!
    $condition: ModelOfferConditionInput
  ) {
    deleteOffer(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createOffer = /* GraphQL */ `
  mutation CreateOffer(
    $input: CreateOfferInput!
    $condition: ModelOfferConditionInput
  ) {
    createOffer(input: $input, condition: $condition) {
      id
      date
      number
      itemNumber
      statusCode
      materialID

      materialEntityRelationID
      date
      salesID

      buyerID

      vendorID

      customerID

      definerID

      price
      moq
      currency
      leadtime
      validDays
      incoTerms {
        terms
        condition
        entityUserRelationID
      }
      paymentTerms
      TP
      TPCurrency
      EAU
      application
      projectName
      endCustomer
      version
      latestVersion
      note

      groupToRead
      groupToEdit
      groupToDelete
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const fragment = /* GraphQL */ `
  fragment NewOffer on Offer {
    id
  }
`;
export const relationsByDefiner = /* GraphQL */ `
  query RelationsByDefiner(
    $definerID: ID!
    $relation: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityRelationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationsByDefiner(
      definerID: $definerID
      relation: $relation
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        relation
        definerID
        entity1ID
        entity1 {
          id
          alias
          name
        }
        entity2ID
        entity2 {
          id
          name
          alias
          relationsAsEntity2 {
            items {
              id
              relation
              entity1ID
            }
          }
        }
        alias
        customerCode
        vendorCode
        statusCode
        type
        incoTerms {
          terms
          condition
          entityUserRelationID
        }
        paymentTerms
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const MaterialEntityRelationsByDefiner = /* GraphQL */ `
  query MaterialEntityRelationsByDefiner(
    $definerID: ID!
    $entityID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMaterialEntityRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    MaterialEntityRelationsByDefiner(
      definerID: $definerID
      entityID: $entityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityID
        materialID
        material {
          id
          mfr
          mpn
        }
        code
        statusCode
        desc
        category
        pdt
        inventoryAlignment
        groupToRead
        groupToEdit
        groupToDelete
        definerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const entitiyUserRelationByEntityID = /* GraphQL */ `
  query EntitiyUserRelationByEntityID(
    $entityID: ID!
    $definerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityUserRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entitiyUserRelationByEntityID(
      entityID: $entityID
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        title
        shownFn
        shownLn
        shownMn
        statusCode
        isEmailVerified
        userID
        user {
          id
          fn
          ln
          mn
          name @client
        }
        entityID
        definerID
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const offerByVendorAndDefiner = /* GraphQL */ `
  query OfferByVendorAndDefiner(
    $vendorID: ID!
    $definerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    offerByVendorAndDefiner(
      vendorID: $vendorID
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        date
        id
        number
        itemNumber
        statusCode
        materialID
        material {
          id
          mpn
          mfr
          desc
        }
        materialEntityRelationID
        materialEntityRelation {
          id
          entityID
          materialID
          code
        }
        salesID
        buyerID
        vendorID
        customerID
        definerID
        price
        currency
        leadtime
        validDays
        incoTerms {
          terms
          condition
          entityUserRelationID
        }
        paymentTerms
        TP
        TPCurrency
        EAU
        application
        projectName
        endCustomer
        version
        latestVersion
        note
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const offerByDefiner = /* GraphQL */ `
  query OfferByDefiner(
    $definerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    offerByDefiner(
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        number
        itemNumber
        statusCode
        materialID
        material {
          id
          mpn
          mfr
          desc
        }
        materialEntityRelationID
        materialEntityRelation {
          id
          entityID
          materialID
          code
        }
        salesID
        sales {
          id
          fn
          ln
          mn
          name @client
        }
        buyerID
        buyer {
          id
          fn
          ln
          mn
          name @client
        }
        vendorID
        vendor {
          id
          name
          alias
        }
        customerID
        customer {
          id
          name
          alias
        }
        definerID
        price
        currency
        leadtime
        validDays
        incoTerms {
          terms
          condition
          entityUserRelationID
        }
        paymentTerms
        TP
        TPCurrency
        EAU
        application
        projectName
        endCustomer
        version
        latestVersion
        note
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const offerByNumber = /* GraphQL */ `
  query OfferByNumber(
    $number: String!
    $itemNumber: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    offerByNumber(
      number: $number
      itemNumber: $itemNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        number
        moq
        itemNumber
        statusCode
        materialID
        material {
          id
          mpn
          mfr
          desc
          mpq
          unit
        }
        materialEntityRelationID
        materialEntityRelation {
          id
          entityID
          materialID
          code
        }
        salesID
        sales {
          id
          email
          fn
          ln
          phoneNumber
          address
          name @client
        }
        buyerID
        buyer {
          id
          email
          fn
          ln
          phoneNumber
          address
          name @client
        }
        vendorID
        vendor {
          id
          name
          alias
          address
          phoneNumber
        }
        customerID
        customer {
          id
          name
          alias
        }
        definerID
        price
        currency
        inquiryValidDays
        leadtime
        validDays
        paymentTerms
        incoTerms {
          terms
          condition
          entityUserRelationID
        }
        TP
        TPCurrency
        EAU
        application
        projectName
        endCustomer
        version
        latestVersion
        note
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const relationsByEntity1 = /* GraphQL */ `
  query RelationsByEntity1(
    $entity1ID: ID!
    $entity2ID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityRelationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationsByEntity1(
      entity1ID: $entity1ID
      entity2ID: $entity2ID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        relation
        definerID
        entity1ID
        entity2ID

        alias
        customerCode
        vendorCode
        statusCode
        type
        paymentTerms
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
