import { onCreate } from "../../../function/onCreate";
import { createOffer, fragment } from "./query";

export default async function onCreateQuote({ client, values }) {
  // console.log({ values });
  // debugger;

  const { data: createOfferData, errors: createOfferErrors } = await onCreate({
    input: values,
    mutation: createOffer,
    fragment,
    updateCacheFields: ["offerByDefiner"],
    client,
    refetchQueries: [],
  });
}

export async function onCreateOffer({ client, values, statusCode }) {
  const {
    date,
    quotes,
    buyer,
    sales,
    number,
    customer,
    vendor,
    definerID,
    incoTerms,
    paymentTerms,
  } = values;

  if (!quotes || quotes?.length === 0) return;

  await Promise.all(
    quotes.map(
      async ({
        currency,
        itemNumber,
        leadtime,
        moq,
        material: { id, materialID, relationID },
        price,
        validDays,
        note,
      }) => {
        const values = {
          date,
          materialID: id || materialID,
          materialEntityRelationID: relationID,
          number,
          itemNumber,
          salesID: sales.id,
          buyerID: buyer.id,
          vendorID: vendor.id,
          customerID: customer?.id,
          definerID,
          currency,
          price,
          moq,
          leadtime,
          validDays,
          paymentTerms,
          incoTerms,
          statusCode: statusCode || "200",
          note,
        };

        await onCreateQuote({ client, values });

        // console.log({ input });
        // debugger;
      }
    )
  );
}
