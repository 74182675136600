export const getEntity = /* GraphQL */ `
  query GetEntity($id: ID!) {
    getEntity(id: $id) {
      id
      name
      address
      phoneNumber
      __typename
    }
  }
`;

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      fn
      mn
      ln
      name @client
      email
      phoneNumber
      address
      __typename
    }
  }
`;

export const getEntityUserRelation = /* GraphQL */ `
  query GetEntityUserRelation($id: ID!) {
    getEntityUserRelation(id: $id) {
      id
      #   role
      #   title
      #   shownFn
      #   shownLn
      #   shownMn
      #   statusCode
      #   isEmailVerified
      userID
      user {
        id
        sub
        email
        statusCode
        fn
        mn
        ln
        name @client
        phoneNumber
        address

        __typename
      }
      entityID
      entity {
        id
        isVerified
        name
        alias
        type
        businessType
        statusCode
        paymentTerms
        groupToRead
        groupToEdit
        groupToDelete
        definerID
        isDeleted
        createdAt
        updatedAt
        __typename
      }

      groupToRead
      groupToEdit
      groupToDelete
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const getMaterial = /* GraphQL */ `
  query GetMaterial($id: ID!) {
    getMaterial(id: $id) {
      id
      mfr
      mpn
      coo
      desc
      unit
      mpq
      __typename
    }
  }
`;

export const getMaterialEntityRelation = /* GraphQL */ `
  query GetMaterialEntityRelation($id: ID!) {
    getMaterialEntityRelation(id: $id) {
      id
      entityID

      materialID

      code

      createdAt
      updatedAt
      __typename
    }
  }
`;

export const mailEntityRelationByEntityRelation = /* GraphQL */ `
  query MailEntityRelationByEntityRelation(
    $entityRelationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMailEntityRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mailEntityRelationByEntityRelation(
      entityRelationID: $entityRelationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        userID
        user {
          id
          fn
          ln
          mn
          name @client
          email
        }
        entityRelationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
