import { useState } from "react";
import { gql, useQuery, useApolloClient } from "@apollo/client";
import {
  Stack,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import onUpdateShipping from "../../onUpdate";

const relationsByDefiner = /* GraphQL */ `
  query RelationsByDefiner(
    $definerID: ID!
    $relation: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityRelationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationsByDefiner(
      definerID: $definerID
      relation: $relation
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        relation
        definerID
        entity1ID
        entity2ID
        entity2 {
          id
          name
          alias
          abbr @client
        }
        alias
        customerCode
        vendorCode
        logisticsAccountNumber
        logisticsTrackingTemplate
        statusCode
        type
        paymentTerms
        serviceFeeRate
        minCharge
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export default function TrackingInfo({ shippingItems, definerID }) {
  const client = useApolloClient();
  const { courierID, trackingNumber } =
    shippingItems?.find((x) => x.itemNumber * 1 === 1) || {};
  const [shippingAgentID, setShippingAgentID] = useState(courierID || "");
  const [tracking, setTracking] = useState(trackingNumber);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data } = useQuery(gql(relationsByDefiner), {
    variables: { definerID, relation: { eq: "PRINCIPAL-CONTRACTOR" } },
  });

  if (!data) return null;

  const couriersOptions =
    data?.relationsByDefiner?.items
      ?.filter((x) => x.type === "logistics")
      ?.map((x) => ({
        ...x.entity2,
        logisticsTrackingTemplate: x.logisticsTrackingTemplate,
      })) || [];

  async function handleClick() {
    if (!isEditing) {
      setIsEditing(true);
    } else {
      if (!tracking || !shippingAgentID) {
        return alert("please input both of the fields");
      } else {
        setLoading(true);
        const id = shippingItems?.find((x) => x.itemNumber * 1 === 1)?.id;
        const input = {
          id,
          trackingNumber: tracking,
          courierID: shippingAgentID,
        };
        // console.log({ input, shippingItems })
        // debugger
        await onUpdateShipping({ values: input, client });
        await client.refetchQueries({
          include: ["RelationsByDefiner"],
        });

        setLoading(false);
        setIsEditing(false);
      }
    }
  }

  return (
    <Stack direction="row" spacing={2} alignItems="flex-end">
      <FormControl fullWidth disabled={!isEditing}>
        <InputLabel id="demo-simple-select-label">Courier</InputLabel>
        <Select
          value={shippingAgentID}
          label="Courier"
          onChange={(e) => {
            setShippingAgentID(e.target.value);
          }}
        >
          {couriersOptions?.map(({ id, abbr }) => (
            <MenuItem key={id} value={id}>
              {abbr}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        disabled={!isEditing}
        sx={{ width: 260 }}
        label="trackingNumber"
        value={tracking || ""}
        onChange={(e) => {
          setTracking(e.target.value);
        }}
      />

      <LoadingButton
        loading={loading}
        onClick={handleClick}
        size="sm"
        variant="contained"
      >
        {isEditing ? "update" : "edit"}
      </LoadingButton>
    </Stack>
  );
}
