import { onUpdate } from "../../../function/onUpdate";
import { getInventoryHistory, updateInventoryHistory } from "./query";

export default async function onUpdateInventory({ values, client }) {
  const fieldsToUpdate = [
    { field: "date", createUpdate: false },
    { field: "timeStamp", createUpdate: false },
    { field: "quantity", createUpdate: true },
  ];

  return await onUpdate({
    type: "InventoryHistory",
    values,
    mutation: updateInventoryHistory,
    query: getInventoryHistory,
    getInitialValue: null,
    fieldsToUpdate,
    client,
    refetchQueries: null,
  });
}

