import { onCreate } from "../../../function/onCreate";
import { createEntityUserRelation } from "./query";

export default async function onCreateRelation({ client, values }) {
  const { id, role, userID, definerID, entity, entityID, title } = values;

  const inputForCreateUserEntityRelation = {
    id,
    role: role?.toLowerCase(),
    userID,
    entityID: entity?.id || entityID,
    definerID,
    title: title?.trim(),
  };

  const fragment = /* GraphQL */ `
    fragment NewEntityUserRelation on EntityUserRelation {
      id
    }
  `;

  await onCreate({
    input: inputForCreateUserEntityRelation,
    mutation: createEntityUserRelation,
    fragment,
    updateCacheFields: [""],
    client,
    refetchQueries: ["GetUser"],
  });
}
