import { useApolloClient } from '@apollo/client'
import * as Yup from 'yup'
import { Stack } from '@mui/material'
import FormikForm, { FormikField } from '../../../../component/formikForm'
import onDelete from '../onDelete'
import onUpdate from '../onUpdate'

const validationSchema = Yup.object().shape({})

export default function Item({ data, onClose }) {

  const client = useApolloClient()

  const onDeleteBtnClick = async () => {
    if (window.confirm('Are you sure you wish to delete this item?')) {
      await onDelete({ values: data, client })
      onClose()
    }
  }

  const onSubmit = async ({ values, client }) => {
    await onUpdate({
      values,
      client,
    })
    onClose && onClose()
  }

  return (
    <FormikForm
      initialValues={{ ...data, isEditing: false }}
      validationSchema={validationSchema}
      FormMainFields={EditFields}
      FormArray={null}
      // handleCancel={onClose}
      handleCancel={({ setFieldValue, setValues }) => {
        setValues(data)
        setFieldValue('isEditing', false)
      }}
      saveButtonLabel='update'
      onSubmit={onSubmit}
      onDelete={onDeleteBtnClick}
      // addonBtns={({ values, setSubmitting, client }) => [
      //   {
      //     label: 'SEND INVITATION',
      //     onClick: async () => {
      //       setSubmitting(true)
      //       console.log('SEND INVITATION')
      //       console.log({ values, client })


      //       setSubmitting(false)
      //     },
      //   },
      // ]}
    />
  )
}

function EditFields({ values }) {
  const { isEditing } = values
  return (
    <Stack spacing={2}>
      <Stack direction='row' spacing={5} alignItems='center'>
        <FormikField
          name='fn'
          label='First Name'
          width={120}
          disabled={!isEditing}
        />
        <FormikField
          name='ln'
          label='Last name'
          width={120}
          disabled={!isEditing}
        />
        <FormikField
          name='email'
          label='Email'
          width={220}
          disabled={!isEditing}
        />
      </Stack>
      <Stack direction='row' spacing={5} alignItems='center'>
        <FormikField
          name='phoneNumber'
          label='Phone Number'
          width={220}
          disabled={!isEditing}
        />
        <FormikField
          name='address'
          label='Address'
          width={300}
          disabled={!isEditing}
        />
      </Stack>
      <Stack direction='row' spacing={5} alignItems='center'>
        <FormikField name='sub' label='sub' width={220} disabled={!isEditing} />
      </Stack>
    </Stack>
  )
}
