import React, { useState, useCallback, useEffect } from "react";
import { Storage, Hub } from "aws-amplify";
import {
  Typography,
  Stack,
  LinearProgress,
  Box,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
import {
  Clear as ClearIcon,
  AddCircle as AddCircleIcon,
  Cancel as CancelIcon,
  KeyboardReturn as KeyboardReturnIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
// import LoadingButton from '@mui/lab/LoadingButton'

import { useDropzone } from "react-dropzone";

async function handleOpenFile(file) {
  const fileKey = file.key;

  const filename = file.key.split("/")?.pop();
  const fileType = filename.split(".").pop()?.toLowerCase();

  const fileTypeToOpen = ["pdf"];
  const fileTypeToDownload = [
    "xls",
    "csv",
    "xlsx",
    "doc",
    "docx",
    "ppt",
    "pptx",
    "txt",
  ];
  const fileTypeToOpenModal = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "tiff",
    "tif",
    "svg",
    "txt",
  ];

  // console.log({ fileKey, filename, fileType });
  try {
    const url = await Storage.get(fileKey, {
      download: false,
      validateObjectExistence: true,
      // level: 'protected',
    });

    // console.log({ type: fileType, url, filename });
    // debugger;
    // window.location.assign(url);
    // window.location.href = url;

    if (fileTypeToOpen.includes(fileType)) {
      window.open(url, "_blank");
    } else if (fileTypeToDownload.includes(fileType)) {
      // window.location.assign(url);
      window.location.href = url;
    } else if (fileTypeToOpenModal.includes(fileType)) {
      Hub.dispatch("dialogMonitor", {
        event: "displayFile",
        data: { type: fileType, url, filename },
        message: "",
      });
    }
  } catch (err) {
    alert({ downloaderr: err });
  }
}

export default function FileZone({
  storagePath,
  allowInputNumber,
  cb,
  maxUploadFiles,
}) {
  const [number, setNumber] = useState(storagePath || "");
  const [files, setFiles] = useState([]);
  const [counter, setCounter] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [retrivedFiles, setRetrivedFiles] = useState("loading");

  // debugger
  async function handleUpload() {
    if (number?.length === 0) {
      return alert("please enter number first");
    }

    if (files?.length > 0) {
      setIsUploading(true);
      await Promise.all(
        files.map(async (x) => {
          const res = await Storage.put(`${number}/${x.name}`, x, {
            // level: 'protected',
            // contentType: x.type,
          });
          // debugger;
        })
      );
    }

    if (cb) {
      try {
        await cb(number, files);
      } catch (err) {
        console.log({ err212: err });
      }
    }

    setFiles([]);
    setIsUploading(false);
    setCounter(counter + 1);
  }
  async function handleDelete(file) {
    if (window.confirm("Are you sure you want to delete this file?")) {
      try {
        // await Storage.remove(file.key, {
        //   level: 'protected',
        //   // contentType: "text/plain",
        // })
        await Storage.remove(file.key);
        setCounter((c) => c + 1);
      } catch (err) {
        console.log("error deleting file", err);
        alert(err);
      }
    }
  }

  useEffect(() => {
    let ignore = false;
    const func = async () => {
      let res;
      try {
        if (storagePath) {
          // res = await Storage.list(storagePath || number, {
          //   level: 'protected',
          // })
          res = await Storage.list(storagePath || number);

          // console.log({ storagePath, res });
        }

        if (!ignore) {
          setRetrivedFiles(res?.results);
          return;
        }
      } catch (err) {
        setRetrivedFiles();
        alert("ds err", err);
      }
    };

    func();

    return () => {
      ignore = true;
    };
  }, [storagePath, number, counter]);

  const onDrop = (acceptedFiles) => {
    // FILTER OUT DUPLICATES
    const filteredFiles = acceptedFiles.filter(
      (x) => !files?.some((y) => y.name === x.name)
    );

    const mergedFiles = [...(files ? files : []), ...filteredFiles];

    if (maxUploadFiles && mergedFiles?.length > maxUploadFiles) {
      return alert(
        `Maxinum file qty${maxUploadFiles}, please remove some files and drop again`
      );
    }
    setFiles(mergedFiles);
    // Do something with the files
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <Stack spacing={1}>
      {allowInputNumber && (
        <TextField
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          label="number"
        />
      )}
      {/* <Stack direction='row' spacing={2} bgcolor='red'> */}
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Box
          sx={{
            height: 120,
            width: 600,
            cursor: "pointer",
            border: "1px dotted purple",
            color: "purple",
            borderRadius: 2,
            display: "flex",
            // bgcolor: 'blue',
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>
            {isDragActive
              ? "Drop the files here ..."
              : 'Drag "n" drop some files here, or click to select files'}
          </Typography>
        </Box>
      </div>
      {/* </Stack> */}

      <Stack direction="column">
        {files?.length > 0 && (
          <>
            {files.map((file) => (
              <Stack key={file.name}>
                <Stack direction="row" alignItems="center">
                  <IconButton
                    onClick={() => {
                      setFiles(files?.filter((x) => x.name !== file.name));
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                  <Typography>{file.name}</Typography>
                </Stack>
              </Stack>
            ))}
            <Button
              size="small"
              color="primary"
              sx={{
                alignSelf: "start",
              }}
              disabled={isUploading}
              variant="contained"
              onClick={handleUpload}
            >
              Upload
            </Button>
          </>
        )}
      </Stack>
      {retrivedFiles !== "loading" && retrivedFiles?.length > 0 && (
        <FilesList retrivedFiles={retrivedFiles} handleDelete={handleDelete} />
      )}
    </Stack>
  );
}

function FilesList({ retrivedFiles, handleDelete }) {
  console.log({ retrivedFiles });
  return (
    <Stack>
      <Typography p={1} variant="h6" bgcolor="yellow">
        FILES
      </Typography>
      <Stack direction="column">
        {!retrivedFiles || retrivedFiles.length === 0 ? (
          <Typography>no file</Typography>
        ) : (
          <>
            {retrivedFiles.map((file) => (
              <Stack key={file.key} alignItems="center" direction="row">
                <IconButton
                  onClick={async () => {
                    await handleDelete(file);
                  }}
                >
                  <ClearIcon />
                </IconButton>
                <Typography
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={async () => {
                    handleOpenFile(file);
                  }}
                >
                  {file.key.split("/").pop()}
                </Typography>
              </Stack>
            ))}
          </>
        )}
      </Stack>
    </Stack>
  );
}
