export const POByNumber = /* GraphQL */ `
  query POByNumber(
    $number: String!
    $itemNumber: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPOFilterInput
    $limit: Int
    $nextToken: String
  ) {
    POByNumber(
      number: $number
      itemNumber: $itemNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        itemNumber
        date
        offerID
        customerID
        vendorID
        definerID
        materialID
        material {
          id
          mfr
          mpn
        }
        materialEntityRelationID
        materialEntityRelation {
          id
          code
        }
        buyerID
        salesID
        qty
        openQty
        price
        priceCur
        paymentTerms
        billingEntityID
        CRD
        shippingStatus
        billingStatus
        paymentStatus
        importAgentID
        paymentAgentID
        forwarderID
        groupToRead
        groupToEdit
        groupToDelete
        version
        latestVersion
        statusCode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const fragment = /* GraphQL */ `
  fragment NewPO on PO {
    id
  }
`;
export const getPO = /* GraphQL */ `
  query GetPO($id: ID!) {
    getPO(id: $id) {
      id
      number
      itemNumber
      date
      offerID

      customerID

      definerID

      materialID

      buyerID

      salesID

      materialEntityRelationID

      qty
      openQty
      price
      priceCur
      paymentTerms

      billingEntityID
      CRD
      shippingStatus
      billingStatus
      paymentStatus
      importAgentID
      paymentAgentID

      groupToRead
      groupToEdit
      groupToDelete
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const materialEntityRelationByCode = /* GraphQL */ `
  query MaterialEntityRelationByCode(
    $code: String!
    $entityID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMaterialEntityRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    materialEntityRelationByCode(
      code: $code
      entityID: $entityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityID
        offers {
          items {
            id
            statusCode
            currency
            price
            paymentTerms
            incoTerms {
              terms
              condition
              entityUserRelationID
            }
            material {
              id
              mfr
              mpn
            }
            materialID
            materialEntityRelationID
            salesID
            buyerID
            currency
            vendorID
            customerID
            vendor {
              id
              name
              alias
            }
            customer {
              id
              name
              alias
            }
          }
        }

        materialID

        code
        statusCode
        desc
        category
        pdt
        inventoryAlignment
        groupToRead
        groupToEdit
        groupToDelete
        definerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
