import EMS from "./EMS";

const ems = {
  path: "ems_services",

  topNav: {
    order: 30,
    label: "EMS",
    entityType: ["public"],
    allowedRole: "all",
    auth: ["unAuthenticated"],
  },

  route: {
    index: {
      element: <EMS />,
      allowedRole: "all",
      entityType: ["public"],
      auth: ["unAuthenticated"],
    },
    // wrapper: {
    //   element: <UnAuthLayout />,
    //   allowedRole: "all",
    //   entityType: "all",
    //   auth: ["unAuthenticated"],
    // },
  },
};

export default ems;
