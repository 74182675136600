import InfoTech from "./InfoTech";

const infoTech = {
  path: "info_tech",

  topNav: {
    order: 50,
    label: "InfoTech",
    entityType: ["public"],
    allowedRole: "all",
    auth: ["unAuthenticated"],
  },

  route: {
    index: {
      element: <InfoTech />,
      allowedRole: "all",
      entityType: ["public"],
      auth: ["unAuthenticated"],
    },
    // wrapper: {
    //   element: <UnAuthLayout />,
    //   allowedRole: "all",
    //   entityType: "all",
    //   auth: ["unAuthenticated"],
    // },
  },
};

export default infoTech;
