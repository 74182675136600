import { useEffect, useMemo } from "react";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";




export default function Step0SelectMaterial({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  setErrors,
  setStatus,
}) {
  const { step } = values;

  useEffect(() => {
    if (step !== 0) {
      console.log({ step });
      setFieldValue("step", 0);
    }
    setErrors({});
  }, [setFieldValue, setErrors, step]);

  return <Stack spacing={5}></Stack>;
}
