import { useMemo } from 'react'
import { Stack, Typography } from '@mui/material'
import SimpleTable from '../../../../../component/SimpleTable'
import mergeArrayAndCombineQty from '../../../../../lib/mergeArrayAndCombineQty'
import { useEffect } from 'react'

export default function Step1Review({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  setErrors,
  setStatus,
}) {
  const { vendor, date, items } = values

  const allitems = items
    .map((x) => x.subItems)
    .flat()
    ?.map(
      ({
        poID,
        itemNumber,
        materialID,
        materialEntityRelationID,
        receivedQty,
        po,
        piID,
      }) => ({
        poID,
        itemNumber,
        materialID,
        materialEntityRelationID,
        qty: receivedQty,
        po,
        piID,
      }),
    )

  console.log({ allitems })

  return (
    <Stack spacing={5}>
      <Stack direction='row' spacing={3}>
        <Typography variant='h6'>Receiving Date: {date}</Typography>
        <Typography variant='h6'>Vendor: {vendor?.name}</Typography>
      </Stack>
      <Stack direction='row' spacing={3}>
        <Typography variant='h6'>
          Total: {items?.reduce((a, v) => a + (v.qty * 1 || 0), 0)}
        </Typography>
      </Stack>
      <Stack direction='row' spacing={3}>
        <Typography variant='h6'></Typography>
      </Stack>
      {items?.map(({ hideSubArray, material, po, qty, subItems }, index) => (
        <Stack key={`${po.materialID}${po.number}`} bgcolor='#fff'>
          <Stack direction='row' spacing={3}>
            <Typography variant='h6'>
              Material: {po.material?.mfr} {po.material?.mpn}
            </Typography>

            <Typography variant='h6'>PO: {po.number}</Typography>
            <Typography variant='h6'>QUANTITY: {qty}</Typography>
          </Stack>
          {subItems.map(({ itemNumber, qty, openQty, receivedQty }) => (
            <Stack key={itemNumber} direction='row' spacing={2}>
              <Typography variant='h6'>Item Number: {itemNumber}</Typography>
              <Typography variant='h6'>
                {qty}/{openQty}
              </Typography>
              <Typography variant='h6'>receivedQty: {receivedQty}</Typography>
            </Stack>
          ))}
        </Stack>
      ))}
    </Stack>
  )
}
