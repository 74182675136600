import { gql, useReactiveVar } from "@apollo/client";
import { useState } from "react";
import { Storage } from "aws-amplify";
import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { materialByMPN, EntitybyAlias, offerByNumber } from "../query";
import { userEntityRelationVar } from "../../../../client/cache";

import { onCreateInquiry } from "../../costs/onCreate";

export const TemplateLabel = ({ rows }) => {
  const { entityID } = useReactiveVar(userEntityRelationVar);

  const [isLoading, setIsloading] = useState(false);
  const filename = "cost.csv";

  const path = `${entityID}/template/${filename}`;

  return (
    <Stack
      direction="row"
      spacing={2}
      // display="flex"
      // justifyContent="center"
      alignItems="center"
      // sx={{ alignItems: "center" }}
    >
      <Typography variant="h6">Template: </Typography>
      <Typography variant="p">{filename}</Typography>

      <LoadingButton
        loading={isLoading}
        size="small"
        onClick={async () => {
          setIsloading(true);
          try {
            const fetchedURL = await Storage.get(path, {
              download: false,
              validateObjectExistence: true,
              // level: 'protected',
            });
            window.location.href = fetchedURL;

            // console.log({window})

            // const downloadFolder = window.downloads.getDownloadFolder();

            // console.log({ downloadFolder });
            // debugger;
          } catch (err) {
            console.log({ err444: err });
          }
          setIsloading(false);
        }}
        variant="contained"
      >
        download
      </LoadingButton>
    </Stack>
  );
};

export const headerIdentifier = [
  // { label: "tee4", isRequired: false },
  { label: "DATE", isRequired: true },
  { label: "NUMBER", isRequired: true },
  { label: "CUSTOMERID", isRequired: false },
  { label: "vendorID", isRequired: false },
  { label: "VENDOR", isRequired: false },
  { label: "materialID", isRequired: false },
  // { label: "MAETRIALENTITYRELATIONID", isRequired: false },
  // { label: "GCAS", isRequired: false },
  { label: "MFR", isRequired: true },
  { label: "MPN", isRequired: true },
  { label: "PRICE", isRequired: true },
  { label: "CURRENCY", isRequired: true },
  { label: "ISEXPIRED", isRequired: false },
  // { label: "INCOTERMS", isRequired: false },
  // { label: "PAYMENTTERMS", isRequired: false },
  // { label: "STATUSCODE", isRequired: false },
];

export async function onCreate({ rows, definerID, client }) {
  await Promise.all(
    rows.map(async (row) => {
      const {
        materialID,
        CUSTOMERID,
        vendorID,
        ISEXPIRED,
        DATE,
        NUMBER,
        PRICE,
        CURRENCY,
      } = row;
      let statusCode = "050";
      if (ISEXPIRED === "TRUE") {
        statusCode = "900";
      }
      const values = {
        date: new Date(DATE).toISOString("en-US").substring(0, 10),
        materialID,

        number: NUMBER,
        itemNumber: "00010",

        customerID: CUSTOMERID,
        vendorID,
        definerID,
        price: PRICE * 1,
        currency: CURRENCY,
      };

      // console.log({ values });
      // debugger;

      await onCreateInquiry({ client, values, statusCode });
    })
  );
}

export async function validator({ rows, definerID, client }) {
  const newarr = await Promise.all(
    rows?.map(async (row) => {
      let ERROR = [];

      const { MPN, MFR, VENDOR, NUMBER, PRICE, CURRENCY } = row;

      let { data: entitybyAliasData } = await client.query({
        query: gql(EntitybyAlias),
        variables: { alias: VENDOR },
      });
      entitybyAliasData = entitybyAliasData?.EntitybyAlias?.items?.[0];

      if (!entitybyAliasData) {
        ERROR.push({
          attr: "VENDOR",
          isMajor: true,
          msg: "Entity not found",
        });
        return { ...row, ERROR };
      }

      const { id: vendorID } = entitybyAliasData;

      const { data: materialByMPNData } = await client.query({
        query: gql(materialByMPN),
        variables: {
          mpn: MPN,
          mfr: { eq: MFR },
          filter: { definerID: { eq: definerID } },
        },
      });
      const materialsFound = materialByMPNData?.materialByMPN?.items;

      console.log({ materialsFound });

      if (materialsFound?.length > 1) {
        ERROR.push({
          attr: "MPN",
          isMajor: true,
          msg: "Multiple materials share the samme MPN and MFR",
        });
        return { ...row, ERROR };
      }
      const materialFound = materialsFound?.[0];

      if (!materialFound) {
        ERROR.push({
          attr: "MPN",
          isMajor: true,
          msg: "Material not found",
        });
        return { ...row, ERROR };
      }

      row.materialID = materialFound.id;
      row.vendorID = vendorID;
      // row.MAETRIALENTITYRELATIONID = null;

      const { data: data2 } = await client.query({
        query: gql(offerByNumber),
        variables: { number: NUMBER, limit: 5000 },
      });

      const offersFound = data2?.offerByNumber?.items?.find(
        (x) => x.price * 1 === PRICE * 1 && x.currency === CURRENCY
      );

      console.log({ offersFound });

      if (offersFound) {
        ERROR.push({
          attr: "PRICE",
          isMajor: true,
          msg: "Offer already exists",
        });
      }

      return { ...row, ERROR };
    })
  );

  return newarr;
}
