import { API } from "aws-amplify";

export default async function sendQuotes({ metaData, items, isPreview }) {
  const res = await API.post("iSupplyAPI", "/restricted", {
    body: {
      operation: "SEND_QUOTE",
      data: { items, meta: { ...metaData, isPreview } },
    },
  });

  console.log("res", res);
}
