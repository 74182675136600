import { useState } from "react";
import { Stack, Box } from "@mui/material";
import { Formik, Form } from "formik";
import { useApolloClient } from "@apollo/client";
import Stepper from "./Stepper";
import FormComp from "./Form";

export default function StepForm({
  steps,
  onSubmit,
  labelOfSumbit,
  initialValues,
  validationSchema,
}) {
  const client = useApolloClient();
  const [activeStep, setActiveStep] = useState(0);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);

  // console.log({ activeStep });

  return (
    <Box width="100%">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          const { resetForm, setSubmitting } = actions;

          // console.log("submitted");
          // debugger;
          await onSubmit({ values, client });

          setSubmitting(false);
          // clear();
        }}
      >
        {(props) => (
          <Stack spacing={10} alignItems="center">
            {console.log({
              values: props.values,
              errors: props.errors,
              touched: props.touched,
            })}
            <Stepper
              formProps={props}
              steps={steps}
              labelOfSumbit={labelOfSumbit}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              isNextButtonDisabled={isNextButtonDisabled}
              setIsNextButtonDisabled={setIsNextButtonDisabled}
            />

            <Form autoComplete="off">
              <FormComp
                props={props}
                steps={steps}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                setIsNextButtonDisabled={setIsNextButtonDisabled}
              />
            </Form>
          </Stack>
          // <Wrapper {...props} labelOfSumbit={labelOfSumbit} steps={steps} />
        )}
      </Formik>
    </Box>
  );
}
