import { gql } from "@apollo/client";
import { POByNumber, materialEntityRelationByCode } from "../query";
import { Stack, TextField, MenuItem } from "@mui/material";
import filterOutSameItemInArray from "../../../../lib/filterOutSameItemInArray";
import { useState } from "react";

const newPOs = [];

const exitingPOs = [];

export default async function handleCPO(rows, client, possibleVendorIDs) {
  try {
    await Promise.all(
      rows.map(async (row, i) => {
        const { number, CRD } = row;

        // console.log({ row });

        const { data: POByNumberData } = await client.query({
          query: gql(POByNumber),
          variables: { number, itemNumber: { eq: "00010" } },
        });

        const fetchPOData = POByNumberData.POByNumber?.items?.[0];

        if (fetchPOData) {
          // console.log({ fetchPOData });
          // console.log({ row });

          const {
            id,
            // date,
            material,
            // materialEntityRelation,
            // CRD,
            // qty,
            // openQty,
            // price,
            // priceCur,
          } = fetchPOData;

          const fields = ["CRD"];
          const oldValues = {};

          fields.forEach((field) => {
            if (row[field] !== fetchPOData[field]) {
              oldValues[field] = fetchPOData[field];
            }
          });
          // console.log({ updatePOInput });
          // console.log({ updatesToCreate });

          row.oldValues = oldValues;
          row.id = id;
          row.mfr = material?.mfr;
          row.mpn = material?.mpn;
          row.price = undefined;
          row.priceCur = undefined;

          exitingPOs.push(row);
        } else {
          const { date, gcas, CRD, qty, openQty, price, priceCur, desc } = row;

          let error = [];

          const { data: materialEntityRelationByCodeData } = await client.query(
            {
              query: gql(materialEntityRelationByCode),
              variables: { code: gcas },
            }
          );

          const materialEntityRelations =
            materialEntityRelationByCodeData.materialEntityRelationByCode
              ?.items;

          const offerOptions = materialEntityRelations
            .map((x) => x.offers?.items)
            ?.flat()
            ?.filter(
              (x) =>
                x.statusCode !== "900" &&
                possibleVendorIDs?.includes(x?.vendorID)
            );

          // console.log({ possibleVendorIDs, offerOptions });

          // if (gcas === '90775493') {
          //   console.log({ offerOptions })
          //   debugger
          // }

          const isPriceValid =
            offerOptions?.length === 1 && offerOptions[0].price === price;

          // console.log({ offerOptions })
          // console.log({ price })

          // let offerFound = offers?.filter((x) => x?.price === price);

          // if (offerFound.length === 0) {
          //   error.push({ attr: "price", isMajor: true, msg: "no offer found" });
          // }

          // if (offerFound.length > 1) {
          //   error.push({
          //     attr: "price",
          //     isMajor: true,
          //     msg: `Multiple offers found for ${gcas} with price ${price}`,
          //   });
          // }

          // offerFound = offerFound[0];

          // console.log({ offerFound });

          const {
            materialID,
            materialEntityRelationID,
            material,
            customer,
            customerID,
            vendor,
            vendorID,
            salesID,
            buyerID,
            incoTerms,
            paymentTerms,
          } = offerOptions[0] || {};

          newPOs.push({
            number,
            gcas,
            mfr: material?.mfr,
            mpn: material?.mpn,
            vendorName: vendor?.alias || vendor?.name,
            customerName: customer?.alias || customer?.name,
            CRD,
            qty,
            openQty,
            offerOptions: isPriceValid ? null : offerOptions,
            priceCur: isPriceValid ? priceCur : null,
            price: isPriceValid ? price : null,
            date,
            desc,
            incoTerms,
            paymentTerms,
            error,
            shipTo: incoTerms?.terms,
            materialID,
            materialEntityRelationID,
            vendorID,
            customerID,
            salesID,
            buyerID,
          });
        }
      })
    );

    return [
      filterOutSameItemInArray(newPOs, "number").sort((a, b) =>
        a.number > b.number ? -1 : 1
      ),
      filterOutSameItemInArray(exitingPOs, "id").sort((a, b) =>
        a.number > b.number ? -1 : 1
      ),
    ];
  } catch (error) {
    console.log({ error9273834: error });
  }
}
