import { useEffect, useMemo } from "react";
import { useApolloClient } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import {
  FormikSwitch,
  FormikCheckbox,
  FormikAutocomplete,
  FormikField,
} from "../../../../../component/formikForm";
import SimpleTable from "../../../../../component/SimpleTable";

import {
  invoiceByType,
  relationsByEntity1AndRelatioin,
  // getEntityUserRelation,
  // POByCustomerAndOpenQty,
} from "./query";
import mergeArrayAndCombineQty from "../../../../../lib/mergeArrayAndCombineQty";
import { vatRate } from "../../../../../settings/formFieldEnum";

const headers = ["no", "paidAmount"];

export default function Step1EditItems({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  setErrors,
  setStatus,
}) {
  // const { step } = values;

  const {
    invoiceItems,
    invoices,
    discounting,
    discountingProvider,
    days,
    needEdit,
  } = values;

  const vendorID = invoices?.[0]?.po?.vendor?.id;

  const { data } = useQuery(gql(relationsByEntity1AndRelatioin), {
    variables: {
      entity1ID: vendorID,
      relation: { eq: "PRINCIPAL-CONTRACTOR" },
      filter: { type: { eq: "discountingPayment" } },
    },
    skip: !vendorID || !discounting,
  });

  const discountingProviderOptions = useMemo(
    () =>
      data?.relationsByEntity1AndRelatioin?.items?.map((x) => ({
        name: x.entity2?.abbr,
        id: x.entity2ID,
        rate: x.serviceFeeRate,
      })),
    [data]
  );

  useEffect(() => {
    if (discountingProviderOptions?.length === 1) {
      setFieldValue("discountingProvider", discountingProviderOptions[0]);
    }
  }, [discountingProviderOptions, setFieldValue]);

  const totalSelectedAmount = useMemo(
    () => invoiceItems?.reduce((a, v) => a + v.paidAmount, 0).toFixed(3) * 1,
    [invoiceItems]
  );

  useEffect(() => {
    setFieldValue("hideArray", !needEdit);
  }, [needEdit, setFieldValue]);

  const calculatedInvoices = invoices?.map((x, i) => {
    const amount = (x.amount * (1 + vatRate))?.toFixed(3) * 1;
    const discounting =
      (((amount * discountingProvider?.rate || 0) / 360) * (days || 0)).toFixed(
        3
      ) * 1;

    const net = (amount - discounting)?.toFixed(3) * 1;

    return { ...x, amount, discounting, net };
  });

  console.log({ calculatedInvoices });

  // useEffect(() => {
  //   if (!needEdit) {
  //     setFieldValue(
  //       "invoiceItems",
  //       invoiceItems.map((x) => ({ ...x, paidAmount: x.amount }))
  //     );
  //   }
  // }, [needEdit, invoiceItems, setFieldValue]);

  return (
    <Stack width={1600} spacing={2}>
      <FormikCheckbox label="Discounted Payment?" name="discounting" />

      {discounting && (
        <Stack direction="row" spacing={2}>
          <FormikAutocomplete
            name="discountingProvider"
            label="discountingProvider"
            options={discountingProviderOptions}
            getOptionLabel={(option) => option.name + " " + option.rate}
            required
            getPrimaryText={(option) => option.name}
            getSecondaryText={(option) => option.rate}
            listItemPrimaryField={null}
            listItemSecondaryField={null}
            disabled={false}
            width={300}
            // isOptionEqualToValue={(option, value) => option.name === value.name}
            onChange={(e, v) => {
              // console.log({ vvvvvvv: v })
              // debugger
              // // setFieldValue("shippingItems", [{ findBy: "code" }]);
              // setFieldValue('invoices', null)
              // setFieldValue("incoTerms", null);
            }}
            getOptionDisabled={null}
          />
          <FormikField name="days" label="Days" width={100} type="number" />
        </Stack>
      )}

      <Stack direction="row" spacing={2}>
        <Typography>Selected Invoices: {totalSelectedAmount}</Typography>
        {invoiceItems?.[0]?.currency === "CNY" && (
          <Typography>
            With VAT: {(totalSelectedAmount * (1 + vatRate))?.toFixed(3) * 1}
          </Typography>
        )}
      </Stack>

      <SimpleTable headers={headers} rows={invoiceItems} />

      {calculatedInvoices.map((x, i) => {
        return (
          <Stack key={x.id} direction="row" spacing={2} bgcolor="pink">
            <Typography variant="h4">Number:{x.number}</Typography>
            <Typography variant="h4">Amount:{x.amount}</Typography>
            <Typography variant="h4">Discounting:{x.discounting}</Typography>
            <Typography variant="h4">Net:{x.net}</Typography>
          </Stack>
        );
      })}
      <Stack direction="row" spacing={2}>
        <Typography variant="h4">
          Total:
          {calculatedInvoices?.reduce((a, v) => a + v.amount, 0).toFixed(3) * 1}
        </Typography>
        <Typography variant="h4">
          After Discounting:
          {calculatedInvoices?.reduce((a, v) => a + v.net, 0).toFixed(3) * 1}
        </Typography>
      </Stack>

      <FormikSwitch
        label="need To edit?"
        name="needEdit"
        // onChange={(val) => {
        //   if (!val) {
        //     setFieldValue(
        //       "invoiceItems",
        //       invoiceItems.map((x) => ({ ...x, paidAmount: x.amount }))
        //     );
        //   } else {
        //   }
        //   // setFieldValue("shippingItems", [{ findBy: "code" }]);
        //   // setFieldValue("vendor", null);
        // }}
      />
    </Stack>
  );
}
