import { onCreate } from "../../../function/onCreate";
import { createMaterialEntityRelation } from "./query";

export default async function onCreateRelation({ client, values }) {
  const { id, materialID, entityID, code, definerID, statusCode } = values;

  const inputForCreateEntityRelations = {
    id,
    definerID,
    code,
    statusCode,
    entityID,
    materialID,
  };

  const fragment = /* GraphQL */ `
    fragment NewEntityRelations on EntityRelations {
      id
    }
  `;

  return await onCreate({
    input: inputForCreateEntityRelations,
    mutation: createMaterialEntityRelation,
    fragment,
    updateCacheFields: [""],
    client,
    refetchQueries: ["GetMaterial"],
  });
}
