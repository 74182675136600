export const listEntities = /* GraphQL */ `
query ListEntities(
  $filter: ModelEntityFilterInput
  $limit: Int
  $nextToken: String
) {
  listEntities(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      alias
      type
      label @client
    }
    nextToken
  }
}
`;

export const deleteEntity = /* GraphQL */ `
  mutation DeleteEntity(
    $input: DeleteEntityInput!
    $condition: ModelEntityConditionInput
  ) {
    deleteEntity(input: $input, condition: $condition) {
      id
    }
  }
`;

export const getEntity = /* GraphQL */ `
  query GetEntity($id: ID!) {
    getEntity(id: $id) {
      id
      name
      alias
      type
      label @client
      createdAt
      updatedAt
    }
  }
`;

export const updateEntity = /* GraphQL */ `
  mutation UpdateEntity(
    $input: UpdateEntityInput!
    $condition: ModelEntityConditionInput
  ) {
    updateEntity(input: $input, condition: $condition) {
      id
      name
      alias
      type
      createdAt
      updatedAt
    }
  }
`;

export const createEntity = /* GraphQL */ `
  mutation CreateEntity(
    $input: CreateEntityInput!
    $condition: ModelEntityConditionInput
  ) {
    createEntity(input: $input, condition: $condition) {
      id
      alias
      name
      type
    }
  }
`;

export const fragment = /* GraphQL */ `
  fragment NewEntity on Entity {
    id
  }
`;