import { useEffect, useMemo } from "react";
import { Stack, Typography, MenuItem } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import {
  FormikField,
  FormikAutocomplete,
  FormikSelect,
} from "../../../../../component/formikForm";
import {
  invoiceByType,
  entitiesByDefiner,
  POByVendorID,
  relationsByDefiner,
  // getEntityUserRelation,
  // POByCustomerAndOpenQty,
} from "./query";
import mergeArrayAndCombineQty from "../../../../../lib/mergeArrayAndCombineQty";
import { vatRate } from "../../../../../settings/formFieldEnum";
import filterOutSameItemInArray from "../../../../../lib/filterOutSameItemInArray";
import groupArrayofObjectsByKeyAndGenerateArr from "../../../../../lib/groupArrayofObjectsByKeyAndGenerateArr";
import calculateForwarderPOFees from "../../../../../function/calculateForwarderPOFees";
import { currencies } from "../../../../../settings/formFieldEnum";

export default function Step0ChooseItems({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  setErrors,
  setStatus,
}) {
  // const { step } = values;

  const {
    definerID,
    paymentType,
    payee,
    invoices,
    invoiceItems,
    POs,
    POItems,
    payeeOptions,
  } = values;

  const { data: invoiceByTypeData } = useQuery(gql(invoiceByType), {
    variables: {
      type: "INBOUND",
      definerID: { eq: definerID },
      filter: {
        paymentStatus: { ne: "PAID" },
        // paymentDueDate: { attributeType: "string" },
      },
      // limit: 5000,
    },
    skip: !definerID || paymentType !== "INVOICE",
  });
  const openInvoices = invoiceByTypeData?.invoiceByType?.items;

  const { data: entitiesByDefinerData } = useQuery(gql(entitiesByDefiner), {
    variables: {
      definerID,

      limit: 5000,
    },
    skip: !definerID || paymentType !== "MANUAL",
  });
  const entities = entitiesByDefinerData?.entitiesByDefiner?.items;

  const { data: relationsByDefinerData } = useQuery(gql(relationsByDefiner), {
    variables: {
      definerID,
      // relation: { eq: "CUSTOMER-SUPPLIER" },
      limit: 5000,
    },
    skip: !definerID || paymentType !== "PO",
  });
  const relations = relationsByDefinerData?.relationsByDefiner?.items;

  const { data: POByVendorIDData } = useQuery(gql(POByVendorID), {
    variables: {
      vendorID: payee?.id,
      definerID: { eq: definerID },
      limit: 5000,
    },
    skip: !definerID || paymentType !== "PO" || !payee?.id,
  });

  useEffect(() => {
    if (paymentType === "INVOICE") {
      let payeeOptions = openInvoices?.map((x) => x.po?.vendor).filter(Boolean);

      payeeOptions = filterOutSameItemInArray(payeeOptions, "id");

      setFieldValue("payeeOptions", payeeOptions);
    } else if (paymentType === "PI") {
    } else if (paymentType === "MANUAL") {
      setFieldValue("payeeOptions", entities);
    } else if (paymentType === "PO") {
      let payeeOptions = relations?.map((x) => x.entity2);
      payeeOptions = filterOutSameItemInArray(payeeOptions, "id");
      setFieldValue("payeeOptions", payeeOptions);
    }
  }, [paymentType, relations, entities, openInvoices, setFieldValue]);

  // const payeeOptions = useMemo(
  //   () => [...new Set(openInvoices?.map((x) => x.po?.vendor))],
  //   [openInvoices]
  // );

  const invoiceOptions = useMemo(() => {
    if (!payee?.id) return [];

    return openInvoices?.filter((x) => x.po?.vendor?.id === payee?.id);
  }, [openInvoices, payee?.id]);

  const mergedInvoices = useMemo(() => {
    return mergeArrayAndCombineQty(invoiceOptions, ["number"], ["amount"]);
  }, [invoiceOptions]);

  const POOptions = POByVendorIDData?.POByVendorID?.items;

  const mergedPOs = useMemo(() => {
    return mergeArrayAndCombineQty(POOptions, ["number"], ["qty", "openQty"]);
  }, [POOptions]);

  const totalSelectedAmount = useMemo(
    () => invoices?.reduce((a, v) => a + v.amount, 0),
    [invoices]
  );

  return (
    <Stack width={1600} spacing={2}>
      <Typography variant="h5">
        INVOICE PAYMENT IS NOT AVAIALBE IF PAYMENTDUEDATE IS NOT DEFINED{" "}
      </Typography>
      <Stack direction="row" spacing={5} alignItems="flex-end">
        <FormikField
          name="date"
          label="Date"
          type="date"
          width={180}
          required
        />

        <FormikSelect
          label="Payment Type"
          name="paymentType"
          width={300}
          options={["INVOICE", "PO", "MANUAL"]}
          required
          onChange={(v) => {
            console.log({ vvvvvv: v });
            setFieldValue("payeeOptions", null);
            setFieldValue("payee", null);

            if (v === "MANUAL") {
              setFieldValue("disableAddButton", false);
            }
          }}
          // labelKey="label"
          // valueKey="value"
        />

        <FormikAutocomplete
          name="payee"
          label="Payee"
          disabled={!payeeOptions}
          options={payeeOptions}
          getOptionLabel={(option) => option?.abbr}
          required
          freeSolo={paymentType === "MANUAL"}
          getPrimaryText={(option) => option?.abbr || option}
          // getSecondaryText={(option) => option.contactName}
          listItemPrimaryField={null}
          listItemSecondaryField={null}
          width={300}
          onChange={() => {
            // setFieldValue("shippingItems", [{ findBy: "code" }]);
            setFieldValue("invoices", null);
            // setFieldValue("incoTerms", null);
          }}
          getOptionDisabled={null}
        />

        {paymentType === "MANUAL" && (
          <Stack direction="row" spacing={2}>
            <FormikField name="desc" label="Desc" width={240} />{" "}
            <FormikField name="currency" label="Currency" width={180} select>
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </FormikField>
            <FormikField name="amount" label="Amount" width={180} />{" "}
          </Stack>
        )}
      </Stack>

      {paymentType === "INVOICE" && (
        <FormikAutocomplete
          disabled={!payee}
          name="invoices"
          label="Invoices"
          multiple
          options={mergedInvoices}
          getOptionLabel={(option) => `${option.number} - ${option.amount}`}
          required
          getPrimaryText={(option) => option.number}
          getSecondaryText={(option) => option.amount}
          listItemPrimaryField={null}
          listItemSecondaryField={null}
          width={900}
          onChange={(e, v) => {
            const items = invoiceOptions
              .filter((x) => v.find((y) => y.number === x.number))
              ?.map((x) => {
                const existingItem = invoiceItems?.find((y) => y.id === x.id);
                return {
                  ...x,
                  paidAmount: existingItem?.paidAmount || x.amount,
                };
              });

            setFieldValue("invoiceItems", items);
            // setFieldValue("shippingItems", [{ findBy: "code" }]);
            // setFieldValue("vendor", null);
            // setFieldValue("incoTerms", null);
          }}
          getOptionDisabled={null}
        />
      )}
      {paymentType === "PO" && (
        <FormikAutocomplete
          disabled={!payee}
          name="POs"
          label="POs"
          multiple
          options={mergedPOs}
          getOptionLabel={(option) =>
            `${option.number} - ${option.qty}/${option.openQty}`
          }
          required
          getPrimaryText={(option) => option.number}
          getSecondaryText={(option) => `${option.qty}/${option.openQty}`}
          listItemPrimaryField={null}
          listItemSecondaryField={null}
          width={900}
          onChange={async (e, v) => {
            const items = POOptions.filter((x) =>
              v.find((y) => y.number === x.number)
            )?.map((x) => {
              const existingItem = POItems?.find((y) => y.id === x.id);
              return {
                ...x,
                paidAmount: existingItem?.paidAmount || x.amount,
              };
            });

            const nonForwarderPOs = items.filter((x) => !x.pi) || [];

            let forwarderPOs = items.filter((x) => x.pi) || [];

            forwarderPOs = groupArrayofObjectsByKeyAndGenerateArr(
              forwarderPOs,
              "number"
            );

            forwarderPOs = await Promise.all(
              forwarderPOs.map(async ({ number, items }) => {
                const formattedItems = items.map((x) => ({
                  ...x,
                  price: x.pi?.price,
                  currency: x.pi?.currency,
                  qty: x.qty,
                  importDutyCN: x.importDutyCN,
                }));

                const exchangeRate = items?.[0].fxRate;
                const serviceFeeRate = items?.[0].serviceFeeRate;
                const minCharge = items?.[0].minCharge;
                const date = items?.[0].date;
                const incoTerms = items?.[0].incoTerms;
                const paymentTerms = items?.[0].paymentTerms;

                // console.log({ formattedItems });

                const [arr, ele] = await calculateForwarderPOFees({
                  items: formattedItems,
                  exchangeRate,
                  serviceFeeRate,
                  minCharge,
                  date,
                  incoTerms,
                  paymentTerms,
                });

                // console.log({ arr, ele });

                return arr.map((x) => ({
                  ...x,
                  taxAndHandling:
                    (x?.vat + x?.importDuty + x?.handlingFee)?.toFixed(3) * 1,
                  originalGoodsPayment:
                    (x?.piPrice * x?.qty * x?.fxRate)?.toFixed(3) * 1,

                  // number: x.poNo.split("-")[0],
                  // itemNumber: x.poNo.split("-")[1],
                }));
              })
            );

            forwarderPOs = forwarderPOs.flat();
            // console.log({ forwarderPOs });

            // debugger
            setFieldValue("POItems", [...nonForwarderPOs, ...forwarderPOs]);
          }}
          getOptionDisabled={null}
        />
      )}
      {paymentType === "INVOICE" && invoices && (
        <Typography>Selected Invoices: {totalSelectedAmount}</Typography>
      )}

      {invoices?.[0]?.currency === "CNY" && (
        <Typography>
          With VAT: {(totalSelectedAmount * (1 + vatRate))?.toFixed(3) * 1}
        </Typography>
      )}
    </Stack>
  );
}
