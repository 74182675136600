import { useState, useEffect } from "react";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";

export default function ConfettiOverlay({ height }) {

  const { width } = useWindowSize();
  const [fps, setFps] = useState(0);

  let frameCount = 0;
  let lastTime = performance.now();

  const calculateFPS = () => {
    const currentTime = performance.now();
    frameCount++;

    if (currentTime - lastTime >= 1000) {
      setFps(frameCount); // Update the FPS state
      frameCount = 0; // Reset frame count
      lastTime = currentTime; // Reset last time
    }

    requestAnimationFrame(calculateFPS); // Request next frame
  };

  useEffect(() => {
    requestAnimationFrame(calculateFPS); // Start FPS calculation

    return () => {
      frameCount = 0; // Cleanup frame count on component unmount
    };
  }, []);
  // console.log({ fps });

  const gravity = fps > 100 ? 0.015 : 0.05;

  // console.log({ gravity });

  // const width = "100vw";

  return (
    // <Box sx={{ height: 700 }}>
    <Confetti
      width={width}
      height={height}
      opacity={0.5}
      // wind={0.01}
      gravity={gravity}
      numberOfPieces={100}
      // drawShape={(ctx) => {
      //   ctx.beginPath();
      //   for (let i = 0; i < 22; i++) {
      //     const angle = 0.35 * i;
      //     const x = (0.2 + 1.5 * angle) * Math.cos(angle);
      //     const y = (0.2 + 1.5 * angle) * Math.sin(angle);
      //     ctx.lineTo(x, y);
      //   }
      //   ctx.stroke();
      //   ctx.closePath();
      // }}
    />
    // </Box>
  );
}
