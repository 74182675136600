import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { API } from "aws-amplify";

export default function PGShippingDoBtn({
  shippingNumber,
  shippingItems,
  packingDetails,
  date,
  courier,
  trackingNumber,
  trackingURL,
  invoices,
  type,
  receiver,
  receiverEntity,
}) {
  const { id, abbr } = courier || {};

  const [isLoading, setIsLoading] = useState(false);
  const { vendor, customer, incoTerms, paymentTerms } =
    shippingItems[0]?.po || {};

  async function onClick() {
    if (
      type === "prev" ||
      window.confirm("Are you sure you wish to send docs?")
    ) {
      setIsLoading(true);
      if (!packingDetails || !invoices || invoices.length === 0) {
        return alert("packing details or invoice items missing");
      }

      const meta = {
        date,
        invoiceNumber: invoices?.[0]?.number,
        vendorName: vendor.name,
        customerName: customer.name,
        hknVendorCode: "15326403",
        shippingNumber,
        vendorAddress:
          "ONE AMERICA PLAZA, 600 W BROADWAY, #175-180, SAN DIEGO, CA 92101",
        vendorPhoneNumber: "+1-951-297-7788",
        incoTerms: incoTerms?.terms,
        // customerBillingAddress,
        // customerBillingPhoneNumber,
        // customerBillingContact,
        // customerBillingSubsdiary,
        paymentTerms,
        currency: invoices?.[0]?.currency,
        customerReceivingAddress: receiver?.address,
        customerReceivingPhoneNumber: receiver?.phoneNumber,
        customerReceivingContact: receiver?.name,
        customerReceivingSubsdiary: receiverEntity?.name,
        trackingNumber,
        carrier: abbr,
        trackingURL,
        internalRecipient: "steven.lu@hankeno.com",
        //please add
        agentRecipient: "pug-avis-rod@geodis.com",
        customerRecipient: "froehlich.d@pg.com, scur.p@pg.com",
      };

      // console.log({ meta, packingDetails, invoices, type });
      // debugger;

      await API.post("iSupplyAPI", "/restricted", {
        body: {
          operation: "SEND_SHIPPING_DOCUMENTS",
          data: {
            meta,
            packingItems: packingDetails,
            invoiceItems: invoices,
            type,
          },
        },
      });
      setIsLoading(false);
    }
  }

  return (
    <LoadingButton loading={isLoading} variant="contained" onClick={onClick}>
      PG Shipping Doc {type}
    </LoadingButton>
  );
}
