import { useState } from "react";
import { Auth } from "aws-amplify";
import { useReactiveVar } from "@apollo/client";

import {
  useSearchParams,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Card, Button, Alert, Box } from "@mui/material";
import SignIn from "./SignIn";
import SignUpSelection from "./SignUpSelection";
import SignUpWithCode from "./SignUpWithCode";
import SignUpWithoutCode from "./SignUpWithoutCode";
import VerifyEmailForForgotPassword from "./VerifyEmailForForgotPassword";
import ResetPassword from "./ResetPassword";
import Message from "./Message";
import ConfirmSignUpVerification from "./ConfirmSignUpVerification";
import UpdateEmailVerification from "./UpdateEmailVerification";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import emitSnackbar from "../../function/emitSnackbar";
import { userVar, userEntityRelationVar } from "../../client/cache";

export default function AuthPage({ onClose }) {
  const navigate = useNavigate();

  const state = useLocation().state || {};
  // console.log({ state })
  // debugger;

  // console.log({type:state.type})

  let [searchParams, setSearchParams] = useSearchParams();
  const user = useReactiveVar(userVar);

  const data = searchParams.get("data");
  const pathname = state.pathname;
  const passcode = searchParams.get("passcode");
  let dataObj = {};
  if (data) {
    dataObj = JSON.parse(Buffer.from(data, "base64"));
  }
  // console.log({ dataObj })
  // debugger

  const username = dataObj.userName;
  const recipientName = dataObj.recipientName;
  const type = state.type || dataObj.type;
  const paymentID = state.paymentID;
  const { role, code, invitationCode: codeFromCustomMessage } = dataObj;

  let isInvitationSentByEmail;
  if (role && code) isInvitationSentByEmail = true;

  // const email = state.email || dataObj.email;

  const [email, setEmail] = useState(state.email || dataObj.email || "");
  // const [email, setEmail] = useState(state.email || dataObj.email || "");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [invitationCode, setInvitationCode] = useState(
    code || codeFromCustomMessage
  );
  const [loading, setLoading] = useState(false);
  const [authType, setAuthType] = useState(type || "signIn");
  const [alert, setAlert] = useState();
  const [message, setMessage] = useState();

    // FOR LOGGED IN USER AUTH PAGE IS ONLY VALID FOR CHANGE PASSWORD

    if (user?.sub && type !== "changePassword" && type !== "updateEmail") {
      return <Navigate to={pathname ? pathname : "/cloud"} replace />;
    }

  // console.log({
  //   recipientName,
  //   type,
  //   pathname,
  //   passcode,
  //   email,
  //   username,
  //   password,
  //   role,
  //   invitationCode,
  //   paymentID,
  // });
  // debugger;

  const actions = {
    signIn: {
      onSubmit: async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
          const user = await Auth.signIn(email, password);

          console.log({ user });

          if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
            const res = await Auth.completeNewPassword(
              user, // the Cognito User Object
              password // the new password
              // OPTIONAL, the required attributes
            );
            console.log({ res, pathname, onClose });
          }

          onClose && onClose();
        } catch (error) {
          switch (error.message) {
            case "User is not confirmed.":
              setAlert({
                severity: "error",
                message: "User is not confirmed",
                btnText: "Resend Link",
                onClick: async () => {
                  console.log("link resent");
                  try {
                    await Auth.resendSignUp(email);
                    setAlert();
                    setMessage({
                      title:
                        "The verification link has been resent to you by email",
                    });
                  } catch (err) {
                    console.log("error resending code: ", err.message);
                  }
                },
              });

              break;
            case "User does not exist.":
            case "Incorrect username or password.":
              // emitSnackbar({
              //   severity: "error",
              //   message: "Incorrect email or password.",
              //   duration: 5000,
              // });
              setAlert({
                severity: "error",
                message: "Incorrect email or password.",
              });
              break;

            default:
              setAlert({
                severity: "error",
                message: error.message,
              });
          }
        }
        setLoading(false);
      },
      FormCompoment: SignIn,
    },
    signUpSelection: {
      FormCompoment: SignUpSelection,
    },
    signUpWithoutCode: {
      onSubmit: async (event) => {
        event.preventDefault();
        console.log({
          email,
          password,
          confirmPassword,
          invitationCode,
          role,
          paymentID,
        });
        debugger;

        setLoading(true);

        try {
          setAlert();

          await Auth.signUp({
            username: email,
            password,
            validationData: { paymentID },
            autoSignIn: {
              // optional - enables auto sign in after user is confirmed
              enabled: true,
            },
          });
          setMessage({
            title:
              "An email has been sent to you, please click the link in the email to continue.",
          });
        } catch (error) {
          console.log("errormessage", { msg: error.message });

          switch (error.message) {
            case "Username cannot be empty":
              setAlert({
                severity: "error",
                message: "email cannot be empty",
              });
              break;
            case " PreSignUp failed with error The invitation code is invalid.":
              console.log(333);
              debugger;
              setAlert({
                severity: "error",
                message: "The invitation code is invalid",
              });
              break;
            default:
              setAlert({
                severity: "error",
                message: error.message,
              });
          }
        }
        setLoading(false);
      },
      FormCompoment: SignUpWithoutCode,
    },
    signUpWithCode: {
      onSubmit: async (event) => {
        event.preventDefault();
        console.log({
          email,
          password,
          confirmPassword,
          invitationCode,
          role,
        });

        setLoading(true);

        try {
          setAlert();

          //ATTENTION validationData is used by PreSignUp trigger, clientMetadata is used by CustomMessage and PostConfirmation trigger

          const res = await Auth.signUp({
            username: email,
            password,
            validationData: { invitationCode },
            clientMetadata: { invitationCode, recipientName },
            autoSignIn: {
              // optional - enables auto sign in after user is confirmed
              enabled: true,
            },
          });
          console.log({ res });

          // debugger

          setMessage({
            title:
              "An email has been sent to you, please click the link in the email to continue.",
          });
          // navigate("/", { replace: true });
        } catch (error) {
          console.log("errormessage", error.message);
          switch (error.message) {
            case "Username cannot be empty":
              setAlert({
                severity: "error",
                message: "email cannot be empty",
              });
              break;
            case "PreSignUp failed with error The invitation code is invalid.":
              setAlert({
                severity: "error",
                message: "The invitation code is invalid",
              });
              break;
            default:
              setAlert({
                severity: "error",
                message: error.message,
              });
          }
        }
        setLoading(false);
      },
      FormCompoment: SignUpWithCode,
    },

    verifyEmailForForgotPassword: {
      onSubmit: async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
          await Auth.forgotPassword(email);
          setMessage({
            title:
              "An email has been sent to you, please click the link in the email to continue.",
          });
        } catch (error) {
          console.log("err msg", error.message);
          setAlert({
            severity: "error",
            message: error.message,
          });
        }
        setLoading(false);
      },
      FormCompoment: VerifyEmailForForgotPassword,
    },
    resetPassword: {
      onSubmit: async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
          await Auth.forgotPasswordSubmit(email, passcode, password);
          await Auth.signIn(email, password);
          navigate("/cloud");
          emitSnackbar({
            message: "Password reset successfully!",
            duration: 5000,
          });
        } catch (error) {
          console.log("err msg", error.message);
          if (error.message.startsWith("Invalid")) {
            // setMessage({title: "The link you click has expired, please request a new link"})
            setMessage({
              title:
                "The link you click has expired, please request a new link",
              onClick: () => {
                setAuthType("signIn");
              },
            });
          } else {
            setMessage({
              title: error.message,
              onClick: () => {
                setAuthType("signIn");
              },
            });
          }
        }
        setLoading(false);
      },
      FormCompoment: ResetPassword,
    },
    confirmSignUp: {
      onSuccess: () => {
        setMessage({
          title: "Your email has been successfully verified",
          btnText: "LOG IN",
          onClick: () => {
            setAuthType("signIn");
          },
          Icon: () => (
            <CheckCircleOutlineOutlinedIcon fontSize="large" color="success" />
          ),
        });
      },
      onFail: (e) => {
        console.log({ msg: e.message });

        switch (e.message) {
          case "User cannot be confirmed. Current status is CONFIRMED":
            setMessage({
              title: "Your email has been verified already",
              btnText: "Continue",
              onClick: () => {
                setAuthType("signIn");
              },
              Icon: () => (
                <CheckCircleOutlineOutlinedIcon
                  fontSize="large"
                  color="success"
                />
              ),
            });

            break;

          default:
            setMessage({
              title: e.message,
              btnText: "Close",
              onClick: () => {
                navigate("/", { replace: true });
              },
              Icon: () => <CloseOutlinedIcon fontSize="large" color="error" />,
            });
        }
      },

      FormCompoment: ConfirmSignUpVerification,
    },
    updateEmail: {
      onSuccess: () => {
        setMessage({
          title:
            "Email updated successfully, please log in with your new email",
          btnText: "Continue",
          Icon: () => (
            <CheckCircleOutlineOutlinedIcon fontSize="large" color="success" />
          ),
          onClick: async () => {
            await Auth.signOut();
            navigate("/auth");
          },
        });
      },
      onFail: (e) => {
        console.log({ msg: e.message });
        setMessage({
          title: e.message,
          btnText: "close",
          Icon: () => <CloseOutlinedIcon fontSize="large" color="error" />,
          onClick: () => {
            navigate("/", { replace: true });
          },
        });
      },

      FormCompoment: UpdateEmailVerification,
    },
  };

  if (user === "loading") return null;

  function clearAlert() {
    setAlert();
  }

  const action = actions[authType];

  if (!action) return <p>Auth Type is not defined</p>;



  return (
    <Box
      sx={{
        height: onClose ? "100%" : "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        py: onClose ? 0 : 15,
        // position: "relative",
        // top: "40px",
        // gap: 3,
        bgcolor: "lightGray",
      }}
      // fixed
    >
      <Card
        sx={{
          // position: "relative",
          // top: "80px",
          p: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: 500,
          minWidth: 450,
          // bgcolor: "purple",
          gap: 2,
        }}
      >
        {message ? (
          <Message
            onClose={onClose}
            message={message}
            setMessage={setMessage}
          />
        ) : (
          <action.FormCompoment
            loading={loading}
            setAuthType={setAuthType}
            action={action}
            onClose={onClose}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            username={username}
            passcode={passcode}
            invitationCode={invitationCode}
            setInvitationCode={setInvitationCode}
            isInvitationSentByEmail={isInvitationSentByEmail}
            clearAlert={clearAlert}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              alignItems: "center",
              // height: "100%",

              // minHeight: 500,
              gap: 3,
              // bgcolor:'red'
            }}
            fromAuthPage
          />
        )}
        {alert && (
          <Alert
            variant="filled"
            sx={{
              width: "100%",
              // bgcolor: "blue",
              // "& .MuiAlert-message": { width: "100%" },
            }}
            {...(alert.btnText && {
              action: (
                <Button
                  color="inherit"
                  size="small"
                  variant="outlined"
                  onClick={alert.onClick}
                >
                  {alert.btnText}
                </Button>
              ),
            })}
            severity={alert.severity}
          >
            {alert.message}
          </Alert>
        )}
      </Card>

      {/* <Copyright sx={{ mt: 8, mb: 1 }} /> */}
    </Box>
  );
}
