import { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import {
  FormikField,
  FormikSelect,
  FormikCheckbox,
  FormikRadioGroup,
} from "../../../../../component/formikForm";
import { entityType } from "../../../../../settings/formFieldEnum";

export default function Step5Contacts({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  setErrors,
  setStatus,
  setActiveStep,
}) {
  const { hasSpecifiedPaymentTerms, step, id } = values;

  // useEffect(() => {
  //   if (hasSpecifiedPaymentTerms === "false") {
  //     console.log({ step });
  //     setActiveStep(2);
  //   }
  //   // setErrors({});
  // }, [setFieldValue, setActiveStep, hasSpecifiedPaymentTerms, setErrors, step]);

  useEffect(() => {
    if (!id) {
      setFieldValue("id", uuidv4());
    }
  }, [id, setFieldValue]);

  return (
    <Stack width={1200} spacing={2}>
      {/* <Typography variant="h6">
        Does this entity have specified payment terms?
      </Typography> */}
      {/* <FormikCheckbox
        label="Does this entity have specified payment terms?"
        name="hasSpecifiedPaymentTerms"
      /> */}
      <FormikRadioGroup
        label="Add onctacts?"
        name="addContacts"
        selections={[
          { label: "Yes", value: "true" },
          { label: "No", value: "false" },
        ]}
        onChange={(e) => {
          if (e === "false") {
            setActiveStep((prev) => prev + 1);
          }
        }}
      />

      {/* <FormikCheckbox
        label="Does this entity have specified inco terms?"
        name="hasSpecifiedIncoTerms"
      /> */}
    </Stack>
  );
}
