import { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { Stack, Box, Typography, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import handleAcceptOrRejectRole from "../../function/handleAcceptOrRejectRole";

export default function Alert({ alerts, handleCloseAlertMenu }) {
  console.log("alerts", alerts);

  return (
    <Box sx={{ p: 2, width: 600, height: 400, bgcolor: "#f0f0f0" }}>
      <>
        {alerts?.map((x, i) => (
          <AlertItem
            key={i}
            item={x}
            handleCloseAlertMenu={handleCloseAlertMenu}
          />
        ))}
      </>
    </Box>
  );
}

function AlertItem({ item, handleCloseAlertMenu }) {
  const client = useApolloClient();
  const [isLoading, setIsloading] = useState(false);

  return (
    <Stack
      // direction="row"
      spacing={2}
      sx={{ bgcolor: "#e1bee7", borderRadius: 1, p: 1, m: 1 }}
    >
      <Typography>
        You are invited to take {item.role} role at {item.entity?.alias}
      </Typography>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={3}
      >
        <LoadingButton
          loading={isLoading}
          onClick={async () => {
            setIsloading(true);
            await handleAcceptOrRejectRole({
              client,
              inviteID: item.id,
              isRoleAccepetd: true,
            });
            setIsloading(false);
          }}
          variant="contained"
        >
          Accept
        </LoadingButton>
        <Button
          onClick={() =>
            handleAcceptOrRejectRole({
              client,
              inviteID: item.id,
              isRoleAccepetd: false,
            })
          }
          variant="contained"
        >
          Reject
        </Button>
      </Stack>
    </Stack>
  );
}
