// export {default as about} from './about'
// export {default as test} from './test'
// export {default as home} from './home'
// export {default as demo} from './demo'
// export {default as plans} from './plans'
// export {default as page404} from './404'

import privacy_policy from "./privacy_policy";
import terms_of_use from "./terms_of_use";

import test from "./test";
import home from "./home";
// import demo from "./demo";
// import plans from "./plans";
import page404 from "./404";
import consolidation from "./consolidation";
import fabrication from "./fabrication";
import infoTech from "./infoTech";
import company from "./company";
import ems from "./ems";

const modules = [
  home,
  //   demo,
  consolidation,
  fabrication,
  infoTech,
  company,
  ems,
  privacy_policy,
  terms_of_use,
  //   plans,
  page404,
];

export default modules;
