import { useEffect } from "react";
import { Container, Box, Typography } from "@mui/material";
import ImageOverlay from "./ImageOverlay";
import HeadMessage from "./HeadMessage";
import Sections from "./Sections";

export default function Template({
  headPicture,
  headTitle,
  headDescription,
  headMessage,
  headTestomoniesTitle,
  headTestomoniesImages,
  sections,
  Content,
  ImageOverlayOverwite,
}) {
  useEffect(() => {
    // const hash = window.location.hash;
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <ImageOverlay img={headPicture} hero>
        {ImageOverlayOverwite ? (
          <ImageOverlayOverwite />
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 5,
              // justifyContent: "center",
              // alignItems: "center",
              color: "#fff",
              pt: 10,
              pl: 5,
            }}
          >
            <Typography sx={{ fontSize: 48, fontWeight: "light" }}>
              {headTitle}
            </Typography>

            {/* <Typography variant="h2"> Stainless Steel Fabrication</Typography> */}
            <Typography sx={{ fontWeight: "light", fontSize: 24, width: 800 }}>
              {headDescription}
            </Typography>
          </Box>
        )}
      </ImageOverlay>
      <Container sx={{ mt: 5, mb: 10 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
          <HeadMessage
            message={headMessage}
            testomonies={{
              title: headTestomoniesTitle,
              images: headTestomoniesImages,
            }}
          />
        </Box>
      </Container>
      {sections && <Sections sections={sections} />}
      {Content && <Content />}
    </Box>
  );
}
