import { useFormikContext, useField } from "formik";
import {
  TextField,
  FormControl,
  InputLabel,
  Stack,
  Box,
  MenuItem,
  Select,
} from "@mui/material";
import FieldErrorMessage from "./FieldErrorMessage";

export default function FormikSelect({
  label,
  name,
  width,
  type,
  options,
  sx,
  multiple,
  disabled,
  getOptionDisabled,
  required,
  labelKey,
  valueKey,
  labelSX,
  onChange,
}) {
  const { setFieldValue, handleBlur } = useFormikContext();

  const [field, meta, helpers] = useField({ name, type });

  const value = field.value;

  const labelSx = labelSX
    ? labelSX
    : { position: "relative", top: 16, left: -13 };

  return (
    <Stack spacing={1} sx={sx}>
      <FormControl required={required} disabled={disabled}>
        <InputLabel sx={labelSx}>{label}</InputLabel>

        <Select
          component={TextField}
          variant="standard"
          sx={{ width: width || 400 }}
          name={name}
          id={name}
          value={value || (multiple ? [] : "")}
          multiple={multiple}
          // label={label}
          onBlur={handleBlur}
          onChange={(e) => {
            setFieldValue(name, e.target.value);
            onChange && onChange(e.target.value);
          }}
        >
          {options?.map((option) => {
            // console.log({
            //   optionoptionoptionoptionoptionoptionoptionoption: option,
            // });
            // debugger;
            return (
              <MenuItem
                disableRipple
                disabled={getOptionDisabled && getOptionDisabled(option)}
                key={labelKey ? option?.[labelKey] : option?.label || option}
                value={valueKey ? option?.[valueKey] : option?.value || option}
              >
                {/* {console.log({ option, labelKey })} */}
                {labelKey ? option?.[labelKey] : option?.label || option}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Box sx={{ height: "5px" }}>
        <FieldErrorMessage name={name} meta={meta} />
      </Box>
    </Stack>
  );
}
