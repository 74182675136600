import { Hub } from "aws-amplify";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Stack, Button, Typography } from "@mui/material";
import FileZone from "../../../component/FileZone";
import Notes from "../../../component/Notes";
import Updates from "../../../component/Updates";
import Item from "./components/Item";
import DataGridComp from "../../../component/DataGrid";
import getFilteredItemsByUserRole from "../../../function/getFilteredItemsByUserRole";
import CreateReceivingStepForm from "./components/CreateReceivingStepForm";
import { getReceivingAndShipping, ShippingByTypeAndMaterial } from "./query";
import onDeleteShipping from "../shippings/onDelete";
import { userEntityRelationVar } from "../../../client/cache";

function ItemView({ lineData: { id }, onClose }) {
  const { entityID } = useReactiveVar(userEntityRelationVar);

  let { data } = useQuery(gql(getReceivingAndShipping), {
    variables: { id },
  });
  data = data?.getReceivingAndShipping;

  if (!data) return null;

  const storagePath = `${entityID}/receivings/${data?.number}`;

  return (
    <Stack
      justifyContent="space-between"
      sx={{ width: 1500, height: 900, padding: 2 }}
    >
      <Stack spacing={5}>
        <Item data={{ ...data, definerID: entityID }} onClose={onClose} />
        <FileZone storagePath={storagePath} />
        <Notes itemID={id} />
        <Updates itemID={id} />
      </Stack>

      <Button
        variant="contained"
        sx={{ alignSelf: "center", justifySelf: "flex-end" }}
        onClick={onClose}
      >
        back
      </Button>
    </Stack>
  );
}

function Comp({ entityType, userRole, isAuthenticated, entityID }) {
  const isAdmin = userRole?.includes("admin");

  const queryVariables = {
    type: "INCOMING",
    limit: 5000,
    // filter: {
    //   or: IDs.map((id) => ({
    //     vendorID: { eq: id },
    //   })),
    // },
  };

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 180,
      userRole: ["admin", "buyer", "warehouse"],
    },

    {
      field: "no",
      headerName: "Number",
      width: 240,
      userRole: ["admin"],
    },
    // {
    //   field: "itemNumber",
    //   headerName: "Item",
    //   width: 90,
    //   userRole: ["admin", "buyer"],
    // },
    {
      field: "customer",
      headerName: "Customer",
      width: 180,
      userRole: ["admin"],
    },
    {
      field: "poNumber",
      headerName: "PO Number",
      width: 240,
      userRole: ["admin", "buyer", "warehouse"],
    },
    {
      field: "code",
      headerName: "Code",
      width: 150,
      userRole: ["admin", "buyer", "warehouse"],
    },

    {
      field: "mfr",
      headerName: "MFR",
      width: 180,
      userRole: ["admin", "buyer", "warehouse"],
    },
    {
      field: "mpn",
      headerName: "MPN",
      width: 180,
      userRole: ["admin", "buyer", "warehouse"],
    },
    {
      field: "qty",
      headerName: "Qty",
      width: 150,
      userRole: ["admin", "buyer", "warehouse"],
    },
    {
      field: "invoice",
      headerName: "invoice",
      width: 150,
      userRole: ["admin", "buyer", "warehouse"],
    },

    // {
    //   field: "incoTerms",
    //   headerName: "IncoTerms",
    //   width: 200,
    //   userRole: ["admin", "buyer"],
    //   renderCell: ({ row, ...props }) => {
    //     return (
    //       <Stack direction="row" spacing={2}>
    //         <Typography>{row?.incoTerms.terms}</Typography>
    //       </Stack>
    //     );
    //   },
    // },
  ];
  const dropdownMenu = [
    {
      label: "Delete",
      userRole: ["admin"],
      onClick: async ({ client, selectionModel, setSelectionModel }) => {
        await onDeleteShipping({
          selectionModel,
          setSelectionModel,
          client,
          type: "RECEIVING",
        });
      },
    },
    {
      label: "Generate Invoices",
      userRole: ["admin"],
      onClick: async ({ client, selectionModel, setSelectionModel }) => {
        if (selectionModel.some((x) => x.invoice?.length > 0)) {
          return alert("Some of the selected items already have invoices");
        }
        Hub.dispatch("dialogMonitor", {
          event: "invoiceGen",
          data: {
            selectionModel,
            setSelectionModel,
            definerID: entityID,
            // attrs: ["price", "priceCur", "paymentTerms"],
            // model:'CPO'
          },
        });
        // await onDeletePO({
        //   selectionModel,
        //   setSelectionModel,
        //   client,
        // });
      },
    },
  ];

  const filteredColumns = getFilteredItemsByUserRole(columns, userRole);

  const filteredDropdownMenu = getFilteredItemsByUserRole(
    dropdownMenu,
    userRole
  );

  const prepareItemsFunc = (items ) => {
    return items.map((x) => {
      return {
        ...x,
        poNumber: x.po?.no,
        customer: x.po?.customer?.abbr,
        // vendor: x.vendor?.alias || x.vendor?.name,
        code: x.materialEntityRelation?.code,
        mfr: x.material?.mfr,
        mpn: x.material?.mpn,
        invoice: x.invoice?.items?.map((x) => x.number).join(", "),
      };
    });
  };

  const sortBy = (arr) => {
    arr.sort((a, b) => (a.itemNumber < b.itemNumber ? -1 : 1));
    arr.sort((a, b) => (a.number > b.number ? 1 : -1));
    arr.sort((a, b) => (a.date < b.date ? 1 : -1));

    return arr;
  };

  const filterAttrs = [
    { label: "mfr", value: "mfr", type: "string" },
    {
      label: "mpn",
      value: "mpn",
      type: "string",
    },
    { label: "code", value: "code", type: "string" },
    { label: "poNumber", value: "poNumber", type: "string" },
  ];

  return (
    <DataGridComp
      query={ShippingByTypeAndMaterial}
      queryVariables={queryVariables}
      sortBy={sortBy}
      columns={filteredColumns}
      onClickAddRoute="new"
      DetailsDialog={isAdmin ? ItemView : null}
      userRole={userRole}
      checkboxSelection={isAdmin ? true : null}
      getDropdownMenu={filteredDropdownMenu}
      prepareItemsFunc={prepareItemsFunc}
      ToolbarInsert={ToolbarInsert}
      filterAttrs={filterAttrs}
    />
  );
}

const receivings = {
  path: "receivings",

  sideNav: {
    group: 2,
    order: 40,
    label: "Receivings",
    entityType: ["200", "300"],
    allowedRole: ["admin", "buyer", "warehouse"],
    auth: ["authenticated"],
  },
  route: {
    index: {
      element: null,
      Comp,
      allowedRole: ["admin", "buyer", "warehouse"],
      entityType: "all",
      auth: ["authenticated"],
    },
    new: {
      element: <CreateReceivingStepForm />,
      allowedRole: ["admin", "buyer", "warehouse"],
      entityType: "all",
      auth: ["authenticated"],
    },
  },
};

function ToolbarInsert({ selectionModel }) {
  return !selectionModel?.length ? null : (
    <Stack
      direction="row"
      spacing={2}
      color="secondary.main"
      sx={{ position: "relative", top: -7 }}
    >
      <Typography variant="title" sx={{ fontWeight: 500 }}>
        SELECTED QTY:
      </Typography>
      <Typography variant="title" sx={{ fontWeight: 500 }}>
        {" "}
        {selectionModel.reduce((a, v) => a + (v?.qty || 0), 0).toFixed(3) * 1}
      </Typography>
    </Stack>
  );
}

export default receivings;
