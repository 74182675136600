import { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { useApolloClient, useReactiveVar } from "@apollo/client";
import { Outlet, Navigate, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  AppBar,
  Tabs,
  Tab,
  Toolbar,
  IconButton,
  Popover,
  MenuItem,
  Stack,
  Menu,
  Tooltip,
  Badge,
  Divider,
  Button,
} from "@mui/material";
import { GTranslate as GTranslateIcon } from "@mui/icons-material";
import getTopNav from "../../function/getTopNav";
import getUserMenu from "../../function/getUserMenu";
import { userVar, userEntityRelationVar } from "../../client/cache";
import SvgIcon from "@mui/material/SvgIcon";
import {
  AccountCircle,
  Mail as MailIcon,
  Notifications as NotificationsIcon,
} from "@mui/icons-material";
import Notification from "./Notification";
import Alert from "./Alert";

import BreadcrumbsComp from "../BreadcrumbsComp";
import { languages } from "../../settings/formFieldEnum";

export default function RequireAuth() {
  const [lang, setLang] = useState();

  let userLocale = navigator.language;

  const user = useReactiveVar(userVar);

  const { entityType, userRole, isUserEntityAdmin } =
    useReactiveVar(userEntityRelationVar) || {};

  const location = useLocation();


  const nav = getTopNav({
    entityType,
    userRole,
    isAuthenticated: true,
  });

  const menusArray = getUserMenu({
    entityType,
    userRole,
    isAuthenticated: true,
  });



  const findLang = languages.find((x) => {
    let langFound = x.value.toLowerCase() === userLocale?.toLowerCase();
    if (!langFound) {
      if (userLocale.toLowerCase().includes("zh")) {
        langFound = x.value.toLowerCase() === "zh_cn";
      } else {
        langFound = x.value.toLowerCase() === "en-us";
      }
    }
    return langFound;
  });

  I18n.setLanguage(lang);

  useEffect(() => {
    setLang(findLang?.value);
  }, [findLang]);

  if (!lang) return null;

  if (user !== "loading" && !user?.sub) {
    const formattedPath = location.pathname + location.search;
    return (
      <Navigate
        to="/auth"
        state={{ type: "signIn", pathname: formattedPath }}
        replace
      />
    );
  }

  return (
    <>
      <AuthNavbar
        lang={lang}
        setLang={setLang}
        nav={nav}
        menusArray={menusArray}
        user={user}
        isUserEntityAdmin={isUserEntityAdmin}
      />
      <Box
        sx={{
          // display: "flex",
          // position: "fixed",
          // p: 5,
          // mr: 2,
          // border: 2,
          // bgcolor: "red",
          // // position: "relative",
          // top: 110,
          // width: "100%",
          mt: 5,
          height: "88vh",
        }}
      >
        <Outlet key={lang} />
      </Box>
    </>
  );

  // Redirect them to the /login page, but save the current location they were
  // trying to go to when they were redirected. This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
  // return <Navigate to='/login' state={{ from: location }} replace />
}

function AuthNavbar({
  lang,
  setLang,
  nav,
  menusArray,
  user,
  isUserEntityAdmin,
}) {
  const [value, setValue] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState();
  const [anchorElMessage, setAnchorElMessage] = useState();
  const [anchorElLang, setAnchorElLang] = useState();
  const [anchorElAlert, setAnchorElAlert] = useState();
  const [anchorElRFQ, setAnchorElRFQ] = useState();

  const client = useApolloClient();
  const location = useLocation();
  let navigate = useNavigate();

  const { receivedMessages, invites: alerts, offersReceived } = user || {};

  useEffect(() => {
    const menuMatched = nav.find(
      (x) => x.path === location.pathname.split("cloud/")[1]?.split("/")[0]
    );
    if (menuMatched) {
      setValue(menuMatched.label);
    } else {
      setValue(false);
    }
    if (anchorElAlert && alerts?.length === 0) {
      setAnchorElAlert();
    }
  }, [navigate, location, nav, alerts, anchorElAlert, setAnchorElAlert]);

  const handleOpenUserMenu = (e) => setAnchorElUser(e.currentTarget);
  const handleCloseUserMenu = () => setAnchorElUser();
  const handleOpenMessageMenu = (e) => setAnchorElMessage(e.currentTarget);
  const handleOpenLangMenu = (e) => setAnchorElLang(e.currentTarget);

  const handleCloseMessageMenu = () => setAnchorElMessage();
  const handleOpenAlertMenu = (e) => setAnchorElAlert(e.currentTarget);
  const handleCloseAlertMenu = () => setAnchorElAlert();
  const handleOpenRFQMenu = (e) => setAnchorElRFQ(e.currentTarget);
  const handleCloseRFQMenu = () => setAnchorElRFQ();
  //   const handleOpenNavMenu = (e) => setAnchorElNav(e.currentTarget);
  //   const handleCloseNavMenu = () => setAnchorElNav(null);

  const countryCode = languages.find((x) => x.value === lang)?.countryCode;
  let newMessageQty = receivedMessages?.filter((x) => !x.isRead).length || 0;

  return (
    // <AppBar position="static" sx={{ height: 100 }} />
    <AppBar
      position="static"
      sx={{
        opacity: 0.8,
        // zIndex: (theme) => {
        //   // console.log({ theme });
        //   return theme.zIndex.drawer + 1;
        // },
      }}
    >
      <Toolbar>
        <Stack direction="row" spacing={1} alignItems="center">
          <SvgIcon color="secondary" sx={{ position: "relative", top: 2 }}>
            <path d="M8.12249 1.60573C8.50171 0.911796 9.49829 0.911796 9.87751 1.60573L10.9602 3.58693C11.1657 3.96292 11.5879 4.16622 12.0099 4.09244L14.234 3.70368C15.0129 3.56751 15.6343 4.34666 15.3282 5.07581L14.4543 7.15757C14.2884 7.55264 14.3927 8.00946 14.7135 8.29344L16.4041 9.78987C16.9963 10.314 16.7745 11.2856 16.0136 11.5009L13.8412 12.1156C13.4289 12.2323 13.1367 12.5986 13.1147 13.0265L12.9989 15.2813C12.9583 16.071 12.0604 16.5034 11.4176 16.0427L9.58254 14.7275C9.23429 14.4779 8.76571 14.4779 8.41746 14.7275L6.58236 16.0427C5.9396 16.5034 5.04172 16.071 5.00113 15.2813L4.88526 13.0265C4.86327 12.5986 4.57112 12.2323 4.15884 12.1156L1.98638 11.5009C1.22546 11.2856 1.0037 10.314 1.59585 9.78987L3.28646 8.29344C3.6073 8.00946 3.71156 7.55264 3.54571 7.15757L2.6718 5.07581C2.36571 4.34666 2.98706 3.56751 3.76604 3.70368L5.99007 4.09244C6.41214 4.16622 6.83431 3.96292 7.03978 3.58693L8.12249 1.60573Z" />
          </SvgIcon>
          <Typography
            variant="h6"
            noWrap
            component="a"
            // href="/"
            onClick={() => {
              setValue(false);
              navigate("/");
            }}
            sx={{
              mr: 2,
              // fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            HANKENO
          </Typography>
        </Stack>

        <Box sx={{ flexGrow: 1 }}>
          <Tabs
            value={value}
            onChange={(e, val) => {
              setValue(val);
            }}
            textColor="inherit"
            indicatorColor="secondary"
            // aria-label="secondary tabs example"
          >
            {nav.map((menu) => (
              <Tab
                key={menu.label}
                value={menu.label}
                label={menu.label}
                onClick={() => navigate(menu.path)}
                sx={{
                  visibility: menu.label === "Home" ? "hidden" : "visible",
                }}
              />
            ))}
          </Tabs>
          {/* <Divider /> */}
        </Box>
        {user && (
          <Stack direction="row" spacing={5}>
            <Typography>
              ROLES:{" "}
              {isUserEntityAdmin
                ? user.groups?.join(", ")
                : user.groups?.map((x) => x.split("-").pop())?.join(", ")}
            </Typography>
            <Typography>{user.email}</Typography>
          </Stack>
        )}
        <Stack
          direction="row"
          spacing={2}
          sx={{ flexGrow: 0 }}
          alignItems="center"
        >
          {receivedMessages?.length > 0 && (
            <>
              <IconButton
                size="large"
                aria-label="show new mails"
                color="inherit"
                onClick={handleOpenMessageMenu}
              >
                <Badge
                  badgeContent={newMessageQty > 0 ? newMessageQty : null}
                  color="error"
                >
                  <MailIcon />
                </Badge>
              </IconButton>
              <Popover
                // sx={{ bgcolor: "blue" }}
                id="popover-appbar-message"
                anchorEl={anchorElMessage}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                // keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={Boolean(anchorElMessage)}
                onClose={handleCloseMessageMenu}
              >
                <Notification messages={receivedMessages?.items} />
              </Popover>
            </>
          )}

          {alerts?.length > 0 && (
            <>
              <IconButton
                size="large"
                aria-label="show new notifications"
                color="inherit"
                onClick={handleOpenAlertMenu}
              >
                <Badge badgeContent={alerts?.length} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <Popover
                // sx={{ bgcolor: "blue" }}
                id="popover-appbar-alert"
                anchorEl={anchorElAlert}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                // keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={Boolean(anchorElAlert)}
                onClose={handleCloseAlertMenu}
              >
                <Alert
                  alerts={alerts}
                  handleCloseAlertMenu={handleCloseAlertMenu}
                />
              </Popover>
            </>
          )}
          {/* {offersReceived?.length > 0 && (
            <>
              <Tooltip
                title={`You have ${offersReceived?.length} new ${
                  offersReceived?.length === 1 ? "inquiry" : "inquiries"
                } `}
              >
                <IconButton
                  size="large"
                  aria-label="show new notifications"
                  color="inherit"
                  onClick={handleOpenRFQMenu}
                >
                  <Badge badgeContent={offersReceived?.length} color="error">
                    <RequestQuoteIcon />
                  </Badge>
                </IconButton>
              </Tooltip>

              <Popover
                // sx={{ bgcolor: "blue" }}
                id="popover-appbar-alert"
                anchorEl={anchorElRFQ}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                // keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={Boolean(anchorElRFQ)}
                onClose={handleCloseRFQMenu}
              >
                <RFQ
                  rfqs={offersReceived}
                  handleCloseAlertMenu={handleCloseRFQMenu}
                />
              </Popover>
            </>
          )} */}
          <Tooltip
            title={`You have ${offersReceived?.length} new ${
              offersReceived?.length === 1 ? "inquiry" : "inquiries"
            } `}
          >
            <IconButton
              size="large"
              aria-label="show users"
              color="inherit"
              onClick={handleOpenUserMenu}
            >
              <Badge variant="dot" color="error" invisible={!offersReceived}>
                <AccountCircle />
              </Badge>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {menusArray?.map((menus, i) => {
              if (menus?.length === 0) return null;
              return (
                <Box key={i} width={120}>
                  {menus.map((menu) => {
                    const BadgeComp = menu.BadgeComp;
                    return (
                      <MenuItem
                        key={menu.label}
                        onClick={() => {
                          handleCloseUserMenu();
                          menu.path && navigate(menu.path);
                          menu.onClick && menu.onClick({ client });
                        }}
                      >
                        <Typography textAlign="center">{menu.label}</Typography>
                        {BadgeComp && (
                          <BadgeComp badgeContent={offersReceived?.length} />
                        )}
                      </MenuItem>
                    );
                  })}

                  {i < menusArray.length - 1 && <Divider />}
                </Box>
              );
            })}
          </Menu>
          <Button sx={{ color: "white" }} onClick={handleOpenLangMenu}>
            <GTranslateIcon />
            <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }}>
              <img
                loading="lazy"
                width="30"
                // srcSet={`https://flagcdn.com/w40/us.png 2x`}
                // src={`https://flagcdn.com/w20/us.png`}
                srcSet={`https://flagcdn.com/w40/${countryCode.toLowerCase()}.png 2x`}
                src={`https://flagcdn.com/w20/${countryCode.toLowerCase()}.png`}
                alt=""
              />
            </Box>
          </Button>
          {/* <Popover
            id="language-popover"
            open={!!anchorElLang}
            anchorEl={anchorElLang}
            onClose={() => {
              setAnchorElLang();
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          > */}
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorElLang}
            open={!!anchorElLang}
            onClose={() => {
              setAnchorElLang();
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {languages.map((x) => {
              return (
                <MenuItem
                  key={x.label}
                  onClick={() => {
                    setLang(x.value);
                    setAnchorElLang();
                  }}
                >
                  <Stack direction="row" spacing={1} alignItems="flex-start">
                    <Typography width={80}> {x.label}</Typography>
                    <Box sx={{ "& > img": { mr: 2, flexShrink: 0 } }}>
                      <img
                        loading="lazy"
                        width="30"
                        // srcSet={`https://flagcdn.com/w40/us.png 2x`}
                        // src={`https://flagcdn.com/w20/us.png`}
                        srcSet={`https://flagcdn.com/w40/${x.countryCode.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${x.countryCode.toLowerCase()}.png`}
                        alt=""
                      />
                    </Box>
                  </Stack>
                </MenuItem>
              );
            })}
          </Menu>
          {/* </Popover> */}
        </Stack>
      </Toolbar>

      <BreadcrumbsComp />
    </AppBar>
  );
}
