export const relationsByDefiner = /* GraphQL */ `
  query RelationsByDefiner(
    $definerID: ID!
    $relation: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityRelationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationsByDefiner(
      definerID: $definerID
      relation: $relation
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        relation
        definerID
        entity1ID
        entity1 {
          id
          alias
          name
          abbr @client
        }
        entity2ID
        entity2 {
          id
          alias
          name
          abbr @client
        }
        alias
        customerCode
        vendorCode
        statusCode
        type
        incoTerms {
          terms
          entityUserRelationID
        }
        paymentTerms
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getEntityUserRelation = /* GraphQL */ `
  query GetEntityUserRelation($id: ID!) {
    getEntityUserRelation(id: $id) {
      id
      role
      title
      shownFn
      shownLn
      shownMn
      statusCode
      isEmailVerified
      userID
      user {
        id
        sub
        email
        statusCode
        fn
        mn
        ln
        name @client
        phoneNumber
        address
        groupToRead
        groupToEdit
        groupToDelete
        owner
        definerID
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      entityID
      entity {
        id
        abbr @client
        isVerified
        name
        alias
        type
        businessType
        statusCode
        paymentTerms
        groupToRead
        groupToEdit
        groupToDelete
        definerID
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      definerID

      __typename
    }
  }
`;

export const POByCustomerAndOpenQty = /* GraphQL */ `
  query POByCustomerAndOpenQty(
    $customerID: ID!
    $openQty: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPOFilterInput
    $limit: Int
    $nextToken: String
  ) {
    POByCustomerAndOpenQty(
      customerID: $customerID
      openQty: $openQty
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        itemNumber
        date
        offerID
        customerID
        vendorID
        pi {
          id
          poID
        }
        vendor {
          id
          name
          abbr @client
          alias
        }
        definerID
        materialID
        material {
          id
          mfr
          mpn
        }
        materialEntityRelationID
        materialEntityRelation {
          id
          code
        }
        buyerID
        salesID
        qty
        openQty
        price
        priceCur
        paymentTerms
        billingEntityID
        CRD
        shippingStatus
        billingStatus
        paymentStatus
        importAgentID
        paymentAgentID
        forwarderID
        groupToRead
        groupToEdit
        groupToDelete
        version
        latestVersion
        statusCode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
