import { gql } from "@apollo/client";
import { onUpdate } from "./index";

export const getUser = gql`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      sub
      email
      createdAt
      updatedAt
    }
  }
`;

const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      statusCode
      name
      fn
      mn
      ln
      phoneNumber
      address
      title
      jobRole
      groups
      owner
      createdAt
      updatedAt
    }
  }
`;

const getThirdPartyUserRelation = /* GraphQL */ `
  query GetThirdPartyUserRelation($id: ID!) {
    getThirdPartyUserRelation(id: $id) {
      id
      role
      title
      shownLn
      shownFn
      statusCode
      entityID
      thridPartyID
      userID
      groups
      createdAt
      updatedAt
    }
  }
`;
const updateThirdPartyUserRelation = /* GraphQL */ `
  mutation UpdateThirdPartyUserRelation(
    $input: UpdateThirdPartyUserRelationInput!
    $condition: ModelThirdPartyUserRelationConditionInput
  ) {
    updateThirdPartyUserRelation(input: $input, condition: $condition) {
      id
      role
      title
      statusCode
      entityID
      shownFn
      shownLn
      thridPartyID
      userID
      groups
      createdAt
      updatedAt
    }
  }
`;

export default async function onUpdateExternalCoworker({ values, client }) {
  const { userID } = values;

  const fieldsToUpdateUser = [{ field: "email", createUpdate: true }];

  async function getInitialValueForUser() {
    let { data } = await client.query({
      query: getUser,
      variables: { id: userID },
    });

    data = data?.getUser || {};

    return data;
  }

  await onUpdate({
    type: "User",
    values,
    mutation: updateUser,
    // query: getUser,
    getInitialValue: getInitialValueForUser,
    fieldsToUpdate: fieldsToUpdateUser,
    client,
    refetchQueries: [],
  });

  const fieldsToUpdateThirdPartyUserRelation = [
    { field: "statusCode", createUpdate: true },
    {
      field: "role",
      createUpdate: true,
    },
    { field: "title", createUpdate: true },
    { field: "shownFn", createUpdate: true },
    { field: "shownLn", createUpdate: true },
  ];

  await onUpdate({
    type: "ThirdPartyUserRelation",
    values,
    mutation: updateThirdPartyUserRelation,
    query: getThirdPartyUserRelation,
    fieldsToUpdate: fieldsToUpdateThirdPartyUserRelation,
    client,
    refetchQueries: ["GetThirdPartyUserRelation"],
  });
}
