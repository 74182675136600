import { v4 as uuidv4 } from "uuid";
import { onCreate } from "../../../function/onCreate";
import { createEntity } from "./query";

export default async function onCreateEntity({ client, values }) {
  const {
    id,
    name,
    alias,
    definerID,
    businessType,
    statusCode,
    incoTerms,
    paymentTerms,
  } = values;

  const inputForCreateEntity = {
    id,
    name: name?.trim(),
    alias: alias?.trim(),
    isVerified: false,
    definerID,
    businessType,
    statusCode,
    incoTerms,
    paymentTerms,

    // groupToRead: [
    //   "admin",
    //   `${entityID}-admin`,
    //   `${entityID}-sales`,
    //   `${entityID}-accounting`,
    // ],
    // groupToEdit: ["admin", `${entityID}-admin`, `${newEntityID}-admin`],
    // groupToDelete: ["admin"],
  };

  const fragment = /* GraphQL */ `
    fragment NewEntity on Entity {
      id
    }
  `;

  const { data, errors: createEntityErrors } = await onCreate({
    input: inputForCreateEntity,
    mutation: createEntity,
    fragment,
    updateCacheFields: [],
    client,
    refetchQueries: ["EntitiesByDefiner"],
  });

  return data.createEntity?.id;
}
