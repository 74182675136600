import { useFormikContext, useField } from 'formik'
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from '@mui/material'
import FieldErrorMessage from './FieldErrorMessage'

export default function FormikRadioGroup({
  label,
  name,
  selections,
  row,
  onChange,
  disabled,
}) {
  const { values, setFieldValue } = useFormikContext()

  const [field, meta, helpers] = useField({ name })

  return (
    <FormControl disabled={disabled}>
      <FormLabel id='radio-buttons-group-label'>{label}</FormLabel>
      <RadioGroup
        row={row}
        aria-labelledby='radio-buttons-group-label'
        id={name}
        value={field.value || ''}
        onChange={(e) => {
          let fieldValue = e.target.value
          // if (fieldValue === "true") {
          //   fieldValue = true;
          // } else if (fieldValue === "false") {
          //   fieldValue = false;
          // }
          setFieldValue(name, fieldValue)
          onChange && onChange(fieldValue)
        }}>
        {selections.map((x) => (
          <FormControlLabel
            key={x.value}
            value={x.value}
            control={<Radio />}
            label={x.label}
          />
        ))}
      </RadioGroup>
      <FieldErrorMessage meta={meta} />
    </FormControl>
  )
}
