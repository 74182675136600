import cnc from "../../../assets/images/cnc.png";
import ge from "../../../assets/images/ge.png";
import tsmc from "../../../assets/images/tsmc.png";
import ericsson from "../../../assets/images/ericsson.png";
import ford from "../../../assets/images/ford.png";
import milling from "../../../assets/images/cncMilling.jpg";
import turning from "../../../assets/images/cncTurning.jpg";
import printing from "../../../assets/images/3dprinting.jpg";
import laser_cutting from "../../../assets/images/laser_cutting.jpg";
import laser_engraving from "../../../assets/images/laser_engraving.jpg";
import stainless_steel_fabrication from "../../../assets/images/stainless_steel_fabrication.jpg";
import Template from "../../../component/Template";

const headPicture = cnc;
const headTitle = "CNC Machining, 3D Printing & Stainless Steel Fabrication";
const headDescription =
  "When you work with Hankeno, you know the job is in good hands. Your precise specifications are met without compromise";

const headMessage = `Our state-of-the-art machinery and experienced team enable us to
            produce high-precision parts with exceptional quality, meeting the
            most demanding specifications. Whether you require complex CNC
            machined components or custom stainless steel fabrications, our
            expertise and attention to detail ensure that your project is
            executed flawlessly.`;

const headTestomoniesTitle = "Trusted by Industry Leaders";
const headTestomoniesImages = [
  {
    src: ge,
    alt: "ge",
    height: 70,
    style: { filter: "grayscale(100%)", opacity: 0.5 },
  },
  {
    src: tsmc,
    alt: "tsmc",
    height: 100,
    style: { filter: "grayscale(100%)", opacity: 0.5 },
  },
  { src: ericsson, alt: "ericsson", height: 100, style: { opacity: 0.4 } },
  {
    src: ford,
    alt: "ford",
    height: 50,
    style: { filter: "grayscale(100%)", opacity: 0.6 },
  },
];

const sections = [
  {
    title: "CNC Milling",
    image: milling,
    description: `Our CNC milling services offer precision machining for a wide range of materials. Utilizing advanced multi-axis milling machines, we can create complex geometries and tight tolerances that meet your exact specifications. With the ability to handle both large and small parts, we ensure efficient production without compromising quality. Our skilled machinists continuously monitor processes to maintain consistency and accuracy throughout the milling operation.`,
  },
  {
    title: "CNC Turning",
    image: turning,
    description: `Our CNC turning services are perfect for producing cylindrical parts with high precision and surface finish. Our state-of-the-art lathes allow for complex shapes and features to be machined with minimal setup time. Whether you require short or long production runs, we can accommodate your needs. We specialize in working with a variety of materials, ensuring that each component meets stringent quality standards.`,
  },
  {
    title: "3D Printing",
    image: printing,
    description: `Our 3D printing capabilities provide rapid prototyping and production solutions for complex designs that are difficult to achieve with traditional methods. We utilize the latest additive manufacturing technologies to create durable parts with intricate details. This service allows for faster turnaround times and cost-effective production, making it ideal for small batches and custom designs. Our team is dedicated to helping you transform your ideas into tangible products.`,
  },
  {
    title: "Laser Cutting",
    image: laser_cutting,
    description: `We offer precision laser cutting services for a variety of materials, including metal, plastic, and wood. Our advanced laser cutting technology ensures clean, accurate cuts with minimal kerf loss, enabling us to produce intricate designs and shapes efficiently. Whether you need one-off pieces or large production runs, our laser cutting services are tailored to meet your specific requirements.`,
  },
  {
    title: "Laser Engraving",
    image: laser_engraving,
    description: `With our laser engraving services, we provide high-quality marking and engraving on various materials. Our precision lasers can engrave logos, text, and designs with exceptional detail, enhancing the appearance of your products. This service is ideal for branding, product identification, and personalization, and we can work with both small and large quantities to suit your needs.`,
  },
  {
    title: "Stainless Steel Fabrication",
    image: stainless_steel_fabrication,
    description: `Our stainless steel fabrication services are designed to create durable and aesthetically pleasing products for a range of applications. From custom enclosures to structural components, we utilize advanced techniques and equipment to deliver high-quality fabrications. Our skilled fabricators work closely with you to understand your requirements, ensuring that each project meets your specifications while maintaining the highest standards of quality and craftsmanship.`,
  },
];

export default function Fabrication() {
  return (
    <Template
      headPicture={headPicture}
      headTitle={headTitle}
      headDescription={headDescription}
      headMessage={headMessage}
      headTestomoniesTitle={headTestomoniesTitle}
      headTestomoniesImages={headTestomoniesImages}
      sections={sections}
    />
  );
}
