import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Stack, Button } from "@mui/material";
import DataGridComp from "../../../component/DataGrid";
import Notes from "../../../component/Notes";
import Updates from "../../../component/Updates";
import FileZone from "../../../component/FileZone";
import getFilteredItemsByUserRole from "../../../function/getFilteredItemsByUserRole";
import { getUserFunc, contactsByDefiner } from "./query";
import onDelete from "./onDelete";
import CreateForm from "./components/CreateForm";
import Item from "./components/Item";
import Relation from "./components/relation/index";
import { userVar, userEntityRelationVar } from "../../../client/cache";

function ItemView({ lineData, onClose }) {
  const { entityID } = useReactiveVar(userEntityRelationVar);

  let { data } = useQuery(gql(getUserFunc(entityID)), {
    variables: { id: lineData?.id },
    skip: !lineData?.id,
  });

  data = data?.getUser;

  if (!data) return null;

  const storagePath = `${entityID}/contacts/${lineData?.id}`;

  return (
    <Stack
      justifyContent="space-between"
      sx={{ width: 1200, height: 1200, padding: 2 }}
    >
      <Stack spacing={5}>
        <Item data={data} onClose={onClose} />
        <Relation data={{ ...data, definerID: entityID }} onClose={onClose} />
        <FileZone storagePath={storagePath} />
        <Notes itemID={lineData?.id} />
        <Updates itemID={lineData?.id} />{" "}
      </Stack>

      <Button
        variant="contained"
        sx={{ alignSelf: "center", justifySelf: "flex-end" }}
        onClick={onClose}
      >
        back
      </Button>
    </Stack>
  );
}

function Comp({ entityType, userRole, isAuthenticated, entityID }) {
  const isAdmin = userRole?.includes("admin");

  const { email, name, entityUserRelations, id, phoneNumber } =
    useReactiveVar(userVar);

  const formatedCurrentUser = {
    email,
    name: `${name}★`,
    id,
    entityUserRelations: { items: entityUserRelations },
    phoneNumber,
  };

  const columns = [
    {
      field: "name",
      headerName: "name",
      width: 300,
      userRole: "all",
    },
    {
      field: "email",
      headerName: "email",
      width: 300,
      userRole: "all",
    },
    {
      field: "entity",
      headerName: "Entity",
      width: 300,
      userRole: "all",
    },
    {
      field: "phoneNumber",
      headerName: "phoneNumber",
      width: 300,
      userRole: "all",
    },
    {
      field: "address",
      headerName: "Address",
      width: 400,
      userRole: "all",
    },
    {
      field: "sub",
      headerName: "sub",
      width: 300,
      userRole: ["admin"],
    },
  ];

  const dropdownMenu = [
    {
      label: "Delete",
      userRole: ["admin"],
      onClick: async ({ client, selectionModel, setSelectionModel }) => {
        await onDelete({
          selectionModel,
          setSelectionModel,
          client,
        });
      },
    },
  ];

  const queryVariables = {
    definerID: entityID,
  };

  const filteredColumns = getFilteredItemsByUserRole(columns, userRole);

  const filteredDropdownMenu = getFilteredItemsByUserRole(
    dropdownMenu,
    userRole
  );

  const prepareItemsFunc = ( items ) => {
    // console.log("items", items);
    // console.log("formatedCurrentUser", formatedCurrentUser);

    // const newArr = items.concat(formatedCurrentUser);

    // console.log({ newArr });

    return items.map((x) => {
      return {
        ...x,
        entity: x?.entityUserRelations?.items
          ?.map((y) => `${y?.role} at ${y?.entity?.alias || y?.entity?.name}`)
          .join(", "),
      };
    });
  };

  const filterAttrs = [
    { label: "name", value: "name", type: "string" },
    {
      label: "email",
      value: "email",
      type: "string",
    },
    { label: "entity", value: "entity", type: "string" },
    { label: "phoneNumber", value: "phoneNumber", type: "string" },
  ];

  return (
    <DataGridComp
      query={contactsByDefiner(entityID)}
      queryVariables={queryVariables}
      onClickAddRoute={isAdmin ? "new" : null}
      columns={filteredColumns}
      DetailsDialog={isAdmin ? ItemView : null}
      userRole={userRole}
      checkboxSelection={isAdmin ? true : null}
      getDropdownMenu={filteredDropdownMenu}
      prepareItemsFunc={prepareItemsFunc}
      filterAttrs={filterAttrs}
    />
  );
}

const contacts = {
  path: "contacts",
  sideNav: {
    group: 1,
    order: 25,
    label: "Contacts",
    entityType: "all",
    allowedRole: ["admin", "sales"],
    auth: ["authenticated"],
  },
  route: {
    index: {
      // element: null,
      Comp,
      allowedRole: "all",
      entityType: "all",
      auth: ["authenticated"],
    },
    new: {
      element: <CreateForm />,
      allowedRole: ["admin", "sales"],
      entityType: "all",
      auth: ["authenticated"],
    },
  },
};

export default contacts;
