import { useEffect, useMemo } from "react";
import { gql, useQuery, useApolloClient } from "@apollo/client";
import { Stack, Button, Typography, stepButtonClasses } from "@mui/material";
import { useFormikContext } from "formik";
import {
  FormikField,
  FormikSelect,
  FormikAutocomplete,
  FormikArrayRemoveItemButton,
  FormikRadioGroup,
} from "../../../../../component/formikForm";
import { currencies } from "../../../../../settings/formFieldEnum";
import filterOutSameItemInArray from "../../../../../lib/filterOutSameItemInArray";
import mergeArrayAndCombineQty from "../../../../../lib/mergeArrayAndCombineQty";
import fillArrayWithNumber from "../../../../../lib/fillArrayWithNumber";

const POByNumber = /* GraphQL */ `
  query POByNumber(
    $number: String!
    $itemNumber: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPOFilterInput
    $limit: Int
    $nextToken: String
  ) {
    POByNumber(
      number: $number
      itemNumber: $itemNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        itemNumber
        date
        offerID
        customerID
        vendorID
        definerID
        materialID
        materialEntityRelationID
        buyerID
        salesID
        qty
        openQty
        price
        priceCur
        paymentTerms
        billingEntityID
        CRD
        shippingStatus
        billingStatus
        paymentStatus
        importAgentID
        paymentAgentID
        forwarderID
        groupToRead
        groupToEdit
        groupToDelete
        version
        latestVersion
        statusCode
        createdAt
        updatedAt
        shippings {
          items {
            id
            number
            itemNumber
            date
            qty
            invoice {
              items {
                id
                date
                qty
                price
              }
            }
          }
        }
        invoice {
          items {
            id
            type
            category
            number
            itemNumber
            date
            price
            qty
            currency
            poID
          }
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export default function InboundInvoiceItem({
  index,
  remove,
  arrayFieldName,
  removeBtnColor,
  size,
}) {
  const { values, setFieldValue } = useFormikContext();
  const client = useApolloClient();

  const { avaiablePOs, vendor, items, caterory } = values;

  //PLEASE NOTE MATERIAL IS ACTUALLY PO
  const { material, hideSubArray, po, billingQty, billedQty } =
    items?.[index] || {};

  const { data } = useQuery(gql(POByNumber), {
    variables: {
      number: po?.number,
      filter: { materialID: { eq: material?.materialID } },
    },
    skip: !po?.number,
  });

  const POs = data?.POByNumber?.items;

  const linePOOptions = useMemo(
    () => avaiablePOs?.filter((x) => x.materialID === material?.materialID),
    [avaiablePOs, material?.materialID]
  );

  useEffect(() => {
    const filteredPO = linePOOptions
      ?.filter((x) => x.number === po?.number)
      ?.map((x) => ({
        itemNumber: x.itemNumber,
        qty: x.qty,
        openQty: x.openQty,
        CRD: x.CRD,
        poID: x.id,
        receivedQty: 0,
        materialEntityRelationID: x.materialEntityRelationID,
        materialID: x.materialID,
        price: x.price,
        priceCur: x.priceCur,
        unit: x.unit,
        invoice: x.invoice,
        toBeBilled: x.qty - billedQty,
      }));

    const filledArray = fillArrayWithNumber(
      filteredPO,
      billingQty * 1,
      "toBeBilled",
      "billingQty"
    );

    setFieldValue(`[${arrayFieldName}][${index}][subItems]`, filledArray);
  }, [
    POs,
    linePOOptions,
    index,
    arrayFieldName,
    billingQty,
    billedQty,
    po,
    setFieldValue,
  ]);

  useEffect(() => {
    if (!POs) return;

    const availableShippings = POs?.map((x) =>
      x.shippings?.items?.map((y) => ({ ...y, poNo: x.no }))
    ).flat();

    setFieldValue(
      `[${arrayFieldName}][${index}][availableShippings]`,
      availableShippings
    );
  }, [POs, setFieldValue, arrayFieldName, index]);

  // useEffect(() => {
  //   if (hideSubArray === undefined) {
  //     setFieldValue(`[${arrayFieldName}][${index}][hideSubArray]`, true);
  //   }
  // }, [setFieldValue, hideSubArray, arrayFieldName, index]);

  const aggregated = mergeArrayAndCombineQty(
    linePOOptions,
    ["number"],
    ["qty", "openQty"]
  );

  // console.log({ aggregated });

  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <FormikAutocomplete
          name={`[${arrayFieldName}][${index}][material]`}
          label="material"
          required
          disabled={!avaiablePOs}
          options={filterOutSameItemInArray(avaiablePOs, "materialID")}
          getOptionLabel={(option) =>
            option.material?.mfr + " " + option.material?.mpn
          }
          getPrimaryText={(option) => option.material.mfr}
          getSecondaryText={(option) => option.material.mpn}
          width={360}
          onChange={(e, value) => {
            setFieldValue(`[${arrayFieldName}][${index}][po]`, null);
          }}
        />

        <FormikAutocomplete
          name={`[${arrayFieldName}][${index}][po]`}
          label="PO Number"
          required
          disabled={!material}
          options={aggregated || []}
          getOptionLabel={(option) =>
            `${option.number} (${option.qty}/${option.openQty})`
          }
          getPrimaryText={(option) => option.number}
          getSecondaryText={(option) => option.qty + "/" + option.openQty}
          width={480}
          getOptionDisabled={(option) =>
            values?.items?.some(
              (x) =>
                x.po?.number === option.number &&
                x.po?.materialID === material.materialID
            )
          }
          onChange={async (e, value) => {
            const invoices = POs?.map((x) => x.invoice.items)?.flat();

            const billedQty =
              invoices?.reduce((acc, cur) => acc + cur.qty, 0) || 0;

            // console.log({ invoices, billedQty });

            if (billedQty === value?.qty) {
              alert("已经全部开票");
            }
            if (billedQty > value?.qty) {
              alert("已超额开票");
            }

            // const filteredPO = linePOOptions.filter(
            //   (x) => x.number === value.number
            // );
            // console.log({ filteredPO });
            setFieldValue(
              `[${arrayFieldName}][${index}][billedQty]`,
              billedQty
            );
            setFieldValue(`[${arrayFieldName}][${index}][billingQty]`, "");
            setFieldValue(`[${arrayFieldName}][${index}][subItems]`, [{}]);
          }}
        />

        <FormikField
          width={180}
          name={`[${arrayFieldName}][${index}][billingQty]`}
          label="Billing Qty"
          type="number"
          checkError={(setError, v) => {
            if (po && v * 1 + billedQty > po.qty) {
              setError("发票数量大于订单数量");
            }

            // if (po && billingQty * 1 + billedQty > po.qty) {
            //   alert("发票数量大于订单数量");
            //   // setFieldValue(`[${arrayFieldName}][${index}][billingQty]`, "");
            //   // return;
            // }
          }}
          // onChange={(v) => {
          //   console.log({ v });
          // }}
          // onBlur={(v) => {
          //   console.log("onBlur", v);

          // }}
        />

        <FormikField
          width={80}
          name={`[${arrayFieldName}][${index}][billedQty]`}
          disabled
          label="Billed Qty"
        />

        <FormikArrayRemoveItemButton
          disabled={index === 0}
          onClick={() => remove(index)}
          name={arrayFieldName}
          removeBtnColor={removeBtnColor}
          size={size}
        />
      </Stack>
    </Stack>
  );
}
