import { onUpdate } from "../../../function/onUpdate";
import { updateEntity, getEntity } from "./query";

export default async function onUpdateEntity({ values, client }) {
  const incoTerms = values.incoTerms?.map((x) => ({
    terms: x.terms,
    condition: x.condition,
    entityUserRelationID: x.entityUserRelation.id,
  }));

  const fieldsToUpdate = [
    { field: "name", createUpdate: true },
    { field: "businessType", createUpdate: true },
    { field: "alias", createUpdate: true },
    { field: "statusCode", createUpdate: true },
    { field: "incoTerms", createUpdate: false },
    { field: "paymentTerms", createUpdate: false },
    { field: "address", createUpdate: true },
    { field: "phoneNumber", createUpdate: true },
  ];

  const res = await onUpdate({
    type: "Entity",
    values: {
      ...values,
      incoTerms,
      phoneNumber: values.phoneNumber?.trim() || null,
    },
    mutation: updateEntity,
    query: getEntity,
    getInitialValue: null,
    fieldsToUpdate,
    client,
    refetchQueries: [],
    onUpdate: null,
  });
  console.log({ res });
  // debugger
  return res;
}
