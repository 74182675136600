import { useEffect, useMemo } from "react";
import { gql, useQuery,useReactiveVar } from "@apollo/client";

import {
  Stack,
  Chip,
  TextField,
  MenuItem,
  Button,
  Typography,
} from "@mui/material";
import { userEntityRelationVar } from "../../../../../client/cache";
import { currencies } from "../../../../../settings/formFieldEnum";
import filterOutSameItemInArray from "../../../../../lib/filterOutSameItemInArray";

const offerByMaterialEntityRelation = /* GraphQL */ `
  query OfferByMaterialEntityRelation(
    $materialEntityRelationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    offerByMaterialEntityRelation(
      materialEntityRelationID: $materialEntityRelationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        number
        itemNumber
        statusCode
        materialID
        materialEntityRelationID
        salesID
        buyerID
        vendorID
        vendor {
          id
          name
          alias
        }
        customerID
        definerID
        price
        currency
        leadtime
        validDays
        incoTerms
        paymentTerms
      }
      nextToken
      __typename
    }
  }
`;

const offerByMaterial = /* GraphQL */ `
  query OfferByMaterial(
    $materialID: ID!
    $customerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    offerByMaterial(
      materialID: $materialID
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        number
        itemNumber
        statusCode
        materialID
        materialEntityRelationID
        salesID
        buyerID
        vendorID
        vendor {
          id
          name
          alias
        }

        customerID
        definerID
        price
        currency
        leadtime
        validDays
        incoTerms {
          terms
          entityUserRelationID
        }
        paymentTerms
      }
      nextToken
      __typename
    }
  }
`;

export default function Step1SelectOffers({ setFieldValue, values }) {
  const { POItem, step } = values;

  const parts = useMemo(
    () =>
      filterOutSameItemInArray(
        POItem.map((x) => ({ ...x, materialID: x.material?.id })),
        "materialID"
      ),
    // filterOutSameItemInArray(POItem.map((x) => x.material)?.flat(), "id"),
    [POItem]
  );

  console.log({ parts });

  useEffect(() => {
    if (step !== 1) {
      setFieldValue("step", 1);
    }
  }, [setFieldValue, step]);

  return (
    <Stack>
      <Stack spacing={5}>
        {parts?.map((item, index) => {
          return (
            <ItemByMPN
              key={item.materialID}
              item={item}
              setFieldValue={setFieldValue}
              values={values}
              parts={parts}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}

const ItemByMPN = ({ item, setFieldValue, values, parts }) => {
  const { POItem, definerID } = values;

  // console.log({ item });

  function onSelectOffer(offer) {


    setFieldValue(
      "POItem",
      POItem.map((x) => {
        // const material = x.material.map((y) => {
        //   return {
        //     ...y,
        //     price: item?.id === y?.id ? offer.price : y.price,
        //     currency: item?.id === y?.id ? offer.currency : y.currency,
        //     vendor: item?.id === y?.id ? offer.vendor : y.vendor,
        //     vendorID: item?.id === y?.id ? offer.vendor.id : y.vendorID,
        //   };
        // });

        // console.log({
        //   input: {
        //     ...x,
        //     price: item?.id === x?.material?.id ? offer.price : x.price,
        //     currency:
        //       item?.id === x?.material?.id ? offer.currency : x.currency,
        //     vendor: item?.id === x?.material?.id ? offer.vendor : x.vendor,
        //     vendorID:
        //       item?.id === x?.material?.id ? offer.vendor.id : x.vendorID,
        //   },
        // });

        return {
          ...x,
          price: item?.material.id === x?.material?.id ? offer.price : x.price,
          currency:
            item?.material.id === x?.material?.id ? offer.currency : x.currency,
          vendor:
            item?.material.id === x?.material?.id ? offer.vendor : x.vendor,
          vendorID:
            item?.material.id === x?.material?.id
              ? offer.vendor.id
              : x.vendorID,
        };
      })
    );
  }

  return (
    <Stack spacing={2}>
      <Chip
        siza="small"
        label={`${item.code || ""} ${item?.material?.mfr}-${item?.material?.mpn}`}
        color="primary"
        variant="contained"
        clickable
      />

      <OfferOptions
        // relationID={item?.relationID}
        materialID={item?.material?.id}
        customerID={definerID}
        onSelectOffer={onSelectOffer}
      />

      <Stack direction="row" spacing={4} alignItems="flex-start">
        {/* <TextField
          label="CRD"
          onChange={(e) => {
            setFieldValue(
              "POItem",
              POItem.map((x) => {
                const material = x.material.map((y) => ({
                  ...y,
                  CRD: item?.id === y?.id ? e.target.value : y.CRD,
                }));

                return { ...x, material };
              })
            );
          }}
          value={item.CRD || ""}
          width={160}
          required
          type="date"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="quantity"
          onChange={(e) => {
            setFieldValue(
              "POItem",
              POItem.map((x) => {
                const material = x.material.map((y) => ({
                  ...y,
                  quantity: item?.id === y?.id ? e.target.value : y.quantity,
                }));

                return { ...x, material };
              })
            );
          }}
          value={item.quantity || ""}
          width={240}
          required
        /> */}
        <TextField
          // disabled={index !== 0 && applyEAUToAll}
          label="Price"
          type="number"
          InputProps={{
            inputProps: { min: 0 },
          }}
          onChange={(e) => {
            setFieldValue(
              "POItem",
              POItem.map((x) => {
                // const material = x.material.map((y) => ({
                //   ...y,
                //   price: item?.id === y?.id ? e.target.value : y.price,
                // }));

                return {
                  ...x,
                  price: item?.id === x?.id ? e.target.value : x.price,
                };
              })
            );
          }}
          value={item.price || ""}
          // width={60}
          sx={{ width: 120 }}
          required
        />
        <TextField
          // disabled={index !== 0 && applyEAUToAll}
          label="currency"
          select
          // defaultValue="CNY"
          onChange={(e) => {
            setFieldValue(
              "POItem",
              POItem.map((x) => {
                // const material = x.material.map((y) => {
                //   return {
                //     ...y,
                //     currency: item?.id === y?.id ? e.target.value : y.currency,
                //   };
                // });

                return {
                  ...x,
                  currency: item?.id === x?.id ? e.target.value : x.currency,
                };
              })
            );
          }}
          value={item.currency || ""}
          sx={{ width: 120 }}
          required
        >
          {currencies.map((option) => (
            <MenuItem key={option.label} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    </Stack>
  );
};

function OfferOptions({ relationID, materialID, customerID, onSelectOffer }) {
  
  const { subsidiaryIDs } = useReactiveVar(userEntityRelationVar)
  const IDs = [customerID, ...subsidiaryIDs];

  const { data: offerByMaterialData } = useQuery(gql(offerByMaterial), {
    variables: {
      materialID,
      filter: {
        price: { attributeExists: true },
        // or: IDs.map((x) => ({ customerID: { eq: x } })),

        // [
        //   { role: { contains: "sales" } },
        //   { role: { contains: "customer service" } },
        // ],
      },
    },
    skip: !materialID || !customerID,
  });

  const { data: offerByMaterialEntityRelationData } = useQuery(
    gql(offerByMaterialEntityRelation),
    {
      variables: { materialEntityRelationID: relationID },
      skip: !relationID,
    }
  );

  const offerByMaterialItems = offerByMaterialData?.offerByMaterial?.items;

  const offerByMaterialEntityRelationItems =
    offerByMaterialEntityRelationData?.offerByMaterialEntityRelation?.items;

  const offers = [
    ...(offerByMaterialItems || []),
    ...(offerByMaterialEntityRelationItems || []),
  ].filter((x) => IDs?.includes(x.customerID));

  const filteredOffers = filterOutSameItemInArray(offers, "id");

  console.log({filteredOffers})

  return (
    <Stack spacing={2} sx={{ alignSelf: "flex-start" }}>
      {filteredOffers?.map((offer) => (
        <Stack
          key={offer.id}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          // width={350}
          p={1}
          bgcolor="blue"
          borderRadius={2}
        >
          <Stack>
            <Typography color="white" width={260}>
              Supplier: {offer.vendor.alias}
            </Typography>
            <Typography color="white" width={260}>
              Price: {offer.currency} {offer.price} on {offer.date}
            </Typography>{" "}
          </Stack>

          <Button
            size="small"
            variant="contained"
            onClick={() => onSelectOffer(offer)}
          >
            select
          </Button>
        </Stack>
      ))}
    </Stack>
  );
}
