export default function fillArrayWithNumber(items, qty, qtyAttr, newAttrName) {
  // console.log({ iteeeeeeeeeeeeees: items });

  if (!items || items.length === 0) return [{}];
  const copyOfItems = JSON.parse(JSON.stringify(items));

  for (let x of copyOfItems) {
    const filledNumber = Math.min(x[qtyAttr], qty);
    x[newAttrName] = filledNumber;
    qty -= filledNumber;

    if (qty === 0) break;
  }

  return copyOfItems;
}
