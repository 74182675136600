import { gql } from "@apollo/client";
import emitSnackbar from "../emitSnackbar";

export { default as onCreateEntity } from "./onCreateEntity";
export { default as onCreatePart } from "./onCreatePart";
export { default as onCreateMaterial } from "./onCreateMaterial";
export { default as onCreateUser } from "./onCreateUser";
export { default as onCreateOffer } from "./onCreateOffer";

export async function onCreate({
  mutation,
  fragment,
  input,
  updateCacheFields,
  client,
  refetchQueries,
}) {
  const res = await client.mutate({
    mutation: gql(mutation),
    variables: { input },
    update: async (cache, { data }) => {
      if (refetchQueries) {
        await client.refetchQueries({ include: refetchQueries });

        // console.log({ refetchQueries, res });
        // debugger
      }

      if (!updateCacheFields) return;

      const dataProperty = Object.keys(data)[0];
      const dataValue = data[dataProperty];
      // console.log({ dataValue });
      // debugger;

      const newItemRef = cache.writeFragment({
        data: dataValue,
        fragment: gql(fragment),
      });

      // debugger

      // console.log({ updateCacheFields });
      // debugger

      updateCacheFields.forEach((field) => {
        // console.log({ field });
        // console.log({ newItemRef });

        // debugger
        // console.log({ cache });

        // debugger;

        cache.modify({
          fields: {
            [field](existingItems) {
              // debugger;

              return {
                ...existingItems,
                items: [
                  ...existingItems.items.filter(
                    (x) => x.__ref !== newItemRef.__ref
                  ),
                  newItemRef,
                ],
              };
            },
          },
        });
      });
    },
  });
  emitSnackbar({
    message: "Data created successfully",
    duration: 3000,
  });
  return res;
}
