import React from "react";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Stack, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import Item from "./components/Item";
import DataGridComp from "../../../component/DataGrid";
import FileZone from "../../../component/FileZone";
import Notes from "../../../component/Notes";
import Updates from "../../../component/Updates";
import getFilteredItemsByUserRole from "../../../function/getFilteredItemsByUserRole";
import { offerStatus } from "../../../settings/formFieldEnum";
import CreateInquiryStepForm from "./components/CreateInquiryStepForm";
import { getOffer, offerByDefiner } from "./query";
import onDelete from "./onDelete";
import CreateForm from "./components/CreateForm";
import { userEntityRelationVar } from "../../../client/cache";

function ItemView({ lineData: { id }, onClose }) {
  const { entityID } = useReactiveVar(userEntityRelationVar);

  let { data } = useQuery(gql(getOffer), { variables: { id } });

  data = data?.getOffer;

  if (!data) return null;

  data = { ...data, definerID: entityID };

  const storagePath = `${entityID}/cost/${data?.number}`;

  return (
    <Stack
      justifyContent="space-between"
      sx={{ width: 1500, height: 900, padding: 2 }}
    >
      <Stack spacing={5}>
        <Item data={data} onClose={onClose} />
        <FileZone storagePath={storagePath} />
        <Notes itemID={id} />
        <Updates itemID={id} />
      </Stack>

      <Button
        variant="contained"
        sx={{ alignSelf: "center", justifySelf: "flex-end" }}
        onClick={onClose}
      >
        back
      </Button>
    </Stack>
  );
}

function Comp({ entityType, userRole, isAuthenticated, entityID }) {
  const isAdmin = userRole?.includes("admin");
  const { subsidiaryIDs } = useReactiveVar(userEntityRelationVar);

  const IDs = [entityID, ...subsidiaryIDs];

  const queryVariables = {
    definerID: entityID,
    filter: {
      or: IDs.map((id) => ({
        customerID: { eq: id },
      })),
    },
    limit: 5000,
  };

  const navigate = useNavigate();
  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 140,
      userRole: ["admin", "buyer"],
    },
    {
      field: "number",
      headerName: "Number",
      width: 180,
      userRole: ["admin"],
    },
    {
      field: "itemNumber",
      headerName: "Item",
      width: 140,
      userRole: ["admin"],
    },
    {
      field: "vendor",
      headerName: "Vendor",
      width: 180,
      userRole: ["admin", "buyer"],
      // renderCell: ({ row, ...props }) =>
      //   row?.vendor?.alias || row?.vendor?.name,
    },
    {
      field: "sales",
      headerName: "Sales",
      width: 180,
      userRole: ["admin", "buyer"],
      renderCell: ({ row, ...props }) => row?.sales?.name || row?.sales?.email,
    },
    {
      field: "code",
      headerName: "Code",
      width: 180,
      userRole: ["admin", "buyer"],
    },
    {
      field: "mfr",
      headerName: "MFR",
      width: 180,
      userRole: ["admin", "buyer"],
    },
    {
      field: "mpn",
      headerName: "MPN",
      width: 180,
      userRole: ["admin", "buyer"],
    },

    {
      field: "targetPrice",
      headerName: "TP",
      width: 140,
      userRole: ["admin", "buyer"],
    },
    {
      field: "offer",
      headerName: "offer",
      width: 200,
      userRole: ["admin", "buyer"],
    },

    {
      field: "leadtime",
      headerName: "leadtime",
      width: 200,
      userRole: ["admin", "buyer"],
    },
    {
      field: "EAU",
      headerName: "EAU",
      width: 200,
      userRole: ["admin", "buyer"],
    },
    {
      field: "status",
      headerName: "Status",
      width: 300,
      userRole: ["admin", "buyer"],
      renderCell: ({ row, ...props }) =>
        offerStatus.find((x) => x.code === row.statusCode)?.status,
    },
    {
      field: "inquiryValidDays",
      headerName: "InquiryValidDays",
      width: 200,
      userRole: ["admin", "buyer"],
    },
    {
      field: "note",
      headerName: "Note",
      width: 400,
      userRole: ["admin", "buyer"],
    },
  ];
  const dropdownMenu = [
    {
      label: "Delete",
      userRole: ["admin"],
      onClick: async ({ client, selectionModel, setSelectionModel }) => {
        await onDelete({
          selectionModel,
          setSelectionModel,
          client,
        });
      },
    },
  ];

  const filteredColumns = getFilteredItemsByUserRole(columns, userRole);

  const filteredDropdownMenu = getFilteredItemsByUserRole(
    dropdownMenu,
    userRole
  );

  const topRightButtons = [
    {
      comp: ({ rows, setRows }) => {
        return (
          <Button
            onClick={() => {
              navigate("newRFQ");
            }}
          >
            <RequestQuoteIcon /> inquiry
          </Button>
        );
      },
      userRole: ["admin"],
    },
  ];

  const filteredTopRightButtons = getFilteredItemsByUserRole(
    topRightButtons,
    userRole
  );

  function prepareItemsFunc( items ) {
    return items.map((x) => ({
      ...x,
      mpn: x?.material?.mpn,
      mfr: x?.material?.mfr,
      vendor: x?.vendor?.abbr,
      code: x.material?.materialEntityRelations?.items
        ?.map((x) => x.code)
        .join(", "),
    }));
  }

  const sortBy = (arr) => {
    arr.sort((a, b) => (a.itemNumber > b.itemNumber ? -1 : 1));
    arr.sort((a, b) => (a.number > b.number ? 1 : -1));
    return arr;
  };
  const filterAttrs = [
    { label: "mfr", value: "mfr", type: "string" },
    {
      label: "mpn",
      value: "mpn",
      type: "string",
    },
    { label: "number", value: "number", type: "string" },
    { label: "vendor", value: "vendor", type: "string" },
    { label: "code", value: "code", type: "string" },
    { label: "price", value: "price", type: "number" },

  ];

  return (
    <DataGridComp
      query={offerByDefiner}
      queryVariables={queryVariables}
      sortBy={sortBy}
      onClickAddRoute={isAdmin ? "new" : null}
      columns={filteredColumns}
      DetailsDialog={isAdmin ? ItemView : null}
      userRole={userRole}
      checkboxSelection={isAdmin ? true : null}
      getDropdownMenu={filteredDropdownMenu}
      prepareItemsFunc={prepareItemsFunc}
      topRightButtons={filteredTopRightButtons}
      filterAttrs={filterAttrs}
    />
  );
}

const inquiries = {
  path: "costs",

  sideNav: {
    group: 2,
    order: 20,
    label: "Costs",
    entityType: ["100", "300"],
    allowedRole: ["admin", "buyer"],
    auth: ["authenticated"],
  },
  route: {
    index: {
      element: null,
      Comp,
      allowedRole: ["admin", "buyer"],
      entityType: "all",
      auth: ["authenticated"],
    },
    new: {
      element: <CreateForm />,
      allowedRole: ["admin", "buyer"],
      entityType: "all",
      auth: ["authenticated"],
    },
    newRFQ: {
      element: <CreateInquiryStepForm />,
      allowedRole: ["admin", "buyer"],
      entityType: "all",
      auth: ["authenticated"],
    },
  },
};

export default inquiries;
