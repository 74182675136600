import { useEffect, useState } from "react";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Hub } from "aws-amplify";
import { Stack, Button, Typography } from "@mui/material";
import Notes from "../../../component/Notes";
import Updates from "../../../component/Updates";
import FileZone from "../../../component/FileZone";
import DataGridComp from "../../../component/DataGrid";
import getFilteredItemsByUserRole from "../../../function/getFilteredItemsByUserRole";
import { POByDefinerID, getPO, POByNumber, POByVendorID } from "./query";
import onDeletePO from "./onDelete";
import Item from "./components/Item";
import ViewByVendor from "./components/ViewByVendor/index.js";

import ForwarderPO from "../../../component/ForwarderPO";
import ChartView from "../../../component/ChartView";
import { vatRate, POStatusCode } from "../../../settings/formFieldEnum";
import { userEntityRelationVar } from "../../../client/cache";

import CreateSPOStepForm from "./components/CreateSPOStepForm";

function FullWidthItemView({ lineData: { id }, onClose, userRole }) {
  const { entityID } = useReactiveVar(userEntityRelationVar);

  let { data } = useQuery(gql(getPO), {
    variables: { id },
  });
  data = data?.getPO;

  if (!data) return null;

  data = { ...data, definerID: entityID };

  const storagePath = `${entityID}/SPO/${data?.number}`;

  return (
    <Stack
      justifyContent="space-between"
      sx={{ width: 1500, height: 900, padding: 2 }}
    >
      <Stack spacing={5}>
        <Item data={data} userRole={userRole} onClose={onClose} />
        <ForwarderPO data={data} onClose={onClose} />
        <ChartView
          currentID={id}
          query={POByNumber}
          variables={{ number: data.number }}
        />
        <FileZone storagePath={storagePath} />
        <Notes itemID={id} />
        <Updates itemID={id} />
      </Stack>

      <Button
        variant="contained"
        sx={{ alignSelf: "center", justifySelf: "flex-end" }}
        onClick={onClose}
      >
        back
      </Button>
    </Stack>
  );
}

function Comp({ entityType, userRole, isAuthenticated, entityID }) {
  const [showETA, setShowETA] = useState(false);

  const isAdmin = userRole?.includes("admin");

  const userEntityRelation = useReactiveVar(userEntityRelationVar);

  const { subsidiaryIDs } = userEntityRelation;

  const IDs = [entityID, ...subsidiaryIDs];

  // const res = useQuery(gql(POByVendorID), {
  //   variables: {
  //     vendorID: "a1dabb19-5536-40e0-819c-cb68a49ec05d",
  //     // limit: 5000,
  //   },
  // });
  // const POByVendorIDdata = res.data?.POByVendorID?.items || [];

  // console.log({ entityID, subsidiaryIDs, IDs });

  const queryVariables = {
    definerID: entityID,

    filter: {
      or: IDs.map((id) => ({
        customerID: { eq: id },
      })),
    },
  };

  if (showETA) {
    return <ViewByVendor setShowETA={setShowETA} />;
  }

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 180,
      userRole: ["admin", "buyer"],
    },
    {
      field: "piNo",
      headerName: "piNo(Forwarder PO only)",
      width: 180,
      userRole: ["admin", "buyer"],
    },
    {
      field: "customer",
      headerName: "Customer",
      width: 180,
      userRole: ["admin", "buyer"],
    },
    {
      field: "number",
      headerName: "Number",
      width: 180,
      userRole: ["admin", "buyer"],
    },
    {
      field: "itemNumber",
      headerName: "Item",
      width: 90,
      userRole: ["admin", "buyer"],
    },

    {
      field: "vendor",
      headerName: "Vendor",
      width: 180,
      userRole: ["admin", "buyer"],
    },
    {
      field: "code",
      headerName: "Code",
      width: 150,
      userRole: ["admin", "buyer"],
    },

    {
      field: "mfr",
      headerName: "MFR",
      width: 180,
      userRole: ["admin", "buyer"],
    },
    {
      field: "mpn",
      headerName: "MPN",
      width: 180,
      userRole: ["admin", "buyer"],
    },
    {
      field: "qty",
      headerName: "Qty",
      width: 150,
      userRole: ["admin", "buyer"],
    },
    {
      field: "openQty",
      headerName: "Open Qty",
      width: 150,
      userRole: ["admin", "buyer"],
    },
    {
      field: "price",
      headerName: "Price",
      width: 100,
      userRole: ["admin", "buyer"],
    },
    {
      field: "priceCur",
      headerName: "Currency",
      width: 100,
      userRole: ["admin", "buyer"],
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 100,
      userRole: ["admin", "buyer"],
    },
    {
      field: "CRD",
      headerName: "CRD",
      width: 140,
      userRole: ["admin", "buyer"],
    },
    {
      field: "ETA",
      headerName: "ETA",
      width: 200,
      userRole: ["admin", "buyer"],
      renderCell: ({ row, value, ...props }) => {
        return (
          <Stack direction="row" spacing={2}>
            <Typography>
              {value?.lines.map((x) => `${x.qty}-${x.eta}`).join(", ")}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "statusCode",
      headerName: "statusCode",
      width: 140,
      userRole: ["admin", "sales"],
      renderCell: (params) => {
        const label = POStatusCode.find((x) => x.value === params.value)?.label;
        return <Typography>{label}</Typography>;
      },
    },

    {
      field: "incoTerms",
      headerName: "IncoTerms",
      width: 200,
      userRole: ["admin", "buyer"],
      renderCell: ({ row, ...props }) => {
        return (
          <Stack direction="row" spacing={2}>
            <Typography>{row?.incoTerms?.terms}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "paymentTerms",
      headerName: "PaymentTerms",
      width: 200,
      userRole: ["admin", "buyer"],
    },
    {
      field: "billingStatus",
      headerName: "Billing Status",
      width: 200,
      userRole: ["admin", "buyer"],
    },
  ];
  const dropdownMenu = [
    {
      label: "Delete",
      userRole: ["admin"],
      onClick: async ({ client, selectionModel, setSelectionModel }) => {
        await onDeletePO({
          selectionModel,
          setSelectionModel,
          client,
        });
      },
    },
    {
      label: "Batch Update",
      userRole: ["admin"],
      onClick: async ({ client, selectionModel, setSelectionModel }) => {
        Hub.dispatch("dialogMonitor", {
          event: "batchUpdate",
          data: {
            selectionModel,
            setSelectionModel,
            attrs: [
              "price",
              "priceCur",
              "billingStatus",
              "materialEntityRelationID",
              "statusCode",
            ],
            model: "SPO",
          },
        });
        // await onDeletePO({
        //   selectionModel,
        //   setSelectionModel,
        //   client,
        // });
      },
    },
  ];

  const filteredColumns = getFilteredItemsByUserRole(columns, userRole);

  const filteredDropdownMenu = getFilteredItemsByUserRole(
    dropdownMenu,
    userRole
  );

  // const topRightButtons = [
  //   {
  //     comp: ({ rows, setRows, client }) => (
  //       <Button
  //         onClick={async () => {
  //           // showInsightVar(!showInsight);
  //         }}
  //       >
  //         TEST
  //       </Button>
  //     ),
  //     userRole: ["admin", "sales"],
  //   },
  // ];

  // const filteredTopRightButtons = getFilteredItemsByUserRole(
  //   topRightButtons,
  //   userRole
  // );

  const toolbarFieldAddon = [
    {
      comp: null,
      element: (
        <Button
          onClick={() => {
            setShowETA(true);
          }}
          variant="contained"
        >
          VIEW BY VENDOR
        </Button>
      ),
      userRole: ["admin", "sales"],
    },
  ];

  const filteredToolbarFieldAddon = getFilteredItemsByUserRole(
    toolbarFieldAddon,
    userRole
  );

  const sortBy = (arr) => {
    arr.sort((a, b) => (a.itemNumber * 1 > b.itemNumber * 1 ? 1 : -1));
    arr.sort((a, b) => (a.number > b.number ? -1 : 1));
    arr.sort((a, b) => (a.date > b.date ? -1 : 1));

    return arr;
  };

  const prepareItemsFunc = (items) => {
    return items.map((x) => {
      return {
        ...x,
        customer: x.customer?.alias || x.customer?.name,
        vendor: x.vendor?.alias || x.vendor?.name,
        code: x.materialEntityRelation?.code,
        mfr: x.material?.mfr,
        mpn: x.material?.mpn,
        piNo: x.pi?.no,
      };
    });
  };
  const filterAttrs = [
    { label: "statusCode", value: "statusCode", type: "string" },

    { label: "code", value: "code", type: "string" },

    { label: "mfr", value: "mfr", type: "string" },
    {
      label: "mpn",
      value: "mpn",
      type: "string",
    },
    { label: "number", value: "number", type: "string" },
    { label: "customer", value: "customer", type: "string" },
    { label: "vendor", value: "vendor", type: "string" },
    { label: "openQty", value: "openQty", type: "number" },

    { label: "date", value: "date", type: "date" },
    { label: "piNo", value: "piNo", type: "string" },
  ];

  return (
    <DataGridComp
      query={POByDefinerID}
      queryVariables={queryVariables}
      onClickAddRoute="new"
      columns={filteredColumns}
      DetailsDialog={FullWidthItemView}
      userRole={userRole}
      sortBy={sortBy}
      checkboxSelection={isAdmin ? true : null}
      getDropdownMenu={filteredDropdownMenu}
      prepareItemsFunc={prepareItemsFunc}
      ToolbarInsert={ToolbarInsert}
      filterAttrs={filterAttrs}
      // topRightButtons={filteredTopRightButtons}
      toolbarFieldAddon={filteredToolbarFieldAddon}
    />
  );
}
const SPOs = {
  path: "spos",

  sideNav: {
    group: 2,
    order: 30,
    label: "Outgoing POs",
    entityType: ["100", "300"],
    allowedRole: ["admin", "buyer"],
    auth: ["authenticated"],
  },
  route: {
    index: {
      element: null,
      Comp,
      allowedRole: ["admin", "buyer"],
      entityType: "all",
      auth: ["authenticated"],
    },
    new: {
      element: <CreateSPOStepForm />,
      allowedRole: ["admin", "buyer"],
      entityType: "all",
      auth: ["authenticated"],
    },
  },
};

export default SPOs;

function ToolbarInsert({ selectionModel }) {
  function getLineQty(v) {
    return (v?.statusCode == "900" ? 0 : v?.qty) || 0;
  }

  function getLineOpenQty(v) {
    return (v?.statusCode == "900" ? 0 : v?.openQty) || 0;
  }
  return !selectionModel?.length ? null : (
    <Stack
      direction="row"
      spacing={2}
      color="secondary.main"
      sx={{ position: "relative", top: -7 }}
    >
      <Stack direction="row">
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          QTY (ALL/OPEN):
        </Typography>
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          {selectionModel.reduce((a, v) => a + getLineQty(v), 0).toFixed(3) * 1}
          /{" "}
          {selectionModel
            .reduce((a, v) => a + getLineOpenQty(v), 0)
            .toFixed(3) * 1}
        </Typography>
      </Stack>

      <Stack direction="row">
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          VALUE (ALL/OPEN):
        </Typography>
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          {selectionModel
            .reduce((a, v) => a + (getLineQty(v) * v?.price || 0), 0)
            .toFixed(3) * 1}{" "}
          /
          {selectionModel
            .reduce((a, v) => a + (getLineOpenQty(v) * v?.price || 0), 0)
            .toFixed(3) * 1}
        </Typography>
      </Stack>
      <Stack direction="row">
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          W.VAT (ALL/OPEN):
        </Typography>
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          {(
            selectionModel
              .reduce((a, v) => a + (getLineQty(v) * v?.price || 0), 0)
              .toFixed(3) *
            (1 + vatRate)
          ).toFixed(2)}
          /{" "}
          {(
            selectionModel
              .reduce((a, v) => a + (getLineOpenQty(v) * v?.price || 0), 0)
              .toFixed(3) *
            (1 + vatRate)
          ).toFixed(2)}
        </Typography>
      </Stack>
    </Stack>
  );
}
