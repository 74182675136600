import { onCreate } from "../../../function/onCreate";
import { createMailEntityRelation } from "./query";

export default async function onCreateMailRelation({ client, values }) {
  const { userID, entityRelationID, type } = values;

  const inputForCreateMailRelations = {
    userID,
    entityRelationID,
    type,
    // groupToRead: [
    //   "admin",
    //   `${entityID}-admin`,
    //   `${entityID}-sales`,
    //   `${entityID}-accounting`,
    // ],
  };

  // console.log({ inputForCreateEntityRelations });
  // debugger

  const fragment = /* GraphQL */ `
    fragment NewMailRelations on MailEntityRelation {
      id
    }
  `;

  const res = await onCreate({
    input: inputForCreateMailRelations,
    mutation: createMailEntityRelation,
    fragment,
    updateCacheFields: [""],
    client,
    refetchQueries: ["MailEntityRelationByEntityRelation"],
  });
  // console.log({ inputForCreateEntityRelations });
  // console.log({ res });
  // debugger;
}
