import View from "./Consolidation";



const consolidation = {
  path: "consolidation",

  topNav: {
    order: 20,
    label: "Consolidation",
    entityType: ["public"],
    allowedRole: "all",
    auth: ["unAuthenticated"],
  },

  route: {
    index: {
      element: <View />,
      allowedRole: "all",
      entityType: ["public"],
      auth: ["unAuthenticated"],
    },
    // wrapper: {
    //   element: <UnAuthLayout />,
    //   allowedRole: "all",
    //   entityType: "all",
    //   auth: ["unAuthenticated"],
    // },
  },
};

export default consolidation;
