import { Typography } from "@mui/material";

const rfq = {
  path: "",
  route: {
    index: {
      element: <Typography variant="h1">ebp home</Typography>,
      allowedRole: "all",
      entityType: ["ebp"],
      auth: ["unAuthenticated"],
    },
  },
};

export default rfq;
