import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Hub } from "aws-amplify";
// import DraggablePaperComp from './DraggablePaperComp'

export default function FullScreenMessageListener() {
  const [payload, setPayload] = useState();
  const { data } = payload || {};
  const { Icon, title, message, onClick } = data || {};

  // console.log({ message, title, onClick });

  useEffect(() => {
    let ignore = false;

    const listener = ({ payload }) => {
      if (!ignore) setPayload(payload);
    };

    Hub.listen("FullScreenMessageMonitor", listener);
    return () => {
      ignore = true;
      Hub.remove("FullScreenMessageMonitor", listener);
    };
  }, []);

  if (!data) return null;

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 10000,
        bgcolor: "red",
        opacity: 0.8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 5,
      }}
    >
      <Box mt={20}>
        {Icon && <Icon fontSize="large" sx={{ color: "white" }} />}
      </Box>

      <Typography variant="h3" color="white">
        {title}
      </Typography>

      <Typography variant="h5" color="white">
        {message}
      </Typography>

      <Button
        variant="contained"
        onClick={() => {
          onClick();
          setPayload();
        }}
      >
        Continue
      </Button>
    </Box>
    // <Alert
    //   sx={{
    //     width: "100%",
    //     // bgcolor: "blue",
    //     "& .MuiAlert-message": { width: "100%" },
    //   }}
    // >
    //   {message}
    // </Alert>
  );
}
