
import Company from "./Company";

const company = {
  path: "company",

  topNav: {
    order: 60,
    label: "company",
    entityType: ["public"],
    allowedRole: "all",
    auth: ["unAuthenticated"],
  },

  route: {
    index: {
      element: <Company />,
      allowedRole: "all",
      entityType: ["public"],
      auth: ["unAuthenticated"],
    },
    // wrapper: {
    //   element: <UnAuthLayout />,
    //   allowedRole: "all",
    //   entityType: "all",
    //   auth: ["unAuthenticated"],
    // },
  },
};

export default company;
