export const relationsByDefiner = /* GraphQL */ `
  query RelationsByDefiner(
    $definerID: ID!
    $relation: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityRelationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationsByDefiner(
      definerID: $definerID
      relation: $relation
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        relation
        definerID
        entity1ID
        entity1 {
          id
          alias
          name
          relationsAsEntity1 {
            items {
              id
              relation
              entity1ID
            }
          }
        }
        entity2ID
        entity2 {
          id
          name
          alias
        }
        alias
        customerCode
        vendorCode
        statusCode
        type
        incoTerms {
          terms
          condition
          entityUserRelationID
        }
        paymentTerms
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`

export const offerByCustomer = /* GraphQL */ `
  query OfferByCustomer(
    $customerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    offerByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        statusCode
        partID
        part {
          id
          mfr
          mpn
        }
        vendorID
        vendor {
          id
          alias
          name
        }
        customerID
        customer {
          id
          alias
          name
        }
        buyerID
        buyer {
          id
          fn
          ln
          email
          name @client
        }
        salesID
        sales {
          id
          fn
          ln
          email
          name @client
        }
        price
        currency
        leadtime
        validDays
        incoTerms
        paymentTerms
        TP
        TPCurrency
        targetPrice @client
        offer @client
        EAU
        application
        projectName
        endCustomer
        version
        latestVersion
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const relationsbyVendorID = /* GraphQL */ `
  query relationsbyVendorID(
    $vendorID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerVendorRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationsbyVendorID(
      vendorID: $vendorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        alias
        customerCode
        vendorCode
        statusCode
        incoTerms
        paymentTerms
        customerID
        customerParentCompanyID
        customer {
          id
          name
          alias
          parentCompanyID
          parentCompany {
            id
            name
            alias
          }
          subsidiaries {
            items {
              id
              name
            }
          }
        }
        vendorID
        vendorParentCompanyID
        groups
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const MaterialEntityRelationsByDefiner = /* GraphQL */ `
  query MaterialEntityRelationsByDefiner(
    $definerID: ID!
    $entityID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMaterialEntityRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    MaterialEntityRelationsByDefiner(
      definerID: $definerID
      entityID: $entityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityID
        materialID
        material {
          id
          mfr
          mpn
        }
        code
        statusCode
        desc
        category
        pdt
        inventoryAlignment
        groupToRead
        groupToEdit
        groupToDelete
        definerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`

export const createPO = /* GraphQL */ `
  mutation CreatePO($input: CreatePOInput!, $condition: ModelPOConditionInput) {
    createPO(input: $input, condition: $condition) {
      id
      number
      date
      offerID

      customerID

      vendorID

      definerID
      materialID

      materialEntityRelationID

      qty
      openQty
      price
      priceCur
      paymentTerms
      incoTerms {
        terms
        entityUserRelationID
      }
      billingEntityID
      CRD
      minCharge
      serviceFeeRate
      importDutyCN
      fxRate
      shippingStatus
      billingStatus
      paymentStatus
      importAgentID
      paymentAgentID

      groupToRead
      groupToEdit
      groupToDelete
      createdAt
      updatedAt
      __typename
    }
  }
`

export const POByVendorID = /* GraphQL */ `
  query POByVendorID(
    $vendorID: ID!
    $definerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPOFilterInput
    $limit: Int
    $nextToken: String
  ) {
    POByVendorID(
      vendorID: $vendorID
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        itemNumber
        date
        offerID
        customerID
        vendorID
        definerID
        materialID
        buyerID
        salesID
        materialEntityRelationID
        qty
        openQty
        price
        priceCur
        paymentTerms
        incoTerms {
          terms
          entityUserRelationID
        }
        billingEntityID
        CRD
        shippingStatus
        billingStatus
        paymentStatus
        importAgentID
        paymentAgentID
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`

export const POByDefinerID = /* GraphQL */ `
  query POByDefinerID(
    $definerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPOFilterInput
    $limit: Int
    $nextToken: String
  ) {
    POByDefinerID(
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amount @client
        statusCode
        number
        itemNumber
        no @client
        date
        offerID
        customerID
        customer {
          id
          name
          alias
          abbr @client
        }
        vendorID
        vendor {
          id
          name
          alias
          abbr @client
        }
        definerID
        materialID
        material {
          id
          mfr
          mpn
        }
        buyerID
        salesID
        materialEntityRelationID
        materialEntityRelation {
          id
          code
        }
        qty
        openQty
        price
        priceCur
        paymentTerms
        incoTerms {
          terms
          entityUserRelationID
        }
        billingEntityID
        CRD
        shippingStatus
        billingStatus
        paymentStatus
        importAgentID
        paymentAgentID
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPO = /* GraphQL */ `
  query GetPO($id: ID!) {
    getPO(id: $id) {
      id
      number
      itemNumber
      date
      offerID
      statusCode
      customerID
      customer {
        id
        isVerified
        name
        alias
        type
        businessType
        statusCode
        groupToRead
        groupToEdit
        groupToDelete
        definerID
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      vendorID
      vendor {
        id
        isVerified
        name
        alias
        type
        businessType
        statusCode
        groupToRead
        groupToEdit
        groupToDelete
        definerID
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      definerID

      materialID
      material {
        id
        definerID
        mpn
        mfr
        coo
        desc
        unit
        leadtime
        mpq
        transitDays
        hsCode
        importDutyCN
        groupToRead
        groupToEdit
        groupToDelete
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      buyerID

      salesID

      materialEntityRelationID
      materialEntityRelation {
        id
        entityID
        materialID
        code
        statusCode
        desc
        category
        pdt
        inventoryAlignment
        groupToRead
        groupToEdit
        groupToDelete
        definerID
        createdAt
        updatedAt
        __typename
      }
      qty
      openQty
      price
      priceCur
      paymentTerms
      incoTerms {
        terms
        entityUserRelationID
      }
      billingEntityID
      CRD
      shippingStatus
      billingStatus
      paymentStatus
      importAgentID
      paymentAgentID

      groupToRead
      groupToEdit
      groupToDelete
      createdAt
      updatedAt
      __typename
    }
  }
`

export const relationsByEntity1 = /* GraphQL */ `
  query RelationsByEntity1(
    $entity1ID: ID!
    $entity2ID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityRelationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationsByEntity1(
      entity1ID: $entity1ID
      entity2ID: $entity2ID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        relation
        definerID
        entity1ID
        entity2ID
        entity2 {
          id
          name
          alias
        }
        alias
        customerCode
        vendorCode
        statusCode
        type
        paymentTerms
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`

export const updatePO = /* GraphQL */ `
  mutation UpdatePO($input: UpdatePOInput!, $condition: ModelPOConditionInput) {
    updatePO(input: $input, condition: $condition) {
      id
      number
      itemNumber
      date
      offerID
      statusCode

      customerID

      vendorID

      definerID

      materialID

      buyerID

      salesID

      materialEntityRelationID

      qty
      openQty
      price
      priceCur
      paymentTerms
      incoTerms {
        terms
        entityUserRelationID
      }
      billingEntityID
      CRD
      shippingStatus
      billingStatus
      paymentStatus
      importAgentID
      paymentAgentID

      groupToRead
      groupToEdit
      groupToDelete
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deletePO = /* GraphQL */ `
  mutation DeletePO($input: DeletePOInput!, $condition: ModelPOConditionInput) {
    deletePO(input: $input, condition: $condition) {
      id
    }
  }
`

export const getEntityUserRelation = /* GraphQL */ `
  query GetEntityUserRelation($id: ID!) {
    getEntityUserRelation(id: $id) {
      id
      role
      title
      shownFn
      shownLn
      shownMn
      statusCode
      isEmailVerified
      userID
      user {
        id
        sub
        email
        statusCode
        fn
        mn
        ln
        name @client
        phoneNumber
        address
        groupToRead
        groupToEdit
        groupToDelete
        owner
        definerID
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      entityID
      entity {
        id
        abbr @client
        isVerified
        name
        alias
        type
        businessType
        statusCode
        paymentTerms
        groupToRead
        groupToEdit
        groupToDelete
        definerID
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      definerID

      __typename
    }
  }
`

export const relationsByEntity1AndRelatioin = /* GraphQL */ `
  query RelationsByEntity1AndRelatioin(
    $entity1ID: ID!
    $relation: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityRelationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationsByEntity1AndRelatioin(
      entity1ID: $entity1ID
      relation: $relation
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        relation
        definerID
        entity1ID
        entity2ID
        alias
        customerCode
        vendorCode
        logisticsAccountNumber
        logisticsTrackingTemplate
        statusCode
        type
        paymentTerms
        incoTerms {
          terms
          entityUserRelationID
        }
        serviceFeeRate
        minCharge
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
