import { Fragment } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import {
  Box,
  Container,
  Stack,
  List,
  ListItem,
  Divider,
  Card,
  CardActionArea,
  ButtonBase,
  Button,
  Typography,
  Grow,
  Fade,
  Zoom,
  Slide,
} from "@mui/material";
import Drawer from "../Drawer";
import { userEntityRelationVar } from "../../client/cache";
import { customizedI18nGet } from "../../settings/dictionary";

import getSideNav from "../../function/getSideNav";

export default function EntityLayout() {
  const { entityType, userRole, entityID } = useReactiveVar(
    userEntityRelationVar
  );

  const { pathname } = useLocation() || {};

  const isInSubMenu = !!pathname.split(`cloud/${entityID}/`)[1];

  if (userRole === "public" || !entityID) return null;

  const menus = getSideNav({
    entityType,
    userRole,
    isAuthenticated: true,
  });


  // console.log(777777, isInSubMenu);

  return (
    <Grow
      // key={isInSubMenu}
      in
      style={
        {
          // transformOrigin: "0 0 0"
        }
      }
      timeout={1500}
    >
      <Stack
        direction="row"
        sx={{
          height: "100%",
        }}
      >
        {isInSubMenu ? (
          <Drawer menusArray={menus} />
        ) : (
          <Box
            key={isInSubMenu}
            sx={{
              width: "100%",
              // minHeight: "100vh",
              justifyContent: "center",
              alignContent: "center",
              // display: "flex",
            }}
          >
            <MenuSelection menusArray={menus} />
          </Box>
        )}

        {isInSubMenu && <Outlet />}

        {/* {isInSubMenu && (
          <Fade in={isInSubMenu} key={pathname} timeout={1000}>
            <Box display="flex" width="100%">
              <Outlet />
            </Box>
          </Fade>
        )} */}
      </Stack>
    </Grow>
  );
}

function MenuSelection({ menusArray }) {
  // console.log({ menusArray });
  return (
    <Container
      maxWidth="xl"
      // component="main"
      // alignContent="center"

      sx={{
        pt: 8,
        pb: 6,
        mt: 10,
        mb: 5,
        bgcolor: "#f1f1f1",
        minHeight: 600,
        borderRadius: 2,

        // bgcolor: "blue",
        justifyContent: "center",
        // alignItems: "center",
        display: "flex",
      }}
    >
      <Box
        sx={
          {
            // bgcolor: "blue"
          }
        }
      >
        {menusArray?.length > 0 && (
          <Stack spacing={5}>
            {menusArray?.map((menus, i) => {
              if (menus?.length === 0) return null;

              return (
                <Fragment key={i}>
                  <Stack
                    direction="row"
                    // spacing={5}
                    // minHeight={50}
                    // display="flex"
                    flexWrap="wrap"
                  >
                    {menus.map((menu) => (
                      <MenuCard key={menu.label} menu={menu} />
                    ))}
                  </Stack>
                  {i < menusArray.length - 1 && <Divider />}
                </Fragment>
              );
            })}
          </Stack>
        )}
      </Box>
    </Container>
  );
}

function MenuCard({ menu }) {
  const navigate = useNavigate();

  return (
    <ButtonBase
      sx={{ margin: 5 }}
      onClick={() => {
        navigate(menu.path);
      }}
    >
      <Card
        sx={{
          width: 160,
          height: 160,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // bgcolor: "#3f51b5",
          color: "white",
          borderRadius: 2,
          background: "linear-gradient(to right bottom, #1a237e, #8c9eff)",
        }}
      >
        <Typography
          variant="h5"
          align="center"
          // lineHeight={7}
          // sx={{ color: "white" }}
        >
          {customizedI18nGet(menu.label)}
        </Typography>
      </Card>
    </ButtonBase>
  );
}
