import { onUpdate } from "../../../function/onUpdate";
import { getInvite, updateInvite } from "./query";

export default async function onUpdateInvite({ values, client }) {
  const fieldsToUpdate = [{ field: "status", createUpdate: true }];


  await onUpdate({
    type: "Invite",
    values,
    mutation: updateInvite,
    query: getInvite,
    // getInitialValue,
    fieldsToUpdate,
    client,
    refetchQueries: [],
  });
}
