import { useQuery, gql } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import SimpleTable from "../../../../../component/SimpleTable";
import { ShippingByNumber } from "../../query";
import BraunPLBtn from "./BraunPLBtn";
import BraunCOAPrevBtn from "./BraunCOAPrevBtn";
import BraunCOABtn from "./BraunCOABtn";

export default function ShippingItems({ number }) {
  const { data } = useQuery(gql(ShippingByNumber), {
    variables: { number },
    skip: !number,
  });

  if (!data) return null;

  const headers = ["poNumber", "itemNumber", "code", "mfr", "mpn", "qty"];

  const shippingItems = data?.ShippingByNumber?.items?.map((item, i) => {
    let poNumber = item.po.no;

    if (poNumber.includes("-")) {
      poNumber = poNumber.split("-")[1];
    }

    return {
      id: item?.id,
      poNumber,
      itemNumber: item?.itemNumber,
      qty: item?.qty,
      coo: item?.coo,
      mpn: item?.material?.mpn,
      mfr: item?.material?.mfr,
      unit: item?.material?.unit,
      code: item?.materialEntityRelation?.code,
      poID: item?.po?.id,
      materialID: item?.material?.id,
      palletQty: item?.qty,
      packageQty: item?.qty,
      remainder: 0,
      number: item?.itemNumber * 1 === 1 ? number : "",
      specifications: item?.material?.specifications,
      trackingNumber: item?.trackingNumber,
      courier: item?.courier?.name,
      po: item?.po,
    };
  });

  shippingItems.sort((a, b) => (a.itemNumber * 1 > b.itemNumber * 1 ? 1 : -1));

  const date = data?.ShippingByNumber?.items?.[0]?.date;

  return (
    <Stack
      spacing={2}
      justifyContent="space-between"
      sx={{ minHeight: 120 }}
      bgcolor="#f1f1f1"
      borderRadius={1}
      padding={2}
    >
      <Stack spacing={3}>
        <Stack spacing={1}>
          <Typography variant="h6" sx={{ alignSelf: "center" }}>
            Shipping Items
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Typography variant="body2" sx={{ alignSelf: "center" }}>
              TOTAL: {shippingItems?.reduce((a, v) => a + (v.qty || 0), 0)}
            </Typography>
          </Stack>
        </Stack>

        <SimpleTable headers={headers} rows={shippingItems} />
        <Stack justifyContent="center" direction="row" spacing={2}>
          <BraunPLBtn
            date={date}
            number={number}
            shippingItems={shippingItems}
          />
          <BraunCOAPrevBtn date={date} shippingItems={shippingItems} />
          <BraunCOABtn date={date} shippingItems={shippingItems} />
        </Stack>
      </Stack>
    </Stack>
  );
}
