export const createMaterial = /* GraphQL */ `
  mutation CreateMaterial(
    $input: CreateMaterialInput!
    $condition: ModelMaterialConditionInput
  ) {
    createMaterial(input: $input, condition: $condition) {
      id
      definerID

      mpn
      mfr
      coo

      desc
      unit
      leadtime
      mpq
      transitDays
      hsCode
      importDutyCN

      groupToRead
      groupToEdit
      groupToDelete
      isDeleted
      createdAt
      updatedAt

      __typename
    }
  }
`;

export const updateMaterial = /* GraphQL */ `
  mutation UpdateMaterial(
    $input: UpdateMaterialInput!
    $condition: ModelMaterialConditionInput
  ) {
    updateMaterial(input: $input, condition: $condition) {
      id
      definerID

      mpn
      mfr
      coo
      specifications {
        order
        attr
        predicator
        specValueFrom
        specValueTo
        specValueEqual
        testValueFrom
        testValueTo
        testValueEqual
        unit
        digits
      }
      desc
      unit
      leadtime
      mpq
      transitDays
      hsCode
      importDutyCN
      inventory
      groupToRead
      groupToEdit
      groupToDelete
      isDeleted
      createdAt
      updatedAt

      __typename
    }
  }
`;

export const deleteMaterial = /* GraphQL */ `
  mutation DeleteMaterial(
    $input: DeleteMaterialInput!
    $condition: ModelMaterialConditionInput
  ) {
    deleteMaterial(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createMaterialEntityRelation = /* GraphQL */ `
  mutation CreateMaterialEntityRelation(
    $input: CreateMaterialEntityRelationInput!
    $condition: ModelMaterialEntityRelationConditionInput
  ) {
    createMaterialEntityRelation(input: $input, condition: $condition) {
      id
      entityID

      materialID

      code
      statusCode
      desc
      category
      pdt
      inventoryAlignment

      groupToRead
      groupToEdit
      groupToDelete
      definerID

      createdAt
      updatedAt

      __typename
    }
  }
`;
export const updateMaterialEntityRelation = /* GraphQL */ `
  mutation UpdateMaterialEntityRelation(
    $input: UpdateMaterialEntityRelationInput!
    $condition: ModelMaterialEntityRelationConditionInput
  ) {
    updateMaterialEntityRelation(input: $input, condition: $condition) {
      id
      entityID

      materialID

      code
      statusCode
      desc
      category
      pdt
      inventoryAlignment

      groupToRead
      groupToEdit
      groupToDelete
      definerID

      createdAt
      updatedAt

      __typename
    }
  }
`;
export const deleteMaterialEntityRelation = /* GraphQL */ `
  mutation DeleteMaterialEntityRelation(
    $input: DeleteMaterialEntityRelationInput!
    $condition: ModelMaterialEntityRelationConditionInput
  ) {
    deleteMaterialEntityRelation(input: $input, condition: $condition) {
      id
    }
  }
`;

export const getMaterial = /* GraphQL */ `
  query GetMaterial($id: ID!) {
    getMaterial(id: $id) {
      id
      definerID
      specifications {
        order
        attr
        predicator
        specValueFrom
        specValueTo
        specValueEqual
        testValueFrom
        testValueTo
        testValueEqual
        unit
        digits
      }
      mpn
      mfr
      coo
      materialEntityRelations {
        items {
          id
          code
          statusCode
          entityID
          entity {
            id
            name
            alias
          }
        }
        nextToken
        __typename
      }

      desc
      unit
      leadtime
      mpq
      transitDays
      hsCode
      importDutyCN

      groupToRead
      groupToEdit
      groupToDelete
      isDeleted
      createdAt
      updatedAt
      inventory

      __typename
    }
  }
`;
export const MaterialsByDefiner = /* GraphQL */ `
  query MaterialsByDefiner(
    $definerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMaterialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    MaterialsByDefiner(
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        definerID
        materialEntityRelations {
          items {
            id
            code
            statusCode
            entityID
            entity {
              id
              name
              alias
            }
          }
        }
        mpn
        mfr
        coo
        desc
        unit
        leadtime
        mpq
        transitDays
        hsCode
        importDutyCN
        groupToRead
        groupToEdit
        groupToDelete
        inventory
        createdAt

        updatedAt

        __typename
      }
      nextToken

      __typename
    }
  }
`;

export const getMaterialEntityRelation = /* GraphQL */ `
  query GetMaterialEntityRelation($id: ID!) {
    getMaterialEntityRelation(id: $id) {
      id
      entityID
      entity {
        id
        isVerified
        name
        alias
      }
      materialID
      material {
        id
        definerID
        mpn
        mfr
        coo
        desc
        unit
        leadtime
        mpq
        transitDays
        hsCode
        importDutyCN
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt

        __typename
      }
      code
      statusCode
      desc
      category
      pdt
      inventoryAlignment

      groupToRead
      groupToEdit
      groupToDelete
      definerID

      createdAt
      updatedAt

      __typename
    }
  }
`;

export const entitiesByDefiner = /* GraphQL */ `
  query EntitiesByDefiner(
    $definerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEntityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entitiesByDefiner(
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        alias
        definerID
      }
      nextToken
      __typename
    }
  }
`;

export const materialEntityRelationByMaterial = /* GraphQL */ `
  query MaterialEntityRelationByMaterial(
    $materialID: ID!
    $entityID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMaterialEntityRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    materialEntityRelationByMaterial(
      materialID: $materialID
      entityID: $entityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityID
        materialID

        definerID
        createdAt
        updatedAt

        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const materialByMPN = /* GraphQL */ `
  query MaterialByMPN(
    $mpn: String!
    $mfr: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMaterialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    materialByMPN(
      mpn: $mpn
      mfr: $mfr
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        definerID
        mpn
        mfr
        coo
        desc
        unit
        leadtime
        mpq
        transitDays
        hsCode
        importDutyCN
        groupToRead
        groupToEdit
        groupToDelete
        isDeleted
        createdAt
        updatedAt
        inventory
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getMaterialInDepth = /* GraphQL */ `
  query GetMaterial($id: ID!) {
    getMaterial(id: $id) {
      id
      definerID
      mpn
      mfr
      coo
      # materialEntityRelations {
      #   items {
      #     id
      #     code
      #     statusCode
      #     entityID
      #     entity {
      #       id
      #       name
      #       alias
      #     }
      #   }
      #   nextToken
      #   __typename
      # }
      offers(filter: { statusCode: { ne: "900" } }) {
        items {
          id
          customerID
          vendorID
          price
          currency
          definerID
        }
      }

      desc
      unit
      leadtime
      mpq
      transitDays
      hsCode
      importDutyCN

      groupToRead
      groupToEdit
      groupToDelete
      isDeleted
      createdAt
      updatedAt
      inventory

      __typename
    }
  }
`;

export const POByMaterialID = /* GraphQL */ `
  query POByMaterialID(
    $materialID: ID!
    $customerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPOFilterInput
    $limit: Int
    $nextToken: String
  ) {
    POByMaterialID(
      materialID: $materialID
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ETARequested
        id
        number
        itemNumber
        date
        offerID
        customerID
        vendorID
        definerID
        materialID
        materialEntityRelationID
        buyerID
        salesID
        qty
        openQty
        price
        priceCur
        paymentTerms
        billingEntityID
        CRD
        shippingStatus
        billingStatus
        paymentStatus
        importAgentID
        paymentAgentID
        forwarderID
        fxRate
        importDutyCN
        serviceFeeRate
        minCharge
        piID
        groupToRead
        groupToEdit
        groupToDelete
        version
        latestVersion
        statusCode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
