import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Stack, Button, Typography } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import Notes from "../../../component/Notes";
import Updates from "../../../component/Updates";
import FileZone from "../../../component/FileZone";
import DataGridComp from "../../../component/DataGrid";
import getFilteredItemsByUserRole from "../../../function/getFilteredItemsByUserRole";
import { entitiesByDefiner, getEntity } from "./query";
import onDeleteEntity from "./onDelete";
import Item from "./components/Item";
import BillingInfo from "./components/BillingInfo";
import Relation from "./components/relations/index";
import CreateEntityStepForm from "./components/CreateEntityStepForm";
import { userEntityRelationVar } from "../../../client/cache";

function ItemView({ lineData, onClose }) {
  const { entityID } = useReactiveVar(userEntityRelationVar);

  let { data } = useQuery(gql(getEntity), {
    variables: { id: lineData?.id },
  });
  data = data?.getEntity;

  if (!data) return null;

  const incoTerms = data?.incoTerms?.map((x) => ({
    entityUserRelationID: x?.entityUserRelationID,
    condition: x?.condition,
    terms: x?.terms,
    entityUserRelation: { id: x.entityUserRelationID },
  }));

  data = { ...data, incoTerms, definerID: entityID };

  const storagePath = `${entityID}/entities/${lineData?.id}`;

  return (
    <Stack
      justifyContent="space-between"
      sx={{ width: 1500, height: 900, padding: 2 }}
    >
      <Stack spacing={5}>
        <Item data={data} onClose={onClose} />
        <BillingInfo data={data} onClose={onClose} />
        <Relation data={data} onClose={onClose} />
        <FileZone storagePath={storagePath} />
        <Notes itemID={lineData?.id} />
        <Updates itemID={lineData?.id} />
      </Stack>

      <Button
        variant="contained"
        sx={{ alignSelf: "center", justifySelf: "flex-end" }}
        onClick={onClose}
      >
        back
      </Button>
    </Stack>
  );
}

function Comp({ entityType, userRole, isAuthenticated, entityID }) {
  const isAdmin = userRole?.includes("admin");
  const columns = [
    {
      field: "businessType",
      headerName: "Business Type",
      width: 180,
      userRole: ["admin", "sales"],
      renderCell: ({ row, ...props }) => {
        return <Typography>{row?.businessType?.join(", ")}</Typography>;
      },
    },
    {
      field: "name",
      headerName: "name",
      width: 360,
      userRole: ["admin", "sales"],
      renderCell: ({ row, ...props }) => {
        return (
          <Stack direction="row" spacing={2}>
            <Typography>
              {row?.name} {row.id === entityID ? "★" : ""}
            </Typography>
            {row?.isVerified && <VerifiedIcon color="success" />}
          </Stack>
        );
      },
    },

    {
      field: "alias",
      headerName: "alias",
      width: 300,
      userRole: ["admin", "sales"],
    },

    {
      field: "statusCode",
      headerName: "statusCode",
      width: 300,
      userRole: ["admin"],
    },
    {
      field: "definer",
      headerName: "Definer",
      width: 300,
      userRole: ["admin"],
      renderCell: ({ row, ...props }) => {
        return (
          <Stack direction="row" spacing={2}>
            <Typography>{row?.definer?.alias || row?.definer?.name}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "relation",
      headerName: "Relation",
      width: 300,
      userRole: ["admin", "sales"],
    },
  ];
  const dropdownMenu = [
    {
      label: "Delete",
      userRole: ["admin"],
      onClick: async ({ client, selectionModel, setSelectionModel }) => {
        await onDeleteEntity({
          selectionModel,
          setSelectionModel,
          client,
        });
      },
    },
  ];
  const queryVariables = { definerID: entityID, limit: 5000 };

  const filteredColumns = getFilteredItemsByUserRole(columns, userRole);

  const filteredDropdownMenu = getFilteredItemsByUserRole(
    dropdownMenu,
    userRole
  );

  const prepareItemsFunc = (items ) => {
    // console.log({ items });

    return items.map((x) => {
      const relationsAsEntity1 =
        x?.relationsAsEntity1?.items?.map(
          (y) =>
            `${y?.relation?.split("-")[0]} of ${
              y?.entity2?.alias || y?.entity2?.name
            }`
        ) || [];

      const relationsAsEntity2 =
        x?.relationsAsEntity2?.items?.map(
          (y) =>
            `${y?.relation?.split("-")[1]} of ${
              y?.entity1?.alias || y?.entity1?.name
            }`
        ) || [];

      return {
        ...x,
        relation: [...relationsAsEntity1, ...relationsAsEntity2].join(", "),
      };
    });
  };
  if (!entityID) return null;

  const filterAttrs = [
    { label: "name", value: "name", type: "string" },
    { label: "alias", value: "alias", type: "string" },

    {
      label: "statusCode",
      value: "statusCode",
      type: "string",
    },
    { label: "relation", value: "relation", type: "string" },
  ];

  return (
    <DataGridComp
      query={entitiesByDefiner}
      queryVariables={queryVariables}
      onClickAddRoute={isAdmin ? "new" : null}
      columns={filteredColumns}
      DetailsDialog={isAdmin ? ItemView : null}
      userRole={userRole}
      checkboxSelection={isAdmin ? true : false}
      getDropdownMenu={filteredDropdownMenu}
      prepareItemsFunc={prepareItemsFunc}
      filterAttrs={filterAttrs}
    />
  );
}

const entities = {
  path: "entities",

  sideNav: {
    group: 1,
    order: 15,
    label: "Entities",
    entityType: ["100", "200", "300"],
    allowedRole: ["admin", "sales"],
    auth: ["authenticated"],
  },
  route: {
    index: {
      element: null,
      Comp,
      allowedRole: "all",
      entityType: "all",
      auth: ["authenticated"],
    },
    new: {
      element: <CreateEntityStepForm />,
      allowedRole: ["admin", "sales"],
      entityType: "all",
      auth: ["authenticated"],
    },
  },
};

export default entities;
