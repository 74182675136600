import React, { useState } from "react";
import {
  Stack,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { useApolloClient } from "@apollo/client";
import LoadingButton from "@mui/lab/LoadingButton";
import updateCPO from "../modules/entityModules/CPOs/onUpdate";
import updateSPO from "../modules/entityModules/SPOs/onUpdate";
import updateReceivingAndShipping from "../modules/entityModules/shippings/onUpdate";

import updateOutgoingInv from "../modules/entityModules/billing/onUpdate";

export default function BatchUpdate({
  selectionModel,
  setSelectionModel,
  attrs,
  model,
  onClose,
}) {
  const client = useApolloClient();
  // console.log({ selectionModel, setSelectionModel, onClose });
  const [attr, setAttr] = useState("");
  // const [type, setType] = useState("");
  const type = attrs?.find((x) => x.label === attr)?.type || "string";

  // setType(
  //   attrs?.find((x) => x.label === e.target.value)?.type || "string"
  // );
  const [fieldValue, setFieldValue] = useState("");
  const [loading, setLoading] = useState(false);

  console.log({ attr, fieldValue, type });

  let func;

  switch (model) {
    case "CPO":
      func = updateCPO;
      break;
    case "SPO":
      func = updateSPO;
      break;
    case "Invoice":
      func = updateOutgoingInv;
      break;
    case "ReceivingAndShipping":
      func = updateReceivingAndShipping;
      break;
    default:
      break;
  }

  async function onSubmit() {
    if (attr === "" || fieldValue === undefined) {
      return alert("please select attr and enter value");
    }
    if (!func) {
      return alert("please set update func");
    }
    setLoading(true);

    try {
      await Promise.all(
        selectionModel.map(async (x) => {
          await func({
            values: { id: x?.id, [attr]: fieldValue },
            client,
          });
        })
      );
      onClose && onClose();
      setSelectionModel && setSelectionModel([]);
      setLoading(false);
    } catch (err) {
      console.log({ err85445: err });
      setLoading(false);
    }
  }

  return (
    <Stack spacing={5} m={5}>
      <Stack
        direction="row"
        width={500}
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        {/* <FormControl sx={{ width: 200 }}> */}
        {/* <InputLabel id="dialog-simple-select-label">Attr</InputLabel> */}
        <TextField
          sx={{ width: 200 }}
          label="Attr"
          // labelId="dialog-simple-select-label" id="dialog-simple-select"
          value={attr}
          onChange={(e) => {
            setAttr(e.target.value);
          }}
          select
        >
          {attrs?.map((x) => (
            <MenuItem key={x.label || x} value={x.label || x}>
              {x.label || x}
            </MenuItem>
          ))}
        </TextField>
        {/* </FormControl> */}
        <TextField
          value={fieldValue || ""}
          type={type}
          InputLabelProps={{ ...(type === "date" && { shrink: true }) }}
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => setFieldValue(null)}>
                <ClearIcon />
              </IconButton>
            ),
          }}
          onChange={(e) => setFieldValue(e.target.value)}
        />
      </Stack>
      <LoadingButton
        disabled={attr === "" || fieldValue === undefined}
        loading={loading}
        sx={{ alignSelf: "center" }}
        variant="contained"
        onClick={onSubmit}
      >
        Submit
      </LoadingButton>
    </Stack>
  );
}
