export const paymentByType = /* GraphQL */ `
  query PaymentByType(
    $type: String!
    $definerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paymentByType(
      type: $type
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        isPrepayment
        date
        poID
        po {
          id
          number
          itemNumber
          no @client
          vendor {
            id
            name
            alias
            abbr @client
          }
        }

        invoiceID
        invoice {
          id
          number
          itemNumber
          no @client
          po {
            id
            vendor {
              id
              name
              alias
              abbr @client
            }
          }
        }
        amount
        desc
        definerID
        createdAt
        updatedAt
        owner
        payeeID
        payee {
          id
          name
          alias
          abbr @client
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      category
      type
      number
      itemNumber
      date
      price
      qty
      currency
      note
      desc
      poID
      po {
        id
        no @client
        number
        itemNumber
        amount @client
        price
        qty
        customer {
          id
          name
          alias
          abbr @client
        }
        vendor {
          id
          name
          alias
          abbr @client
        }
        materialEntityRelation {
          id
          code
        }
        material {
          id
          mfr
          mpn
          unit
        }
      }

      paymentDueDate
      billingEntityID

      shippingID

      paymentStatus
      deductionDate
      definerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
