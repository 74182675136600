import { useRef } from "react";
import { useApolloClient } from "@apollo/client";
import { Box, Stack, Typography, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { Form, Formik } from "formik";
import FormButtons from "./FormButton";
import FormikArray from "./FormikArray";
// import FieldErrorMessage from "./FieldErrorMessage";

export { default as FormikField } from "./FormikField";
export { default as FormikRadioGroup } from "./FormikRadioGroup";
export { default as FormikArrayAddItemButton } from "./FormikArrayAddItemButton";
export { default as FormikArrayRemoveItemButton } from "./FormikArrayRemoveItemButton";
export { default as FormikSelect } from "./FormikSelect";
export { default as FormikAutocomplete } from "./FormikAutocomplete";
export { default as FormikCheckbox } from "./FormikCheckbox";
export { default as FormikSwitch } from "./FormikSwitch";

export { FormikArray };

export default function FormikForm({
  initialValues,
  validationSchema,
  FormMainFields,
  FormArray,
  handleCancel,
  saveButtonLabel = "submit",
  onSubmit,
  onDelete,
  disableEdit,
  addonBtns,
}) {
  const renderTimes = useRef(0);
  renderTimes.current = renderTimes.current + 1;
  const client = useApolloClient();
  // console.log({ initialValues });

  //ATTENTION  PLEASE ENABLE REINITIALIZE
  return (
    <Box width="100%">
      <Formik
        // enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          // console.log({ values, actions });

          const { resetForm, setSubmitting } = actions;
          // setSubmitting(true);
          await onSubmit({ values, client });

          //  else if (onClose) {
          //   onClose();
          // }

          setSubmitting(false);
          // resetForm();
        }}
      >
        {(props) => {
          const { values, isSubmitting, setSubmitting, setFieldValue } = props;

          let arr = FormArray;

          if (typeof FormArray === "function") {
            arr = FormArray(values);
          }

          // async function handleDelete() {
          //   // debugger;
          //   if (onDelete) {
          //     await onDelete({ values, client });
          //   }
          //   if (onClose) {
          //     onClose();
          //   }
          // }

          return (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              bgcolor="#f0f0f0"
              sx={{ p: 3 }}
            >
              {console.log({
                values: props.values,
                errors: props.errors,
                touched: props.touched,
              })}
              {/* <Typography>
                Form wrapper rendered {renderTimes.current} times
              </Typography> */}
              {/* <RenderCounter /> */}
              <Form
                autoComplete="off"
                onSubmit={async (e) => {
                  e.preventDefault();
                  const errors = await props.validateForm();
                  props.setTouched({ ...errors });

                  // console.log({ errors });
                  // debugger;

                  if (Object.keys(errors)?.length === 0) {
                    // console.log(111);
                    // debugger;

                    await props.submitForm();
                    setFieldValue("isEditing", false);
                  }
                }}
              >
                <Box
                  sx={{
                    // alignSelf: "center",
                    display: "flex",
                    // width: "100%",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: 3,
                    borderRadius: 1,

                    p: 3,
                    minWidth: 800,
                    // py: 2,
                    // m: 5,
                    // bgcolor: "purple",
                  }}
                >
                  <FormMainFields
                    onClose={handleCancel}
                    // initialValues={initialValues}
                    // isEditing={isEditing}
                    {...props}
                  />
                  {arr?.map((x) => {
                    // const isDisabled = x.disabled?.(values);

                    let disabled = false;

                    if (x.disabled) {
                      disabled = x.disabled(values);
                    }

                    return !disabled ? (
                      <FormikArray
                        key={x.name}
                        name={x.name}
                        Item={x.Item}
                        width={x.width}
                        subArray={x.subArray}
                        bgcolor={x.bgcolor}
                        btnColor={x.btnColor}
                        size={x.size}
                        hideLabel={x.hideLabel}
                        disableAdd={x.disableAdd}
                        narrow={x.narrow}
                        defaultValue={x.defaultValue}
                      />
                    ) : null;
                  })}

                  {values.isEditing ? (
                    <>
                      <FormButtons
                        isSubmitting={isSubmitting}
                        onSubmit={onSubmit}
                        handleCancel={handleCancel}
                        saveButtonLabel={saveButtonLabel}
                        onDelete={onDelete}
                      />
                    </>
                  ) : (
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{ alignSelf: "center" }}
                    >
                      {!disableEdit && (
                        <Button
                          variant="contained"
                          onClick={() => {
                            setFieldValue("isEditing", true);
                          }}
                          children="Edit"
                        />
                      )}
                      {addonBtns &&
                        addonBtns({ values, client, setSubmitting }).map(
                          (x) => (
                            <LoadingButton
                              key={x.label}
                              disabled={isSubmitting}
                              variant="contained"
                              onClick={x.onClick}
                              children={x.label}
                            />
                          )
                        )}

                      {onDelete && (
                        <LoadingButton
                          variant="contained"
                          onClick={onDelete}
                          children="delete"
                        />
                      )}
                    </Stack>
                  )}
                </Box>
              </Form>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
}

function RenderCounter() {
  const count = useRef(0);
  count.current = count.current + 1;

  return (
    <Typography> Formik children rendered {count.current} times </Typography>
  );
}
