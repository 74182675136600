import prototyping from "../../../assets/images/prototyping2.png";
import smt_assembly from "../../../assets/images/smt-assembly.jpg";
import ems from "../../../assets/images/ems.jpg";
import thru_hole_assembly from "../../../assets/images/thru_hole_assembly.jpg";
import Template from "../../../component/Template";

const headPicture = ems;
const headTitle = "Electronic Manufacturing Services";
const headDescription =
  "You'll enjoy reduced stress and increased productivity - so you can focus on driving growth and innovation in your business";

const headMessage = `We partner with industry-leading Electronic Manufacturing Services
            (EMS) providers to offer a range of reliable and cost-efficient
            manufacturing solutions. By leveraging the expertise and
            capabilities of these top-tier partners, we're able to provide our
            customers with comprehensive manufacturing services that meet their
            unique needs and goals`;
const sections = [
  {
    title: "Prototyping",
    image: prototyping,
    description: `Our prototyping services are designed to turn your ideas into reality quickly and efficiently. We understand that the prototyping phase is crucial for validating designs and identifying potential issues early in the development process. With our state-of-the-art equipment and skilled technicians, we can produce high-quality prototypes that accurately represent your final product. From concept to completion, we work closely with you to ensure that your specifications are met, enabling you to test, refine, and iterate your designs with ease.`,
  },
  {
    title: "SMT Assembly",
    image: smt_assembly,
    description: `Partnered with industry-leading providers, our SMT assembly service offers unparalleled quality and reliability. With a deep understanding of the latest trends and innovations in 
electronics manufacturing, we provide turnkey solutions for your most complex projects. Our expert team ensures precision engineering, rigorous testing, and 
timely delivery. By leveraging the strengths of our partner networks, we bring together the best resources and expertise to deliver exceptional results.`,
  },

  {
    title: "Through-Hole Assembly",
    image: thru_hole_assembly,
    description: `For applications requiring robust connections, our through-hole assembly service is the ideal solution. Our team is well-versed in various through-hole techniques, allowing us to accommodate a wide range of components and specifications. We prioritize quality and precision, ensuring that every assembly meets the rigorous standards demanded by the industry.`,
  },
];

export default function Fabrication() {
  return (
    <Template
      headPicture={headPicture}
      headTitle={headTitle}
      headDescription={headDescription}
      headMessage={headMessage}
      sections={sections}
    />
  );
}
