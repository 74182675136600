import _ from "lodash";

export default function filterOutSameItemInArray(arr, key) {
  if (!arr) return [];

  return arr.reduce((acc, obj) => {
    // if (!obj) {
    //   alert(`error 2575 obj ${obj} not found in ${JSON.stringify(arr)}`);
    //   return acc;
    // }

    const _key = obj[key];

    // if (!_key) {
    //   alert(`error 2577 key ${key} not found in ${JSON.stringify(obj)}`);
    //   return acc;
    // }

    if (acc.some((x) => x[key] === _key)) {
      const index = acc.findIndex((x) => x[key] === _key);

      // PLEASE TRY EITHER ONE BELOW

      // acc[index] = { ...acc[index], ...obj };

      acc[index] = _.mergeWith({}, acc[index], obj, (o, s) =>
        _.isNull(s) ? o : s
      );

      // acc[index] = {
      //   ..._.omitBy(acc[index], _.isNull),
      //   ..._.omitBy(obj, _.isNull),
      // };

      return acc;
    }

    acc.push(obj);
    return acc;
  }, []);
}
