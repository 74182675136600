import { useEffect, useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import {
  FormikField,
  FormikSelect,
  FormikAutocomplete,
  FormikArrayRemoveItemButton,
  FormikRadioGroup,
} from "../../../../../component/formikForm";
import { currencies } from "../../../../../settings/formFieldEnum";
import filterOutSameItemInArray from "../../../../../lib/filterOutSameItemInArray";

export const MaterialsByDefiner = /* GraphQL */ `
  query MaterialsByDefiner(
    $definerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMaterialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    MaterialsByDefiner(
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        definerID
        mpn
        mfr
      }
      nextToken
      __typename
    }
  }
`;

export default function QuoteItems({
  index,
  remove,
  arrayFieldName,
  removeBtnColor,
  size,
}) {
  const { values, setFieldValue } = useFormikContext();

  const { codes, definerID, quotes } = values;
  const { code, findBy } = quotes?.[index] || {};

  const fileredCodes = filterOutSameItemInArray(codes, "code").map(
    (x) => x.code
  );

  const materialOptions = useMemo(
    () => codes?.filter((x) => x.code === code),
    [codes, code]
  );

  const { data } = useQuery(gql(MaterialsByDefiner), {
    variables: { definerID },
  });

  const MPNOptions = data?.MaterialsByDefiner?.items;

  useEffect(() => {
    setFieldValue(
      `[${arrayFieldName}][${index}][itemNumber]`,
      ((index + 1) * 10).toString().padStart(5, "0")
    );

    if (codes?.length > 0) {
      setFieldValue(`[${arrayFieldName}][${index}][findBy]`, "code");
    } else {
      setFieldValue(`[${arrayFieldName}][${index}][findBy]`, "mpn");
    }
  }, [arrayFieldName, setFieldValue, index, codes?.length]);

  // useEffect(() => {
  //   if (materialOptions?.length === 1) {
  //     setFieldValue(
  //       `[${arrayFieldName}][${index}][material]`,
  //       materialOptions[0]
  //     );
  //   }
  // }, [materialOptions, setFieldValue, arrayFieldName, index]);

  return (
    <Stack spacing={1} m={2}>
      <Stack direction="row" spacing={2}>
        <Typography>
          item: {values[arrayFieldName][index]?.itemNumber}
        </Typography>

        {codes?.length > 0 && (
          <FormikRadioGroup
            name={`[${arrayFieldName}][${index}][findBy]`}
            label="Find material by"
            selections={[
              { label: "customer's code", value: "code" },
              { label: "mpn", value: "mpn" },
            ]}
            row
            onChange={(e) => {
              setFieldValue(`[${arrayFieldName}][${index}][code]`, null);
              setFieldValue(`[${arrayFieldName}][${index}][material]`, null);
            }}
          />
        )}
      </Stack>

      <Stack direction="row" spacing={5} alignItems="flex-end">
        {findBy === "code" ? (
          <Stack direction="row" spacing={3}>
            {codes?.length > 0 && (
              <FormikAutocomplete
                name={`[${arrayFieldName}][${index}][code]`}
                label="CODE"
                required
                options={fileredCodes}
                // getOptionLabel={(option) => option.code}
                // getPrimaryText={(option) => option.code}
                width={240}
                onChange={(e) => {
                  setFieldValue(
                    `[${arrayFieldName}][${index}][material]`,
                    null
                  );
                }}
              />
            )}

            <FormikAutocomplete
              name={`[${arrayFieldName}][${index}][material]`}
              label="material"
              required
              disabled={findBy === "code" && !code}
              options={materialOptions}
              getOptionLabel={(option) => option.mfr + " " + option.mpn}
              getPrimaryText={(option) => option.mfr}
              getSecondaryText={(option) => option.mpn}
              width={240}
            />
          </Stack>
        ) : (
          <FormikAutocomplete
            name={`[${arrayFieldName}][${index}][material]`}
            label="material"
            required
            options={MPNOptions}
            getOptionLabel={(option) => option.mfr + " " + option.mpn}
            getPrimaryText={(option) => option.mfr}
            getSecondaryText={(option) => option.mpn}
            width={240}
          />
        )}

        <FormikSelect
          label="currency"
          required
          name={`[${arrayFieldName}][${index}][currency]`}
          options={currencies}
          labelKey="label"
          valueKey="value"
          width={120}
        />

        <FormikField
          width={120}
          name={`[${arrayFieldName}][${index}][price]`}
          label="Price"
          type="number"
        />
        <FormikField
          width={120}
          name={`[${arrayFieldName}][${index}][moq]`}
          label="MOQ"
          type="number"
        />
        <FormikField
          width={120}
          name={`[${arrayFieldName}][${index}][leadtime]`}
          label="LT (wks)"
          type="number"
        />
        <FormikField
          width={120}
          name={`[${arrayFieldName}][${index}][validDays]`}
          label="ValidDays"
          type="number"
        />

        <FormikField
          width={240}
          name={`[${arrayFieldName}][${index}][note]`}
          label="Note"
        />
        <FormikArrayRemoveItemButton
          onClick={() => remove(index)}
          removeBtnColor={removeBtnColor}
          size={size}
        />
      </Stack>
    </Stack>
  );
}
