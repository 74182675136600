import { getTypeSettings } from "./typeSettings";

export default function inputConversion({ data, type }) {
  // console.log({ data, type });
  const { headerIdentifier } = getTypeSettings(type);
  // ?.filter((x) => x.isRequired)
  // ?.map((x) => x.label);

  return getHeaderRowIndex(headerIdentifier, data);
}

function getHeaderRowIndex(headerIdentifier, data) {
  if (!headerIdentifier || !data) return null;

  // console.log({ headerIdentifier, data })

  let headerRow = data.find((row) => {
    const loweredCaseRow = row?.map((cell) => cell.toLowerCase());

    const requiredIdentifier = headerIdentifier
      ?.filter((x) => x.isRequired)
      ?.map((x) => x.label);

    // console.log({ requiredIdentifier })

    return requiredIdentifier.every((header) => {
      return loweredCaseRow.includes(header?.toLowerCase());
    });
  });

  if (!headerRow) return null;
  const headerRowIdx = data.indexOf(headerRow);
  const validRows = data.slice(headerRowIdx + 1);

  // console.log({ headerRowIdx })

  headerRow = headerRow?.map((cell) => cell?.toUpperCase());

  // console.log({ headerRow })
  // console.log({ headerIdentifier })

  // const indexedHeaderIdentifier = headerIdentifier?.map((header) => ({
  //   header: header?.label,
  //   idx: headerRow.indexOf(header?.label),
  // }));

  // console.log({ indexedHeaderIdentifier })

  const formatedData = validRows.map((row) => {
    let obj = {};
    // console.log({ headerRow });

    // console.log({ row });

    row.forEach((value, i) => {
      if (headerRow[i]?.length > 0) {
        obj[headerRow[i]] = value;
      }
    });

    //  console.log({ row })

    //   indexedHeaderIdentifier.forEach(({ header, idx }) => {
    //     obj[header] = row[idx];
    //  });

    return obj;
  });

  return formatedData;
}
