import { gql } from "@apollo/client";
import { onCreate } from "../../../function/onCreate";
import { materialByMPN, createMaterial } from "./query";

export default async function onCreateMaterial({ client, values }) {
  const { mfr, mpn, definerID, desc, unit, mpq } = values;

  const input = {
    mfr,
    mpn,
    desc,
    unit,
    definerID,
    mpq,
  };

  try {
    const {
      data: {
        materialByMPN: { items },
      },
    } = await client.query({
      query: gql(materialByMPN),
      variables: {
        mpn: mpn,
        mfr: { eq: mfr },
        filter: { definerID: { eq: definerID } },
      },
    });

    console.log("items", items);
    if (items.length > 0) {
      alert(`Material ${mfr} ${mpn} already exists`);
      return;
    }

    const fragment = /* GraphQL */ `
      fragment NewMaterial on Material {
        id
      }
    `;
    const { data } = await onCreate({
      input,
      mutation: createMaterial,
      fragment,
      updateCacheFields: ["MaterialsByDefiner"],
      client,
    });
    return data.createMaterial?.id;
  } catch (e) {
    console.log("errorCreatingPart", e.message);
  }
}
