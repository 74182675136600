import { useState, Fragment } from "react";
import {
  Box,
  Step,
  Stepper,
  StepLabel,
  Button,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

export default function StepperComp({
  steps,
  labelOfSumbit,
  activeStep,
  setActiveStep,
  isNextButtonDisabled,
  setIsNextButtonDisabled,
  formProps,
}) {
  const { touched, setTouched, validateForm, submitForm, isSubmitting } =
    formProps;

  const [skipped, setSkipped] = useState(new Set());
  const isStepOptional = (index) => {
    return steps[index].isOptional;
  };
  const isStepSkipped = (index) => {
    return skipped.has(index);
  };
  const handleNext = async () => {
    const errors = await validateForm();
    const formFields = steps[activeStep].fields;

    // console.log({ errors, formFields });
    // debugger;

    let newTouched = {};
    let hasError;

    formFields?.forEach((x) => {
      if (errors[x]) {
        newTouched[x] = errors[x];
        hasError = true;
      }
    });
    setTouched({ ...touched, ...newTouched });

    if (hasError) {
      setIsNextButtonDisabled(true);
      return;
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: "800px", alignSelf: "center" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((step, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={step.label} {...stepProps}>
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </Fragment>
      ) : (
        <Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              disabled={activeStep === 0 || isSubmitting}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}
            <LoadingButton
              loading={isSubmitting}
              disabled={isNextButtonDisabled}
              variant="contained"
              color="secondary"
              onClick={async () => {
                if (activeStep === steps.length - 1) {
                  await submitForm();
                  // console.log({ res });
                  // debugger;
                } else {
                  handleNext();
                  // console.log(444);
                  // debugger;
                }

                // handleNext();
              }}
            >
              {activeStep === steps.length - 1 ? labelOfSumbit : "Next"}
            </LoadingButton>
          </Box>
        </Fragment>
      )}
    </Box>
  );
}
