import { useMemo } from 'react'
import { Stack, Typography } from '@mui/material'
import SimpleTable from '../../../../../component/SimpleTable'
import mergeArrayAndCombineQty from '../../../../../lib/mergeArrayAndCombineQty'
import calculatePaymentDue from '../../../../../lib/calculatePaymentDue'

import { useEffect } from 'react'
import { vatRate } from '../../../../../settings/formFieldEnum'
import { getPO } from './query'

export default function Step1Review({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  setErrors,
  setStatus,
}) {
  const {
    batch,
    number,
    category,
    date,
    definerID,
    invoiceItem,
    preparedData,
  } = values
  const billingEntity = invoiceItem?.[0]?.po?.customer

  useEffect(() => {
    const formattedItems = invoiceItem.map((x) => {
      let terms = 0
      if (x.po?.paymentTerms?.startsWith('NET')) {
        terms = x.po?.paymentTerms?.split(' ')?.[1] * 1
      }



      return {
        number,
        itemNumber: x.itemNumber,
        shippingID: x.shipping?.id,
        poID: x.po?.id,
        po: x.po,
        qty: x.qty,
        currency: x.currency,
        price: x.price,
        desc: x.desc,
        paymentDueDate: calculatePaymentDue({
          terms,
          shippingDate: x.shipping?.date,
          invoicingDate: new Date(),
        }),
      }
    })

    const data = {
      date,
      batch,
      category,
      definerID,
      billingEntityID: billingEntity?.id,
      invoiceItems: formattedItems,
    }
    setFieldValue('preparedData', data)
  }, [
    date,
    number,
    invoiceItem,
    batch,
    category,
    definerID,
    billingEntity?.id,
    setFieldValue,
  ])

  console.log({ preparedData })

  return (
    <Stack spacing={5}>
      <Stack direction='row' spacing={3}>
        <Typography variant='h6'>Billing Date: {date}</Typography>
        <Typography variant='h6'>Category: {category}</Typography>
        <Typography variant='h6'>Number: {number}</Typography>
        <Typography variant='h6'>Batch: {batch}</Typography>
        <Typography variant='h6'>
          Billing Entity: {billingEntity?.name}
        </Typography>
      </Stack>
      <Stack direction='row' spacing={3}>
        <Typography variant='h6'>
          Total: {invoiceItem?.reduce((a, v) => a + (v.qty * v.price || 0), 0)}{' '}
          Total including VAT:
          {(
            invoiceItem?.reduce((a, v) => a + (v.qty * v.price || 0), 0) *
            (1 + vatRate)
          )?.toFixed(2)}
        </Typography>
      </Stack>

      {preparedData?.invoiceItems?.map(
        ({ po, qty, price, currency, paymentDueDate }, i) => (
          <Stack key={i} direction='row' spacing={3}>
            <Typography variant='h6'>
              Code: {po.materialEntityRelation?.code}
            </Typography>
            <Typography variant='h6'>
              Material: {po.material?.mfr} {po.material?.mpn}
            </Typography>

            <Typography variant='h6'>PO: {po.no}</Typography>
            <Typography variant='h6'>QTY: {qty}</Typography>
            <Typography variant='h6'>
              PRICE: {currency}
              {price}
            </Typography>
            <Typography variant='h6'>AMOUNT: {qty*price}</Typography>

            <Typography variant='h6'>
              paymentDueDate: {paymentDueDate}
            </Typography>
          </Stack>
        ),
      )}
    </Stack>
  )
}
