export const getUserFunc = (entityID) => {

  if (!entityID) return null
  // const id = "896dc48f-3c18-4154-b552-b2751645d1b3";

  return /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      fn
      ln
      mn
      name @client
      sub
      email
      phoneNumber
      address
      createdAt
      updatedAt
      entityUserRelations(
        definerID: { eq: "${entityID}" }
      ) {
        items {
          id
          role
          entity {
            id
            name
            alias
            abbr@client
          }
        }
      }
    }
  }
`
}

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      fn
      ln
      mn
      name @client
      sub
      email
      phoneNumber
      address
      createdAt
      updatedAt
    }
  }
`

export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      statusCode
      name @client
      fn
      mn
      ln
      phoneNumber
      address
      createdAt
      updatedAt
    }
  }
`

// export const updateThirdPartyUserRelation = /* GraphQL */ `
//   mutation UpdateThirdPartyUserRelation(
//     $input: UpdateThirdPartyUserRelationInput!
//     $condition: ModelThirdPartyUserRelationConditionInput
//   ) {
//     updateThirdPartyUserRelation(input: $input, condition: $condition) {
//       id
//       role
//       title
//       statusCode
//       entityID
//       shownFn
//       shownLn
//       thridPartyID
//       userID
//       createdAt
//       updatedAt
//     }
//   }
// `;

// export const relationsByCustomerID = /* GraphQL */ `
//   query relationsByCustomerID(
//     $customerID: ID!
//     $sortDirection: ModelSortDirection
//     $filter: ModelCustomerVendorRelationFilterInput
//     $limit: Int
//     $nextToken: String
//   ) {
//     relationsByCustomerID(
//       customerID: $customerID
//       sortDirection: $sortDirection
//       filter: $filter
//       limit: $limit
//       nextToken: $nextToken
//     ) {
//       items {
//         id
//         customerCode
//         vendorCode
//         statusCode
//         customerID
//         vendorID
//         vendor {
//           id
//           name
//           alias
//         }
//         createdAt
//         updatedAt
//       }
//       nextToken
//     }
//   }
// `;

// export const relationsByEntity1 = /* GraphQL */ `
//   query RelationsByEntity1(
//     $entity1ID: ID!
//     $entity2ID: ModelIDKeyConditionInput
//     $sortDirection: ModelSortDirection
//     $filter: ModelEntityRelationsFilterInput
//     $limit: Int
//     $nextToken: String
//   ) {
//     relationsByEntity1(
//       entity1ID: $entity1ID
//       entity2ID: $entity2ID
//       sortDirection: $sortDirection
//       filter: $filter
//       limit: $limit
//       nextToken: $nextToken
//     ) {
//       items {
//         id
//         relation
//         entity1ID
//         entity2ID
//         entity2 {
//           id
//           name
//           alias
//         }
//         alias
//         customerCode
//         vendorCode
//         statusCode
//         type
//         paymentTerms
//         groupToRead
//         groupToEdit
//         groupToDelete
//         createdAt
//         updatedAt
//       }
//       nextToken
//     }
//   }
// `;

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      statusCode
      name @client
      fn
      mn
      ln
      phoneNumber
      address
      owner
      definerID
    }
  }
`

export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
    }
  }
`
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: AWSEmail!
    $sub: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sub: $sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        email
      }
      nextToken
    }
  }
`

// export const entitiesByDefiner = /* GraphQL */ `
//   query EntitiesByDefiner(
//     $definerID: ID!
//     $sortDirection: ModelSortDirection
//     $filter: ModelEntityFilterInput
//     $limit: Int
//     $nextToken: String
//   ) {
//     entitiesByDefiner(
//       definerID: $definerID
//       sortDirection: $sortDirection
//       filter: $filter
//       limit: $limit
//       nextToken: $nextToken
//     ) {
//       items {
//         id
//         name
//         alias
//       }
//       nextToken
//       __typename
//     }
//   }
// `;

export const contactsByDefiner = (definerID) => /* GraphQL */ `
  query ContactsByDefiner(
    $definerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactsByDefiner(
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name @client
        id
        sub
        email
        statusCode
        fn
        mn
        ln
        phoneNumber
        address
        groupToRead
        groupToEdit
        groupToDelete
        owner
        definerID
        createdAt
        updatedAt
        entityUserRelations (definerID: {eq: "${definerID}"}){
          items {
            id
            role
            entity {
              id
              name
              alias
            }
          }
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`

export const entitiesByDefiner = /* GraphQL */ `
  query EntitiesByDefiner(
    $definerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEntityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entitiesByDefiner(
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        alias
        type
        businessType
        statusCode
        definerID
      }
      nextToken
      __typename
    }
  }
`

export const createEntityUserRelation = /* GraphQL */ `
  mutation CreateEntityUserRelation(
    $input: CreateEntityUserRelationInput!
    $condition: ModelEntityUserRelationConditionInput
  ) {
    createEntityUserRelation(input: $input, condition: $condition) {
      id
      role
      title
      shownFn
      shownLn
      shownMn
      statusCode
      isEmailVerified
      userID

      entityID

      definerID

      groupToRead
      groupToEdit
      groupToDelete
      createdAt
      updatedAt
      __typename
    }
  }
`

export const deleteEntityUserRelation = /* GraphQL */ `
  mutation DeleteEntityUserRelation(
    $input: DeleteEntityUserRelationInput!
    $condition: ModelEntityUserRelationConditionInput
  ) {
    deleteEntityUserRelation(input: $input, condition: $condition) {
      id
    }
  }
`

export const entitiyUserRelationByUserID = /* GraphQL */ `
  query EntitiyUserRelationByUserID(
    $userID: ID!
    $definerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityUserRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entitiyUserRelationByUserID(
      userID: $userID
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        entityID
        definerID
      }
      nextToken
      __typename
    }
  }
`
