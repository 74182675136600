import React, { useState, useEffect, useCallback } from "react";
import { ApolloClient, gql, useQuery } from "@apollo/client";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API } from "aws-amplify";
import {
  TextField,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Typography,
  Stack,
  CssBaseline,
  Box,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import cache from "../../../../client/cache";
import linkForAM from "../../../../client/linkForAM";
import { currencies } from "../../../../settings/formFieldEnum";
import { useSearchParams } from "react-router-dom";
import { offerByNumber, updateOffer } from "../query";
import { onUpdate } from "../../../../function/onUpdate";
import getDatesDiff from "../../../../lib/getDatesDiff";

const client = new ApolloClient({
  link: linkForAM,
  cache,
  connectToDevTools: true,
});

export default function RFQForUnregisterdUser() {
  let [searchParams, setSearchParams] = useSearchParams();

  let offers = [{}];
  // const { entityID } = useParams();
  const data = searchParams.get("data");
  let dataObj = {};
  if (data) {
    try {
      dataObj = JSON.parse(Buffer.from(data, "base64"));
    } catch (e) {
      console.log("error parsing data");
    }
  }

  const { data: offerByNumberData, loading } = useQuery(gql(offerByNumber), {
    variables: {
      number: dataObj.inquiryNumber,
      // filter: { statusCode: { eq: "100" } },
    },
    skip: !dataObj.inquiryNumber,
    client,
  });

  offers = offerByNumberData?.offerByNumber?.items || [];

  offers = [...offers]?.sort((a, b) => (a.itemNumber > b.itemNumber ? 1 : -1));

  const { inquiryValidDays, createdAt } = offers?.[0] || {};

  const diff = getDatesDiff(Date.now(), createdAt);

  if (diff > inquiryValidDays)
    // if (offers?.length === 0) {
    //   return (
    //     <Typography variant="h3">
    //       Something went wrong, please try again later
    //     </Typography>
    //   );
    // }

    console.log({
      inquiryValidDays,

      diff,
      offers,
    });

  const txt = offers?.length > 1 ? "items" : "item";
  return (
    <Box
      sx={{
        minHeight: "100vh",
        // bgcolor: "red",
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
      }}
    >
      {/* <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      /> */}
      <CssBaseline />
      {loading ? (
        <CircularProgress sx={{ alignSelf: "center" }} color="secondary" />
      ) : (
        <Container
          disableGutters
          maxWidth="lg"
          component="main"
          // alignContent="center"

          sx={{
            pt: 8,
            pb: 6,
            mt: 10,
            mb: 5,
            // bgcolor: "blue",
            justifyContent: "center",
            // alignItems: "center",
            display: "flex",
          }}
        >
          <Stack spacing={10}>
            {diff > inquiryValidDays ? (
              <Typography variant="h3" color="secondary" sx={{ mt: 20 }}>
                The RFQ has expired.
              </Typography>
            ) : offers?.length === 0 ? (
              <Typography variant="h3" color="secondary" sx={{ mt: 20 }}>
                Something went wrong, please try again later
              </Typography>
            ) : offers?.length > 0 &&
              !offers.some((x) => x.statusCode === "100") ? (
              <Typography variant="h3" color="secondary" sx={{ mt: 20 }}>
                The quote has been submitted, thank you!
              </Typography>
            ) : (
              <>
                <Stack spacing={2}>
                  <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    fontWeight={300}
                    color="secondary"
                    gutterBottom
                  >
                    RFQ
                  </Typography>
                  <Typography
                    variant="h4"
                    align="center"
                    color="text.secondary"
                    component="p"
                  >
                    This RFQ is for {offers?.[0]?.vendor?.name}
                  </Typography>
                  <Typography
                    variant="h5"
                    align="center"
                    color="text.secondary"
                    component="p"
                  >
                    {`Please quote the best price possible for the following ${txt}`}
                  </Typography>
                </Stack>
                <TableContainer component={Paper} sx={{ minHeight: 200 }}>
                  <Table sx={{ minWidth: 1200 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>ITEM</TableCell>
                        <TableCell>MFR</TableCell>
                        <TableCell>MPN</TableCell>
                        <TableCell>EAU</TableCell>
                        <TableCell>APPLICATION</TableCell>
                        <TableCell>TARGET PRICE</TableCell>

                        <TableCell align="center"> QUOTE</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {offers?.map((row) => (
                        <Item row={row} key={row.id} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Stack>
        </Container>
      )}
    </Box>
  );
}

function Item({ row }) {
  const {
    id,
    material,
    EAU,
    TP,
    TPCurrency,
    application,
    itemNumber,
    statusCode,
  } = row;

  const [price, setPrice] = useState();
  const [currency, setCurrency] = useState();
  // const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  const onSubmit = async () => {
    if (!price || !currency) {
      alert("Please enter price and currency");
      return;
    }
    try {
      setIsloading(true);
      const fieldsToUpdate = [
        { field: "currency", createUpdate: false },
        { field: "price", createUpdate: false },
        { field: "leadtime", createUpdate: false },
        { field: "statusCode", createUpdate: false },
      ];

      await onUpdate({
        type: "Offer",
        values: { id, price, currency, statusCode: "200" },
        mutation: updateOffer,
        // query: getOffer,
        getInitialValue: () => ({
          id,
          price: row.price,
          currency: row.currency,
          statusCode: row.statusCode,
        }),
        fieldsToUpdate,
        client,
        refetchQueries: [""],
      });
      setIsloading(false);
    } catch (err) {
      console.log({ err });
    }
  };

  return (
    <TableRow
      key={row.id}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell component="th" scope="row">
        {itemNumber}
      </TableCell>
      <TableCell component="th" scope="row">
        {material?.mfr}
      </TableCell>
      <TableCell>{material?.mpn}</TableCell>
      <TableCell>{EAU}</TableCell>
      <TableCell>{application}</TableCell>
      <TableCell>
        {TPCurrency}
        {TP}
      </TableCell>

      <TableCell align="right" width={280}>
        <Stack direction="row" spacing={1}>
          <TextField
            disabled={statusCode === "200"}
            type="number"
            sx={{ width: 200 }}
            label="Please enter price"
            variant="outlined"
            InputProps={{ inputProps: { min: 0 } }}
            value={price || row.price || ""}
            onChange={(e) => setPrice(e.target.value)}
          />
          <FormControl sx={{ width: 160 }}>
            <InputLabel id="demo-simple-select-label">Currency</InputLabel>
            <Select
              disabled={statusCode === "200"}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={currency || row.currency || ""}
              label="Currency"
              onChange={(e) => setCurrency(e.target.value)}
            >
              {currencies.map(({ label, value }) => (
                <MenuItem key={label} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <LoadingButton
            loading={isLoading}
            disabled={statusCode === "200"}
            variant="contained"
            sx={{ alignSelf: "center", width: 120 }}
            onClick={onSubmit}
          >
            {statusCode === "200" ? "SUBMITTED" : "SUBMIT"}
          </LoadingButton>
        </Stack>
      </TableCell>
    </TableRow>
  );
}
