import { useEffect, useState, useMemo } from "react";
import { gql, useQuery, useApolloClient } from "@apollo/client";
import {
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  CircularProgress,
  FilledInput,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useFormikContext } from "formik";
import {
  FormikField,
  FormikSelect,
  FormikAutocomplete,
  FormikArrayRemoveItemButton,
  FormikRadioGroup,
} from "../../../../../../component/formikForm";
import { currencies } from "../../../../../../settings/formFieldEnum";
import filterOutSameItemInArray from "../../../../../../lib/filterOutSameItemInArray";

const POByNumber = /* GraphQL */ `
  query POByNumber(
    $number: String!
    $itemNumber: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPOFilterInput
    $limit: Int
    $nextToken: String
  ) {
    POByNumber(
      number: $number
      itemNumber: $itemNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        itemNumber
        no @client
        date
        offerID
        customerID
        customer {
          id
          name
          alias
          abbr @client
        }
        vendorID
        definerID
        materialID
        material {
          id
          mfr
          mpn
        }
        materialEntityRelationID
        materialEntityRelation {
          id
          code
        }
        buyerID
        salesID
        qty
        openQty
        price
        priceCur
        paymentTerms
        billingEntityID
        CRD
        shippingStatus
        billingStatus
        paymentStatus
        importAgentID
        paymentAgentID
        forwarderID
        fxRate
        importDutyCN
        serviceFeeRate
        minCharge
        piID
        groupToRead
        groupToEdit
        groupToDelete
        version
        latestVersion
        statusCode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

const receivingAndShippingByPO = /* GraphQL */ `
  query ReceivingAndShippingByPO(
    $poID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReceivingAndShippingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    receivingAndShippingByPO(
      poID: $poID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        number
        batchCode
        itemNumber
        no @client
        qty
        coo
        unit
        warehouseCode
        type
        poID
        materialID
        materialEntityRelationID
        courierID
        courier {
          id
          name
          alias
          abbr @client
        }
        trackingNumber
        warehouseStaffID
        driverID
        creatorID
        definerID
        billingStatus
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export default function OutboundInvoiceItem({
  index,
  remove,
  arrayFieldName,
  removeBtnColor,
  size,
}) {
  const client = useApolloClient();
  const { values, setFieldValue } = useFormikContext();

  const { invoiceItem } = values;
  const { POOptions, shippings, po } = invoiceItem[index] || {};
  const [loadingPO, setLoadingPO] = useState(false);
  const [poNumber, setPoNumber] = useState(po?.number || "");

  async function handleSearchPO() {
    if (poNumber?.length < 1) return alert("please enter po number");

    setLoadingPO(true);
    const { data } = await client.query({
      query: gql(POByNumber),
      variables: {
        number: poNumber,
      },
    });
    const POs = data?.POByNumber?.items;

    setFieldValue(`[${arrayFieldName}][${index}][POOptions]`, POs);

    if (POs?.length === 1) {
      setFieldValue(`[${arrayFieldName}][${index}][po]`, POs[0]);
    }
    setLoadingPO(false);
  }

  useEffect(() => {
    async function func() {
      if (!po?.id) return;

      const { data: receivingAndShippingByPOData } = await client.query({
        query: gql(receivingAndShippingByPO),
        variables: {
          poID: po?.id,
        },
      });
      const shippings =
        receivingAndShippingByPOData?.receivingAndShippingByPO?.items;

      console.log({ shippings });

      setFieldValue(`[${arrayFieldName}][${index}][shippings]`, shippings);
      setFieldValue(`[${arrayFieldName}][${index}][currency]`, po?.priceCur);
      setFieldValue(`[${arrayFieldName}][${index}][price]`, po?.price);
    }

    func();
  }, [arrayFieldName, client, po, setFieldValue, index]);

  return (
    <Stack direction="row" spacing={2}>
      <Stack spacing={2}>
        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            PO Number
          </InputLabel>
          <FilledInput
            id="outlined-adornment-password"
            onChange={(e) => {
              setPoNumber(e.target.value);
            }}
            value={poNumber}
            // type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="search po"
                  onClick={handleSearchPO}
                  // onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {!loadingPO ? <SearchIcon /> : <CircularProgress size={20} />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>

        {POOptions?.length > 0 ? (
          <Stack spacing={1}>
            <Stack direction="row" spacing={2} alignItems="flex-end">
              <FormikField
                width={120}
                name={`[${arrayFieldName}][${index}][itemNumber]`}
                label="itemNumber"
              />

              <FormikAutocomplete
                name={`[${arrayFieldName}][${index}][po]`}
                label="PO Number"
                required
                disabled={!POOptions}
                options={POOptions || []}
                getOptionLabel={(option) => option.no}
                getPrimaryText={(option) => option.no}
                // getSecondaryText={(option) => option.itemNumber}
                width={480}
                // getOptionDisabled={(option) =>
                //   values?.items?.some(
                //     (x) =>
                //       x.po?.number === option.number &&
                //       x.po?.materialID === material.materialID,
                //   )
                // }
                onChange={async (e, value) => {
                  const { priceCur, price } = value;

                  setFieldValue(
                    `[${arrayFieldName}][${index}][currency]`,
                    priceCur
                  );
                  setFieldValue(`[${arrayFieldName}][${index}][price]`, price);
                }}
              />
              <FormikAutocomplete
                name={`[${arrayFieldName}][${index}][shipping]`}
                label="Shipping"
                required
                disabled={!shippings}
                options={shippings || []}
                getOptionLabel={(option) => `${option.no} / ${option.qty}`}
                getPrimaryText={(option) => `${option.no} / ${option.qty}`}
                // getSecondaryText={(option) => option.itemNumber}
                width={480}
                onChange={async (e, value) => {
                  setFieldValue(
                    `[${arrayFieldName}][${index}][qty]`,
                    value.qty
                  );
                }}
              />
            </Stack>
            <Stack direction="row" spacing={2} alignItems="flex-end">
              <Typography>MFR:{po?.material?.mfr}</Typography>
              <Typography>MPN:{po?.material?.mpn}</Typography>
              <Typography>CODE:{po?.materialEntityRelation?.code}</Typography>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="flex-end">
              <FormikSelect
                label="Currency"
                required
                name={`[${arrayFieldName}][${index}][currency]`}
                options={currencies}
                labelKey="label"
                valueKey="value"
                width={120}
              />
              <FormikField
                width={120}
                name={`[${arrayFieldName}][${index}][price]`}
                label="Price"
                type="number"
              />
              <FormikField
                width={180}
                name={`[${arrayFieldName}][${index}][qty]`}
                label="Qty"
                type="number"
              />
            </Stack>
          </Stack>
        ) : (
          <>{!POOptions ? null : <Typography>no PO found</Typography>}</>
        )}
      </Stack>
      <FormikArrayRemoveItemButton
        onClick={() => remove(index)}
        name={arrayFieldName}
        removeBtnColor={removeBtnColor}
        size={size}
      />
    </Stack>
  );
}

// function Offers({ relationID, materialID, customerID, onSelectOffer }) {
//   const { data: offerByMaterialData } = useQuery(gql(offerByMaterial), {
//     variables: { materialID, customerID: { eq: customerID } },
//     skip: !materialID || !customerID,
//   })

//   const { data: offerByMaterialEntityRelationData } = useQuery(
//     gql(offerByMaterialEntityRelation),
//     {
//       variables: { materialEntityRelationID: relationID },
//       skip: !relationID,
//     },
//   )

//   const offerByMaterialItems = offerByMaterialData?.offerByMaterial?.items

//   const offerByMaterialEntityRelationItems =
//     offerByMaterialEntityRelationData?.offerByMaterialEntityRelation?.items

//   const offers = [
//     ...(offerByMaterialItems || []),
//     ...(offerByMaterialEntityRelationItems || []),
//   ]
//   const filteredOffers = filterOutSameItemInArray(offers, 'id')

//   return (
//     <Stack>
//       {filteredOffers?.map((offer) => (
//         <Stack
//           key={offer.id}
//           direction='row'
//           justifyContent='space-between'
//           alignItems='center'
//           // width={350}
//           p={1}
//           bgcolor='blue'
//           borderRadius={2}>
//           <Typography color='white' width={260}>
//             Price: {offer.currency} {offer.price} on {offer.date}
//           </Typography>
//           <Button
//             size='small'
//             variant='contained'
//             onClick={() => onSelectOffer(offer)}>
//             select
//           </Button>
//         </Stack>
//       ))}
//     </Stack>
//   )
// }

// function POs({ relationID, materialID, customerID, onSelectOffer }) {
//   const { data: offerByMaterialData } = useQuery(gql(offerByMaterial), {
//     variables: { materialID, customerID: { eq: customerID } },
//     skip: !materialID || !customerID,
//   })

//   const { data: offerByMaterialEntityRelationData } = useQuery(
//     gql(offerByMaterialEntityRelation),
//     {
//       variables: { materialEntityRelationID: relationID },
//       skip: !relationID,
//     },
//   )

//   const offerByMaterialItems = offerByMaterialData?.offerByMaterial?.items

//   const offerByMaterialEntityRelationItems =
//     offerByMaterialEntityRelationData?.offerByMaterialEntityRelation?.items

//   const offers = [
//     ...(offerByMaterialItems || []),
//     ...(offerByMaterialEntityRelationItems || []),
//   ]
//   const filteredOffers = filterOutSameItemInArray(offers, 'id')

//   return (
//     <Stack>
//       {filteredOffers?.map((offer) => (
//         <Stack
//           key={offer.id}
//           direction='row'
//           justifyContent='space-between'
//           alignItems='center'
//           // width={350}
//           p={1}
//           bgcolor='blue'
//           borderRadius={2}>
//           <Typography color='white' width={260}>
//             Price: {offer.currency} {offer.price} on {offer.date}
//           </Typography>
//           <Button
//             size='small'
//             variant='contained'
//             onClick={() => onSelectOffer(offer)}>
//             select
//           </Button>
//         </Stack>
//       ))}
//     </Stack>
//   )
// }
