import { useEffect, useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import { Stack, Button, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import {
  FormikField,
  FormikSelect,
  FormikAutocomplete,
  FormikArrayRemoveItemButton,
  FormikRadioGroup,
} from "../../../../../../component/formikForm";
import { currencies } from "../../../../../../settings/formFieldEnum";
import filterOutSameItemInArray from "../../../../../../lib/filterOutSameItemInArray";
import mergeArrayAndCombineQty from "../../../../../../lib/mergeArrayAndCombineQty";
import fillArrayWithNumber from "../../../../../../lib/fillArrayWithNumber";

const POByNumber = /* GraphQL */ `
  query POByNumber(
    $number: String!
    $itemNumber: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPOFilterInput
    $limit: Int
    $nextToken: String
  ) {
    POByNumber(
      number: $number
      itemNumber: $itemNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        itemNumber
        no @client
        date
        offerID
        customerID
        vendorID
        definerID
        materialID
        materialEntityRelationID
        buyerID
        salesID
        qty
        openQty
        price
        priceCur
        paymentTerms
        billingEntityID
        CRD
        shippingStatus
        billingStatus
        paymentStatus
        importAgentID
        paymentAgentID
        forwarderID
        fxRate
        importDutyCN
        serviceFeeRate
        minCharge
        piID
        groupToRead
        groupToEdit
        groupToDelete
        version
        latestVersion
        statusCode
        createdAt
        updatedAt
        invoice {
          items {
            id
            date
            price
            currency
            qty
            paymentDueDate
          }
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export default function ReceivingItem({
  index,
  remove,
  arrayFieldName,
  removeBtnColor,
  size,
}) {
  const { values, setFieldValue } = useFormikContext();

  const { avaiablePOs, vendor, items } = values;

  //PLEASE NOTE MATERIAL IS ACTUALLY PO
  const { material, hideSubArray, po } = items?.[index] || {};

  // console.log({ material, po });

  const { data } = useQuery(gql(POByNumber), {
    variables: {
      number: po?.number,
      filter: { materialID: { eq: material?.materialID } },
    },
    skip: !po?.number,
  });

  const POs = data?.POByNumber?.items;

  useEffect(() => {
    if (!POs) return;

    const availableInvoices = POs?.map((x) =>
      x.invoice?.items?.map((y) => ({ ...y, poNo: x.no }))
    )
      .flat()
      ?.filter((x) => !x.paymentDueDate);

    setFieldValue(
      `[${arrayFieldName}][${index}][availableInvoices]`,
      availableInvoices
    );
  }, [POs, setFieldValue, arrayFieldName, index]);

  // useEffect(() => {
  //   if (hideSubArray === undefined) {
  //     setFieldValue(`[${arrayFieldName}][${index}][hideSubArray]`, true);
  //   }
  // }, [setFieldValue, hideSubArray, arrayFieldName, index]);

  const linePOOptions = avaiablePOs?.filter(
    (x) => x.materialID === material?.materialID
  );

  const aggregated = mergeArrayAndCombineQty(
    linePOOptions,
    ["number"],
    ["qty", "openQty"]
  );

  // console.log({ aggregated });

  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <FormikAutocomplete
          name={`[${arrayFieldName}][${index}][material]`}
          label="material"
          required
          disabled={!avaiablePOs}
          options={filterOutSameItemInArray(avaiablePOs, "materialID")}
          getOptionLabel={(option) =>
            option.material?.mfr + " " + option.material?.mpn
          }
          getPrimaryText={(option) => option.material.mfr}
          getSecondaryText={(option) => option.material.mpn}
          width={360}
          onChange={(e, value) => {
            setFieldValue(`[${arrayFieldName}][${index}][po]`, null);
          }}
        />

        <FormikAutocomplete
          name={`[${arrayFieldName}][${index}][po]`}
          label="PO Number"
          required
          disabled={!material}
          options={aggregated || []}
          getOptionLabel={(option) =>
            `${option.number} (${option.qty}/${option.openQty})`
          }
          getPrimaryText={(option) => option.number}
          getSecondaryText={(option) => option.qty + "/" + option.openQty}
          width={480}
          getOptionDisabled={(option) =>
            values?.items?.some(
              (x) =>
                x.po?.number === option.number &&
                x.po?.materialID === material.materialID
            )
          }

          // onChange={(e, value) => {
          //   const filteredPO = linePOOptions.filter(
          //     (x) => x.number === value.number
          //   );
          //   console.log({ filteredPO });
          //   setFieldValue(
          //     `[${arrayFieldName}][${index}][subItems]`,
          //     filteredPO
          //   );
          // }}
        />

        <FormikField
          width={180}
          name={`[${arrayFieldName}][${index}][qty]`}
          label="Qty"
          type="number"
          // onChange={(v) => {
          //   console.log({ v });
          // }}
          onBlur={(v) => {
            // console.log("onBlur", v);

            if (po && v * 1 > po.openQty) {
              alert(
                "收货数量大于订单数量, but this is might be a bug because it only count the current po item not the whole po"
              );
              setFieldValue(`[${arrayFieldName}][${index}][qty]`, "");
              return;
            }

            const filteredPO = linePOOptions
              .filter((x) => x.number === po?.number)
              ?.map((x) => ({
                itemNumber: x.itemNumber,
                qty: x.qty,
                openQty: x.openQty,
                CRD: x.CRD,
                poID: x.id,
                receivedQty: 0,
                materialEntityRelationID: x.materialEntityRelationID,
                materialID: x.materialID,
                po: { pi: x.pi },
              }));

            const filledArray = fillArrayWithNumber(
              filteredPO,
              v * 1,
              "openQty",
              "receivedQty"
            );

            if (po && v * 1 < po.openQty) {
              // console.log({ filteredPO });
              // setFieldValue(
              //   `[${arrayFieldName}][${index}][hideSubArray]`,
              //   false
              // );
            } else {
              // setFieldValue(
              //   `[${arrayFieldName}][${index}][hideSubArray]`,
              //   true
              // );
            }
            setFieldValue(
              `[${arrayFieldName}][${index}][subItems]`,
              filledArray
            );
          }}
        />

        <FormikArrayRemoveItemButton
          disabled={index === 0 ? true : false}
          onClick={() => remove(index)}
          name={arrayFieldName}
          removeBtnColor={removeBtnColor}
          size={size}
        />
      </Stack>
    </Stack>
  );
}
