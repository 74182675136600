import { onCreate } from "../../../function/onCreate";
import { createPO } from "./query";

export default async function onCreateOrder({ client, values }) {
  const fragment = /* GraphQL */ `
    fragment NewPO on PO {
      id
    }
  `;

  await onCreate({
    input: values,
    mutation: createPO,
    fragment,
    updateCacheFields: [],
    client,
    refetchQueries: [],
  });
}

export async function onCreatePO({ client, values }) {
  const {
    POItems,
    customerID,
    customer,
    vendorID,
    vendor,
    date,
    incoTerms,
    paymentTerms,
    number,
    definerID,
    fxRate,
    minCharge,
    serviceFeeRate,
  } = values;

  if (!POItems || POItems?.length === 0) return;

  await Promise.all(
    POItems.map(
      async ({
        id,
        itemNumber,
        currency,
        materialID,
        materialEntityRelationID,
        material,
        price,
        qty,
        CRD,
        piID,
        importDutyCN,
      }) => {
        const values = {
          id,
          piID,
          number,
          itemNumber,
          date,
          customerID: customer?.id || customerID,
          vendorID: vendor?.id || vendorID,
          priceCur: currency,
          price,
          CRD,
          qty: qty * 1,
          openQty: qty * 1,
          paymentTerms,
          incoTerms,
          materialEntityRelationID:
            materialEntityRelationID || material?.relationID,
          materialID: materialID || material?.materialID || material?.id,
          definerID,
          billingStatus: "OPEN",
          fxRate,
          minCharge,
          serviceFeeRate,
          importDutyCN,
        };

        // console.log({ values });

        // debugger;
        const res = await onCreateOrder({ client, values });

        // console.log({ res });
        // debugger;
      }
    )
  );
  await client.refetchQueries({ include: ["POByDefinerID"] });
}
