import { useState, useEffect } from "react";
import { gql, useReactiveVar, useApolloClient } from "@apollo/client";
import { Stack, Box, Container, Typography } from "@mui/material";
import { TreeView, TreeItem, LoadingButton } from "@mui/lab";
import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";
import { userEntityRelationVar } from "../../../../../client/cache";

import groupArrayofObjectsByKeyAndGenerateArr from "../../../../../lib/groupArrayofObjectsByKeyAndGenerateArr";
import POItemLine from "./POItemLine";
import handleSelected from "../functions/handleSelected";
import BackdropoLoader from "../../../../../component/BackdropLoader";

import { POByDefinerID } from "../../query";

export default function Tree() {
  const { entityID, selfAndSubsidiaries } = useReactiveVar(
    userEntityRelationVar
  );
  const [nodes, setNodes] = useState(null);
  const [expanded, setExpanded] = useState(["root"]);
  const [selected, setSelected] = useState([]);
  const [selectedPOItems, setSelectedPOItems] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);

  // console.log({ selectedPOItems});

  useEffect(() => {
    if (!expanded.includes("root")) setExpanded((old) => ["root"].concat(old));
  }, [expanded]);

  const handleToggle = (event, nodeIds) => {
    // console.log("toggle");
    // console.log({ nodeIds, event });
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    // console.log("select");
    // console.log({ nodeIds, event });
    setSelected(nodeIds);

    // const closedNodes = nodeIds.filter((x) => nodes.children());
  };

  // const handleExpandClick = () => {
  //   setExpanded((oldExpanded) =>
  //     oldExpanded.length === 0 ? ['1', '5', '6', '7'] : [],
  //   );
  // };

  // const handleSelectClick = () => {
  //   setSelected((oldSelected) =>
  //     oldSelected.length === 0 ? ['1', '2', '3', '4', '5', '6', '7', '8', '9'] : [],
  //   );
  // };

  // console.log({ selfAndSubsidiaries, nodes });
  const client = useApolloClient();

  useEffect(() => {
    async function func() {
      setIsPageLoading(true);
      const { data: POByDefinerIDData } = await client.query({
        query: gql(POByDefinerID),
        variables: {
          definerID: entityID,
          openQty: { gt: 0 },
          filter: {
            and: {
              statusCode: { ne: "900" },
              or: selfAndSubsidiaries.map((x) => ({
                vendorID: { eq: x.id },
              })),
            },
          },
        },
      });

      const items = POByDefinerIDData.POByDefinerID.items.filter(
        (x) => x.openQty > 0
      );

      const groupedByCustomer = groupArrayofObjectsByKeyAndGenerateArr(
        items,
        "customerID"
      );

      const children = await Promise.all(
        groupedByCustomer?.map(async (x) => {
          x.items.sort((a, b) =>
            a.materialEntityRelation.code > b.materialEntityRelation.code
              ? 1
              : -1
          );

          const byMaterialEntityRelationID =
            groupArrayofObjectsByKeyAndGenerateArr(
              x.items,
              "materialEntityRelationID"
            );

          return {
            id: x.customerID,
            name: x.items[0].customer?.abbr,
            children: await Promise.all(
              byMaterialEntityRelationID.map(async (y) => {
                y.items.sort((a, b) => (a.number > b.number ? 1 : -1));
                y.items.sort((a, b) =>
                  a.itemNumber * 1 < b.itemNumber * 1 ? 1 : -1
                );
                y.items.sort((a, b) => (a.date < b.date ? 1 : -1));

                const subChildren = y.items.map((z) => {
                  return {
                    id: z.id,
                    name: (
                      <POItemLine
                        po={z}
                        setSelectedPOItems={setSelectedPOItems}
                        selectedPOItems={selectedPOItems}
                      />
                    ),
                  };
                });

                subChildren.unshift({
                  id: `${y.materialEntityRelationID}-header`,
                  disabled: true,
                  name: (
                    <Stack direction="row" spacing={2}>
                      <Typography variant="title" width={260}>
                        Number
                      </Typography>
                      <Typography variant="title" width={120}>
                        Date
                      </Typography>
                      <Typography variant="title" width={300}>
                        MPN
                      </Typography>
                      <Typography variant="title" width={120}>
                        Qty
                      </Typography>
                      <Typography variant="title" width={120}>
                        Open Qty
                      </Typography>
                      <Typography variant="title" width={120}>
                        CRD
                      </Typography>
                    </Stack>
                  ),
                });

                const name = (
                  <Stack direction="row" spacing={2} m={2}>
                    <Typography variant="title" width={160}>
                      {y.items[0].materialEntityRelation?.code}
                    </Typography>
                    <Typography variant="title" width={220}>
                      MFR:
                      {[...new Set(y.items.map((x) => x.material?.mfr))].join(
                        ", "
                      )}
                    </Typography>
                    <Typography variant="title" sx={{ width: 600 }}>
                      MPN:
                      {[...new Set(y.items.map((x) => x.material?.mpn))].join(
                        ", "
                      )}
                    </Typography>
                    <Typography variant="title" sx={{ width: 600 }}>
                      OPEN QTY:{" "}
                      {y.items.reduce((a, b) => a + b.openQty, 0).toFixed(3) *
                        1}
                    </Typography>
                  </Stack>
                );

                return {
                  id: y.materialEntityRelationID,
                  name,
                  children: subChildren,
                };
              })
            ),
          };
        })
      );

      const nd = { id: "root", name: "ORDERS", children };

      setNodes(nd);
      setIsPageLoading(false);
    }

    func();
  }, [selfAndSubsidiaries, client, selectedPOItems, entityID]);

  const renderTree = (nodes) => {
    if (!nodes) return null;

    return (
      <TreeItem
        key={nodes.id}
        nodeId={nodes.id}
        label={nodes.name}
        disabled={nodes.disabled}
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => renderTree(node))
          : null}
      </TreeItem>
    );
  };

  // if (!nodes) return null;

  if (isPageLoading) return <BackdropoLoader isLoading={isPageLoading} />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 2,
        px: 5,
        // mt: 5,
        // height: "100%",
        // bgcolor: "lightBlue",
      }}
    >
      <Stack direction="row" spacing={2}>
        {/* <Button onClick={handleExpandClick}>
          {expanded.length === 0 ? 'Expand all' : 'Collapse all'}
        </Button>
        <Button onClick={handleSelectClick}>
          {selected.length === 0 ? 'Select all' : 'Unselect all'}
        </Button> */}
        {/* <Button
          variant="text"
          disabled={expanded.length < 2}
          onClick={() => {
            setExpanded(["root"]);
          }}
        >
          close all
        </Button> */}
        {/* <LoadingButton
          disabled={selectedPOItems.length === 0}
          loading={isLoading}
          variant="contained"
          onClick={async () => {
            setIsloading(true);
            try {
              await handleSelected({ selectedPOItems, client });
            } catch (e) {
              console.log({ error778: e });
            }

            setIsloading(false);
          }}
        >
          Handle Selected
        </LoadingButton> */}
      </Stack>
      <TreeView
        aria-label="rich object"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpanded={["root"]}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        multiSelect
      >
        {renderTree(nodes)}
      </TreeView>
    </Box>
  );
}
