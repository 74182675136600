import { useEffect, useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import { Stack, MenuItem, Button, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import {
  FormikField,
  FormikSelect,
  FormikAutocomplete,
  FormikArrayRemoveItemButton,
  FormikRadioGroup,
} from "../../../../../../component/formikForm";
import { currencies } from "../../../../../../settings/formFieldEnum";
import filterOutSameItemInArray from "../../../../../../lib/filterOutSameItemInArray";

export default function ShippingItem({
  index,
  remove,
  arrayFieldName,
  removeBtnColor,
  size,
}) {
  const { values, setFieldValue } = useFormikContext();

  const { POOptions, shippingItems } = values;
  const { code, findBy, material } = shippingItems[index] || {};

  const materialOptions = useMemo(() => {
    let options;

    if (code) {
      options = POOptions?.filter(
        (x) => x.materialEntityRelation?.code === code
      );
    } else {
      options = POOptions;
    }

    return filterOutSameItemInArray(options, "materialID");
  }, [POOptions, code]);

  useEffect(() => {
    if (materialOptions?.length === 1) {
      setFieldValue(
        `[${arrayFieldName}][${index}][material]`,
        materialOptions[0]
      );
    }
  }, [materialOptions, setFieldValue, arrayFieldName, index]);

  // useEffect(() => {
  //   if (!findBy) {
  //     setFieldValue(`[${arrayFieldName}][${index}][findBy]`, "code");
  //   }
  // }, [findBy, setFieldValue, arrayFieldName, index]);

  const itemPOOptions = useMemo(() => {
    let options;

    if (code) {
      options = POOptions?.filter(
        (x) =>
          x.materialEntityRelation?.code === code &&
          x.materialID === material?.materialID
      );
    } else {
      options = POOptions?.filter((x) => x.materialID === material?.materialID);
    }

    return options;
  }, [POOptions, code, material?.materialID]);


  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        {/* <Typography>
          item: {values[arrayFieldName][index]?.itemNumber}
        </Typography> */}
        <FormikRadioGroup
          name={`[${arrayFieldName}][${index}][findBy]`}
          label="Find material by"
          selections={[
            { label: "customer's code", value: "code" },
            { label: "mpn", value: "mpn" },
          ]}
          row
          onChange={(e) => {
            setFieldValue(`[${arrayFieldName}][${index}][code]`, null);
            setFieldValue(`[${arrayFieldName}][${index}][po]`, null);
          }}
        />
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        alignItems="flex-end"
        justifyContent="space-between"
      >
        {findBy === "code" ? (
          <Stack direction="row" spacing={3}>
            <FormikAutocomplete
              name={`[${arrayFieldName}][${index}][code]`}
              label="Code"
              required
              disabled={!POOptions}
              options={[
                ...new Set(
                  POOptions?.map((x) => x.materialEntityRelation?.code)?.filter(
                    Boolean
                  )
                ),
              ]?.sort((a, b) => (a > b ? 1 : -1))}
              // getOptionLabel={(option) =>
              //   `${option.materialEntityRelation.code} (${option.material.mfr} - ${option.material.mpn})`
              // }
              // getPrimaryText={(option) =>
              //   `${option.materialEntityRelation.code} `
              // }
              // getSecondaryText={(option) =>
              //   `${option.material.mfr} - ${option.material.mpn})`
              // }
              listItemPrimaryField={null}
              listItemSecondaryField={null}
              width={240}
              // onChange={(e) => {
              //   setFieldValue(`[${arrayFieldName}][${index}][material]`, null);
              //   setFieldValue(`[${arrayFieldName}][${index}][price]`, null);
              //   setFieldValue(`[${arrayFieldName}][${index}][currency]`, null);
              // }}
              getOptionDisabled={null}
            />
            <FormikAutocomplete
              name={`[${arrayFieldName}][${index}][material]`}
              label="material"
              required
              disabled={!POOptions}
              options={materialOptions || []}
              getOptionLabel={(option) =>
                option.material?.mfr + " " + option.material?.mpn
              }
              getPrimaryText={(option) => option.material.mfr}
              getSecondaryText={(option) => option.material.mpn}
              width={360}
              // onChange={(e) => {
              //   setFieldValue(`[${arrayFieldName}][${index}][price]`, null);
              //   setFieldValue(`[${arrayFieldName}][${index}][currency]`, null);
              // }}
            />
          </Stack>
        ) : (
          <FormikAutocomplete
            name={`[${arrayFieldName}][${index}][material]`}
            label="material"
            required
            disabled={!POOptions}
            options={materialOptions || []}
            getOptionLabel={(option) =>
              option.material?.mfr + " " + option.material?.mpn
            }
            getPrimaryText={(option) => option.material.mfr}
            getSecondaryText={(option) => option.material.mpn}
            width={360}
          />
        )}

        <FormikAutocomplete
          name={`[${arrayFieldName}][${index}][po]`}
          label="PO Number"
          required
          disabled={!materialOptions}
          options={itemPOOptions || []}
          getOptionLabel={(option) =>
            `${option.number}-${option.itemNumber} (${option.qty}/${option.openQty})`
          }
          getPrimaryText={(option) => option.number + "- " + option.itemNumber}
          getSecondaryText={(option) => option.qty + "/" + option.openQty}
          width={480}
        />

        <FormikField
          width={180}
          name={`[${arrayFieldName}][${index}][qty]`}
          label="Qty"
          type="number"
        />

        <FormikField
          width={180}
          name={`[${arrayFieldName}][${index}][coo]`}
          label="coo"
          select
          children={(material?.material?.coo || [])?.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        />

        <FormikArrayRemoveItemButton
          onClick={() => remove(index)}
          name={arrayFieldName}
          removeBtnColor={removeBtnColor}
          size={size}
        />
      </Stack>
    </Stack>
  );
}
