import { Container, Box, Typography } from "@mui/material";

export default function Sections({ title, sections }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {title && (
        <Typography variant="h2" sx={{ mb: 3 }}>
          {title}
        </Typography>
      )}

      {sections?.map((section, i) => (
        <Section {...section} key={i} i={i} />
      ))}
    </Box>
  );
}

function Section({ title, image, description, imageObjectFit = "cover", i }) {
  return (
    <Box
      sx={{
        alignSelf: "stretch",
        color: "#555555",
        // height: 480,
        bgcolor: i % 2 ? "#fff" : "#f1f1f1",
      }}
    >
      {title && (
        <Typography sx={{ textAlign: "center", mt: 3, mb: 5 }} variant="h4">
          {title}
        </Typography>
      )}

      <Container
        sx={{
          display: "flex",
          flexDirection: i % 2 ? "row" : "row-reverse",
          gap: 5,
          alignItems: "flex-start",
          height: 360,
        }}
      >
        <img
          src={image}
          alt={title}
          style={{
            height: "300px",
            width: "480px",
            objectFit: imageObjectFit,
            borderRadius: 5,
            flexShrink: 0,
          }}
        />
        <Typography sx={{ fontSize: 18 }}>{description}</Typography>
      </Container>
    </Box>
  );
}
