import { onDelete } from "../../../function/onDelete";
import { deleteReceivingAndShipping } from "./query";
import updatePOOpenQtyFromArray from "../../../function/utilityAPI/updatePOOpenQtyFromArray";
import updateInvenotryFromArray from "../../../function/utilityAPI/updateInvenotryFromArray";

export default async function onDeleteShipping({
  selectionModel,
  setSelectionModel,
  client,
  values,
  type,
}) {
  if (type !== "RECEIVING" && type !== "SHIPPING")
    return alert("type must be RECEIVING or SHIPPING");
  if (selectionModel) {
    await Promise.all(
      selectionModel?.map(async (items) => {
        await deleteSingleItem(items, client);
      })
    );
    setSelectionModel && setSelectionModel([]);
  } else if (values) {
    await deleteSingleItem(values, client);
  }

  await updatePOOpenQtyFromArray({
    client,
    arr: selectionModel || [values],
    type: "add",
  });

  await updateInvenotryFromArray({
    client,
    arr: selectionModel || [values],
    type: type === "SHIPPING" ? "add" : "deduct",
  });
}

async function deleteSingleItem(item, client) {
  // console.log({ item, client });
  const { id, number } = item || {};

  await onDelete({
    input: { id },
    mutation: deleteReceivingAndShipping,
    // updateCacheFields: ["relationsByCustomerID"],
    client,
    storagePath: number,
  });
}
