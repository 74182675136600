// import { UnAuthLayout } from "../../../layout";
import Home from "./Home";

const home = {
  path: "",
  route: {
    index: {
      element: <Home />,
      allowedRole: "all",
      entityType: ["public"],
      auth: ["unAuthenticated"],
    },
    // wrapper: {
    //   element: <UnAuthLayout />,
    //   allowedRole: "all",
    //   entityType: "all",
    //   auth: ["unAuthenticated"],
    // },
  },
};

export default home;
