import { onUpdate } from "../../../function/onUpdate";
import { updateUser, getUser } from "./query";

export default async function onUpdateContact({ values, client }) {
  // const { userID } = values;

  const fieldsToUpdate = [
    { field: 'email', createUpdate: true },
    { field: 'fn', createUpdate: true },
    { field: 'ln', createUpdate: true },
    { field: 'mn', createUpdate: true },
    { field: 'phoneNumber', createUpdate: true },
    { field: 'address', createUpdate: true },
    { field: 'sub', createUpdate: false },
  ]

  // async function getInitialValueForUser() {
  //   let { data } = await client.query({
  //     query: gql(getUser),
  //     variables: { id: userID },
  //   });

  //   data = data?.getUser || {};

  //   return data;
  // }

  await onUpdate({
    type: "User",
    values,
    mutation: updateUser,
    query: getUser,
    // getInitialValue: getInitialValueForUser,
    fieldsToUpdate,
    client,
    refetchQueries: [],
  });

  // const fieldsToUpdateThirdPartyUserRelation = [
  //   { field: "statusCode", createUpdate: true },
  //   {
  //     field: "role",
  //     createUpdate: true,
  //   },
  //   { field: "title", createUpdate: true },
  //   { field: "shownFn", createUpdate: true },
  //   { field: "shownLn", createUpdate: true },
  // ];

  // await onUpdate({
  //   type: "ThirdPartyUserRelation",
  //   values,
  //   mutation: updateThirdPartyUserRelation,
  //   query: getThirdPartyUserRelation,
  //   fieldsToUpdate: fieldsToUpdateThirdPartyUserRelation,
  //   client,
  //   refetchQueries: ["GetThirdPartyUserRelation"],
  // });
}
