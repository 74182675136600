import { Box, Stack, Typography } from "@mui/material";

export default function HeadTestomonies({ title, images }) {
  return (
    <Box>
      <Typography
        variant="h4"
        sx={{ textAlign: "center", color: "#666666", mb: 2 }}
      >
        {title}
      </Typography>
      <Stack
        p={0}
        // bgcolor='red'
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        {images?.map((image, i) => (
          <img
            src={image.src}
            alt={image.alt}
            height={image.height}
            style={image.style}
            key={i}
          />
        ))}
      </Stack>
    </Box>
  );
}
