import { useQuery, gql } from "@apollo/client";
import { Stack, Box, Typography } from "@mui/material";
import { Tree, TreeNode } from "react-organizational-chart";

export default function ChartView({ currentID, query, variables }) {
  //console.log({ currentID, query, variables });

  // debugger;

  let { data } = useQuery(gql(query), {
    variables,
  });

  // console.log("data", data);

  // debugger;

  if (!data) return null;

  const key = Object.keys(data)?.[0];

  data = data?.[key];

  let label = "please define label";

  if (data?.__typename?.startsWith("Model")) {
    label = (
      <Box
        sx={{
          borderRadius: 2,
          display: "inline-block",
          border: "2px solid gray",
          padding: 2,
        }}
      >
        <Stack>
          <Typography>
            {data?.__typename.replace("Model", "").replace("Connection", "")}
          </Typography>
        </Stack>
      </Box>
    );
  } else {
    label = (
      <Box
        sx={{
          borderRadius: 1,
          display: "inline-block",
          padding: 1,
          bgcolor: currentID === data?.id ? "lightblue" : "#f1f1f1",
        }}
      >
        <Stack alignItems="flex-start">{label}</Stack>
      </Box>
    );
  }

  return (
    <Box sx={{ overflow: "scroll", overflowY: "hidden" }}>
      <Tree
        lineWidth={"5px"}
        lineColor={"gray"}
        lineBorderRadius={"20px"}
        label={label}
      >
        {data.items ? (
          data.items.map((item, i) => (
            <ItemNode key={item.id} data={item} currentID={currentID} />
          ))
        ) : (
          <ItemNode data={data} currentID={currentID} />
        )}
      </Tree>
    </Box>
  );
}

function ItemNode({ data, currentID }) {
  const { __typename } = data || {};

  if (!data) return null;

  const arrs = Object.values(data)?.filter((x) => x?.items);

  let label;

  switch (__typename) {
    case "PO":
      label = (
        <>
          <Typography>PO: {data?.no}</Typography>
          {data?.materialEntityRelation?.code && (
            <Typography> {data?.materialEntityRelation?.code}</Typography>
          )}

          {data?.material?.mfr && data?.material?.mpn && (
            <Typography>
              {data?.material?.mfr}: {data?.material?.mpn}
            </Typography>
          )}

          <Typography>
            Qty/OpenQty: {data?.qty}/ {data?.openQty}
          </Typography>
        </>
      );
      break;
    case "Invoice":
      label = (
        <>
          <Typography>{data?.date}</Typography>
          <Typography>Qty:{data?.qty}</Typography>
          <Typography>Amount: {data?.amount}</Typography>
        </>
      );
      arrs.push(data.forwarderPO);
      break;

    case "ReceivingAndShipping":
      label = (
        <Typography>
          {data?.date}: {data?.qty}
        </Typography>
      );
      break;
    case "Payment":
      label = (
        <>
          <Typography>
            {data?.date}:{data?.amount}
          </Typography>
          <Typography>{data?.desc}</Typography>
        </>
      );
      break;

    default:
      label = __typename;
  }

  return (
    <TreeNode
      label={
        <Box
          sx={{
            borderRadius: 1,
            display: "inline-block",
            padding: 1,
            bgcolor: currentID === data?.id ? "lightblue" : "#f1f1f1",
          }}
        >
          <Stack alignItems="flex-start">{label}</Stack>
        </Box>
      }
    >
      {arrs?.map((item, i) => {
        if (item?.__typename?.startsWith("Model")) {
          return <ArrayNode key={i} data={item} currentID={currentID} />;
        } else {
          return <ItemNode key={i} data={item} currentID={currentID} />;
        }
      })}
    </TreeNode>
  );
}

function ArrayNode({ data, currentID }) {
  const { __typename, items } = data || {};

  return (
    <TreeNode
      label={
        <Box
          sx={{
            borderRadius: 2,
            display: "inline-block",
            border: "2px solid gray",
            padding: 2,
          }}
        >
          <Stack>
            <Typography>
              {__typename.replace("Model", "").replace("Connection", "")}
            </Typography>
          </Stack>
        </Box>
      }
    >
      {items?.map((item, i) => {
        return <ItemNode key={i} data={item} currentID={currentID} />;
      })}
    </TreeNode>
  );
}

// function Node({ data, currentID }) {
//   if (!data) return null;
//   if (data?.__typename?.startsWith("Model")) {
//     return <ArrayNode data={data} currentID={currentID} />;
//   } else {
//     return <ItemNode data={data} currentID={currentID} />;
//   }
// }
