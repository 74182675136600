import { useApolloClient } from '@apollo/client'
import * as Yup from 'yup'
import { Stack, Typography, Button } from '@mui/material'
import FormikForm, {
  FormikField,
  FormikSelect,
} from '../../../../component/formikForm'
import onDeleteInvoice from '../onDelete'
import { vatRate } from '../../../../settings/formFieldEnum'
import onUpdate from '../onUpdate'

const validationSchemaForEdit = Yup.object().shape({})

export default function Item({ data, onClose }) {
  const client = useApolloClient()

  const onDeleteBtnClick = async () => {
    if (window.confirm('Are you sure you wish to delete this item?')) {
      await onDeleteInvoice({ values: data, client })
      onClose()
    }
  }

  const onSubmit = async ({ values, client }) => {
    await onUpdate({
      values,
      client,
    })
    onClose && onClose()
  }

  return (
    <FormikForm
      initialValues={{ ...data, isEditing: false }}
      validationSchema={validationSchemaForEdit}
      FormMainFields={EditFields}
      FormArray={null}
      handleCancel={({ setFieldValue, setValues }) => {
        setValues(data)
        setFieldValue('isEditing', false)
      }}
      saveButtonLabel='update'
      onSubmit={onSubmit}
      onDelete={onDeleteBtnClick}
    />
  )
}

const EditFields = ({ values }) => {
  const { isEditing, po, price, currency, qty, paymentDueDate, paymentStatus } =
    values || {}

  const { material, materialEntityRelation, customer } = po

  return (
    <Stack spacing={5} width={1200}>
      <Stack direction='row' spacing={5}>
        <Typography> CUSTMER: {customer?.name}</Typography>
        {materialEntityRelation && (
          <Typography> CODE: {materialEntityRelation?.code}</Typography>
        )}

        <Typography> MFR: {material?.mfr}</Typography>
        <Typography> MPN: {material?.mpn}</Typography>
      </Stack>

      <Stack direction='row' spacing={5} alignItems='flex-end'>
        <FormikField
          width={200}
          name='date'
          label='date'
          type='date'
          disabled={!isEditing}
        />
        <FormikField
          width={200}
          name='number'
          label='number'
          disabled={!isEditing}
        />
        <FormikField
          width={200}
          name='itemNumber'
          label='itemNumber'
          disabled={!isEditing}
        />
    
        <FormikField
          width={200}
          name='price'
          label='price'
          disabled={!isEditing}
        />

        <FormikSelect
          label='Payment Status'
          // required
          name='paymentStatus'
          options={['PAID', 'PARTIAL', 'UNPAID', 'OVERPAID', 'OVERDUE']}
          // labelKey="label"
          // valueKey="value"
          width={120}
          disabled={!isEditing}
        />

        <FormikField
          width={200}
          name='paymentDueDate'
          label='paymentDueDate'
          type='date'
          disabled={!isEditing}
        />
      </Stack>
      <Stack direction='row' spacing={5}>
        <Typography>
          QTY: {qty} {material?.unit}
        </Typography>
        <Typography>AMOUNT: {(price * qty).toFixed(3) * 1} </Typography>
        {currency === 'CNY' && (
          <Typography>
            AMOUNT WITH VAT: {(price * qty * (1 + vatRate)).toFixed(3) * 1}{' '}
          </Typography>
        )}
        <Typography>CURRENCY: {currency}</Typography>
      </Stack>
    </Stack>
  )
}
