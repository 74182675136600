import { useEffect, useState } from "react";
import { useApolloClient, useReactiveVar } from "@apollo/client";
import {
  useLocation,
  Link as RouterLink,
  useParams,
  useNavigate,
} from "react-router-dom";
import { Breadcrumbs, Typography, Link } from "@mui/material";
import { userVar } from "../client/cache";

export default function BreadcrumbsComp() {
  const user = useReactiveVar(userVar);

  const { groups, entityUserRelations } = user || {};

  // console.log({ entitiesinbreadCrumbs: entities });
  const client = useApolloClient();
  const [formattedPathname, setFormattedPathname] = useState();
  const { entityID } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const pathmap = {
      cloud: "Dashboard",
      company_profile: "Company Profile",
      internal_coworkers: "Internal Coworkers",
      external_coworkers: "External Coworkers",
      data: "Data",
      entity: "Entity",
      entities: "Entities",
      hydration: "Hydration",
      "admin-console": "Admin Console",
      tree_view: "Tree View",
      new: "new",
    };

    async function func() {
      const pathnames = pathname.split("/").filter(Boolean);
      if (user === "Authenticating") return;

      // console.log({ entityieee: entityUserRelations })
      // debugger;

      setFormattedPathname(
        await Promise.all(
          pathnames.map(async (value, index) => {
            const entityFound = entityUserRelations?.find(
              (x) => x.entity?.id === value
            );
            let formmattedValue = value;
            if (pathmap[value]) {
              formmattedValue = pathmap[value];
            } else if (entityFound) {
              formmattedValue =
                entityFound?.entity?.alias || entityFound?.entity?.name;
            } else if (value === entityID) {
              // IF ENTITYID PRESEND BUT FAILED TO FIND ENTITY IN THE LAST STEP, IT MEANS THE ENTITYID IS INVALID, THUS RETURN TO DASHBOARD
              // console.log({ entityID, entities, entityFound });
              // debugger;
              // return navigate("/cloud");
            }

            const last = index === pathnames.length - 1;
            const to = `/${pathnames.slice(0, index + 1).join("/")}`;

            return last ? (
              <Typography color="black" key={to}>
                {formmattedValue}
              </Typography>
            ) : (
              <Link
                component={RouterLink}
                underline="hover"
                color="inherit"
                to={to}
                key={to}
              >
                {formmattedValue}
              </Link>
            );
          })
        )
      );
    }
    func();
  }, [pathname, entityID, entityUserRelations, user, client, navigate]);

  // console.log({ formattedPathname })

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      bgcolor="white"
      p={1}
      sx={{ height: 30 }}
    >
      {/* {user === "Authenticating" ? null : formattedPathname} */}
      {formattedPathname}
    </Breadcrumbs>
  );
}
