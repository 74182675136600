import { Box, Card, Container, Typography } from "@mui/material";
import { Route } from "react-router-dom";
// import entityModules from "../modules/entityModules";
// import privateModules from "../modules/privateModules";
// import publicModules from "../modules/publicModules";
// import systemAdminModules from "../modules/systemAdminModules";
// import externalPartnerModules from "../modules/externalPartnerModules";

export default function iterateRoute({
  route,
  entityType,
  userRole,
  isAuthenticated,
  entityID,
}) {
  // console.log({ route, entityType, userRole, isAuthenticated });

  const auth = !!isAuthenticated ? "authenticated" : "unAuthenticated";

  if (!route || typeof route !== "object") return;

  const pathEntries = Object.entries(route);

  return pathEntries.map(([key, value]) => {
    if (!value) return null;

    const nestedKeys = Object.keys(value);

    let wrapper;

    if (
      nestedKeys?.includes("wrapper") &&
      (value.wrapper.allowedRole === "all" ||
        value.wrapper.allowedRole?.some((y) => userRole?.includes(y)))
    ) {
      wrapper = value.wrapper.element;
    }

    if (nestedKeys.includes("element") || nestedKeys.includes("Comp")) {
      // console.log({ allowedRole: value.allowedRole })
      // console.log({ key: nestedKeys })

      if (
        (value.allowedRole === "all" ||
          value.allowedRole?.some((y) => userRole?.includes(y))) &&
        (value.entityType === "all" ||
          value.entityType?.some((z) => entityType === z)) &&
        (value.auth === "all" || value.auth?.some((a) => a === auth))
      ) {
        // console.log({ typeofelement: typeof value.element });

        let element = value.element;

        if (value.Comp) {
          const Comp = value.Comp;

          element = (
            <Comp
              entityType={entityType}
              userRole={userRole}
              isAuthenticated={isAuthenticated}
              entityID={entityID}
            />
          );
        }
        return (
          <Route
            index={key === "index"}
            key={key}
            {...(key !== "index" && { path: key })}
            element={element}
          />
        );
      } else {
        return (
          <Route
            index={key === "index"}
            key={key}
            {...(key !== "index" && { path: key })}
            element={
              <Container
                sx={{
                  height: "100%",
                }}
                // maxWidth="sm"
              >
                <Box
                  bgcolor="#f1f1f1"
                  sx={{
                    minHeight: 600,
                    display: "flex",
                    justifyContent: "center",
                    padding: 3,
                    gap: 5,
                  }}
                >
                  <Typography
                    variant="h4"
                    // bgcolor="red"
                    color="red"
                    alignSelf="center"
                  >
                    Please contact system administator to get access to this
                    page
                  </Typography>
                </Box>
              </Container>

              // <Box bgcolor="blue" width="100%">
              //   <Container bgcolor="red">
              //     <Typography> invalid URL 888</Typography>
              //   </Container>
              // </Box>
            }
          />
        );
      }
    } else {
      // console.log({ key2: key, value2: value });

      return (
        <Route key={key} path={key} {...(wrapper && { element: wrapper })}>
          {Object.keys(value).length > 0 &&
            iterateRoute({
              route: value,
              entityType,
              userRole,
              isAuthenticated,
              entityID,
            })}
        </Route>
      );
    }
  });
}
