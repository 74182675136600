import { useApolloClient } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import FormikForm, { FormikField } from "../../../../component/formikForm";
import onUpdate from "../onUpdate";

import onDeleteShipping from "../onDelete";

export default function Item({ data, onClose }) {
  const client = useApolloClient();

  const onDeleteBtnClick = async () => {
    if (window.confirm("Are you sure you wish to delete this item?")) {
      await onDeleteShipping({ values: data, client, type: "SHIPPING" });
      onClose();
    }
  };
  const onSubmit = async ({ values, client }) => {
    await onUpdate({
      values,
      client,
    });
    onClose && onClose();
  };

  return (
    <FormikForm
      initialValues={{ ...data, isEditing: false }}
      validationSchema={null}
      FormMainFields={EditFields}
      FormArray={null}
      handleCancel={({ setFieldValue, setValues }) => {
        setValues(data);
        setFieldValue("isEditing", false);
      }}
      saveButtonLabel="update"
      onDelete={onDeleteBtnClick}
      onSubmit={onSubmit}
      // disableEdit
    />
  );
}

const EditFields = ({ values }) => {
  const { isEditing, material, materialEntityRelation, qty } = values;
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <FormikField
          name="number"
          label="number"
          required
          width={140}
          disabled={!isEditing}
        />
        <FormikField
          name="itemNumber"
          label="itemNumber"
          required
          width={100}
          disabled={!isEditing}
        />
      </Stack>

      <Stack direction="row" spacing={5}>
        {materialEntityRelation && (
          <Typography> CODE: {materialEntityRelation?.code}</Typography>
        )}

        <Typography> MFR: {material?.mfr}</Typography>
        <Typography> MPN: {material?.mpn}</Typography>
      </Stack>
      <Stack direction="row"  alignItems='center'>
        <FormikField
          name="qty"
          label="Qty"
          required
          width={100}
          disabled={!isEditing}
        />
        <Typography>{material?.unit}</Typography>
       
      </Stack>
    </Stack>
  );
};
