// export { default as company_profile } from "./company_profile";
// export { default as CPOs } from "./CPOs";
// export { default as customers } from "./customers";
// export { default as external_coworker } from "./external_coworker";
// export { default as incoming_invoices } from "./incoming_invoices";
// export { default as entityIndex } from "./index/index";
// export { default as internal_coworker } from "./internal_coworker";
// export { default as materials } from "./materials";
// export { default as offers } from "./offers";
// export { default as billing } from "./billing";
// export { default as parts } from "./parts";
// export { default as quotation } from "./quotation";
// export { default as SPOs } from "./SPOs";
// export { default as suppliers } from "./suppliers";
// export { default as tree_view } from "./tree_view";

import company_profile from "./company_profile";
import CPOs from "./CPOs";
import log from "./log";
// import ETA from "./ETA";
import shippings from "./shippings";
import receiving from "./receiving";
import inventory from "./inventory";
import vat_gen from "./vat_gen";
import vat_delivery from "./vat_delivery";
import batchImport from "./batchImport";
import CPOImport from "./CPOImport";
import arbitaryEmail from "./arbitaryEmail";
import inbound_payment from "./inbound_payment";
import incoming_invoices from "./incoming_invoices";
import outbound_payments from "./outbound_payments";
import entityIndex from "./home/index";
import playground from "./playground";
import costs from "./costs";
import billing from "./billing";
import materials from "./materials";
import pricing from "./pricing";
import SPOs from "./SPOs";
import home from "./home";
import entities from "./entities";
import contacts from "./contacts";

const modules = [
  home,
  entityIndex,
  batchImport,
  company_profile,
  materials,
  entities,
  inbound_payment,
  inventory,
  vat_gen,
  vat_delivery,
  pricing,
  costs,
  SPOs,
  log,
  // ETA,
  incoming_invoices,
  outbound_payments,
  playground,
  CPOs,
  shippings,
  receiving,
  CPOImport,
  arbitaryEmail,
  billing,
  contacts,
];

export default modules;
