import { useCallback } from "react";
import { gql, useQuery, useApolloClient, useReactiveVar } from "@apollo/client";
import { Hub } from "aws-amplify";
import { Stack, Typography, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import FormikForm, {
  FormikField,
  FormikAutocomplete,
  FormikSelect,
} from "../../../../component/formikForm";

import { materialPartRelationCode } from "../../../../settings/formFieldEnum";
import onCreateRelation from "../onCreateRelation";
import onDeleteRelation from "../onDeleteRelation";
import onUpdateRelation from "../onUpdateRelation";
import { entitiesByDefiner } from "../query";
import { userEntityRelationVar } from "../../../../client/cache";

const validationSchemaForCreateRelation = Yup.lazy((values) => {
  console.log({ valuesinSchema: values });
  return Yup.object().shape({});
});

export default function Relation({ data, onClose }) {
  const onAddBtnClick = useCallback(() => {
    Hub.dispatch("dialogMonitor", {
      event: "showDetails",
      data: {
        Component: AddOrEditRelationship,
        lineData: { materialID: data?.id, editType: "create", isEditing: true },
      },
    });
  }, [data.id]);

  return (
    <Stack
      spacing={2}
      justifyContent="space-between"
      sx={{ minHeight: 120 }}
      bgcolor="#f1f1f1"
      borderRadius={1}
      padding={2}
    >
      <Stack spacing={3}>
        <Typography variant="h6" sx={{ alignSelf: "center" }}>
          Entity Relations
        </Typography>

        {data?.materialEntityRelations?.items?.map((item) => (
          <RelationLine key={item.id} lineData={item} onClose={onClose} />
        ))}
      </Stack>

      <Button
        variant="contained"
        sx={{ alignSelf: "center" }}
        onClick={onAddBtnClick}
      >
        add
      </Button>
    </Stack>
  );
}

const RelationLine = ({ lineData }) => {
  const { entity, code, statusCode } = lineData;
  const client = useApolloClient();

  function handleDelete() {
    onDeleteRelation({ values: { id: lineData?.id }, client });
  }

  const handleEdit = useCallback(() => {
    Hub.dispatch("dialogMonitor", {
      event: "showDetails",
      data: {
        Component: AddOrEditRelationship,
        lineData: { ...lineData, editType: "edit", isEditing: true },
      },
    });
  }, [lineData]);

  return (
    <Stack bgcolor="#fff" p={2}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography flexGrow={1}>{entity.alias || entity.name}</Typography>

        <Typography width={200}>{code}</Typography>
        <Typography width={100}>
          {materialPartRelationCode.find((x) => x.value === statusCode).label}
        </Typography>

        <Stack direction="row" spacing={2}>
          <Button onClick={handleEdit}>edit</Button>
          <Button onClick={handleDelete}>delete</Button>
        </Stack>
      </Stack>
      {/* <Updates itemID={lineData?.id} /> */}
    </Stack>
  );
};

export function AddOrEditRelationship({ lineData, onClose, closeAll }) {
  // console.log({ lineDataeee: lineData });

  // const { id: userID, name } = lineData;
  const { entityID } = useReactiveVar(userEntityRelationVar);

  if (!entityID) {
    alert("3333 entityID not retrieved");
  }

  const onSubmit = async ({ values, client }) => {
    if (values.editType === "edit") {
      await onUpdateRelation({ values, client });
    } else if (values.editType === "create") {
      await onCreateRelation({ values, client });
    }

    onClose && onClose();
  };

  const handleCancel = ({ isCloseAll }) => {
    if (isCloseAll) {
      closeAll();
    } else {
      onClose && onClose();
    }
  };

  const initialValues = { ...lineData, definerID: entityID };

  return (
    <FormikForm
      initialValues={initialValues}
      validationSchema={validationSchemaForCreateRelation}
      onSubmit={onSubmit}
      FormMainFields={CreateRelationFields}
      FormArray={null}
      handleCancel={handleCancel}
    />
  );
}

export const CreateRelationFields = ({ values, onClose, setFieldValue }) => {
  const params = useParams();

  const definerID = values?.definerID || params.entityID;

  const navigate = useNavigate();

  const { data } = useQuery(gql(entitiesByDefiner), {
    variables: { definerID },
    skip: !definerID,
  });

  const entityOptions = data?.entitiesByDefiner?.items || [];

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={4} alignItems="flex-end">
        {values?.id && <FormikField name="id" label="ID" disabled />}
        <FormikAutocomplete
          name={"entity"}
          options={entityOptions || []}
          // required
          // multiple
          freeSolo={false}
          width={400}
          type={null}
          label="Entity"
          getOptionLabel={(option) => option.alias || option.name}
          listItemPrimaryField="alias"
          listItemSecondaryField={null}
          disableCloseOnSelect={false}
          getOptionDisabled={(option) => option?.id === values.id}
          getPrimaryText={null}
          getSecondaryText={null}
          disabled={false}
          onChange={(e, v) => {
            setFieldValue("entityID", v?.id);
          }}
          noOptionsText={
            <Stack direction="row" justifyContent="space-between">
              <Typography>No Options</Typography>
              <Button
                variant="contained"
                onClick={() => {
                  navigate(`/cloud/${definerID}/entities/new`, {});

                  onClose && onClose({ isCloseAll: true });
                }}
              >
                create entity
              </Button>
            </Stack>
          }
        />
      </Stack>
      <FormikField name="code" label="CODE" />
      <FormikSelect
        label="statusCode"
        name="statusCode"
        width={300}
        options={materialPartRelationCode}
        // required
        labelKey="label"
        valueKey="value"
      />
    </Stack>
  );
};

// function EditRelationship({ data, onClose }) {
//   const { entityID } = useParams();

//   return <p>edit relationship</p>;
// }
