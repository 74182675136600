import { useCallback, useState } from "react";
import { gql } from "@apollo/client";
import * as Yup from "yup";
import StepForm from "../../../../../component/formikForm/StepForm";
import Step0ChooseItems from "./Step0ChooseItems";
import Step1Review from "./Step1Review";
import { useNavigate, useParams } from "react-router-dom";
import InboundInvoiceItem from "../FormArray/InboundInvoiceItem";
import InboundInvoiceItemSubArray from "../FormArray/InboundInvoiceItemSubArray";
import DiscountingInvoiceItem from "../FormArray/DiscountingInvoiceItem";
import ArbitaryInvoiceItem from "../FormArray/ArbitaryInvoiceItem";
import BackdropLoader from "../../../../../component/BackdropLoader";
import onCreateInvoiceItems from "../../../billing/onCreate";
import onUpdateInvoice from "../../../billing/onUpdate";
import { getPO } from "./query";
// import * as ReactDOMServer from "react-dom/server";

const validationSchema = Yup.object().shape({
  date: Yup.date().required("Please enter date"),
  number: Yup.string().required("Please enter number"),
  category: Yup.string().required("Please select category"),
  vendor: Yup.lazy((value) => {
    if (typeof value === "string") {
      return Yup.string().required("Please enter vendor");
    } else {
      return Yup.object().required("Please select vendor").nullable();
    }
  }),

  // items: Yup.array()
  //   .of(
  //     Yup.object({
  //       material: Yup.object().required("Please select material").nullable(),
  //       po: Yup.object().required("Please select PO").nullable(),
  //       billingQty: Yup.number()
  //         .min(0.0001, "Quanty must be greater than zero")
  //         .required("Please enter quantity"),
  //     })
  //   )
  items: Yup.array()
    .when("category", {
      is: (value) => value === "DISCOUNTING",
      then: Yup.array().of(
        Yup.object().shape({
          discountingInvoices: Yup.array()
            .min(1, "Please select at least one item")
            .required("Please select item")
            .nullable(),
        })
      ),
    })
    .when("category", {
      is: (value) => ["VAT", "PI", "RECEIPT"].includes(value),
      then: Yup.array().of(
        Yup.object({
          material: Yup.object().required("Please select material").nullable(),
          po: Yup.object().required("Please select PO").nullable(),
          billingQty: Yup.number()
            .min(0.0001, "Quanty must be greater than zero")
            .required("Please enter quantity"),
        })
      ),
    })
    .when("category", {
      is: (value) => ["SERVICE", "OTHERS"].includes(value),
      then: Yup.array().of(
        Yup.object({
          desc: Yup.string().required("Please enter description").nullable(),
          descDetails: Yup.lazy((value, ctx) => {
            // console.log({ ctxssssssssssssc: ctx });
            if (["商品", "杂费"].includes(ctx.parent.desc)) {
              return Yup.string().required("请输入详情");
            } else {
              return Yup.string().nullable();
            }
          }),
          price: Yup.number()
            .required("Please enter price")
            .min(0.0001, "Price must be greater than zero"),
          currency: Yup.string().required("Please select currency"),
          taxRate: Yup.number()
            .required("Please enter taxRate")
            .min(0, "rate must be greater than zero"),
          qty: Yup.number()
            .min(0.0001, "Quanty must be greater than zero")
            .required("Please enter quantity"),
        })
      ),
    })

    .required("Please select item")
    .nullable(),
});

const labelOfSumbit = "CREATE INVOICES";

export default function CreateInvoiceForm() {
  const { entityID } = useParams();

  const defaultLineItem = {
    currency: "CNY",
    taxRate: 6,
    desc: "",
    subItems: [{}],
  };

  const initialValues = {
    isEditing: true,
    items: [defaultLineItem],
    definerID: entityID,
    entityID,
    date: new Date().toISOString("en-US").slice(0, 10),
    currency: "CNY",
    taxRate: 6,
    defaultLineItem,
    // number: Date.now().toString().slice(1, 9),
  };
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const steps = [
    {
      step: 0,
      label: "Select Items",
      form: Step0ChooseItems,
      formArray: [
        {
          Item: DiscountingInvoiceItem,
          name: "items",
          disabled: (values) => values.category !== "DISCOUNTING",
          // width: "95%",
          bgcolor: "#fff",
          btnColor: "purple",
          size: "md",
          // disableAdd: true,
        },
        {
          Item: ArbitaryInvoiceItem,
          name: "items",
          defaultValue: defaultLineItem,

          disabled: (values) =>
            !["SERVICE", "OTHERS"].includes(values.category),
          // width: "95%",
          bgcolor: "#fff",
          btnColor: "purple",
          size: "md",
          // disableAdd: true,
        },
        {
          Item: InboundInvoiceItem,
          name: "items",
          defaultValue: defaultLineItem,
          disabled: (values) =>
            !["VAT", "PI", "RECEIPT"].includes(values.category),
          bgcolor: "#fff",
          btnColor: "purple",
          size: "md",
          // disableAdd: true,
          subArray: [
            {
              Item: InboundInvoiceItemSubArray,
              name: "subItems",
              // width: 600,
              bgcolor: "#f3e5f5",
              btnColor: "blue",
              size: "sm",
              disableAdd: true,
            },
          ],
        },
      ],
      isOptional: false,
      fields: ["date", "number", "category", "items"],
    },
    {
      step: 1,
      label: "Review",
      form: Step1Review,
      isOptional: false,
      // formArray: (values) => {
      //   if (values.category !== 'DISCOUNTING') {
      //     return null
      //   } else {
      //     return [
      //       {
      //         Item: DiscountingInvoiceItem,
      //         name: 'items',
      //         // width: "95%",
      //         bgcolor: '#fff',
      //         btnColor: 'purple',
      //         size: 'md',
      //         // disableAdd: true,
      //       },
      //     ]
      //   }
      // },
    },
  ];

  async function handleItemsWithPO({
    client,
    items,
    definerID,
    date,
    category,
    vendor,
    number,
  }) {
    let invoiceItems = items
      .map((x) => x.subItems)
      .flat()
      ?.map(
        (
          { poID, billingQty, price, priceCur, taxRate, desc, shippingID },
          i
        ) => ({
          poID,
          number,
          itemNumber: i + 1,
          qty: billingQty,
          price,
          currency: priceCur,
          desc,
          shippingID,
          taxRate,
        })
      )
      ?.filter((x) => x.qty > 0);

    // CALCULATE PAYMENT DUE DATE

    invoiceItems = await Promise.all(
      invoiceItems.map(async (x) => {
        // console.log({ xxxx0: x });

        if (!x.poID || !x.shippingID) {
          // console.log({ xxxx1: x });
          // debugger;
          return { ...x, paymentDueDate: null };
        }
        const { data } = await client.query({
          query: gql(getPO),
          variables: { id: x.poID },
        });
        const { paymentTerms } = data?.getPO || {};

        let days = 0;

        if (paymentTerms?.startsWith("NET")) {
          days = paymentTerms?.split(" ")?.[1] * 1;
        }

        const paymentDueDate = new Date(Date.now() + days * 24 * 60 * 60 * 1000)
          ?.toISOString("en-US")
          .substring(0, 10);

        return { ...x, paymentDueDate };
      })
    );

    await onCreateInvoiceItems({
      client,
      values: {
        invoiceItems,
        definerID,
        date,
        category,
        issuerID: vendor.id,
      },
      type: "INBOUND",
    });
  }
  async function handleDiscounting({
    client,
    items,
    definerID,
    date,
    category,
    vendor,
    number,
  }) {
    const invoices = items?.map((x) => x.discountingInvoices).flat();

    // console.log({ invoices })
    // debugger

    await Promise.all(
      invoices.map((x) =>
        onUpdateInvoice({
          client,
          values: {
            id: x.id,
            number: x.number,
            date: new Date().toISOString("en-US").substring(0, 10),
            // statusCode: '900',
            statusCode: null,
          },
        })
      )
    );
  }
  async function handleServiceItems({
    client,
    items,
    definerID,
    date,
    category,
    vendor,
    billingEntity,
    number,
  }) {
    await onCreateInvoiceItems({
      client,
      values: {
        invoiceItems: items.map((x, i) => {
          let desc = x.desc;

          desc = x.descDetails ? `${desc}-${x.descDetails}` : desc;

          desc = vendor?.id ? desc : `${vendor}-${desc}`;

          return {
            ...x,
            number,
            itemNumber: i + 1,
            desc,
            taxRate: x.taxRate / 100,
            poID: null,
            shippingID: null,
          };
        }),
        definerID,
        date,
        category,
        issuerID: vendor.id,
        billingEntityID: billingEntity?.id,
      },
      type: "INBOUND",
    });
  }

  const onSubmit = useCallback(
    async ({ values, client }) => {
      setIsLoading(true);

      const {
        vendor,
        billingEntity,
        definerID,
        number,
        category,
        date,
        items,
      } = values;

      if (category === "DISCOUNTING") {
        await handleDiscounting({
          client,
          items,
          definerID,
          date,
          category,
          vendor,
          number,
        });
      } else if (["VAT", "PI", "RECEIPT"].includes(category)) {
        await handleItemsWithPO({
          client,
          items,
          definerID,
          date,
          category,
          vendor,
          number,
        });
      } else if (["SERVICE", "OTHERS"].includes(category)) {
        await handleServiceItems({
          client,
          items,
          definerID,
          date,
          category,
          vendor,
          number,
          billingEntity,
        });
      }

      setIsLoading(false);

      navigate(-1);
    },
    [navigate]
  );

  return (
    <>
      <StepForm
        steps={steps}
        onSubmit={onSubmit}
        labelOfSumbit={labelOfSumbit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      />
      <BackdropLoader isLoading={isLoading} />
    </>
  );
}
