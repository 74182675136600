import React, { useState, useMemo, useEffect } from "react";
import { API } from "aws-amplify";
import { gql, useQuery, useMutation, useApolloClient } from "@apollo/client";
import {
  Stack,
  TextField,
  Tooltip,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Typography,
} from "@mui/material";
import groupVAT from "../lib/groupVAT";
import { VATMaxAmountPerInv, VATMaxLines } from "../settings/formFieldEnum";
import SimpleTable from "./SimpleTable";
import { LoadingButton } from "@mui/lab";
// import { onCreate } from "../function/onCreate";
import onCreateInvoices from "../modules/entityModules/billing/onCreate";

const getPO = /* GraphQL */ `
  query GetPO($id: ID!) {
    getPO(id: $id) {
      id
      number
      itemNumber
      date

      materialID

      materialEntityRelationID
      vendor {
        id
        name
      }
      customer {
        id
        name
        billingInfo {
          billingName
          regNumber
          phoneNumber
          taxID
          address
          bank
          accountNumber
          swiftCode
          bankAddress
          routingNumber
          iban
          entityUserRelationID
        }
      }

      qty
      openQty
      price
      priceCur
      paymentTerms
      incoTerms {
        terms
        condition
        entityUserRelationID
        __typename
      }
      billingEntityID
      CRD
      shippingStatus
      billingStatus
      paymentStatus
      importAgentID
      paymentAgentID
      forwarderID

      version
      latestVersion
      statusCode

      invoice {
        nextToken
        __typename
      }

      __typename
    }
  }
`;

const getEntityUserRelation = /* GraphQL */ `
  query GetEntityUserRelation($id: ID!) {
    getEntityUserRelation(id: $id) {
      id
      role
      title
      shownFn
      shownLn
      shownMn
      statusCode
      isEmailVerified
      userID
      user {
        id
        sub
        email
        statusCode
        fn
        mn
        ln
        phoneNumber
        address
        groupToRead
        groupToEdit
        groupToDelete
        owner
        definerID
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      entityID
      entity {
        id
        isVerified
        name
        alias
        type
        businessType
        statusCode
        paymentTerms
        groupToRead
        groupToEdit
        groupToDelete
        definerID
        isDeleted
        createdAt
        updatedAt
        __typename
      }

      __typename
    }
  }
`;

const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      id
      category
      type
      number
      itemNumber
      date
      price
      qty
      currency
      note
      desc
      poID

      paymentDueDate
      billingEntityID

      shippingID

      definerID
      paymentStatus
      deductionDate
      createdAt
      updatedAt
      __typename
    }
  }
`;

export default function InvoiceGen({
  onClose,
  selectionModel,
  setSelectionModel,
  definerID,
}) {
  const client = useApolloClient();

  const [items, setItems] = useState();
  // const [invoiceType, set] = useState();

  useEffect(() => {
    async function func() {
      const arr = await Promise.all(
        selectionModel.map(async (x) => {
          const { data } = await client.query({
            query: gql(getPO),
            variables: { id: x.poID },
          });

          const PO = data?.getPO;
          const {
            paymentTerms,
            price,
            priceCur,
            number,
            itemNumber,
            vendor,
            customer,
          } = PO;
          return {
            shippingID: x.id,
            materialID: PO.materialID,
            materialEntityRelationID: PO.materialEntityRelationID,
            code: x.code,
            mfr: x.mfr,
            mpn: x.mpn,
            poID: x.poID,
            unit: x.material?.unit,
            desc: x.material?.desc || x.mpn,
            poNumber: number,
            poItemNumber: itemNumber,
            qty: x.qty,
            price,
            priceCur,
            paymentTerms,
            shippingDate: x.date,
            vendorName: vendor.name,
            customerID: customer.id,
            customerName: customer.name,
            billingInfo: customer.billingInfo,
          };
        })
      );
      setItems(arr);
    }

    if (selectionModel?.length) {
      func();
    }
  }, [selectionModel, client]);

  if (!items?.length) return null;

  if ([...new Set(items?.map((x) => x.customerID))].length > 1) {
    alert("Please select POs with the same customer");
    return;
  }

  if (items?.every((x) => x.priceCur === "CNY"))
    return (
      <VATGen
        items={items}
        definerID={definerID}
        setSelectionModel={setSelectionModel}
        onClose={onClose}
      />
    );
  else if (items?.every((x) => x.priceCur === "USD"))
    return (
      <PIGen
        items={items}
        definerID={definerID}
        setSelectionModel={setSelectionModel}
        onClose={onClose}
      />
    );
  else {
    console.log({ items });
    alert("Please select POs with the same currency");
    return null;
  }
}

function VATGen({ items, definerID, setSelectionModel, onClose }) {
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);

  const headers = [
    "invoiceNumber",
    "invoiceItemNumber",
    "mfr",
    "mpn",
    "code",
    "poNumber",
    "poItemNumber",
    "qty",
    "price",
    "priceCur",
    "amount",
    "subTotal",
  ];
  const [startingVAT, setStartingVAT] = useState(1);

  const vats = useMemo(() => {
    const aggregatedItems = groupVAT(
      items,
      VATMaxAmountPerInv,
      VATMaxLines,
      startingVAT
    )?.flat();
    return aggregatedItems.map((x) => {
      return {
        ...x,
        amount: (x.qty * x.price).toFixed(3) * 1,
        subTotal:
          x.invoiceItemNumber === 1
            ? aggregatedItems
                .filter((y) => y.invoiceNumber === x.invoiceNumber)
                .reduce((a, b) => a + b.qty * b.price, 0)
                .toFixed(3) * 1
            : null,
      };
    });
  }, [items, startingVAT]);

  const totaoValue =
    vats.reduce((a, v) => a + v.price * v.qty, 0).toFixed(3) * 1;
  const totalQty = vats.reduce((a, v) => a + v.qty, 0).toFixed(3) * 1;

  // console.log({ vats });

  const { vendorName, customerName, billingInfo } = vats[0];
  const {
    accountNumber,
    bank,
    bankAddress,
    billingName,
    iban,
    routingNumber,
    swiftCode,
    entityUserRelationID,
    taxID,
  } = billingInfo || {};

  const { data: getEntityUserRelationData } = useQuery(
    gql(getEntityUserRelation),
    { variables: { id: entityUserRelationID }, skip: !entityUserRelationID }
  );

  const { user } = getEntityUserRelationData?.getEntityUserRelation || {};
  const { address, phoneNumber, email } = user || {};

  const onSubmit = async () => {
    setLoading(true);
    const date = new Date().toISOString("en-US").slice(0, 10);
    const batch = Date.now().toString().slice(1, 9);
    const invoiceItems = vats.map((x) => {
      let paymentDueDate;
      let days = 130;

      if (x.shippingDate) {
        const paymentDue =
          Math.max(new Date(x.shippingDate).getTime(), Date.now()) +
          days * 86400000;

        paymentDueDate = new Date(paymentDue).toISOString("en-US").slice(0, 10);
      }

      return {
        number: x.invoiceNumber,
        itemNumber: x.invoiceItemNumber,
        shippingID: x.shippingID,
        poID: x.poID,
        qty: x.qty,
        currency: x.priceCur,
        price: x.price,
        desc: x.desc,
        paymentDueDate,
      };
    });

    const values = {
      date,
      batch,
      billingEntityID: vats[0].customerID,
      category: "VAT",
      definerID,
      invoiceItems,
    };

    // console.log({ values });

    // debugger;

    await onCreateInvoices({ client, values, type: "OUTGOING" });

    await client.refetchQueries({ include: ["InvoiceByType"] });

    const res = await API.post("iSupplyAPI", "/restricted", {
      body: {
        operation: "SEND_VAT_BATCH_NUMBER",
        data: {
          batch,
          to: "shiying.qian@hankeno.com",
          cc: "steven.lu@hankeno.com",
        },
      },
    });

    setSelectionModel && setSelectionModel([]);
    setLoading(false);

    onClose && onClose();
  };

  return (
    <Stack spacing={3} minWidth={1600} p={5}>
      <Stack direction="row" spacing={4}>
        <TextField
          label="STARTING VAT NUMBER"
          sx={{ alignSelf: "flex-start", width: 300 }}
          value={startingVAT}
          placeholder="STARTING VAT NUMBER"
          onChange={(e) => setStartingVAT(e.target.value)}
        />

        <Stack>
          <Typography variant="h5">Total Value: {totaoValue}</Typography>
          <Typography variant="h5">Total Qty: {totalQty}</Typography>
        </Stack>

        <Stack>
          <Stack direction="row">
            <Typography variant="title" width={120}>
              VENDOR:
            </Typography>
            <Typography variant="title">{vendorName}</Typography>
          </Stack>
          <Stack direction="row">
            <Typography variant="title" width={120}>
              CUSTOMER:
            </Typography>
            <Typography variant="title">{customerName}</Typography>
          </Stack>
          <Stack direction="row">
            <Typography variant="title" width={120}>
              BILL TO:
            </Typography>
            <Stack>
              <Typography variant="title">{billingName}</Typography>
              <Typography variant="title">TaxID: {taxID}</Typography>
              <Typography variant="title">
                Billing Address: {address} {phoneNumber} {email}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <SimpleTable headers={headers} rows={vats} />
      <LoadingButton
        loading={loading}
        sx={{ alignSelf: "center" }}
        variant="contained"
        onClick={onSubmit}
      >
        GENERATE INVOICES
      </LoadingButton>
    </Stack>
  );
}

function PIGen({ items, definerID, setSelectionModel, onClose }) {
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();

  const date = new Date().toISOString("en-US").slice(0, 10);
  const number = `${Date.now().toString().slice(1, 9)}`;

  const invoiceItems = items.map((x, i) => {
    let paymentDueDate;
    let days = 0;
    if (x?.paymentTerms.startsWith("NET")) {
      days = x.paymentTerms.split(" ")[1] * 1;
    }

    if (x.shippingDate) {
      const paymentDue =
        Math.max(new Date(x.shippingDate).getTime(), Date.now()) +
        days * 86400000;

      paymentDueDate = new Date(paymentDue).toISOString("en-US").slice(0, 10);
    }

    return {
      ...x,
      number,
      itemNumber: i + 1,
      shippingID: x.shippingID,
      poID: x.poID,
      qty: x.qty,
      currency: x.priceCur,
      price: x.price,
      desc: x.desc,
      paymentDueDate,
      amount: (x.qty * x.price).toFixed(3) * 1,
    };
  });

  const values = {
    date,
    billingEntityID: items[0].customerID,
    category: "PI",
    definerID,
    invoiceItems,
  };

  const onSubmit = async () => {
    setLoading(true);
    // debugger;

    await onCreateInvoices({ client, values, type: "OUTGOING" });

    await client.refetchQueries({
      include: ["InvoiceByType", "ShippingByTypeAndMaterial"],
    });

    setSelectionModel && setSelectionModel([]);
    setLoading(false);

    onClose && onClose();
  };

  const headers = [
    "number",
    "itemNumber",
    "mfr",
    "mpn",
    "code",
    "poNumber",
    "poItemNumber",
    "qty",
    "price",
    "currency",
    "amount",
  ];

  return (
    <Stack minWidth={1200} spacing={5}>
      <SimpleTable headers={headers} rows={invoiceItems} />
      <LoadingButton
        loading={loading}
        sx={{ alignSelf: "center" }}
        variant="contained"
        onClick={onSubmit}
      >
        GENERATE INVOICES
      </LoadingButton>
    </Stack>
  );
}
