import { useEffect, useState } from "react";
import { gql, useApolloClient, useQuery } from "@apollo/client";
import { API, Storage } from "aws-amplify";
import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { getMaterial, updateMaterial } from "../materials/query";

const listMaterials = /* GraphQL */ `
  query ListMaterials(
    $filter: ModelMaterialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMaterials(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        definerID
        mpn
        mfr
        coo
        desc
        unit
        leadtime
        mpq
        transitDays
        hsCode
        importDutyCN
        groupToRead
        groupToEdit
        groupToDelete
        isDeleted
        statusCode
        inventory
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
function Comp({ entityType, userRole, isAuthenticated, entityID }) {
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();

  async function onClick() {
    alert("clicked");
  }

  // const { data } = useQuery(gql(listPOS));

  // const items = data?.listPOS?.items;
  // console.log({ items });

  return (
    <Stack>
      <Typography>TEST</Typography>
      <LoadingButton
        loading={loading}
        variant="contained"
        onClick={async () => {
          setLoading(true);

          await onClick();

          setLoading(false);
        }}
      >
        run func
      </LoadingButton>
    </Stack>
  );
}

const playground = {
  path: "playGround",

  sideNav: {
    group: 1,
    order: 60,
    label: "Playground",
    entityType: "all",
    allowedRole: ["admin"],
    auth: ["authenticated"],
  },
  route: {
    index: {
      element: null,
      Comp,
      allowedRole: ["admin"],
      entityType: "all",
      auth: ["authenticated"],
    },
  },
};

export default playground;
