import { gql } from '@apollo/client'
import { onCreate } from '../../../function/onCreate'
import { createReceivingAndShipping } from './query'
import updatePOOpenQtyFromArray from '../../../function/utilityAPI/updatePOOpenQtyFromArray'
import updateInvenotryFromArray from '../../../function/utilityAPI/updateInvenotryFromArray'

const getPO = /* GraphQL */ `
  query GetPO($id: ID!) {
    getPO(id: $id) {
      id
      number
      itemNumber
      date
      offerID
      customerID
      vendorID
      paymentTerms
    }
  }
`

const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      id
      category
      paymentDueDate
      type
      number
      itemNumber
      date
      price
      qty
      currency
      note
      desc
      poID

      forwarderPOID

      __typename
    }
  }
`

async function onCreateShippingItem({ client, values }) {
  const fragment = /* GraphQL */ `
    fragment NewReceivingAndShipping on ReceivingAndShipping {
      id
    }
  `

  await onCreate({
    input: values,
    mutation: createReceivingAndShipping,
    fragment,
    updateCacheFields: [],
    client,
    refetchQueries: [],
  })
}

export async function onCreateShippings({ client, values, type }) {
  if (type !== 'INCOMING' && type !== 'OUTGOING')
    return alert('type must be INCOMING or OUTGOING')
  const { date, number, definerID, aggregatedShippingItems } = values

  if (!aggregatedShippingItems || aggregatedShippingItems?.length === 0) return

  await Promise.all(
    aggregatedShippingItems.map(
      async ({
        itemNumber,
        materialID,
        materialEntityRelationID,
        poID,
        qty,
        coo,
      }) => {
        const values = {
          number,
          itemNumber,
          date,
          type,
          qty: qty * 1,
          poID,
          definerID,
          materialID,
          materialEntityRelationID,
          coo: coo?.toUpperCase(),
          timeStamp: new Date().getTime(),
        }

        await onCreateShippingItem({ client, values })
      },
    ),
  )

  // console.log({ aggregatedShippingItems });
  // debugger;
  // THIS LOGIC INCLUDES BOTH OF CURRENT PO AND FORWARDER PO'S ORGINAL PO)
  await updatePOOpenQtyFromArray({
    client,
    arr: aggregatedShippingItems,
    type: 'deduct',
  })

  await updateInvenotryFromArray({
    client,
    arr: aggregatedShippingItems,
    type: type === 'OUTGOING' ? 'deduct' : 'add',
  })

  await Promise.all(
    aggregatedShippingItems.map(async (x) => {
      if (!x.piID) return
      const { data } = await client.query({
        query: gql(getPO),
        variables: { id: x.poID },
      })
      const { paymentTerms } = data?.getPO || {}

      // console.log({ paymentTerms, x });

      let days = 0

      if (paymentTerms?.startsWith('NET')) {
        days = paymentTerms.split(' ')?.[1] * 1
      }

      const paymentDueDate = new Date(Date.now() + days * 24 * 60 * 60 * 1000)
        ?.toISOString('en-US')
        .substring(0, 10)

      await client.mutate({
        mutation: gql(updateInvoice),
        variables: {
          input: {
            id: x.piID,
            paymentDueDate,
          },
        },
      })
    }),
  )
  await client.refetchQueries({
    include: ['ShippingByTypeAndMaterial'],
  })
}
