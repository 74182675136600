import Template from "../../../component/Template";

import evaluation from "../../../assets/images/evaluation.jpg";
import sourcing from "../../../assets/images/sourcing.jpg";
import warehouse from "../../../assets/images/warehouse.jpg";
import cost_management from "../../../assets/images/cost_management.png";
import logistics from "../../../assets/images/logistics.jpg";
import ordering from "../../../assets/images/ordering.jpg";

const headPicture = warehouse;
const headTitle = "Supply Consolidation";
const headDescription =
  "Hankeno's comprehensive sourcing and logistics solutions deliver a seamless and hassle-free procurement experience to our customers";

const headMessage = `At Hankeno, we specialize in supply consolidation designed to streamline your procurement processes and enhance operational efficiency. Our comprehensive approach allows businesses to reduce costs, improve supplier relationships, and ensure timely delivery of materials. With our expertise and industry connections, we help you navigate the complexities of supply chain management, enabling you to focus on your core business operations while we handle the details. Trust us to be your partner in optimizing your supply chain and achieving your business goals.`;

const sections = [
  {
    title: "Evaluation",
    image: evaluation,
    description: `Our supply consolidation services begin with collaboration with your R&D team in the early stages of your project. We help in designing specifications that not only meet your requirements but also focus on cost optimization. By identifying potential efficiencies and alternatives, we ensure that your project is positioned for success from the very start.`,
  },
  {
    title: "Sourcing",
    image: sourcing,

    description: `We leverage our extensive network to source high-quality materials and components at competitive prices. Our sourcing strategy is rooted in thorough market research and supplier evaluation, allowing us to identify the best options that align with your project goals. Whether you're looking for specific materials or a broader range of supplies, we ensure a seamless sourcing process that meets your timelines and budget.`,
  },
  {
    title: "Ordering",
    image: ordering,
    description: `We take the hassle out of procurement by managing the entire process on your behalf. From placing orders to tracking deliveries, we handle all aspects of procurement, ensuring that your materials arrive on time and in perfect condition. Our procurement experts are dedicated to maintaining open lines of communication, providing updates and addressing any concerns along the way.`,
  },
  {
    title: "Cost Management",
    imageObjectFit: "contain",
    image: cost_management,
    description: `Effective cost management is at the core of our supply consolidation services. We analyze your procurement needs and develop strategies to reduce expenses without compromising on quality. Our team works diligently to identify cost-saving opportunities, enabling you to maximize your budget and improve your overall project profitability.`,
  },
  {
    title: "Material Hub",
    image: warehouse,
    description: `We offer a centralized material hub that simplifies inventory management and supply access. Our hub enables you to efficiently manage your materials, reducing lead times and ensuring that you have the right supplies when you need them. This streamlined approach enhances your operational efficiency and minimizes disruptions to your production schedule.`,
  },
  {
    title: "Logistics",
    imageObjectFit: "contain",
    image: logistics,
    description: `Our logistics solutions are designed to facilitate the smooth transportation of materials from suppliers to your location. We work with trusted logistics partners to provide reliable shipping and delivery services, ensuring that your materials are handled with care. By coordinating logistics effectively, we help you maintain project momentum and keep your timelines on track.`,
  },
];

export default function Consolidation() {
  return (
    <Template
      headPicture={headPicture}
      headTitle={headTitle}
      headDescription={headDescription}
      headMessage={headMessage}
      sections={sections}
    />
  );
}
