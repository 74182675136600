import { onUpdate } from "../../../function/onUpdate";
import { updateEntity, getEntity } from "./query";

export default async function onUpdateBilling({ values, client }) {
  // console.log({ values });

  const {
    id,
    entityUserRelation,
    billingName,
    regNumber,
    taxID,
    address,
    bank,
    phoneNumber,
    accountNumber,
    bankAddress,
    swiftCode,
    routingNumber,
    iban,
    entityUserRelationID,
  } = values;
  const input = {
    id,
    billingInfo: {
      billingName,
      regNumber,
      phoneNumber,
      taxID,
      address,
      bank,
      accountNumber,
      bankAddress,
      swiftCode,
      routingNumber,
      iban,
      entityUserRelationID: entityUserRelation?.id || entityUserRelationID,
    },
  };

  console.log({ input });

  // debugger

  const fieldsToUpdate = [
    // { field: "name", createUpdate: true },
    // { field: "businessType", createUpdate: true },
    // { field: "alias", createUpdate: true },
    // { field: "statusCode", createUpdate: true },
    // { field: "incoTerms", createUpdate: true, key: "terms" },
    { field: "billingInfo", createUpdate: false },
  ];

  const res = await onUpdate({
    type: "Entity",
    values: input,
    mutation: updateEntity,
    query: getEntity,
    getInitialValue: null,
    fieldsToUpdate,
    client,
    refetchQueries: [],
    onUpdate: null,
  });
  console.log({ res });
  return res;
}
