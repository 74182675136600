import {
  Tooltip,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";

export default function SimpleTable({ headers, rows }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, i) => {
            return (
              <TableRow
                // {...(minRowHeight && {
                //   height: minRowHeight,
                // })}
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {headers.map((header) => {
                  const oldValue = row.oldValues?.[header];

                  if (oldValue) {
                    return (
                      <TableCell key={header} bgcolor="orange">
                        {`${oldValue} => ${row[header]}`}
                      </TableCell>
                    );
                  }

                  const error = row.error?.find((x) => x.attr === header);
                  const { isMajor, msg } = error || {};

                  return (
                    <Tooltip key={header} title={msg}>
                      <TableCell
                        {...(error && {
                          bgcolor: isMajor ? "red" : "orange",
                        })}
                      >
                        {row[header]}
                      </TableCell>
                    </Tooltip>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
