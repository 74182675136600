import { API, Auth } from "aws-amplify";
import emitSnackbar from "../../../function/emitSnackbar";
import onUpdate from './onUpdate'


export default async function onDeleteRole({
  selectionModel,
  setSelectionModel,
  client,
}) {
  await Promise.all(
    selectionModel.map(async ({ id }) => {
      await onUpdate({
        values: {
          id,
          status: "REMOVED",
        },
        client,
      });

      const res = await API.post("iSupplyAPI", "/restricted", {
        body: {
          operation: "REMOVE_FROM_GROUP",
          meta: { invitationID: id },
        },
      });

      console.log({ restestfunc: res });
    })
  );

  //YOU CAN RUN THIS OPTIONAL DEPENING
  await Auth.currentAuthenticatedUser({ bypassCache: true });

  emitSnackbar({ message: "Role removed successfully", duration: 5000 });
  setSelectionModel([]);
  // const res = await client.refetchQueries({
  //   include: ["UserBySub"],
  // });
  // console.log({ res });
  // debugger;
}
