import { API } from "aws-amplify";
import { gql } from "@apollo/client";

import { onCreate } from "../../../function/onCreate";
import emitSnackbar from "../../../function/emitSnackbar";

import { userByEmail, createUser, createInvite, fragment } from "./query";

export default async function onCreateInvite({ client, values }) {
  const {
    email,
    entity: { id: entityID, name: entityName },
    role,
    ln,
    fn,
  } = values;

  const code = values.code || Date.now().toString().slice(1, 9);

  // FETCH USER

  const { data: getUserData } = await client.query({
    query: gql(userByEmail),
    variables: { email },
    fetchPolicy: "network-only",
  });

  console.log({ getUserData });

  let { id: userID, sub } = getUserData?.userByEmail?.items[0] || {};

  console.log({ userID, sub });

  // CREATE USER IF USER DOES NOT EXIST

  if (!userID) {
    console.log("create new user");
    const fragment = /* GraphQL */ `
      fragment NewUser on User {
        id
      }
    `;

    const inputCreateUser = {
      email,
      sub: "PENDING",
      ln,
      fn,
      groupToEdit: ["admin"],
      groupToDelete: ["admin"],
    };

    console.log({ inputCreateUser });

    // debugger;
    const { data: createUserData } = await onCreate({
      input: inputCreateUser,
      mutation: createUser,
      fragment,
      updateCacheFields: ["listUsers"],
      client,
    });

    userID = createUserData?.createUser?.id;
  }

  // CREATE INVITATION

  const input = {
    id: code,
    ln,
    fn,
    code,
    userID,
    entityID,
    role,
    status: "PENDING",
    groupToEdit: ["admin"],
    groupToDelete: ["admin"],
  };

  const { data: createInviteData, errors: createInviteErrors } = await onCreate(
    {
      input,
      mutation: createInvite,
      fragment,
      updateCacheFields: ["listInvites", "inviteByEntity"],
      client,
    }
  );
  console.log({ createInviteData, createInviteErrors });

  // SEND INVITATION IF USER IS NOT REGISTERED

  try {
    if (!sub || sub === "PENDING") {
      const meta = {
        to: email,
        code,
        role: role[0],
        fn: "steve",
        entity: entityName,
      };
      await API.post("iSupplyAPI", "/restricted", {
        body: {
          operation: "SEND_INVITATION",
          meta,
        },
      });
      emitSnackbar({
        message: "invitation sent successfully",
        duration: 5000,
      });
    }
  } catch (e) {
    console.error({ e23325: e.message });
  }
}
