// import comparePropertyBetweenObjects from "./comparePropertyBetweenObjects";

export default async function crossCheckArray({
  originalArr,
  newArr,
  funcAdd,
  funcDelete,
  funcCheckUpdate,
  checkIsItemInNewArrayExisting,
  checkIsItemInOldArrayRemaining,
}) {
  if (
    // !Array.isArray(attrs) ||
    (originalArr && !Array.isArray(originalArr)) ||
    !Array.isArray(newArr) ||
    typeof funcDelete !== "function" ||
    typeof funcAdd !== "function"
  ) {
    throw Error("Type Error 8009");
  }

  originalArr = originalArr || [];

  // debugger
  await Promise.all(
    newArr.map(async (x) => {
      let isExisting = false;

      if (checkIsItemInNewArrayExisting) {
        isExisting = checkIsItemInNewArrayExisting({
          item: x,
          originalArr,
        });
      } else {
        isExisting = originalArr.includes(x);
      }

      if (!isExisting) {
        await funcAdd(x);
      } else {
        await funcCheckUpdate({ currentItem: x });
      }
    })
  );

  await Promise.all(
    originalArr.map(async (x) => {
      let isRemaining = false;

      if (checkIsItemInOldArrayRemaining) {
        isRemaining = checkIsItemInOldArrayRemaining({
          item: x,
          newArr,
        });
      } else {
        isRemaining = newArr.includes(x);
      }

      if (!isRemaining) {
        await funcDelete(x);
      }
    })
  );
}
