import { useApolloClient } from "@apollo/client";
import * as Yup from "yup";
import { Stack } from "@mui/material";
import FormikForm, {
  FormikField,
  FormikSelect,
} from "../../../../component/formikForm";
import onDeleteOffer from "../onDelete";
import onUpdate from "../onUpdate";
import { currencies, offerStatus } from "../../../../settings/formFieldEnum";

const validationSchemaForEdit = Yup.object().shape({
  price: Yup.number().min(0).required("Please enter Price"),
  currency: Yup.string().min(2, "Too Short").required("Please enter Currency"),
});

export default function Item({ data, onClose }) {
  const client = useApolloClient();

  const onDeleteBtnClick = async () => {
    if (window.confirm("Are you sure you wish to delete this item?")) {
      await onDeleteOffer({ values: data, client });
      onClose();
    }
  };

  const onSubmit = async ({ values, client }) => {
    await onUpdate({
      values,
      client,
    });
    onClose && onClose();
  };

  return (
    <FormikForm
      initialValues={{ ...data, isEditing: false }}
      validationSchema={validationSchemaForEdit}
      FormMainFields={EditFields}
      FormArray={null}
      // handleCancel={onClose}
      handleCancel={({ setFieldValue, setValues }) => {
        setValues(data);
        setFieldValue("isEditing", false);
      }}
      saveButtonLabel="update"
      onSubmit={onSubmit}
      onDelete={onDeleteBtnClick}
    />
  );
}

const EditFields = ({ values }) => {
  const { isEditing } = values || [];

  return (
    <Stack
      width='100%'
      spacing={3}
      justifyContent='flex-start'
      alignItems='flex-start'>
      <Stack direction='row' spacing={5} alignItems='flex-end'>
        <FormikSelect
          name='currency'
          label='Currency'
          width={100}
          options={currencies}
          labelKey='label'
          valueKey='value'
          required
          labelSX={{}}
          disabled={!isEditing}
        />
        <FormikField
          name='date'
          label='Date'
          type='date'
          required
          width={120}
          disabled={!isEditing}
        />
        <FormikField
          name='price'
          label='Price'
          type='number'
          required
          width={100}
          disabled={!isEditing}
        />
        <FormikField
          name='moq'
          label='MOQ'
          type='number'
          required
          width={100}
          disabled={!isEditing}
        />

        <FormikField
          name='leadtime'
          label='leadtime (wks)'
          width={120}
          // required
          disabled={!isEditing}
        />
        <FormikField
          name='validDays'
          label='validDays'
          type='number'
          width={120}
          // required
          disabled={!isEditing}
        />
        <FormikField
          name='note'
          label='Note'
          width={300}
          // required
          disabled={!isEditing}
        />
        <FormikSelect
          label='statusCode'
          name='statusCode'
          width={200}
          options={offerStatus}
          required
          labelKey='status'
          valueKey='code'
          disabled={!isEditing}
        />
      </Stack>
    </Stack>
  )
};
