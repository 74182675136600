import { useReactiveVar } from "@apollo/client";

import DataGridComp from "../../../component/DataGrid";
import getFilteredItemsByUserRole from "../../../function/getFilteredItemsByUserRole";
import onDelete from "./onDelete";
import { listLogs } from "./query";
import { userVar, userEntityRelationVar } from "../../../client/cache";

function Comp({ entityType, userRole, isAuthenticated, entityID }) {
  const user = useReactiveVar(userVar);

  console.log({ user });
  if (user?.email !== "steven.lu@hankeno.com") return null;
  const isAdmin = userRole?.includes("admin");

  const columns = [
    {
      field: "id",
      headerName: "id",
      width: 300,
      userRole: "all",
    },
    {
      field: "timeString",
      headerName: "timeString",
      width: 300,
      userRole: "all",
    },
    {
      field: "createdAt",
      headerName: "createdAt",
      width: 300,
      userRole: "all",
    },
    {
      field: "type",
      headerName: "type",
      width: 300,
      userRole: "all",
    },
    {
      field: "user",
      headerName: "user",
      width: 300,
      userRole: "all",
    },
    {
      field: "city",
      headerName: "city",
      width: 300,
      userRole: "all",
    },
    {
      field: "region",
      headerName: "region",
      width: 300,
      userRole: "all",
    },
    {
      field: "country",
      headerName: "country",
      width: 300,
      userRole: "all",
    },
    {
      field: "postal",
      headerName: "postal",
      width: 300,
      userRole: "all",
    },
    {
      field: "hostname",
      headerName: "hostname",
      width: 400,
      userRole: "all",
    },
    {
      field: "loc",
      headerName: "loc",
      width: 300,
      userRole: ["admin"],
    },
    {
      field: "ip",
      headerName: "ip",
      width: 300,
      userRole: ["admin"],
    },
    {
      field: "timezone",
      headerName: "timezone",
      width: 300,
      userRole: ["admin"],
    },
  ];

  const dropdownMenu = [
    {
      label: "Delete",
      userRole: ["admin"],
      onClick: async ({ client, selectionModel, setSelectionModel }) => {
        await onDelete({
          selectionModel,
          setSelectionModel,
          client,
        });
      },
    },
  ];

  const filteredColumns = getFilteredItemsByUserRole(columns, userRole);

  const filteredDropdownMenu = getFilteredItemsByUserRole(
    dropdownMenu,
    userRole
  );

  const prepareItemsFunc = (items) => {
    // console.log("items", items);
    // console.log("formatedCurrentUser", formatedCurrentUser);

    // const newArr = items.concat(formatedCurrentUser);

    // console.log({ newArr });

    return items.map((x) => {
      return {
        ...x,
        user: `${x?.user?.fn} ${x?.user?.ln}`,
      };
    });
  };

  const filterAttrs = [
    { label: "type", value: "type", type: "string" },
    { label: "user", value: "user", type: "string" },

    { label: "timeString", value: "timeString", type: "date" },

    {
      label: "country",
      value: "country",
      type: "string",
    },
  ];

  return (
    <DataGridComp
      query={listLogs}
      queryVariables={null}
      // onClickAddRoute={isAdmin ? "new" : null}
      columns={filteredColumns}
      // DetailsDialog={isAdmin ? ItemView : null}
      userRole={userRole}
      checkboxSelection={isAdmin ? true : null}
      getDropdownMenu={filteredDropdownMenu}
      prepareItemsFunc={prepareItemsFunc}
      filterAttrs={filterAttrs}
    />
  );
}

const log = {
  path: "log",
  sideNav: {
    group: 6,
    order: 40,
    label: "Log",
    entityType: "all",
    allowedRole: ["admin"],
    auth: ["authenticated"],
  },

  route: {
    index: {
      // element: null,
      Comp,
      allowedRole: "all",
      entityType: "all",
      auth: ["authenticated"],
    },
  },
};

export default log;
