import {
  Box,
  Link,
  FormControlLabel,
  Stack,
  TextField,
  Checkbox,
  Typography,
} from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import LoadingButton from '@mui/lab/LoadingButton'
export default function SignUpWithCode({
  loading,
  setAuthType,
  action,
  clearAlert,
  setEmail,
  email,
  setPassword,
  password,
  setConfirmPassword,
  confirmPassword,
  sx,
  invitationCode,
  setInvitationCode,
  isInvitationSentByEmail,
  ...props
}) {
  const label = 'Sign Up'

  return (
    <Box sx={{ ...sx, width: 400 }}>
      <LockOutlinedIcon fontSize='large' color='secondary' />
      <Typography variant='h5'>{label}</Typography>
      <Box
        component='form'
        width='100%'
        onSubmit={action.onSubmit}
        sx={{ display: 'flex', flexDirection: 'column' }}>
        
        {!isInvitationSentByEmail && (
          <TextField
            margin='normal'
            required
            // disabled={isInvitationSentByEmail}
            fullWidth
            id='invitationCode'
            label='Invitation Code'
            name='invitationCode'
            autoComplete='invitationCode'
            // autoFocus
            value={invitationCode || ''}
            onChange={(e) => setInvitationCode(e.target.value)}
          />
        )}

        <TextField
          margin='normal'
          required
          fullWidth
          disabled={isInvitationSentByEmail}
          id='email'
          type='email'
          label='Email Address'
          name='email'
          autoComplete='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin='normal'
          required
          fullWidth
          name='password'
          label='Password'
          type='password'
          id='password'
          autoComplete='current-password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {/* <TextField
          margin="normal"
          required
          fullWidth
          name="confirmPassword"
          label="Retype Password"
          type="password"
          id="confirmPassword"
          autoComplete="current-password"
           value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        /> */}
        <FormControlLabel
          control={<Checkbox value='remember' color='primary' />}
          label='Remember me'
        />
        <LoadingButton
          loading={loading}
          type='submit'
          fullWidth
          variant='contained'
          sx={{ mt: 3, mb: 2, alignSelf: 'center', width: 220 }}>
          Sign Up
        </LoadingButton>
      </Box>
      <Stack width='90%' direction='row' justifyContent='space-between'>
        <Link
          component='button'
          variant='body2'
          onClick={() => {
            clearAlert()
            setAuthType('signIn')
          }}>
          {'Have an account already? Sign in'}
        </Link>
      </Stack>
    </Box>
  )
}
