import { useState } from "react";
import { gql, useReactiveVar } from "@apollo/client";
import { API, Storage } from "aws-amplify";
import Excel from "exceljs";
import { saveAs } from "file-saver";

import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { POByNumber } from "../query";
import { ShippingByNumber } from "../../shippings/query";
import mergeArrayAndCombineQty from "../../../../lib/mergeArrayAndCombineQty";
import { userEntityRelationVar } from "../../../../client/cache";

import { onCreateShippings } from "../../shippings/onCreate";

export const navigateTo = "../shippings";

export const Bar = ({ rows }) => {
  return (
    <Stack direction="row" sx={{ alignSelf: "flex-start" }}>
      {rows?.length > 0 && (
        <Typography variant="h6">
          Total Qty: {rows?.reduce((a, v) => a + v.qty, 0)}
        </Typography>
      )}
    </Stack>
  );
};

function FileLine({ filename, entityID }) {
  const [isLoading, setIsloading] = useState(false);

  const path = `${entityID}/template/${filename}`;

  return (
    <Stack direction="row" justifyContent="space-between" spacing={1}>
      <Typography variant="p">{filename}</Typography>
      <LoadingButton
        loading={isLoading}
        size="small"
        onClick={async () => {
          setIsloading(true);
          try {
            const fetchedURL = await Storage.get(path, {
              download: false,
              validateObjectExistence: true,
              // level: 'protected',
            });
            window.location.href = fetchedURL;

            // console.log({window})

            // const downloadFolder = window.downloads.getDownloadFolder();

            // console.log({ downloadFolder });
            // debugger;
          } catch (err) {
            console.log({ err444: err });
          }
          setIsloading(false);
        }}
        variant="contained"
      >
        download
      </LoadingButton>
    </Stack>
  );
}

export const TemplateLabel = ({ rows }) => {
  const { entityID } = useReactiveVar(userEntityRelationVar);

  const filenames = ["BRAUN_TEMPLATE_SHIPPING.xlsx", "国内送货单导入模板.xls"];

  return (
    <Stack spacing={2}>
      <Typography variant="h6">Template: </Typography>

      <Stack spacing={1}>
        {filenames.map((filename) => (
          <FileLine key={filename} filename={filename} entityID={entityID} />
        ))}
      </Stack>
    </Stack>
  );
};

export const headerIdentifier = [
  { label: "number", isRequired: false },
  { label: "itemNumber", isRequired: false },
  { label: "DOCUMENT", isRequired: true },
  { label: "poOpenQty", isRequired: false },
  { label: "MATERIAL", isRequired: true },
  { label: "OPEN QUANTITY", isRequired: true },
  { label: "SUPPLIER DELIV.DATE", isRequired: false },
  { label: "ETA PLANT", isRequired: false },
];

export async function onCreate({ rows, definerID, client }) {
  const number = rows[0].number;
  const date = rows[0].date.substring(0, 10);

  // console.log({ dateinOnCreate: date });

  const aggregatedShippingItems = rows.map((row) => {
    const { itemNumber, materialID, materialEntityRelationID, poID } = row;
    return {
      itemNumber,
      materialID,
      materialEntityRelationID,
      poID,
      qty: row["OPEN QUANTITY"],
    };
  });

  const values = {
    date,
    number,
    definerID,
    aggregatedShippingItems,
  };
  await onCreateShippings({ client, values, type: "OUTGOING" });

  const meta = {
    date,
    shippingNumber: number,
    hknVendorCode: "20625219",
    vendorPhoneNumber: "021-50595180",
    customerName: "博朗(上海)有限公司",
    to: "steven.lu@hankeno.com",
    vendorAlias: "SHJH",
    vendorName: "上海汉肯诺电子科技有限公司",
    driver: "司威峰",
    driverPhoneNumber: "18717751966",
  };
  const { data } = await client.query({
    query: gql(ShippingByNumber),
    variables: { number },
  });

  const shippingItems = data?.ShippingByNumber?.items?.map((item, i) => {
    
    let poNumber = item?.po?.no;

    if (poNumber.includes("-")) {
      poNumber = poNumber.split("-")[1];
    }

    return {
      id: item?.id,
      poNumber,
      itemNumber: item?.itemNumber,
      qty: item?.qty,
      mpn: item?.material?.mpn,
      mfr: item?.material?.mfr,
      materialID: item?.material?.id,
      specifications: item?.material?.specifications,
      code: item?.materialEntityRelation?.code,
      palletQty: item?.qty,
      packageQty: item?.qty,
      remainder: 0,
      number: item?.itemNumber * 1 === 1 ? number : "",
    };
  });

  shippingItems.sort((a, b) => (a.itemNumber * 1 > b.itemNumber * 1 ? 1 : -1));

  await saveShippingList({ data: { meta, items: shippingItems } });

  await sendCOAPreview({ shippingItems, date });
}

export async function validator({ rows, definerID, client }) {
  let date = rows[0]["SUPPLIER DELIV.DATE"] || rows[0]["ETA PLANT"];

  date = new Date(date);

  const formattedDate = date.toLocaleString("zh-CN", {
    timeZone: "Asia/Shanghai",
  });

  const numbers = formattedDate.split("/");

  const year = numbers[0];
  const month = numbers[1]?.padStart(2, 0);
  const day = numbers[2].split(" ")[0]?.padStart(2, 0);

  let serial = 1;
  const number = `SHJH${year}${month}${day}0${serial}`;

  let newarr = await Promise.all(
    rows?.map(async (row) => {
      let ERROR = [];
      const { MATERIAL, DOCUMENT } = row;
      const qty = row["OPEN QUANTITY"] * 1;

      if (DOCUMENT) {
        const PONumber = `A6P-${DOCUMENT}`;
        const { data } = await client.query({
          query: gql(POByNumber),
          variables: { number: PONumber, itemNumber: { eq: "00010" } },
        });
        const poFound = data?.POByNumber?.items?.[0];

        // console.log({ poFound })

        if (!poFound) {
          ERROR.push({
            attr: "DOCUMENT",
            isMajor: true,
            msg: "PO not found",
          });
          return { ...row, ERROR };
        }

        const {
          id,
          materialID,
          materialEntityRelationID,
          materialEntityRelation,
          openQty,
        } = poFound;

        if (materialEntityRelation.code !== MATERIAL) {
          ERROR.push({
            attr: "MATERIAL",
            isMajor: true,
            msg: "PO AND MATERIAL DO NOT MATCH",
          });
          return { ...row, ERROR };
        }

        if (openQty < qty) {
          ERROR.push({
            attr: "OPEN QUANTITY",
            isMajor: true,
            msg: "PO OPEN QUANTITY IS LESS THAN SHIPPING QTY",
          });
          return { ...row, ERROR };
        }
        row.poID = id;
        row.materialID = materialID;
        row.materialEntityRelationID = materialEntityRelationID;
        row.poOpenQty = openQty;
        row.qty = qty;
        row.PONumber = PONumber;
        row.number = number;
        row.date = date;
      }

      return { ...row, ERROR };
    })
  );

  newarr = mergeArrayAndCombineQty(newarr, ["poID"], ["qty"])
    .sort((a, b) => (a.PONumber > b.PONumber ? -1 : 1))
    .sort((a, b) => (a.MATERIAL > b.MATERIAL ? 1 : -1))
    ?.map((x, i) => ({
      ...x,
      // itemNumber: ((i + 1) * 10).toString().padStart(5, '0'),
      itemNumber: i + 1,
    }));

  return newarr;
}

async function saveShippingList({ data }) {
  const { buffer, filename } = await generateShippingListBuffer({ data });
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  saveAs(blob, filename);
}

async function generateShippingListBuffer({ data }) {
  const { items, meta } = data || {};

  const {
    date,
    hknVendorCode,
    shippingNumber,
    customerName,
    vendorAlias,
    vendorName,
    driver,
    driverPhoneNumber,
  } = meta || {};

  items.sort((a, b) => (a.itemNumber * 1 > b.itemNumber * 1 ? 1 : -1));

  const workbook = new Excel.Workbook();

  const sheet = workbook.addWorksheet(`page ${1}`, {
    pageSetup: {
      paperSize: 9,
      margins: {
        left: 0.4,
        right: 0.4,
        top: 0.4,
        bottom: 0.4,
        header: 0,
        footer: 0,
      },
      orientation: "landscape",
      horizontalCentered: true,
      fitToPage: false,
      // blackAndWhite: true,
    },
    properties: {
      defaultRowHeight: 15,
    },
  });

  const rows = items.map((x) => [
    x.itemNumber,
    x.poNumber,
    "",
    x.code,
    x.mpn,
    "",
    "",
    x.palletQty,
    x.packageQty,
    x.remainder,
    x.qty,
    x.number,
    "",
  ]);

  const borderedRow = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
  ];

  sheet.columns = [
    { key: "itemNumber", width: 5 },
    { key: "poNumber", width: 18 },
    { key: "workingOrderNumber", width: 6 },
    { key: "code", width: 9 },
    { key: "mpn", width: 18 },
    { key: "batch", width: 11 },
    { key: "datecode", width: 7 },
    { key: "palletQty", width: 9 },
    { key: "packageQty", width: 9 },
    { key: "remainder", width: 8 },
    { key: "qty", width: 9 },
    { key: "number", width: 14 },
    { key: "note", width: 8 },
  ];

  sheet.addRow([`${vendorName}送货单`]);
  sheet.getRow(1).font = { size: 14 };
  sheet.getRow(1).height = 20;
  sheet.getRow(1).alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  sheet.mergeCells("A1: M1");
  sheet.addRow(["", "CM No:", "", "", "", "", "", "", "", "", "", ""]);

  sheet.getRow(2).font = { size: 10 };
  sheet.addRow(
    [
      "",
      "SAP供应商号码:",
      hknVendorCode,
      null,
      "",
      "供应商缩写: ",
      vendorAlias,
      null,
      "",
      "",
      "",
      "",
    ],
    "i"
  );

  sheet.addRow(
    [
      "",
      "From (来自): ",
      vendorName,
      null,
      null,
      "To (发送至): ",
      customerName,
      null,
      null,
      "",
      "",
      "",
    ],
    "i"
  );
  sheet.addRow(
    ["", "发货日期:", date, null, "", "到货日期", date, null, "", "", "", ""],
    "i"
  );
  sheet.addRow(
    [
      "",
      "申请人: ",
      driver,
      null,
      "",
      "申请人联系方式",
      driverPhoneNumber,
      null,
      "",
      "",
      "",
      "",
    ],
    "i"
  );
  sheet.addRow(
    ["", "收货人签名: ", "", "", "", "", "", "", "", "", "", ""],
    "i"
  );
  sheet.addRow(["", "实收日期: ", "", "", "", "", "", "", "", "", "", ""], "i");
  sheet.addRow(["", "备注: ", "", "", "", "", "", "", "", "", "", ""], "i");

  sheet.addRow();
  sheet.addRow();
  sheet.addRow();
  sheet.addRow([
    "项目号",
    "订单号",
    "工单号",
    "物料编号",
    "产品描述",
    "批次",
    "制造日期",
    "每箱(板)数量",
    "整箱(板)数量",
    "尾数",
    "总数量",
    "BOL(单号)",
    "备注",
  ]);

  sheet.getRow(13).font = { size: 8 };
  sheet.getRow(13).height = 12;
  sheet.getRow(13).alignment = {
    vertical: "middle",
    horizontal: "left",
  };

  borderedRow.forEach((x) => {
    sheet.getCell(`${x}13`).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  });

  // sheet.addRow(["", "", "", "", "", "", "", "", "", "", "", "", ""], "i");

  sheet.addRows(rows, "i");

  if (items.length < 14)
    sheet.addRows(
      Array(14 - items.length)
        .fill()
        .map((y) => ["", "", "", "", "", "", "", "", "", "", "", "", ""]),
      "i"
    );

  const buffer = await workbook.xlsx.writeBuffer();
  const filename = `${shippingNumber}.xls`;

  return { buffer, filename };
}

async function sendCOAPreview({ shippingItems, date }) {
  let items = mergeArrayAndCombineQty(shippingItems, ["materialID"], ["qty"]);
  const formID = Date.now().toString().slice(1, 9);
  items.forEach((x, i) => {
    if (!x.specifications || x.specifications?.length === 0) {
      return alert(`${x.code} spec not found`);
    }
    x.formID = `${formID}-${i + 1}`;
  });

  items = items.map((x) => ({
    formID: x.formID,
    specifications: x.specifications,
    qty: x.qty,
    mfr: x.mfr,
    mpn: x.mpn,
    code: x.code,
  }));
  const meta = {
    date,
    vendorPhoneNumber: "+86-21-50595180",
    to: null,
    cc: null,
    bcc: "steven.lu@hankeno.com",
    isPreview: true,
    vendorName: "SHANGHAI HANKENO ELECTRONICS TECHNOLOGY CO LTD",
    vendorAddress:
      "3/F, BLDG 2, CHANGZHENG INDUSTRIAL PARK, ALLEY 1225, TONGPU RD, PUTUO DISTRICT, SHANGHAI, PRC",
    inspectedBy: "TONY LIU",
    verifiedBy: "JAMES LEUNG",
  };
  try {
    const res = await API.post("iSupplyAPI", "/restricted", {
      body: {
        operation: "SEND_COA",
        data: {
          meta,
          items,
        },
      },
    });
    console.log({ res });
  } catch (err) {
    console.log({ error3333: err });
  }
}
