import { useEffect, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import {
  FormikField,
  FormikAutocomplete,
} from "../../../../../component/formikForm";
import {
  invoiceByType,
  // getEntityUserRelation,
  // POByCustomerAndOpenQty,
} from "./query";
import mergeArrayAndCombineQty from "../../../../../lib/mergeArrayAndCombineQty";
import { vatRate } from "../../../../../settings/formFieldEnum";

export default function Step0ChooseInvoices({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  setErrors,
  setStatus,
}) {
  // const { step } = values;

  const { definerID, customer, invoices, invoiceItems } = values;

  const { data } = useQuery(gql(invoiceByType), {
    variables: {
      type: "OUTGOING",
      definerID: { eq: definerID },
      filter: { paymentStatus: { ne: "PAID" } },
      limit: 5000,
    },
    skip: !definerID,
  });

  const openInvoices = data?.invoiceByType?.items;

  const customerOptions = useMemo(
    () => [...new Set(openInvoices?.map((x) => x.po?.customer))],
    [openInvoices]
  );

  const invoiceOptions = useMemo(() => {
    if (!customer?.id) return [];

    return openInvoices
      ?.filter((x) => x.po?.customer?.id === customer?.id)
      ?.map((x) => ({ ...x, amount: (x.price * x.qty).toFixed(3) * 1 }));
  }, [openInvoices, customer?.id]);

  const mergedInvoices = useMemo(() => {
    return mergeArrayAndCombineQty(invoiceOptions, ["number"], ["amount"]);
  }, [invoiceOptions]);

  const totalSelectedAmount = useMemo(
    () => invoices?.reduce((a, v) => a + v.amount, 0),
    [invoices]
  );

  // useEffect(() => {
  //   setFieldValue("invoiceItems", invoiceItems);
  // }, [invoiceItems, setFieldValue]);

  // const customerOptions = useMemo(
  //   () =>
  //     invoices?.map((x) => ({
  //       name: x.entity1.abbr,
  //       id: x.entity1.id,
  //     })),
  //   [invoices]
  // );

  return (
    <Stack width={1600} spacing={2}>
      <Stack direction="row" spacing={5} alignItems="flex-end">
        <FormikField
          name="date"
          label="Date"
          type="date"
          width={180}
          required
        />

        <FormikAutocomplete
          name="customer"
          label="Customer"
          options={customerOptions}
          getOptionLabel={(option) => option?.abbr || 'UNKNOWN'}
          required
          getPrimaryText={(option) => option?.abbr || 'UNKNOWN'}
          // getSecondaryText={(option) => option.contactName}
          listItemPrimaryField={null}
          listItemSecondaryField={null}
          disabled={false}
          width={300}
          onChange={() => {
            // setFieldValue("shippingItems", [{ findBy: "code" }]);
            setFieldValue("invoices", null);
            // setFieldValue("incoTerms", null);
          }}
          getOptionDisabled={null}
        />
      </Stack>
      <FormikAutocomplete
        disabled={!customer}
        name="invoices"
        label="Invoices"
        multiple
        options={mergedInvoices}
        getOptionLabel={(option) => `${option?.number} - ${option?.amount}`}
        required
        getPrimaryText={(option) => option?.number}
        getSecondaryText={(option) => option?.amount}
        listItemPrimaryField={null}
        listItemSecondaryField={null}
        width={900}
        onChange={(e, v) => {
          const items = invoiceOptions
            .filter((x) => v.find((y) => y.number === x.number))
            ?.map((x) => {
              const existingItem = invoiceItems?.find((y) => y.id === x.id);
              return { ...x, paidAmount: existingItem?.paidAmount || x.amount };
            });
          // console.log({ v, invoiceItems });

          // debugger

          setFieldValue("invoiceItems", items);
          // setFieldValue("shippingItems", [{ findBy: "code" }]);
          // setFieldValue("vendor", null);
          // setFieldValue("incoTerms", null);
        }}
        getOptionDisabled={null}
      />

      <Typography>Selected Invoices: {totalSelectedAmount}</Typography>

      {invoices?.[0]?.currency === "CNY" && (
        <Typography>
          With VAT: {(totalSelectedAmount * (1 + vatRate))?.toFixed(3) * 1}
        </Typography>
      )}
    </Stack>
  );
}
