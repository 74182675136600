import Profile from "./Profile";

const profile = {
  path: "profile",
  userMenu: {
    group: 1,
    order: 10,
    label: "Profile",
    entityType: "all",
    // path: "profile",
    allowedRole: "all",
    auth: ["authenticated"],
  },
  route: {
    index: {
      element: <Profile />,
      allowedRole: "all",
      entityType: "all",
      auth: ["authenticated"],
    },
  },
};

export default profile;
