import { Stack } from "@mui/material";
import {
  FormikField,
  FormikSelect,
  FormikArrayRemoveItemButton,
} from "../../../../../../component/formikForm";
import { userEntityRole } from "../../../../../../settings/formFieldEnum";

export default function ContactItemForEntityForm({
  index,
  remove,
  arrayFieldName,
  removeBtnColor,
  size,
}) {
  return (
    <Stack direction="row" spacing={5} alignItems="flex-end">
      <Stack>
        <Stack direction="row" spacing={2} alignItems="flex-end">
          <FormikField
            required
            label="First Name"
            name={`[${arrayFieldName}][${index}][fn]`}
            width={180}
          />
          <FormikField
            required
            label="Last Name"
            name={`[${arrayFieldName}][${index}][ln]`}
            width={180}
          />
          <FormikField
            required
            label="Email"
            name={`[${arrayFieldName}][${index}][email]`}
            type="email"
          />
          <FormikField
            label="Title"
            name={`[${arrayFieldName}][${index}][title]`}
            width={180}
          />
          <FormikSelect
            required
            label="Role"
            name={`[${arrayFieldName}][${index}][role]`}
            multiple
            options={userEntityRole}
          />
        </Stack>
        <Stack direction="row" spacing={2} alignItems="flex-end">
          <FormikField
            required
            label="phone Number"
            name={`[${arrayFieldName}][${index}][phoneNumber]`}
          />
          <FormikField
            label="Address"
            name={`[${arrayFieldName}][${index}][address]`}
            width={180}
          />
        </Stack>
      </Stack>

      <FormikArrayRemoveItemButton
        onClick={() => remove(index)}
        removeBtnColor={removeBtnColor}
        size={size}
      />
    </Stack>
  );
}
