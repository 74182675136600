import { Stack, Typography, Button } from '@mui/material'
import * as Yup from 'yup'
import FormikForm, {
  FormikField,
  FormikRadioGroup,
  FormikSelect,
} from '../../../../component/formikForm'
import onUpdate from '../onUpdate'
import IncoTerms from '../../entities/components/IncoTerms'
import {
  entityTypeSelection,
  purePaymentTerms as paymentTermsOptions,
} from '../../../../settings/formFieldEnum'

const FormArrayForCreateVendorOrCustomer = [
  {
    Item: IncoTerms,
    name: 'incoTerms',
    // width: 1200,
    bgcolor: '#e3f2fd',
    btnColor: '#3f51b5',
    size: 'md',
    hideLabel: false,
  },
]

const validationSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Too Short').required('Please enter company name'),
  alias: Yup.string()
    .min(2, 'Too Short')
    .required('Please enter company alias'),
  type: Yup.string().required('Please select type'),
})

export default function Item({ data, onClose }) {
  const onSubmit = async ({ values, client }) => {
    await onUpdate({
      values,
      client,
    })
    onClose && onClose()
  }

  return (
    <FormikForm
      initialValues={{ ...data, isEditing: false }}
      validationSchema={validationSchema}
      FormMainFields={EditFields}
      FormArray={FormArrayForCreateVendorOrCustomer}
      handleCancel={({ setFieldValue, setValues }) => {
        setValues(data)
        setFieldValue('isEditing', false)
      }}
      saveButtonLabel='update'
      onSubmit={onSubmit}
    />
  )
}

function EditFields({ values }) {
  const { isEditing } = values || {}
  return (
    <Stack spacing={3}>
      <Stack direction='row' spacing={2}>
        <FormikField name='name' label='Name' required disabled={!isEditing} />
        <FormikField
          name='alias'
          label='Alias'
          required
          disabled={!isEditing}
        />
        <FormikRadioGroup
          row
          name='type'
          label='Type'
          selections={entityTypeSelection.map((x) => ({
            ...x,
            label: `to ${x.label}`,
          }))}
          disabled={!isEditing}
        />
      </Stack>

      <FormikSelect
        label='PaymentTerms'
        name='paymentTerms'
        multiple
        width={600}
        options={paymentTermsOptions}
        required
        disabled={!isEditing}
        // labelKey="label"
        // valueKey="value"
      />
    </Stack>
  )
}
