import { useApolloClient } from '@apollo/client'
import * as Yup from 'yup'
import { Stack } from '@mui/material'
import FormikForm, {
  FormikField,
  FormikAutocomplete,
} from '../../../../component/formikForm'
import onDeleteMaterial from '../onDelete'
import onUpdate from '../onUpdate'
import { countries } from '../../../../settings/formFieldEnum'

const validationSchema = Yup.object().shape({
  mfr: Yup.string().min(2, 'Too Short'),
  mpn: Yup.string().min(2, 'Too Short'),
  desc: Yup.string().min(2, 'Too Short').nullable(),
})

export default function Item({ data, onClose }) {
  const client = useApolloClient()

  const onDeleteBtnClick = async () => {
    if (window.confirm('Are you sure you wish to delete this item?')) {
      await onDeleteMaterial({ values: data, client })
      onClose()
    }
  }

  const onSubmit = async ({ values, client }) => {
    await onUpdate({
      values,
      client,
    })
    onClose && onClose()
  }

  return (
    <FormikForm
      initialValues={{ ...data, isEditing: false }}
      validationSchema={validationSchema}
      FormMainFields={EditFields}
      FormArray={null}
      // handleCancel={onClose}
      handleCancel={({ setFieldValue, setValues }) => {
        setValues(data)
        setFieldValue('isEditing', false)
      }}
      saveButtonLabel='update'
      onSubmit={onSubmit}
      onDelete={onDeleteBtnClick}
    />
  )
}

const EditFields = ({ values }) => {
  const { isEditing } = values || []
  return (
    <Stack spacing={3} justifyContent='flex-start' alignItems='flex-start'>
      <Stack direction='row' spacing={3}>
        <FormikField
          name='mfr'
          label='MFR'
          required
          disabled={!isEditing}
          width={120}
        />
        <FormikField name='mpn' label='MPN' required disabled={!isEditing} />
      </Stack>
      <Stack direction='row' spacing={3}>
        <FormikField name='desc' label='Description' disabled={!isEditing} />
        <FormikField
          name='unit'
          label='Unit'
          disabled={!isEditing}
          width={80}
        />
        <FormikField name='mpq' label='MPQ' disabled={!isEditing} width={80} />
        <FormikField
          name='importDutyCN'
          label='ImportDutyCN'
          type='number'
          width={120}
          disabled={!isEditing}
        />
        <FormikField
          name='inventory'
          label='Inventory'
          type='number'
          width={120}
          disabled={!isEditing}
        />
      </Stack>
      <FormikAutocomplete
        disabled={!isEditing}
        label='COO'
        name='coo'
        multiple
        options={countries}
        getOptionLabel={(option) => option.label}
        listItemPrimaryField='label'
        isOptionEqualToValue={(option, value) => {
          // console.log('value', value)
          // debugger
          return (
            option?.label?.toUpperCase() === value?.label?.toUpperCase() ||
            (value !== null &&
              typeof value !== 'object' &&
              option?.label?.toUpperCase() === value?.toUpperCase())
          )
        }}
      />
    </Stack>
  )
}
