export const entitiesByDefiner = /* GraphQL */ `
  query EntitiesByDefiner(
    $definerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEntityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entitiesByDefiner(
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isVerified
        name
        alias
        type
        businessType
        statusCode
        paymentTerms
        incoTerms {
          terms
          entityUserRelationID
        }
        groupToRead
        groupToEdit
        groupToDelete
        definerID
        definer {
          id
          name
          alias

        }
        relationsAsEntity2 {
          items {
            id
            relation
            type
            entity1 {
              id
              name
              alias
              abbr @client

            }
          }
        }
        relationsAsEntity1 {
          items {
            id
            relation
            type
            entity2 {
              id
              name
              alias
              abbr @client

            }
          }
        }

        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getEntity = /* GraphQL */ `
  query GetEntity($id: ID!) {
    getEntity(id: $id) {
      id
      isVerified
      name
      alias
      type
      businessType
      statusCode
      definerID
      address
      phoneNumber
      paymentTerms
      incoTerms {
        terms
        condition
        entityUserRelationID
      }
      definer {
        id
        name
        alias
      }

      relationsAsEntity1 {
        items {
          id
          relation
          minCharge
          serviceFeeRate
          logisticsTrackingTemplate
          logisticsAccountNumber
          entity2ID
          entity1 {
            id
            name
            alias
            abbr @client
          }
          entity2 {
            id
            name
            alias
            abbr @client
          }
          incoTerms {
            terms
            condition
            entityUserRelationID
          }
          paymentTerms
          vendorCode
          type
        }
      }
      relationsAsEntity2 {
        items {
          id
          relation
          minCharge
          serviceFeeRate
          logisticsTrackingTemplate
          logisticsAccountNumber
          type
          entity1 {
            id
            name
            alias
            abbr @client

          }
          entity2 {
            id
            name
            alias
            abbr @client

          }
          vendorCode
          customerCode
          incoTerms {
            terms
            condition
            entityUserRelationID
          }
          paymentTerms
        }
      }
      statusCode
      createdAt
      updatedAt
      billingInfo {
        billingName
        regNumber
        taxID
        address
        phoneNumber
        bank
        accountNumber
        swiftCode
        bankAddress
        routingNumber
        iban
        entityUserRelationID
      }
      __typename
    }
  }
`;

export const createEntity = /* GraphQL */ `
  mutation CreateEntity(
    $input: CreateEntityInput!
    $condition: ModelEntityConditionInput
  ) {
    createEntity(input: $input, condition: $condition) {
      id
      name
      alias
      statusCode
      type
      businessType
      address
      phoneNumber
    }
  }
`;

export const updateEntity = /* GraphQL */ `
  mutation UpdateEntity(
    $input: UpdateEntityInput!
    $condition: ModelEntityConditionInput
  ) {
    updateEntity(input: $input, condition: $condition) {
      id
      type
      businessType
      isVerified
      address
      phoneNumber
      name
      alias
      statusCode
      updatedAt
      paymentTerms
      incoTerms {
        terms
        condition
        entityUserRelationID
      }
      billingInfo {
        billingName
        regNumber
        phoneNumber
        taxID
        address
        bank
        accountNumber
        swiftCode
        bankAddress
        routingNumber
        iban
        entityUserRelationID
      }
    }
  }
`;

export const deleteEntity = /* GraphQL */ `
  mutation DeleteEntity(
    $input: DeleteEntityInput!
    $condition: ModelEntityConditionInput
  ) {
    deleteEntity(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createEntityRelations = /* GraphQL */ `
  mutation CreateEntityRelations(
    $input: CreateEntityRelationsInput!
    $condition: ModelEntityRelationsConditionInput
  ) {
    createEntityRelations(input: $input, condition: $condition) {
      id
      relation
      type
      definerID
      entity1ID
      entity2ID
      alias
      customerCode
      vendorCode
      statusCode
      incoTerms {
        terms
      }
      paymentTerms
      serviceFeeRate
      minCharge
      groupToRead
      groupToEdit
      groupToDelete
      createdAt
      updatedAt
    }
  }
`;

export const getEntityRelations = /* GraphQL */ `
  query GetEntityRelations($id: ID!) {
    getEntityRelations(id: $id) {
      id
      relation
      type
      serviceFeeRate
      minCharge
      entity1ID
      entity2ID
      entity2 {
        id
        name
        alias
        type
        statusCode
        isVerified
        createdAt
        updatedAt
      }
      alias
      customerCode
      vendorCode
      statusCode
      incoTerms {
        terms
      }
      paymentTerms
      groupToRead
      groupToEdit
      groupToDelete
      createdAt
      updatedAt
    }
  }
`;

export const deleteEntityRelations = /* GraphQL */ `
  mutation DeleteEntityRelations(
    $input: DeleteEntityRelationsInput!
    $condition: ModelEntityRelationsConditionInput
  ) {
    deleteEntityRelations(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateEntityRelations = /* GraphQL */ `
  mutation UpdateEntityRelations(
    $input: UpdateEntityRelationsInput!
    $condition: ModelEntityRelationsConditionInput
  ) {
    updateEntityRelations(input: $input, condition: $condition) {
      id
      relation
      type
      serviceFeeRate
      minCharge
      entity1ID

      entity2ID

      alias
      customerCode
      vendorCode
      statusCode
      incoTerms {
        terms
      }
      paymentTerms
      groupToRead
      groupToEdit
      groupToDelete
      createdAt
      updatedAt
    }
  }
`;

export const relationsByDefiner = /* GraphQL */ `
  query RelationsByDefiner(
    $definerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEntityRelationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationsByDefiner(
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        relation
        serviceFeeRate
        minCharge
        definerID
        entity1ID
        entity2ID
        entity2 {
          id
          name
          alias
          businessType
          statusCode
          isVerified
        }
        alias

        customerCode
        vendorCode
        statusCode
        type
        incoTerms {
          terms
        }
        paymentTerms
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const relationsByEntity2 = /* GraphQL */ `
  query RelationsByEntity2(
    $entity2ID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEntityRelationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationsByEntity2(
      entity2ID: $entity2ID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        relation
        definerID
        entity1ID
        entity2ID
        serviceFeeRate
        minCharge
        alias
        customerCode
        vendorCode
        statusCode
        type
        paymentTerms
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const entitiyUserRelationByEntityID = /* GraphQL */ `
  query EntitiyUserRelationByEntityID(
    $entityID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEntityUserRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entitiyUserRelationByEntityID(
      entityID: $entityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id

        entityID
      }
      nextToken
      __typename
    }
  }
`;

export const deleteEntityUserRelation = /* GraphQL */ `
  mutation DeleteEntityUserRelation(
    $input: DeleteEntityUserRelationInput!
    $condition: ModelEntityUserRelationConditionInput
  ) {
    deleteEntityUserRelation(input: $input, condition: $condition) {
      id

      __typename
    }
  }
`;

export const materialEntityRelationByEntityAndStatusCode = /* GraphQL */ `
  query MaterialEntityRelationByEntityAndStatusCode(
    $entityID: ID!
    $statusCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMaterialEntityRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    materialEntityRelationByEntityAndStatusCode(
      entityID: $entityID
      statusCode: $statusCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityID
        materialID
        code
        statusCode
        desc
        category
        pdt
        inventoryAlignment
        groupToRead
        groupToEdit
        groupToDelete
        definerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const deleteMaterialEntityRelation = /* GraphQL */ `
  mutation DeleteMaterialEntityRelation(
    $input: DeleteMaterialEntityRelationInput!
    $condition: ModelMaterialEntityRelationConditionInput
  ) {
    deleteMaterialEntityRelation(input: $input, condition: $condition) {
      id

      __typename
    }
  }
`;

export const relationsByEntity1 = /* GraphQL */ `
  query RelationsByEntity1(
    $entity1ID: ID!
    $entity2ID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityRelationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationsByEntity1(
      entity1ID: $entity1ID
      entity2ID: $entity2ID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id

        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const contactsByDefiner = /* GraphQL */ `
  query ContactsByDefiner(
    $definerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactsByDefiner(
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        email
        statusCode
        fn
        mn
        ln
        name @client
        entityUserRelations {
          items {
            id
            entity {
              id
              name
              alias
              abbr @client
            }
          }
        }
        phoneNumber
        address
        groupToRead
        groupToEdit
        groupToDelete
        owner
        definerID
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const createMailEntityRelation = /* GraphQL */ `
  mutation CreateMailEntityRelation(
    $input: CreateMailEntityRelationInput!
    $condition: ModelMailEntityRelationConditionInput
  ) {
    createMailEntityRelation(input: $input, condition: $condition) {
      id
      type
      userID
      user {
        id
        sub
        email
        statusCode
        fn
        mn
        ln
        phoneNumber
        address
        groupToRead
        groupToEdit
        groupToDelete
        owner
        definerID
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      entityRelationID
      entityRelation {
        id
        relation
        definerID
        entity1ID
        entity2ID
        alias
        customerCode
        vendorCode
        statusCode
        type
        paymentTerms
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const mailEntityRelationByEntityRelation = /* GraphQL */ `
  query MailEntityRelationByEntityRelation(
    $entityRelationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMailEntityRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mailEntityRelationByEntityRelation(
      entityRelationID: $entityRelationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        userID
        user {
          id
          fn
          ln
          mn
          name @client
        }
        entityRelationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const deleteMailEntityRelation = /* GraphQL */ `
  mutation DeleteMailEntityRelation(
    $input: DeleteMailEntityRelationInput!
    $condition: ModelMailEntityRelationConditionInput
  ) {
    deleteMailEntityRelation(input: $input, condition: $condition) {
      id
      type
      userID

      createdAt
      updatedAt
      __typename
    }
  }
`;
