import publicModules from "../modules/publicModules";
import systemAdminModules from "../modules/systemAdminModules";
import externalPartnerModules from "../modules/externalPartnerModules";
import entityModules from "../modules/entityModules";

export const topNav = [
  {
    label: "Admin Console",
    order: 1,
    entityType: "all",
    path: "admin-console",
    allowedRole: ["systemAdmin"],
    auth: ["authenticated"],
  },
];

export default function getTopNav({ entityType, userRole, isAuthenticated }) {
  const auth = !!isAuthenticated ? "authenticated" : "unAuthenticated";

  // console.log({ entityModules, systemAdminModules, publicModules });
  // console.log({ entityType, userRole, isAuthenticated });

  // // if (!userRole || !entityType) return;

  const retrivedModules = [
    ...systemAdminModules,
    ...publicModules,
    ...externalPartnerModules,
    ...entityModules,
  ]
    .filter((x) => !!x.topNav)
    .map((x) => ({ ...x.topNav, path: x.path }));

  const nav = topNav
    .concat(retrivedModules)
    .filter(
      (x) =>
        (x.allowedRole === "all" ||
          x.allowedRole?.some((y) => userRole?.includes(y))) &&
        (x.entityType === "all" ||
          x.entityType?.some((z) => entityType === z)) &&
        (x.auth === "all" || x.auth?.some((a) => a === auth))
    );

  nav.sort((a, b) => (a.order > b.order ? 1 : -1));

  return nav;
}
