import { Auth } from "aws-amplify";
import { ApolloLink, createHttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import awsconfig from "../aws-exports";
import emitSnackbar from "../function/emitSnackbar";

const url = awsconfig.aws_appsync_graphqlEndpoint;
const region = awsconfig.aws_appsync_region;

const auth = {
  type: awsconfig.aws_appsync_authenticationType,
  // type: "AWS_IAM",

  jwtToken: async () => {
    try {
      const { signInUserSession } = await Auth.currentAuthenticatedUser();
      return signInUserSession?.idToken?.jwtToken;
    } catch (e) {
      console.log("A0232", e);
    }
  },
};
const errorLink = onError(
  ({ graphQLErrors, networkError, response = {}, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(
          `[GraphQL error88888888888]: Message: ${message}, Location: ${JSON.stringify(
            locations
          )}, Path: ${path}`
        );

        console.log("operation", operation);
        emitSnackbar({ severity: "error", message, duration: 5000 });

        let showMessage;

        if (message?.toLowerCase().startsWith("conflict")) {
          showMessage = "Some terms has been changed while you are editing";

          console.error(showMessage);
        }
      });
    }
    if (networkError) {
      emitSnackbar({
        severity: "error",
        message: JSON.stringify(networkError),
        duration: 5000,
      });

      console.log(
        `[Network error99999999999]: ${JSON.stringify(networkError)}`
      );
    }

    // response.errors = null;
  }
);

const httpLink = createHttpLink({ uri: url });

const addNoteEditorLink = new ApolloLink(async (operation, forward) => {
  if (operation.operationName === "CreateNote") {
    const {
      attributes: { email },
    } = await Auth.currentAuthenticatedUser();

    operation.variables.input.createdBy = email;
  }

  return forward(operation);

  // operation.setContext({ start: new Date() })
  // return forward(operation).map((data) => {
  // Called after server responds
  // console.log('dataaaaaaaaa', data)
  // const context = operation.getContext()
  // const response = context.response
  // console.log('context,', context)
  // console.log('response,', response)

  // const time = new Date() - operation.getContext().start
  // console.log(
  //   `Operation ${operation.operationName} took ${time} ms to complete`,
  // )
  // return data
  // })
});
const updateEditorLink = new ApolloLink(async (operation, forward) => {
  const {
    attributes: { email },
  } = await Auth.currentAuthenticatedUser();

  if (operation.operationName === "CreateUpdate") {
    operation.variables.input.createdBy = email;
  }

  if (
    // operation.operationName?.toLowerCase().startsWith("update") ||
    operation.operationName?.toLowerCase().startsWith("delete")
  ) {
    // alert(
    //   "You don't have permission for this operation, if you believe this is a mistake, please contact the administrator"
    // );

    if (email !== "steven.lu@hankeno.com") {
      throw new Error(
        "You don't have permission for this operation, if you believe this is a mistake, please contact the administrator"
      );
    }
  }

  return forward(operation);

  // operation.setContext({ start: new Date() })
  // return forward(operation).map((data) => {
  // Called after server responds
  // console.log('dataaaaaaaaa', data)
  // const context = operation.getContext()
  // const response = context.response
  // console.log('context,', context)
  // console.log('response,', response)

  // const time = new Date() - operation.getContext().start
  // console.log(
  //   `Operation ${operation.operationName} took ${time} ms to complete`,
  // )
  // return data
  // })
});

const testLink = new ApolloLink(async (operation, forward) => {
  operation.setContext({ start: new Date() });

  return forward(operation).map((data) => {
    // ALERT USER OF NEXT TOKEN
    function loopData(data) {
      if (data.nextToken) {
        // alert(`nextToken in ${operation.operationName}`);
        console.info(
          `message from the developer: nextToken in ${operation.operationName}`
        );
      } else {
        Object.keys(data).forEach((key) => {
          if (data[key] && typeof data[key] === "object") {
            loopData(data[key]);
          }
        });
      }
    }

    loopData(data);

    // console.log({ dataInLink: data });
    // console.log('dataaaaaaaaa', data)
    // const context = operation.getContext()
    // const response = context.response
    // console.log('context,', context)
    // console.log('response,', response)

    const time = new Date() - operation.getContext().start;
    console.log(
      `Operation ${operation.operationName} took ${time} ms to complete`
    );
    return data;
  });
});

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key, value) =>
      key === "__typename" ? undefined : value;

    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward(operation).map((data) => {
    return data;
  });
});

console.log("run link");

const maxQtyLink = new ApolloLink(async (operation, forward) => {
  if (!!operation.variables) {
    operation.variables.limit = 5000;
  }

  return forward(operation);
});

const link = ApolloLink.from([
  cleanTypeName,
  errorLink,
  createAuthLink({ url, region, auth }),
  addNoteEditorLink,
  updateEditorLink,
  maxQtyLink,
  testLink,
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
]);

export default link;
