import ETAForUnregisterdUser from "./component/ETA";

const eta = {
  path: "eta",

  // topNav: {
  //   order: 30,
  //   label: "RFQ",
  //   entityType: ["ebp"],
  //   allowedRole: "all",
  //   auth: ["unAuthenticated"],
  // },
  route: {
    index: {
      element: null,
      Comp: ETAForUnregisterdUser,
      allowedRole: ["public"],
      entityType: ["ebp"],
      auth: ["unAuthenticated"],
    },
  },
};

export default eta;
