// import { UnAuthLayout } from "../../../layout";

import { Typography } from "@mui/material";

const page404 = {
  path: "*",
  route: {
    "*": {
      element: (
        <Typography mt={15} variant="h3">
          404 page not found
        </Typography>
      ),
      allowedRole: "all",
      entityType: "all",
      auth: "all",
    },
  },
};

export default page404;
