import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { CircularProgress } from "@mui/material";

export default function ConfirmSignUpVerification({
  action,
  email,
  passcode,
  invitationCode,
}) {
  // console.log({ email, passcode });
  // debugger;
  const navigate = useNavigate();
  let triggerTime = 0;

  useEffect(() => {
    async function func() {
      if (email && passcode) {
        try {
          console.log("triggerTime", triggerTime);
          if (triggerTime === 0) {
            triggerTime++;

            console.log("in useEffect confirm signup");
            // console.log({ email, passcode, invitationCode });
            // debugger
            await Auth.confirmSignUp(email, passcode, {
              clientMetadata: { invitationCode },
            });
            action?.onSuccess();
          } else {
            return;
          }
        } catch (e) {
          action?.onFail(e);
        }
      } else {
        navigate("/", { replace: true });
      }
    }
    func();
  }, [triggerTime, navigate, invitationCode, passcode, action, email]);

  return <CircularProgress color="secondary" />;
}
