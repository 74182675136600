import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Stack, Box, Container } from "@mui/material";
import Notes from "../../../component/Notes";
import Updates from "../../../component/Updates";
import FileZone from "../../../component/FileZone";
import { getEntity } from "./query";
import Item from "./components/Item";
import { userEntityRelationVar } from "../../../client/cache";

function ItemView({ lineData, onClose }) {
  const { entityID } = useReactiveVar(userEntityRelationVar);

  let { data } = useQuery(gql(getEntity), {
    variables: { id: entityID },
  });
  data = data?.getEntity;

  if (!data) return null;

  const incoTerms = data?.incoTerms.map((x) => ({
    entityUserRelationID: x?.entityUserRelationID,
    condition: x?.condition,
    terms: x?.terms,
    entityUserRelation: { id: x.entityUserRelationID },
  }));

  data = { ...data, incoTerms, definerID: entityID };

  const storagePath = `${entityID}/entities/${lineData?.id}`;

  return (
    <Stack spacing={5}>
      <Item data={data} onClose={null} />
      {/* <Relation data={data} onClose={onClose} /> */}
      <FileZone storagePath={storagePath} />
      <Notes itemID={lineData?.id} />
      <Updates itemID={lineData?.id} />
    </Stack>
  );
}

function Comp({ entityType, userRole, isAuthenticated, entityID }) {
  //   const entityID = useParams().entityID || id;
  return (
    <Box
      sx={{
        // bgcolor: "red",
        display: "flex",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="lg">
        <ItemView lineData={{ id: entityID }} />
      </Container>
    </Box>
  );
}

const company_profile = {
  path: "company_profile",
  sideNav: {
    group: 1,
    order: 10,
    label: "Company Profile",
    entityType: "all",
    allowedRole: ["admin"],
    auth: ["authenticated"],
  },
  route: {
    index: {
      element: null,
      Comp,
      allowedRole: "all",
      entityType: "all",
      auth: ["authenticated"],
    },
  },
};

export default company_profile;
