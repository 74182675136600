import { onUpdate } from "../../../function/onUpdate";
import { getPO, updatePO } from "./query";

export default async function onUpdatePO({ values, client }) {
  let statusCode = values.statusCode;
  if (values.openQty === 0) {
    statusCode = "900";
  }

  const fieldsToUpdate = [
    { field: "date", createUpdate: false },
    { field: "number", createUpdate: false },
    { field: "itemNumber", createUpdate: false },
    { field: "priceCur", createUpdate: true },
    { field: "price", createUpdate: true },
    { field: "CRD", createUpdate: true },
    { field: "qty", createUpdate: true },
    { field: "openQty", createUpdate: true },
    { field: "materialEntityRelationID", createUpdate: false },
    { field: "materialID", createUpdate: true },
    { field: "statusCode", createUpdate: false },
    { field: "incoTerms", createUpdate: false },
  ];

  return await onUpdate({
    type: "PO",
    values: {
      ...values,
      statusCode,
      ...(values.incoTerms && {
        incoTerms: {
          terms: values.incoTerms?.terms,
          entityUserRelationID: values.incoTerms?.relationID,
        },
      }),
    },
    mutation: updatePO,
    query: getPO,
    getInitialValue: null,
    fieldsToUpdate,
    client,
    refetchQueries: null,
  });
}
