import { onUpdate } from "../../../function/onUpdate";
import { getOffer, updateOffer } from "./query";

export default async function onUpdateOffer({ values, client }) {
  const fieldsToUpdate = [
    { field: 'currency', createUpdate: true },
    { field: 'price', createUpdate: true },
    { field: 'moq', createUpdate: true },
    { field: 'validDays', createUpdate: false },
    { field: 'date', createUpdate: true },

    { field: 'leadtime', createUpdate: true },
    { field: 'statusCode', createUpdate: true },
    { field: 'note', createUpdate: false },
  ]

  await onUpdate({
    type: "Offer",
    values,
    mutation: updateOffer,
    query: getOffer,
    getInitialValue: null,
    fieldsToUpdate,
    client,
    refetchQueries: ["GetOffer"],
  });
}
