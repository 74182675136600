import { useMemo } from "react";
import { Stack } from "@mui/material";
import { gql, useQuery, useApolloClient } from "@apollo/client";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import FormikForm, {
  FormikField,
  FormikSelect,
  FormikAutocomplete,
} from "../../../../component/formikForm";
import { onCreatePO } from "../onCreate";
import { relationsByDefiner, MaterialEntityRelationsByDefiner } from "../query";
import POItem from "./Array/POItem";
import filterOutSameItemInArray from "../../../../lib/filterOutSameItemInArray";
import { useEffect } from "react";

const FormArray = [
  {
    Item: POItem,
    name: "POItems",
    // width: ,
    bgcolor: "#e3f2fd",
    btnColor: "#3f51b5",
    size: "md",
  },
];

const validationSchemaForCreate = Yup.object().shape({
  //   name: Yup.lazy((value) =>
  //     typeof value === "string"
  //       ? Yup.string()
  //           .required("Please enter  name")
  //           .min(2, "At least 2 characters")
  //           .max(50, "Maximum 50 characters")
  //           .required("Please enter of select  name")
  //       : Yup.object().required("Please enter  name")
  //   ),
  //   alias: Yup.string().min(2, "Too Short").max(20, "Too Long"),
  // });
});

export default function CreateForm() {
  const { state } = useLocation() || {};
  const { entityID } = useParams();

  const initialValues = {
    ...state,
    date: new Date().toISOString("en-US").slice(0, 10),
    definerID: entityID,
    POItems: [{ itemNumber: "00010", findBy: "code" }],
    isEditing: true,
  };

  const navigate = useNavigate();
  const onSubmit = async ({ values, client }) => {
    await onCreatePO({
      values,
      client,
    });

    navigate(-1);
  };
  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <FormikForm
      initialValues={initialValues}
      validationSchema={validationSchemaForCreate}
      onSubmit={onSubmit}
      FormMainFields={CreateFields}
      FormArray={FormArray}
      handleCancel={handleCancel}
    />
  );
}

const CreateFields = ({ values, setFieldValue }) => {
  const { definerID } = values;

  const client = useApolloClient();

  const { data: relationsByDefinerData } = useQuery(gql(relationsByDefiner), {
    variables: {
      definerID,
      relation: { eq: "CUSTOMER-SUPPLIER" },
    },
  });

  let customerOptions = useMemo(
    () =>
      relationsByDefinerData?.relationsByDefiner?.items?.map((x) => ({
        ...x.entity1,
        paymentTerms: x.paymentTerms,
        incoTerms: x.incoTerms,
        vendorCode: x.vendorCode,
      })),
    [relationsByDefinerData]
  );

  customerOptions = useMemo(
    () => filterOutSameItemInArray(customerOptions, "id"),
    [customerOptions]
  );

  console.log({ customerOptions });

  const vendorOptions = useMemo(
    () =>
      relationsByDefinerData?.relationsByDefiner?.items
        ?.filter((x) => x.entity1ID === values?.customer?.id)
        ?.map((x) => x.entity2),
    [relationsByDefinerData, values?.customer?.id]
  );
  // console.log({ vendorOptions });

  useEffect(() => {
    async function func() {
      if (values?.customer?.id) {
        let { data } = await client.query({
          query: gql(MaterialEntityRelationsByDefiner),
          variables: {
            definerID,
            entityID: { eq: values?.customer?.id },
            skip: !values?.customer?.id || !definerID,
            // filter: { relation: { eq: "CUSTOMER" } },
          },
        });

        data = data?.MaterialEntityRelationsByDefiner?.items?.map((x) => ({
          code: x.code,
          relationID: x.id,
          materialID: x.materialID,
          mfr: x.material.mfr,
          mpn: x.material.mpn,
        }));

        setFieldValue("codes", data);
      }
    }

    func();
  }, [values?.customer?.id, client, setFieldValue, definerID]);

  const incoTermsOptions = values?.customer?.incoTerms;

  const paymentTermsOptions = values?.customer?.paymentTerms;

  useEffect(() => {
    if (incoTermsOptions?.length === 1) {
      setFieldValue("incoTerms", incoTermsOptions[0]?.value);
    }
  }, [incoTermsOptions, setFieldValue]);

  useEffect(() => {
    if (paymentTermsOptions?.length === 1) {
      setFieldValue("paymentTerms", paymentTermsOptions[0]?.value);
    }
  }, [paymentTermsOptions, setFieldValue]);

  useEffect(() => {
    if (vendorOptions?.length === 1) {
      setFieldValue("vendor", vendorOptions[0]);
    } else {
      setFieldValue("vendor", null);
    }
  }, [setFieldValue, vendorOptions]);

  return (
    <Stack width={1600} spacing={2}>
      <Stack direction="row" spacing={5} alignItems="flex-end">
        <FormikAutocomplete
          name="customer"
          label="Customer"
          options={customerOptions}
          getOptionLabel={(option) => option.alias || option.name}
          required
          getPrimaryText={(option) => option.alias || option.name}
          getSecondaryText={null}
          listItemPrimaryField={null}
          listItemSecondaryField={null}
          disabled={false}
          width={240}
          onChange={() => {
            setFieldValue("POItems", [{ itemNumber: "00010", findBy: "code" }]);

            setFieldValue("vendor", null);
            setFieldValue("incoTerms", null);
            setFieldValue("paymentTerms", null);
          }}
          getOptionDisabled={null}
        />
        {/* <FormikAutocomplete
          name="billingEntity"
          label="billingEntity"
          options={billingEntityOptions}
          getOptionLabel={(option) => option.alias || option.name}
          required
          getPrimaryText={(option) => option.alias || option.name}
          getSecondaryText={null}
          listItemPrimaryField={null}
          listItemSecondaryField={null}
          disabled={billingEntityOptions?.length === 1}
          width={240}
          onChange={null}
          getOptionDisabled={null}
        /> */}
        <FormikAutocomplete
          name="vendor"
          label="Vendor"
          options={vendorOptions}
          getOptionLabel={(option) => option.alias || option.name}
          required
          getPrimaryText={(option) => option.alias || option.name}
          width={240}
          // getOptionDisabled={getOptionDisabled}
        />
        <FormikAutocomplete
          name="incoTerms"
          label="incoTerms"
          options={incoTermsOptions}
          getOptionLabel={(option) => option.terms}
          required
          getPrimaryText={(option) => option.terms}
          getSecondaryText={null}
          listItemPrimaryField={null}
          listItemSecondaryField={null}
          disabled={false}
          width={240}
          onChange={null}
          getOptionDisabled={null}
        />

        <FormikSelect
          label="PaymentTerms"
          name="paymentTerms"
          width={300}
          options={paymentTermsOptions}
          required
        />
      </Stack>

      <Stack direction="row" spacing={2}>
        <FormikField name="number" label="PO Number" required width={180} />
        <FormikField
          name="date"
          label="Date"
          type="date"
          required
          width={180}
        />
      </Stack>
    </Stack>
  );
};
