import React from "react";
import { GridOverlay } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
// import BackdropoLoader from "../../BackdropLoader";

export default function LoadingOverlay() {
  return null
  // return (
    // <BackdropoLoader isLoading={true}  />
    // <GridOverlay>
    //   <div style={{ position: 'absolute', top: 0, width: '100%' }}>
    //     <LinearProgress />
    //   </div>
    // </GridOverlay>
  //);
}

