import { useEffect, useState, useRef } from "react";
import { Auth } from "aws-amplify";
import { useReactiveVar } from "@apollo/client";
import {
  Container,
  Card,
  TextField,
  Stack,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ChangePassword from "../../../component/auth/ChangePassword";
import emitSnackbar from "../../../function/emitSnackbar";
import { userVar } from "../../../client/cache";

export default function Profile() {
  const { email } = useReactiveVar(userVar);

  return (
    <Container
      sx={{
        height: "100%",
        // alignItems: "center",
        // justifyContent: "flex-start",
        // display: "flex",
        // flexDirection: "column",
      }}
      maxWidth="sm"
    >
      <Card
        sx={{
          minHeight: 600,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: 3,
          gap: 5,
        }}
      >
        <Typography variant="h4" color="primary" alignSelf="center">
          Profile
        </Typography>
        <ProfileEdit
          label="Email"
          initialValue={email}
          onClick={async (value) => {
            console.log({ value });
            const user = await Auth.currentAuthenticatedUser();

            try {
              const res = await Auth.updateUserAttributes(user, {
                email: value,
              });

              console.log({ res });

              emitSnackbar({
                duration: 5000,
                severity: "warning",
                message:
                  "An email was sent to the new address, please click the link in the email",
              });
            } catch (err) {
              emitSnackbar({
                duration: 3000,
                severity: "error",
                message: err.message,
              });
            }
          }}
        />

        <Typography variant="h4" color="primary" alignSelf="center">
          Security
        </Typography>
        <ChangePassword />
      </Card>
    </Container>
  );
}

const ProfileEdit = ({ label, initialValue, onClick }) => {
  const inputRef = useRef();
  const [value, setValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }

    if (isEditing) {
      inputRef.current.focus();
    }
  }, [initialValue, isEditing]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <TextField
        inputRef={inputRef}
        sx={{ width: 340 }}
        label={label}
        // autoFocus
        variant="standard"
        disabled={!isEditing}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      {isEditing ? (
        <Stack spacing={1} direction="row">
          <IconButton
            onClick={() => {
              setIsEditing(false);
              setValue(initialValue);
            }}
          >
            <CloseOutlinedIcon color="warning" />
          </IconButton>
          <IconButton
            onClick={async () => {
              setIsEditing(false);
              if (value === initialValue) {
                return;
              } else {
                setLoading(true);
                await onClick(value);
                setLoading(false);
              }
            }}
          >
            <CheckOutlinedIcon color="success" />
          </IconButton>
        </Stack>
      ) : (
        <>
          {loading ? (
            <CircularProgress color="secondary" size={30} />
          ) : (
            <IconButton
              onClick={() => {
                setIsEditing(true);
              }}
            >
              <EditOutlinedIcon />
            </IconButton>
          )}
        </>
      )}
    </Stack>
  );
};
