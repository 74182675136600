import Excel from "exceljs";

export default async function simpleSheet(items, orientation) {
  if (items?.length === 0) return;

  const headers = Object.keys(items[0]);

  const workbook = new Excel.Workbook();

  const sheet = workbook.addWorksheet(`page ${1}`, {
    pageSetup: {
      paperSize: 9,
      margins: {
        left: 0.4,
        right: 0.4,
        top: 0.4,
        bottom: 0.4,
        header: 0,
        footer: 0,
      },
      orientation: orientation || "landscape",
      horizontalCentered: true,
      fitToPage: false,
      // blackAndWhite: true,
    },
    properties: {
      defaultRowHeight: 15,
    },
  });

  sheet.columns = headers.map((x) => ({ key: x, width: 10 }));

  sheet.addRow(headers);
  sheet.addRows(items, "i");

  return await workbook.xlsx.writeBuffer();
}
