import { useEffect } from "react";
import { Stack, Box, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import {
  FormikField,
  FormikArrayRemoveItemButton,
  FormikRadioGroup,
  FormikCheckbox,
} from "../../../../../../component/formikForm";
import ChartView from "../../../../../../component/ChartView";
import ForwarderPO from "../../../../../../component/ForwarderPO";
import { POByNumber } from "../../../../SPOs/query";

export default function POPaymentItem({
  index,
  remove,
  arrayFieldName,
  removeBtnColor,
  size,
}) {
  const { values, setFieldValue } = useFormikContext();

  const { paymentType, POItems, updatedFxRate } = values;
  const po = POItems[index] || {};
  const {
    piPrice,
    qty,
    taxAndHandlingChecked,
    goodsPaymentChecked,
    taxAndHandling,
    goodsPayment,
    fxRate,
    payments,
  } = po;

  const paymentSent = payments?.items;

  useEffect(() => {
    const exchangeRate = updatedFxRate || fxRate;
    setFieldValue(
      `[${arrayFieldName}][${index}][goodsPayment]`,
      (piPrice * qty * exchangeRate)?.toFixed(3) * 1
    );
  }, [
    updatedFxRate,
    fxRate,
    setFieldValue,
    arrayFieldName,
    index,
    piPrice,
    qty,
  ]);

  if (paymentType !== "PO") return null;

  return (
    <Stack spacing={5} p={2} sx={{ maxWidth: 2600, borderRadius: 2 }}>
      <Stack direction="row" spacing={2} alignItems="flex-end">
        <FormikField
          width={240}
          name={`[${arrayFieldName}][${index}][number]`}
          label="Number"
          disabled
          // type="number"
        />
        <FormikField
          width={80}
          name={`[${arrayFieldName}][${index}][itemNumber]`}
          label="itemNumber"
          disabled
          // type="number"
        />

        <FormikField
          width={120}
          name={`[${arrayFieldName}][${index}][qty]`}
          label="Qty"
          disabled
          // type="number"
        />
        <FormikField
          width={120}
          name={`[${arrayFieldName}][${index}][openQty]`}
          label="openQty"
          disabled
          // type="number"
        />
        <FormikField
          width={120}
          name={`[${arrayFieldName}][${index}][priceCur]`}
          label="Currency"
          disabled
          // type="number"
        />
        <FormikField
          width={120}
          name={`[${arrayFieldName}][${index}][price]`}
          label="Price"
          disabled
          // type="number"
        />

        {!po?.fxRate ? (
          <>
            <FormikField
              width={120}
              name={`[${arrayFieldName}][${index}][amount]`}
              label="Amount"
              type="number"
              disabled
            />
            <FormikField
              width={120}
              name={`[${arrayFieldName}][${index}][paidAmount]`}
              label="paidAmount"
              type="number"
            />
          </>
        ) : (
          <>
            <FormikField
              width={120}
              name={`[${arrayFieldName}][${index}][vat]`}
              label="vat"
              type="number"
              disabled
            />
            <FormikField
              width={120}
              name={`[${arrayFieldName}][${index}][importDuty]`}
              label="importDuty"
              type="number"
              disabled
            />
            <FormikField
              width={120}
              name={`[${arrayFieldName}][${index}][handlingFee]`}
              label="handlingFee"
              type="number"
              disabled
            />
            <FormikField
              width={120}
              name={`[${arrayFieldName}][${index}][originalGoodsPayment]`}
              label="originalGoodsPayment"
              type="number"
              disabled
            />
            <Stack direction="row">
              <FormikCheckbox
                label="taxAndHandling"
                name={`[${arrayFieldName}][${index}][taxAndHandlingChecked]`}
                disabled={paymentSent?.some(
                  (x) =>
                    x.desc === "tax/handling/duty" &&
                    x.amount === taxAndHandling
                )}
              />
              <FormikField
                width={120}
                name={`[${arrayFieldName}][${index}][taxAndHandling]`}
                label=""
                type="number"
                disabled={taxAndHandlingChecked ? false : true}
              />
            </Stack>

            <Stack direction="row">
              <FormikCheckbox
                label="goodPayment"
                name={`[${arrayFieldName}][${index}][goodsPaymentChecked]`}
                disabled={paymentSent?.some((x) => x.desc === "goods payment")}
              />
              <FormikField
                width={120}
                name={`[${arrayFieldName}][${index}][goodsPayment]`}
                label=""
                disabled={goodsPaymentChecked ? false : true}
                type="number"
              />
            </Stack>
          </>
        )}

        <FormikArrayRemoveItemButton
          onClick={() => remove(index)}
          name={arrayFieldName}
          removeBtnColor={removeBtnColor}
          size={size}
        />
      </Stack>

      {paymentSent?.length > 0 && (
        <Stack>
          <Typography variant="h6">Payments Made</Typography>
          {paymentSent?.map(({ id, date, amount, desc, currency }) => (
            <Stack key={id} direction="row" spacing={1}>
              <Typography>Date: {date}</Typography>
              <Typography>
                Amount: {currency}
                {amount}
              </Typography>
              <Typography>Desc: {desc}</Typography>
            </Stack>
          ))}
        </Stack>
      )}

      {po.itemNumber * 1 === 1 && po.fxRate && <ForwarderPO data={po} />}

      {/* <Box width={2600}> */}
      <ChartView
        query={POByNumber}
        variables={{ number: po?.number }}
        currentID={po?.id}
      />
      {/* </Box> */}
    </Stack>
  );
}
