import Template from "../../../component/Template";
import saas from "../../../assets/images/saas.jpg";
import visibility from "../../../assets/images/visibility.jpg";
import api from "../../../assets/images/api.jpg";

const headPicture = saas;
const headTitle = "IT Solutions";
const headDescription =
  "When Industry Insights Meet Technical Genius, Success Ensues";
const headMessage = `At Hankeno, we understand that technology is a vital component of modern business operations. We leverage our unique blend of IT expertise and deep understanding of your industry to craft tailored IT solutions that perfectly suit your business needs, streamlining your workflow, empowering your organization to achieve greater efficiency and success.`;

const sections = [
  {
    title: "SaaS (Software as a Service)",
    image: saas,
    description: `Our SaaS solutions provide businesses with the flexibility and scalability they need to succeed. By offering cloud-based software applications, we enable you to access powerful tools without the burden of maintaining complex IT infrastructure. Our SaaS products are designed to streamline operations, improve collaboration, and enhance productivity. Whether you need project management, customer relationship management, or any other software solution, our team will work with you to customize a solution that meets your specific requirements.`,
  },
  {
    title: "API Gateway",
    image: api,
    description: `With our API Gateway services, we facilitate seamless communication between your applications and external services. Our API solutions ensure that your systems can easily integrate with third-party applications, enhancing functionality and expanding your capabilities. By providing a secure and efficient way to manage APIs, we help you reduce development time, improve performance, and create a better user experience. Our team is skilled in designing and implementing API strategies that align with your business goals.`,
  },
  {
    title: "End-to-End Visibility",
    image: visibility,
    description: `We offer end-to-end visibility solutions that allow businesses to gain comprehensive insights into their operations. By integrating data from various sources, we enable you to monitor performance, identify inefficiencies, and make informed decisions. Our end-to-end visibility services help you track progress, streamline processes, and enhance accountability throughout your organization. With our support, you can ensure that every aspect of your business is aligned and operating at its best.`,
  },
];

export default function InfoTech() {
  return (
    <Template
      headPicture={headPicture}
      headTitle={headTitle}
      headDescription={headDescription}
      headMessage={headMessage}
      sections={sections}
    />
  );
}
