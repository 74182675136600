export const createPO = /* GraphQL */ `
  mutation CreatePO($input: CreatePOInput!, $condition: ModelPOConditionInput) {
    createPO(input: $input, condition: $condition) {
      id
      number
      itemNumber
      date
      offerID
      statusCode

      customerID

      vendorID

      definerID

      materialID

      materialEntityRelationID

      buyerID

      salesID

      qty
      openQty
      price
      priceCur
      paymentTerms
      incoTerms {
        terms
        condition
        entityUserRelationID
        __typename
      }
      billingEntityID
      shippingStatus
      billingStatus
      paymentStatus
      importAgentID
      paymentAgentID
      forwarderID

      groupToRead
      groupToEdit
      groupToDelete

      version
      latestVersion
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const fragment = /* GraphQL */ `
  fragment NewPO on PO {
    id
  }
`;

export const POByDefinerID = /* GraphQL */ `
  query POByDefinerID(
    $definerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPOFilterInput
    $limit: Int
    $nextToken: String
  ) {
    POByDefinerID(
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ETA {
          response {
            date
            responderID
          }
          lines {
            line
            eta
            qty
            note
          }
        }
        statusCode
        amount @client
        piID
        pi {
          id
          number
          itemNumber
          no @client
        }
        number
        itemNumber
        date
        offerID
        customerID
        customer {
          id
          name
          alias
        }
        vendorID
        vendor {
          id
          name
          alias
        }
        definerID
        materialID
        material {
          id
          mfr
          mpn
        }
        buyerID
        salesID
        materialEntityRelationID
        materialEntityRelation {
          id
          code
        }
        qty
        openQty
        price
        priceCur
        paymentTerms
        incoTerms {
          terms
          entityUserRelationID
        }
        billingEntityID
        CRD
        shippingStatus
        billingStatus
        paymentStatus
        importAgentID
        paymentAgentID
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPO = /* GraphQL */ `
  query GetPO($id: ID!) {
    getPO(id: $id) {
      id
      number
      itemNumber
      statusCode

      date
      offerID
      fxRate
      minCharge
      serviceFeeRate
      importDutyCN
      ETA {
        response {
          date

          responderID
        }
        lines {
          line
          eta
          qty
          note
        }
      }

      pi {
        id
        poID
        price
        currency
        qty
        number
      }

      customerID
      customer {
        id
        isVerified
        name
        alias
      }
      vendorID
      vendor {
        id
        isVerified
        name
        alias
        abbr @client
      }
      definerID

      materialID
      material {
        id
        definerID
        mpn
        mfr
        coo
        desc
        unit
        leadtime
        mpq
        transitDays
        hsCode
        importDutyCN

        __typename
      }
      buyerID

      salesID

      materialEntityRelationID
      materialEntityRelation {
        id
        entityID
        materialID
        code
        statusCode
        desc
        category
        pdt
        inventoryAlignment
        groupToRead
        groupToEdit
        groupToDelete
        definerID
        createdAt
        updatedAt
        __typename
      }
      qty
      openQty
      price
      priceCur
      paymentTerms
      incoTerms {
        terms
        entityUserRelationID
      }
      billingEntityID
      CRD
      shippingStatus
      billingStatus
      paymentStatus
      importAgentID
      paymentAgentID

      groupToRead
      groupToEdit
      groupToDelete
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePO = /* GraphQL */ `
  mutation DeletePO($input: DeletePOInput!, $condition: ModelPOConditionInput) {
    deletePO(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updatePO = /* GraphQL */ `
  mutation UpdatePO($input: UpdatePOInput!, $condition: ModelPOConditionInput) {
    updatePO(input: $input, condition: $condition) {
      id
      number
      itemNumber
      date
      offerID
      statusCode
      ETA {
        response {
          date
          responderID
        }
        lines {
          line
          eta
          qty
          note
        }
      }

      customerID

      vendorID

      definerID

      materialID

      buyerID

      salesID

      materialEntityRelationID

      qty
      openQty
      price
      priceCur
      paymentTerms
      incoTerms {
        terms
        entityUserRelationID
      }
      billingEntityID
      CRD
      shippingStatus
      billingStatus
      paymentStatus
      importAgentID
      paymentAgentID

      groupToRead
      groupToEdit
      groupToDelete
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const POByNumber = /* GraphQL */ `
  query POByNumber(
    $number: String!
    $itemNumber: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPOFilterInput
    $limit: Int
    $nextToken: String
  ) {
    POByNumber(
      number: $number
      itemNumber: $itemNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        statusCode
        ETA {
          response {
            date
            responderID
          }
          lines {
            line
            eta
            qty
            note
          }
        }

        payments {
          items {
            id
            type
            date
            desc
            amount
          }
        }
        invoice {
          items {
            id
            amount @client

            forwarderPO {
              id
              number
              qty
              openQty
              date
              itemNumber
              no @client
              payments {
                items {
                  id
                  type
                  date
                  amount
                  desc
                }
              }
              invoice {
                items {
                  id
                  category
                  number
                  itemNumber
                  amount @client

                  no @client
                  date
                  qty
                  price
                  currency
                  payments {
                    items {
                      id
                      type
                      date
                      amount
                      desc
                    }
                  }
                }
              }
              shippings {
                items {
                  id
                  date
                  number
                  itemNumber
                  no @client
                  qty
                }
              }
            }
            category
            number
            itemNumber
            no @client
            date
            qty
            price
            currency
            payments {
              items {
                id
                type
                date
                amount
                currency
                desc
              }
            }
          }
        }
        shippings {
          items {
            id
            date
            number
            itemNumber
            no @client
            qty
            # invoice {
            #   items {
            #     id
            #     number
            #     itemNumber
            #     price
            #     qty
            #     currency
            #   }
            # }
          }
        }

        number
        itemNumber
        no @client
        date
        pi {
          id
          price
          currency
          qty
        }
        customerID
        vendorID
        definerID
        materialID
        material {
          id
          mfr
          mpn
          importDutyCN
        }
        materialEntityRelationID
        materialEntityRelation {
          id
          code
        }
        buyerID
        salesID
        qty
        openQty
        price
        priceCur
        paymentTerms
        billingEntityID
        CRD
        shippingStatus
        billingStatus
        paymentStatus
        importAgentID
        paymentAgentID
        forwarderID
        fxRate
        minCharge
        serviceFeeRate
        importDutyCN
        piID
        groupToRead
        groupToEdit
        groupToDelete
        version
        latestVersion
        statusCode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const POByCustomerAndOpenQty = /* GraphQL */ `
  query POByCustomerAndOpenQty(
    $customerID: ID!
    $openQty: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPOFilterInput
    $limit: Int
    $nextToken: String
  ) {
    POByCustomerAndOpenQty(
      customerID: $customerID
      openQty: $openQty
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        itemNumber
        no @client
        date
        qty
        definerID
        openQty
        CRD
        ETA {
          response {
            date
            responderID
            responderName
          }
          lines {
            line
            eta
            qty
            note
          }
        }

        material {
          id
          mfr
          mpn
        }

        customerID
        customer {
          id
          name
          alias
          abbr @client
        }
        vendorID
        vendor {
          id
          name
          alias
          abbr @client
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const relationsByEntity1 = /* GraphQL */ `
  query RelationsByEntity1(
    $entity1ID: ID!
    $entity2ID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityRelationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationsByEntity1(
      entity1ID: $entity1ID
      entity2ID: $entity2ID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        relation
        definerID
        entity1ID
        entity2ID
        alias
        customerCode
        vendorCode
        statusCode
        type
        paymentTerms
        incoTerms {
          terms
          condition
          entityUserRelationID
        }

        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const mailEntityRelationByEntityRelation = /* GraphQL */ `
  query MailEntityRelationByEntityRelation(
    $entityRelationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMailEntityRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mailEntityRelationByEntityRelation(
      entityRelationID: $entityRelationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        userID
        user {
          id
          sub
          fn
          ln
          mn
          name @client
          email
        }
        entityRelationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const POByVendorID = /* GraphQL */ `
  query POByVendorID(
    $vendorID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPOFilterInput
    $limit: Int
    $nextToken: String
  ) {
    POByVendorID(
      vendorID: $vendorID

      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        itemNumber
        date
        offerID
        statusCode

        customerID

        vendorID

        definerID

        materialID

        buyerID

        salesID

        materialEntityRelationID

        qty
        openQty
        price
        priceCur
        paymentTerms

        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getSimplePO = /* GraphQL */ `
  query GetPO($id: ID!) {
    getPO(id: $id) {
      id
      number
      itemNumber
      statusCode

      date
      offerID
      fxRate
      minCharge
      serviceFeeRate
      importDutyCN

      customerID
      customer {
        id
        name
        alias
        abbr @client
      }

      vendorID
      vendor {
        id
        name
        alias
        abbr @client
      }

      definerID

      materialID

      buyerID

      salesID

      materialEntityRelationID

      qty
      openQty
      price
      priceCur
      paymentTerms

      billingEntityID
      CRD
      shippingStatus
      billingStatus
      paymentStatus
      importAgentID
      paymentAgentID

      groupToRead
      groupToEdit
      groupToDelete
      createdAt
      updatedAt
      __typename
    }
  }
`;
