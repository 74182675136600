import { Hub } from "aws-amplify";

export default function emitSnackbar({ duration, message, onCloseAlert, severity }) {
  Hub.dispatch("snackbarMonitor", {
    data: {
      severity: severity || "success",
      message,
      duration,
      onCloseAlert,
    },
  });
}
