import { Typography } from "@mui/material";
import HeadTestomonies from "./HeadTestomonies";

export default function HeadMessage({ message, testomonies }) {
  return (
    <>
      {testomonies && (
        <HeadTestomonies
          title={testomonies?.title}
          images={testomonies?.images}
        />
      )}
      <Typography
        style={{
          color: "#666666",
          fontWeight: "light",
          fontSize: 24,
          lineHeight: 1.5,
          // letterSpacing: 1,
        }}
      >
        {message}
      </Typography>
    </>
  );
}
