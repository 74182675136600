import { gql, useReactiveVar } from "@apollo/client";
import { useState } from "react";
import { Storage } from "aws-amplify";
import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { userEntityRelationVar } from "../../../../client/cache";

import {
  materialByMPN,
  materialEntityRelationByCode,
  POByNumber,
} from "../query";

import createCPO from "../../CPOs/onCreate";

export const TemplateLabel = ({ rows }) => {
  const { entityID } = useReactiveVar(userEntityRelationVar);

  const [isLoading, setIsloading] = useState(false);
  const filename = "cpo.csv";
  const path = `${entityID}/template/${filename}`;

  return (
    <Stack
      direction="row"
      spacing={2}
      // display="flex"
      // justifyContent="center"
      alignItems="center"
      // sx={{ alignItems: "center" }}
    >
      <Typography variant="h6">Template: </Typography>
      <Typography variant="p">{filename}</Typography>

      <LoadingButton
        loading={isLoading}
        size="small"
        onClick={async () => {
          setIsloading(true);
          try {
            const fetchedURL = await Storage.get(path, {
              download: false,
              validateObjectExistence: true,
              // level: 'protected',
            });
            window.location.href = fetchedURL;

            // console.log({window})

            // const downloadFolder = window.downloads.getDownloadFolder();

            // console.log({ downloadFolder });
            // debugger;
          } catch (err) {
            console.log({ err444: err });
          }
          setIsloading(false);
        }}
        variant="contained"
      >
        download
      </LoadingButton>
    </Stack>
  );
};

export const headerIdentifier = [
  // { label: "tee4", isRequired: false },
  // { label: "ERROR", isRequired: false },
  { label: "PONUMBER", isRequired: true },

  { label: "MFR", isRequired: true },
  { label: "MPN", isRequired: true },
  { label: "CUSTOMERID", isRequired: false },
  // { label: "CUSTOMER", isRequired: false },
  { label: "VENDORID", isRequired: false },
  // { label: "VENDOR", isRequired: false },
  { label: "MATERIALID", isRequired: false },
  { label: "MAETRIALENTITYRELATIONID", isRequired: false },
  { label: "GCAS", isRequired: true },
  { label: "PRICE", isRequired: true },
  { label: "CURRENCY", isRequired: true },
  // { label: "UNIT", isRequired: true },
  { label: "INCOTERMS", isRequired: true },
  { label: "PAYMENTTERMS", isRequired: true },
  // { label: "STATUSCODE", isRequired: true },
];

export async function onCreate({ rows, definerID, client }) {
  await Promise.all(
    rows.map(async (row) => {
      const {
        MATERIALID,
        MAETRIALENTITYRELATIONID,
        CUSTOMERID,
        // SALESID,
        // BUYERID,
        VENDORID,
        DATE,
        NUMBER,
        ITEMNUMBER,
        PRICE,
        CURRENCY,
        PAYMENTTERMS,
        QTY,
        OPENQTY,
        CRD,
        // STATUSCODE,
        INCOTERMS,
        INCOTERMENTITYUSERRELATIONID,
      } = row;
      const values = {
        date: new Date(DATE).toISOString("en-US").substring(0, 10),
        materialID: MATERIALID,
        materialEntityRelationID: MAETRIALENTITYRELATIONID,

        number: NUMBER,
        itemNumber: ITEMNUMBER,
        // salesID: SALESID,
        // buyerID: BUYERID,
        vendorID: VENDORID.trim(),
        customerID: CUSTOMERID.trim(),
        qty: QTY,
        openQty: OPENQTY,
        CRD: new Date(CRD).toISOString("en-US").substring(0, 10),
        definerID,
        price: PRICE * 1,
        priceCur: CURRENCY.trim(),
        paymentTerms: PAYMENTTERMS.trim(),
        incoTerms: {
          terms: INCOTERMS.trim(),
          entityUserRelationID: INCOTERMENTITYUSERRELATIONID,
        },
      };

      await createCPO({ client, values });
    })
  );
}

export async function validator({ rows, definerID, client }) {
  const newarr = await Promise.all(
    rows?.map(async (row) => {
      let ERROR = [];
      // console.log({ row });
      const { GCAS, MPN, MFR, CUSTOMERID, PONUMBER } = row;

      if (GCAS) {
        const { data } = await client.query({
          query: gql(materialEntityRelationByCode),
          variables: { code: row.GCAS, entityID: { eq: CUSTOMERID } },
        });
        const materialEntityRelationFound =
          data?.materialEntityRelationByCode?.items?.find(
            (x) => x.material.mpn === MPN && x.material.mfr === MFR
          );

        if (!materialEntityRelationFound) {
          ERROR.push({
            attr: "GCAS",
            isMajor: true,
            msg: "GCAS and material don't match",
          });
          return { ...row, ERROR };
        }
        row.MATERIALID = materialEntityRelationFound.materialID;
        row.MAETRIALENTITYRELATIONID = materialEntityRelationFound.id;

        console.log({ materialEntityRelationFound });
      } else {
        const { data } = await client.query({
          query: gql(materialByMPN),
          variables: {
            mpn: MPN,
            mfr: { eq: MFR },
            filter: { definerID: { eq: definerID } },
          },
        });
        const materialsFound = data?.materialByMPN?.items;

        console.log({ materialsFound });

        if (materialsFound?.length > 1) {
          ERROR.push({
            attr: "MPN",
            isMajor: false,
            msg: "Multiple materials share the samme MPN and MFR",
          });

          // ERROR.push("Multiple materials share the samme MPN and MFR");
          return { ...row, ERROR };
        }
        const materialFound = materialsFound?.[0];

        if (!materialFound) {
          ERROR.push({
            attr: "MPN",
            isMajor: true,
            msg: "Material not found",
          });
          return { ...row, ERROR };
        }

        row.MATERIALID = materialFound.id;
        row.MAETRIALENTITYRELATIONID = null;
      }

      const split = PONUMBER.split("-");
      const number = `${split[0]}-${split[1]}`;
      const itemNumber = split[2];

      row.NUMBER = number;
      row.ITEMNUMBER = itemNumber;

      // console.log({split: split,number,itemNumber})

      const { data: data2 } = await client.query({
        query: gql(POByNumber),
        variables: {
          number,
          itemNumber: { eq: itemNumber },
        },
      });

      const POFound = data2?.POByNumber?.items?.[0];

      console.log({ POFound });

      if (POFound) {
        ERROR.push({
          attr: "PONUMBER",
          isMajor: true,
          msg: "PO already exists",
        });
      }

      return { ...row, ERROR };
    })
  );

  return newarr;
}
