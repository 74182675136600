import { useMemo, useEffect } from "react";
import { Stack } from "@mui/material";
import { gql, useQuery, useApolloClient,useReactiveVar } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import FormikForm, {
  FormikField,
  FormikSelect,
  FormikAutocomplete,
} from "../../../../component/formikForm";
import { onCreateOffer } from "../onCreate";
import {
  relationsByDefiner,
  MaterialEntityRelationsByDefiner,
  entitiyUserRelationByEntityID,
} from "../query";
import QuoteItem from "./Array/QuoteItem";
import filterOutSameItemInArray from "../../../../lib/filterOutSameItemInArray";
import { userEntityRelationVar } from "../../../../client/cache";

const validationSchemaForCreate = Yup.object().shape({
  // alias: Yup.string().min(2, "Too Short").max(10, "Too Long"),
  // // .required("Please enter a alias"),
  // vendorCode: Yup.string().required("Please enter vendor code"),
  // statusCode: Yup.string().required("Please enter status code"),
  // contacts: Yup.array().of(
  //   Yup.object({
  //     fn: Yup.string()
  //       .min(2, "At least 2 characters")
  //       .max(10, "Too Long")
  //       .required("Please enter first name"),
  //     ln: Yup.string()
  //       .min(2, "At least 2 characters")
  //       .max(10, "Too Long")
  //       .required("Please enter last name"),
  //     email: Yup.string()
  //       .email("Invalid email")
  //       .required("Please enter a valid email"),
  //     role: Yup.array()
  //       .min(1, "Please assign at least one role")
  //       .required("Please select role(s)"),
  //   })
  // ),
});

const FormArray = [
  {
    Item: QuoteItem,
    name: "quotes",
    // width: "95%",
    bgcolor: "#f3e5f5",
    btnColor: "purple",
    size: "md",
  },
];
export default function CreateForm() {
  // const { state } = useLocation() || {};
  const { entityID } = useParams();

  const initialValues = {
    number: `S${Date.now().toString().slice(1, 9)}`,
    date: new Date().toISOString("en-US").slice(0, 10),
    definerID: entityID,
    isEditing: true,
    quotes: [{}],
  };

  const navigate = useNavigate();
  const onSubmit = async ({ values, client }) => {
    await onCreateOffer({
      values,
      client,
    });

    navigate(-1);
  };
  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <FormikForm
      initialValues={initialValues}
      validationSchema={validationSchemaForCreate}
      onSubmit={onSubmit}
      FormMainFields={CreateFields}
      FormArray={FormArray}
      handleCancel={handleCancel}
    />
  );
}

const CreateFields = ({ values, setFieldValue, setValues }) => {

  

  const {entityID,subsidiaryIDs} = useReactiveVar(userEntityRelationVar);

  const client = useApolloClient();

  const { definerID } = values;

  let { data: relationsByDefinerData } = useQuery(gql(relationsByDefiner), {
    variables: {
      definerID,
      relation: { eq: "CUSTOMER-SUPPLIER" },
      filter: {
        or: [entityID, ...subsidiaryIDs].map((x) => ({ entity2ID: { eq: x } })),
      },
    },
  });
  relationsByDefinerData = relationsByDefinerData?.relationsByDefiner?.items;

  const { data: entitiyUserRelationByEntityIDData } = useQuery(
    gql(entitiyUserRelationByEntityID),
    {
      variables: {
        entityID: values?.customer?.id,
        definerID: { eq: definerID },
        filter: { role: { eq: "buyer" } },
      },
      skip: !values?.customer?.id,
    }
  );

  const buyerOptions = useMemo(
    () =>
      entitiyUserRelationByEntityIDData?.entitiyUserRelationByEntityID?.items?.map(
        (x) => ({
          ...x.user,
        })
      ),
    [entitiyUserRelationByEntityIDData?.entitiyUserRelationByEntityID?.items]
  );

  let { data: entitiyUserRelationByEntityIDData2 } = useQuery(
    gql(entitiyUserRelationByEntityID),
    {
      variables: {
        entityID: values?.vendor?.id,
        definerID: { eq: definerID },
        filter: { role: { eq: "sales" } },
      },
      skip: !values?.vendor?.id,
    }
  );

  const salesOptions = useMemo(
    () =>
      entitiyUserRelationByEntityIDData2?.entitiyUserRelationByEntityID?.items?.map(
        (x) => ({
          ...x.user,
        })
      ),
    [entitiyUserRelationByEntityIDData2?.entitiyUserRelationByEntityID?.items]
  );

  let customerOptions = relationsByDefinerData?.map((x) => ({
    ...x.entity1,
    paymentTerms: x.paymentTerms,
    incoTerms: x.incoTerms,
    vendorCode: x.vendorCode,
  }));

  customerOptions = useMemo(
    () => filterOutSameItemInArray(customerOptions, "id"),
    [customerOptions]
  );

  const vendorOptions = useMemo(
    () =>
      relationsByDefinerData
        ?.filter((x) => x.entity1ID === values?.customer?.id)
        ?.map((x) => x.entity2),
    [relationsByDefinerData, values?.customer?.id]
  );

  const incoTermsOptions = values?.customer?.incoTerms;

  const paymentTermsOptions = values?.customer?.paymentTerms;

  useEffect(() => {
    async function func() {
      if (values?.customer?.id) {
        let { data } = await client.query({
          query: gql(MaterialEntityRelationsByDefiner),
          variables: {
            definerID,
            entityID: { eq: values?.customer?.id },
            skip: !values?.customer?.id || !values?.entityID,
            limit: 5000,
            // filter: { relation: { eq: "CUSTOMER" } },
          },
        });

        data = data?.MaterialEntityRelationsByDefiner?.items?.map((x) => ({
          code: x.code,
          relationID: x.id,
          materialID: x.materialID,
          mfr: x.material.mfr,
          mpn: x.material.mpn,
        }));

        setFieldValue("codes", data);
      }
    }

    func();
  }, [values?.customer?.id, definerID, client, setFieldValue, values.entityID]);

  useEffect(() => {
    if (buyerOptions?.length === 1) {
      setFieldValue("buyer", buyerOptions[0]);
    }
  }, [buyerOptions, setFieldValue]);

  useEffect(() => {
    if (salesOptions?.length === 1) {
      setFieldValue("sales", salesOptions[0]);
    }
  }, [salesOptions, setFieldValue]);

  useEffect(() => {
    if (incoTermsOptions?.length === 1) {
      setFieldValue("incoTerms", incoTermsOptions[0]);
    }
  }, [incoTermsOptions, setFieldValue]);

  useEffect(() => {
    if (paymentTermsOptions?.length === 1) {
      setFieldValue("paymentTerms", paymentTermsOptions[0]);
    }
  }, [paymentTermsOptions, setFieldValue]);

  useEffect(() => {
    if (vendorOptions?.length === 1) {
      setFieldValue("vendor", vendorOptions[0]);
    } else {
      setFieldValue("vendor", null);
    }
  }, [setFieldValue, vendorOptions]);

  return (
    <Stack width={1600} spacing={2}>
      <Stack direction="row" spacing={5} alignItems="flex-end">
        <FormikField
          name="number"
          label="Number"
          disabled
          required
          width={180}
        />
        <FormikField
          name="date"
          label="Date"
          type="date"
          required
          width={180}
        />
      </Stack>

      <Stack direction="row" spacing={5} alignItems="flex-end">
        <FormikAutocomplete
          name="customer"
          label="Customer"
          options={customerOptions}
          getOptionLabel={(option) => option.alias || option.name}
          required
          getPrimaryText={(option) => option.alias || option.name}
          getSecondaryText={null}
          listItemPrimaryField={null}
          listItemSecondaryField={null}
          disabled={false}
          width={240}
          onChange={() => {
            setFieldValue("quotes", []);
            setFieldValue("buyer", null);
            setFieldValue("sales", null);
            setFieldValue("vendor", null);
            setFieldValue("incoTerms", null);
            setFieldValue("paymentTerms", null);
          }}
          getOptionDisabled={null}
        />
        <FormikAutocomplete
          name="buyer"
          label="Buyer"
          options={buyerOptions}
          getOptionLabel={(option) => option.name}
          required
          getPrimaryText={(option) => option.name}
          getSecondaryText={null}
          listItemPrimaryField={null}
          listItemSecondaryField={null}
          disabled={false}
          width={240}
          onChange={null}
          getOptionDisabled={null}
        />
        <FormikAutocomplete
          name="vendor"
          label="Vendor"
          options={vendorOptions}
          getOptionLabel={(option) => option.alias || option.name}
          required
          getPrimaryText={(option) => option.alias || option.name}
          width={240}
          // getOptionDisabled={getOptionDisabled}
        />
        <FormikAutocomplete
          name="sales"
          label="Sales"
          options={salesOptions}
          getOptionLabel={(option) => option.name}
          required
          getPrimaryText={(option) => option.name}
          getSecondaryText={null}
          listItemPrimaryField={null}
          listItemSecondaryField={null}
          disabled={false}
          width={240}
          onChange={null}
          getOptionDisabled={null}
        />
      </Stack>

      <Stack direction="row" spacing={2} alignItems="flex-end">
        <FormikAutocomplete
          name="incoTerms"
          label="incoTerms"
          options={incoTermsOptions}
          getOptionLabel={(option) => option.terms}
          required
          getPrimaryText={(option) => option.terms}
          getSecondaryText={null}
          listItemPrimaryField={null}
          listItemSecondaryField={null}
          disabled={false}
          width={240}
          onChange={null}
          getOptionDisabled={null}
        />

        <FormikSelect
          label="PaymentTerms"
          name="paymentTerms"
          width={300}
          options={paymentTermsOptions}
          required
          // labelKey="label"
          // valueKey="value"
        />
      </Stack>
    </Stack>
  );
};
