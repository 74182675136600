import { useApolloClient } from "@apollo/client";
import * as Yup from "yup";
import { Stack, Typography } from "@mui/material";
import FormikForm, { FormikField } from "../../../../component/formikForm";
import onUpdateInventory from "../onUpdate";
import onDeleteInventory from "../onDelete";

const validationSchema = Yup.object().shape({
  mfr: Yup.string().min(2, "Too Short"),
  mpn: Yup.string().min(2, "Too Short"),
  desc: Yup.string().min(2, "Too Short").nullable(),
});

export default function Item({ data, onClose, userRole }) {
  const isAdmin = userRole?.includes("admin");

  const client = useApolloClient();

  const onSubmit = async ({ values, client }) => {
    await onUpdateInventory({
      values,
      client,
    });

    onClose && onClose();
  };

  const onDeleteBtnClick = async () => {
    if (window.confirm("Are you sure you wish to delete this item?")) {
      await onDeleteInventory({ values: data, client });
      onClose();
    }
  };

  return (
    <FormikForm
      initialValues={{ ...data, isEditing: false }}
      validationSchema={validationSchema}
      FormMainFields={EditFields}
      FormArray={null}
      // handleCancel={onClose}
      handleCancel={({ setFieldValue, setValues }) => {
        setValues(data);
        setFieldValue("isEditing", false);
      }}
      saveButtonLabel="update"
      onDelete={isAdmin ? onDeleteBtnClick : null}
      onSubmit={isAdmin ? onSubmit : null}
      disableEdit={isAdmin ? false : true}
    />
  );
}

const EditFields = ({ values }) => {
  const { isEditing, material, creator, date, timeStamp } = values || {};
  return (
    <Stack spacing={3} justifyContent="flex-start" alignItems="flex-start">
      <Stack direction="row" spacing={3}>
        <FormikField
          name="quantity"
          label="quantity"
          type="number"
          width={120}
          required
          disabled={!isEditing}
        />
      </Stack>
      <Stack direction="row" spacing={3}>
        <Typography>CURRENT INV:{material?.inventory}</Typography>
        <Typography>MFR:{material?.mfr}</Typography>
        <Typography>MPN:{material?.mpn}</Typography>
        <Typography>UNIT:{material?.unit}</Typography>
      </Stack>

      <Stack direction="row" spacing={3}>
        <Typography>DATE:{date}</Typography>
        <Typography>TIMESTAMP:{timeStamp}</Typography>

        <Typography>CREATOR:{creator?.name}</Typography>
      </Stack>
    </Stack>
  );
};
