import laser_cutting from "../../../assets/images/laser_cutting.jpg";
import milling from "../../../assets/images/cncMilling.jpg";
import turning from "../../../assets/images/cncTurning.jpg";
import Sections from "../../../component/Sections";
import Fabrication from "./Fabrication";

const sections = [
  {
    title: "selecion1",
    image: laser_cutting,
    description:
      "I cannot live a life where I'm deprived. I'd much rather be five, 10 pounds heavier. With my luck, I'll get myself to that perfect goal weight, and I'll get hit by a bus. Then I'll be like... looking at myself from some afterlife going, 'You idiot. You could have had that agnolotti, dummy.",
  },
  {
    title: "selecion2",
    image: milling,
    description:
      "I cannot live a life where I'm deprived. I'd much rather be five, 10 pounds heavier. With my luck, I'll get myself to that perfect goal weight, and I'll get hit by a bus. Then I'll be like... looking at myself from some afterlife going, 'You idiot. You could have had that agnolotti, dummy.",
  },
  {
    title: "selecion3",
    image: turning,
    description:
      "I cannot live a life where I'm deprived. I'd much rather be five, 10 pounds heavier. With my luck, I'll get myself to that perfect goal weight, and I'll get hit by a bus. Then I'll be like... looking at myself from some afterlife going, 'You idiot. You could have had that agnolotti, dummy.",
  },
  {
    title: "selecion1",
    image: laser_cutting,
    description:
      "I cannot live a life where I'm deprived. I'd much rather be five, 10 pounds heavier. With my luck, I'll get myself to that perfect goal weight, and I'll get hit by a bus. Then I'll be like... looking at myself from some afterlife going, 'You idiot. You could have had that agnolotti, dummy.",
  },
];

const fabrication = {
  path: "fabrication",

  topNav: {
    order: 40,
    label: "fabrication",
    entityType: ["public"],
    allowedRole: "all",
    auth: ["unAuthenticated"],
  },

  route: {
    index: {
      element: <Fabrication />,
      allowedRole: "all",
      entityType: ["public"],
      auth: ["unAuthenticated"],
    },
    milling: {
      element: <Sections sections={sections} title="Fabrication" />,
      allowedRole: "all",
      entityType: ["public"],
      auth: ["unAuthenticated"],
    },
  },
};

export default fabrication;
