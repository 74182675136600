import { Stack, Typography, Checkbox, Button } from "@mui/material";

export default function VendorNameLine({
  po,
  selectedPOItems,
  setSelectedPOItems,
  vendorPOs,
  vendorID,
  customerID,
  setExpanded,
  id,
}) {
  const { vendor } = po || {};

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography width={100}>{vendor.abbr}</Typography>

      <Button
        onClick={async (e) => {
          e.stopPropagation();
        }}
      >
        SELECT ALL
        <Checkbox
          checked={vendorPOs.every((x) => selectedPOItems.includes(x.id))}
          onChange={(e) => {
            console.log({ vendorPOs });
            e.stopPropagation();
            if (e.target.checked) {
              // console.log({ e: e.target.checked, poid: po.id });
              setExpanded((prev) => [...prev, id]);

              setSelectedPOItems([
                ...selectedPOItems,
                ...vendorPOs.map((x) => x.id),
              ]);
            } else {
              setSelectedPOItems(
                selectedPOItems.filter(
                  (x) => !vendorPOs.map((y) => y.id).includes(x)
                )
              );
            }
          }}
        />
        {vendorPOs.some((x) => selectedPOItems.includes(x.id)) &&
        vendorPOs.some((x) => !selectedPOItems.includes(x.id))
          ? "partially selected"
          : ""}
      </Button>
    </Stack>
  );
}
