import { useEffect, useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import { Stack, Button, Typography, Chip } from "@mui/material";
import { useFormikContext, useField } from "formik";
import {
  FormikField,
  FormikSelect,
  FormikAutocomplete,
  FormikArrayRemoveItemButton,
  FormikRadioGroup,
} from "../../../../../component/formikForm";
import { currencies } from "../../../../../settings/formFieldEnum";
import filterOutSameItemInArray from "../../../../../lib/filterOutSameItemInArray";
import mergeArrayAndCombineQty from "../../../../../lib/mergeArrayAndCombineQty";

export default function InboundInvoiceItemSubArray({
  index,
  remove,
  arrayFieldName,
  removeBtnColor,
  size,
}) {
  const { values, setFieldValue } = useFormikContext();

  let parentLine = arrayFieldName?.split("][");

  let poped = parentLine?.pop();

  parentLine = parentLine.map((x) => x.replace("[", ""));
  parentLine = parentLine.map((x) => `[${x}]`);
  parentLine = parentLine.join("");

  const [parentLineField] = useField({ name: parentLine });
  const parentLineQty = parentLineField.value?.qty * 1;
  const availableShippings = parentLineField.value?.availableShippings;

  const thisLineName = `${arrayFieldName}[${index}]`;

  const [thisLineField, meta, helpers] = useField({ name: thisLineName });

  const {
    qty,
    billingQty,
    toBeBilled,
    priceCur,
    price,
    shippingID: thisLineShippingID,
  } = thisLineField.value || {};

  // console.log({ parentLineQty, thisLineQty, thisLineOpenQty });

  // const [field, meta, helpers] = useField({ name: parentLine });
  // let value = parentLineField.value;
  // const lineQty = value.qty * 1;

  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <FormikField
          width={180}
          name={`[${arrayFieldName}][${index}][itemNumber]`}
          label="Item Number"
          disabled
        />
        <FormikField
          width={80}
          name={`[${arrayFieldName}][${index}][price]`}
          label="Price"
          disabled
        />
        <FormikField
          width={50}
          name={`[${arrayFieldName}][${index}][priceCur]`}
          label="PriceCur"
          disabled
        />
        <FormikField
          width={120}
          name={`[${arrayFieldName}][${index}][qty]`}
          label="qty"
          disabled
        />
        <FormikField
          width={120}
          name={`[${arrayFieldName}][${index}][toBeBilled]`}
          label="toBeBilled"
          disabled
        />

        <FormikField
          width={120}
          name={`[${arrayFieldName}][${index}][billingQty]`}
          label="Billing Qty"
          type="number"
          onBlur={(v) => {
            console.log("onBlur", v);

            if (v * 1 > toBeBilled) {
              alert("发票数量大于订单数量");

              //BOTH OF THE FLLOWING WORKS
              // setFieldValue(`[${arrayFieldName}][${index}][billingQty]`, "");
              // // helpers.setValue({ ...thisLineField.value, billingQty: '' });
              // return;
            }
          }}
          // disabled
        />

        <FormikField
          width={50}
          name={`[${arrayFieldName}][${index}][priceCur]`}
          label="priceCur"
          disabled
        />
        <FormikField
          width={100}
          name={`[${arrayFieldName}][${index}][price]`}
          label="Price"
          type="number"
          // disabled
        />

        <Typography width={300} variant="h6">
          {billingQty > 0 &&
            `Amount: ${priceCur} ${(billingQty * price).toFixed(3) * 1}`}
        </Typography>

        <Stack width={500}>
          {availableShippings && availableShippings.length > 0 && (
            <>
              <Typography sx={{ alignSelf: "center" }}>
                Please select shipping
              </Typography>

              <Stack direction="row" spacing={2}>
                {availableShippings.map(
                  ({ id, currency, price, qty, date, invoice }) => (
                    <Stack key={id}>
                      <Chip
                        {...(id === thisLineShippingID && {
                          color: "secondary",
                        })}
                        label={
                          <Stack>
                            <Typography>
                              {date}: {qty}
                            </Typography>
                          </Stack>
                        }
                        onClick={() => {
                          setFieldValue(
                            `[${arrayFieldName}][${index}][shippingID]`,
                            id
                          );
                        }}
                        onDelete={() => {
                          setFieldValue(
                            `[${arrayFieldName}][${index}][shippingID]`,
                            null
                          );
                        }}
                      />
                      {invoice?.items?.length > 0 && (
                        <Stack>
                          <Typography> Invoices</Typography>
                          <Stack>
                            {invoice?.items?.map((x) => (
                              <Typography key={x.id}>
                                {x.date}: {x.qty}
                              </Typography>
                            ))}
                          </Stack>
                        </Stack>
                      )}
                    </Stack>
                  )
                )}
              </Stack>
            </>
          )}
        </Stack>

        {/* <FormikField
          width={200}
          name={`[${arrayFieldName}][${index}][CRD]`}
          label="CRD"
          type="date"
        /> */}
        {/* <FormikArrayRemoveItemButton
          onClick={() => remove(index)}
          name={arrayFieldName}
          removeBtnColor={removeBtnColor}
          size={size}
        /> */}
      </Stack>
    </Stack>
  );
}
