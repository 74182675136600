import { useEffect } from "react";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { useFormikContext } from "formik";
import { Stack, Typography } from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import FormikForm, {
  FormikField,
  FormikAutocomplete,
  FormikSelect,
  FormikArrayRemoveItemButton,
} from "../../../../component/formikForm";

import onCreateOrUpdateBatchInventory from "../onCreate";
import { MaterialsByDefiner, InventoryByMaterial } from "../query";
import { warehouseSelection } from "../../../../settings/formFieldEnum";
import { userVar } from "../../../../client/cache";

const validationSchemaForCreate = Yup.object().shape({
  // mfr: Yup.string().min(2, "Too Short"),
  // mpn: Yup.string().min(2, "Too Short"),
  // desc: Yup.string().min(2, "Too Short"),
  inventoryItems: Yup.array().of(
    Yup.object({
      material: Yup.object().required("Please select material").nullable(),
      quantity: Yup.number()
        .min(0.0001, "Quanty must be greater than zero")
        .required("Please enter number"),
    })
  ),
});

export default function CreateForm() {
  const { id: creatorID } = useReactiveVar(userVar);

  const navigate = useNavigate();

  const { state } = useLocation();
  const { entityID } = useParams();

  const { data: MaterialsByDefinerData } = useQuery(gql(MaterialsByDefiner), {
    variables: { definerID: entityID },
  });

  const materialOptions = MaterialsByDefinerData?.MaterialsByDefiner?.items.map(
    (x) => {
      return {
        id: x.id,
        material: `${x.mpn} ${x.mfr} `,
        unit: x.unit,
      };
    }
  );

  if (!materialOptions) return null;

  const defaultValue = { warehouseCode: 101 };

  const initialValues = {
    ...state,
    definerID: entityID,
    creatorID,
    isEditing: true,
    date: new Date().toISOString("en-US").slice(0, 10),
    materialOptions,
    inventoryItems: [defaultValue],
  };

  const onSubmit = async ({ values, client }) => {
    await onCreateOrUpdateBatchInventory({
      values,
      client,
    });

    navigate(-1);
  };
  const handleCancel = () => {
    navigate(-1);
  };

  const FormArray = [
    {
      Item: InventoryItem,
      name: "inventoryItems",
      // width: ,
      bgcolor: "#e3f2fd",
      btnColor: "#3f51b5",
      size: "md",
      defaultValue,
    },
  ];

  return (
    <FormikForm
      initialValues={initialValues}
      validationSchema={validationSchemaForCreate}
      onSubmit={onSubmit}
      FormMainFields={CreateFields}
      FormArray={FormArray}
      handleCancel={handleCancel}
    />
  );
}

function CreateFields({ values, setFieldValue }) {
  return (
    <Stack width={1600} spacing={2}>
      <Stack direction="row" spacing={5}>
        <FormikField
          name="date"
          label="Date"
          type="date"
          required
          width={180}
        />
      </Stack>
    </Stack>
  );
}

function InventoryItem({
  index,
  remove,
  arrayFieldName,
  removeBtnColor,
  size,
}) {
  const { entityID } = useParams();

  const { values, setFieldValue, setFieldError } = useFormikContext();
  const { materialOptions, inventoryItems } = values;
  const { material } = inventoryItems[index] || {};

  // console.log({ mateiralID: material?.id, entityID });

  const { data: InventoryByMaterialData } = useQuery(gql(InventoryByMaterial), {
    variables: {
      materialID: material?.id,
      definerID: { eq: entityID },
    },
    skip: !material,
  });

  const inventoryFound =
    InventoryByMaterialData?.InventoryByMaterial?.items?.[0];

  const materialInv = inventoryFound?.material?.inventory;
  const receivings =
    inventoryFound?.material?.receivingAndShippings?.items?.filter((x) => {
      return x.type === "INCOMING" && x.timeStamp > inventoryFound?.timeStamp;
    });

  const shippings =
    inventoryFound?.material?.receivingAndShippings?.items?.filter((x) => {
      return x.type === "OUTGOING" && x.timeStamp > inventoryFound?.timeStamp;
    });

  const calculatedInv =
    inventoryFound?.quantity +
    receivings?.reduce((a, v) => a + v.qty, 0) -
    shippings?.reduce((a, v) => a + v.qty, 0);

  // console.log({
  //   inventoryFound,
  //   materialInv,
  //   receivings,
  //   shippings,
  //   calculatedInv,
  // });

  useEffect(() => {
    if (inventoryFound) {
      setFieldValue(
        `[${arrayFieldName}][${index}][inventoryFound]`,
        inventoryFound
      );
    }
  }, [setFieldValue, arrayFieldName, index, inventoryFound]);

  return (
    <Stack spacing={3}>
      <Stack direction="row" width={1000} justifyContent="space-between">
        <Stack spacing={2} direction="row" alignItems="flex-end">
          <FormikSelect
            required
            width={160}
            label="Warehouse"
            name={`[${arrayFieldName}][${index}][warehouseCode]`}
            // multiple
            options={warehouseSelection}
            labelKey="name"
            valueKey="code"
          />
          <FormikAutocomplete
            width={360}
            getOptionDisabled={(option) => {
              return inventoryItems.some((x) => x.material?.id === option?.id);
            }}
            // disableCloseOnSelect={!disableMultiple}
            name={`[${arrayFieldName}][${index}][material]`}
            label="material"
            // required
            options={materialOptions}
            getOptionLabel={(option) => option.material}
            getPrimaryText={(option) => option.material}
          />

          <FormikField
            width={180}
            name={`[${arrayFieldName}][${index}][quantity]`}
            label="quantity"
            type="number"
            InputProps={{
              inputProps: { min: 0 },
            }}
            checkError={(setError, v) => {
              if (v * 1 !== calculatedInv * 1) {
                setError("input qty should be equal to the calculatedInv");
              }
            }}
            // onBlur={(setError) => {
            //   // if (v * 1 !== calculatedInv * 1) {
            //   // alert(`calculatedInv: ${calculatedInv} !== ${v}`);
            //   // setFieldValue(`[${arrayFieldName}][${index}][quantity]`, 0);
            //   setFieldError(
            //     `[${arrayFieldName}][${index}][quantity]`,
            //     "calculatedInv !== v"
            //   );

            //   //   return;
            //   // }
            // }}
            endAdornment={
              <Typography> {material?.unit}</Typography>
              // <IconButton onClick={() => setFieldValue('paymentDueDate', null)}>
              //   <ClearIcon />
              // </IconButton>
            }
          />
        </Stack>

        <FormikArrayRemoveItemButton
          disabled={index === 0 ? true : false}
          onClick={() => remove(index)}
          name={arrayFieldName}
          removeBtnColor={removeBtnColor}
          size={size}
        />
      </Stack>

      {inventoryFound && (
        <Stack direction="row" width={1000} justifyContent="space-between">
          <Stack spacing={2}>
            <Typography> last Record</Typography>
            <Typography> Date: {inventoryFound?.date}</Typography>
            <Typography> TimeStamp: {inventoryFound?.timeStamp}</Typography>
            <Typography> Quantity: {inventoryFound?.quantity}</Typography>
            <Typography> Creator: {inventoryFound?.creator?.name}</Typography>
          </Stack>

          {receivings?.length > 0 && (
            <Stack spacing={2}>
              <Typography>
                {" "}
                Receivings since last record{" "}
                {receivings.reduce((a, v) => a + v.qty, 0)}
              </Typography>
              {receivings?.map((x) => (
                <Stack direction="row" spacing={2}>
                  <Typography> Date: {x?.date}</Typography>
                  <Typography> Date: {x?.qty}</Typography>
                </Stack>
              ))}
            </Stack>
          )}

          {shippings?.length > 0 && (
            <Stack spacing={2}>
              <Typography>
                {" "}
                Shippings since last record{" "}
                {shippings.reduce((a, v) => a + v.qty, 0)}
              </Typography>
              {shippings?.map((x) => (
                <Stack direction="row" spacing={2}>
                  <Typography> Date: {x?.date}</Typography>
                  <Typography> Date: {x?.qty}</Typography>
                </Stack>
              ))}
            </Stack>
          )}

          <Stack spacing={2}>
            <Typography> calculatedInv: {calculatedInv}</Typography>
            <Typography> MaterialInvRecord: {materialInv}</Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
