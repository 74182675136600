export const invoiceByType = /* GraphQL */ `
  query InvoiceByType(
    $type: String!
    $definerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoiceByType(
      type: $type
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        category
        batch
        type
        taxRate
        no @client
        number
        itemNumber
        date
        price
        qty
        currency
        note
        desc
        poID
        amount @client
        po {
          id
          no @client
          number
          itemNumber
          customer {
            id
            name
            alias
            abbr @client
          }
          vendor {
            id
            name
            alias
            abbr @client
          }
          materialEntityRelation {
            id
            code
          }
          material {
            id
            mfr
            mpn
            unit
          }
        }

        paymentDueDate
        billingEntityID
        shippingID
        paymentStatus
        deductionDate
        definerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      category
      taxRate

      type
      number
      batch
      itemNumber
      date
      price
      qty
      forwarderPOID
      forwarderPO {
        id
        number
        itemNumber
        no @client
      }
      currency
      note
      desc
      poID
      po {
        id
        no @client
        number
        itemNumber
        amount @client
        price
        qty
        customer {
          id
          name
          alias
          abbr @client
        }
        vendor {
          id
          name
          alias
          abbr @client
        }
        materialEntityRelation {
          id
          code
        }
        material {
          id
          mfr
          mpn
          unit
        }
      }

      paymentDueDate
      billingEntityID

      shippingID

      paymentStatus
      deductionDate
      definerID
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      id
      category
      type
      taxRate

      batch
      number
      itemNumber
      forwarderPOID
      forwarderPO {
        id
        number
        itemNumber
        no @client
      }
      date
      price
      qty
      currency
      note
      desc
      poID

      paymentDueDate
      billingEntityID

      shippingID

      paymentStatus
      deductionDate
      definerID

      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    deleteInvoice(input: $input, condition: $condition) {
      id

      __typename
    }
  }
`;

export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      id
      category
      type
      taxRate

      number
      batch
      itemNumber
      date
      price
      qty
      currency
      note
      desc
      poID

      paymentDueDate
      billingEntityID

      shippingID

      definerID
      paymentStatus
      deductionDate
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const getPO = /* GraphQL */ `
  query GetPO($id: ID!) {
    getPO(id: $id) {
      id
      number
      itemNumber
      qty
      invoice {
        items {
          id
          qty
        }
      }
      __typename
    }
  }
`;


export const relationsByDefiner = /* GraphQL */ `
  query RelationsByDefiner(
    $definerID: ID!
    $relation: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityRelationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationsByDefiner(
      definerID: $definerID
      relation: $relation
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        relation
        definerID
        entity1ID
        entity1 {
          id
          alias
          name
          relationsAsEntity1 {
            items {
              id
              relation
              entity1ID
            }
          }
        }
        entity2ID
        entity2 {
          id
          name
          alias
        }
        alias
        customerCode
        vendorCode
        statusCode
        type
        incoTerms {
          terms
          condition
          entityUserRelationID
        }
        paymentTerms
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`

export const MaterialEntityRelationsByDefiner = /* GraphQL */ `
  query MaterialEntityRelationsByDefiner(
    $definerID: ID!
    $entityID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMaterialEntityRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    MaterialEntityRelationsByDefiner(
      definerID: $definerID
      entityID: $entityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityID
        materialID
        material {
          id
          mfr
          mpn
        }
        code
        statusCode
        desc
        category
        pdt
        inventoryAlignment
        groupToRead
        groupToEdit
        groupToDelete
        definerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`