import { gql } from "@apollo/client";
import { onUpdate } from "./index";
import comparePropertyBetweenObjects from "../../lib/comparePropertyBetweenObjects";
import { onCreate } from "../onCreate/index";
import { onDelete } from "../onDelete/index";

const getMaterial = gql`
  query GetMaterial($id: ID!) {
    getMaterial(id: $id) {
      gcas
      id
      desc
      entityID
      category
      parts {
        items {
          id
          statusCode
          partID
          part {
            id
            mfr
            mpn
          }
        }
        nextToken
      }
      notes {
        nextToken
      }
      pdt
      inventoryAlignment
      createdAt
      updatedAt
    }
  }
`;

const updateMaterial = /* GraphQL */ `
  mutation UpdateMaterial(
    $input: UpdateMaterialInput!
    $condition: ModelMaterialConditionInput
  ) {
    updateMaterial(input: $input, condition: $condition) {
      gcas
      id
      desc
      entityID
      category
      parts {
        items {
          id
          partID
          materialID
        }
        nextToken
      }
      pdt
      inventoryAlignment
      createdAt
      updatedAt
    }
  }
`;

const deleteMaterialPartRelation = gql`
  mutation DeleteMaterialPartRelation(
    $input: DeleteMaterialPartRelationInput!
    $condition: ModelMaterialPartRelationConditionInput
  ) {
    deleteMaterialPartRelation(input: $input, condition: $condition) {
      id
    }
  }
`;
const createMaterialPartRelation = gql`
  mutation CreateMaterialPartRelation(
    $input: CreateMaterialPartRelationInput!
    $condition: ModelMaterialPartRelationConditionInput
  ) {
    createMaterialPartRelation(input: $input, condition: $condition) {
      id
      statusCode
      materialID
      partID
    }
  }
`;

const updateMaterialPartRelation = gql`
  mutation UpdateMaterialPartRelation(
    $input: UpdateMaterialPartRelationInput!
    $condition: ModelMaterialPartRelationConditionInput
  ) {
    updateMaterialPartRelation(input: $input, condition: $condition) {
      id
      statusCode
      materialID
      material {
        gcas
        id
        desc
        entityID
        category
        pdt
        inventoryAlignment
        createdAt
        updatedAt
      }
      partID
      part {
        id
        mpn
        mfr
        coo
        desc
        unit
        leadtime
        mpq
        transitDays
        initialInventory
        currentInventory
        hsCode
        importDutyCN
        createdAt
        updatedAt
      }
    }
  }
`;
export default async function onUpdateMaterial({ values, client }) {
  const { id } = values;

  const fieldsToUpdate = [
    { field: "gcas", createUpdate: true },
    {
      field: "parts",
      // createUpdate: false,
      funcAdd: async ({ addedItem, updatesToCreate }) => {
        const message = `${addedItem.part.mfr} ${addedItem.part.mpn} was added with statusCode ${addedItem.statusCode}`;
        updatesToCreate.push(message);
        const fragment = /* GraphQL */ `
          fragment NewMaterialPartRelation on MaterialPartRelation {
            id
          }
        `;
        await onCreate({
          mutation: createMaterialPartRelation,
          fragment,
          input: {
            materialID: id,
            partID: addedItem.part.id,
            statusCode: addedItem.statusCode,
          },
          // updateCacheFields: ["materialPartRelationByMaterial"],
          // refetchQueries: ["GetMaterial"],
          client,
        });
      },
      funcDelete: async function funDelete({ deletedItem, updatesToCreate }) {
        const message = `${deletedItem.part.mfr} ${deletedItem.part.mpn} was deleted with statusCode ${deletedItem.statusCode}`;
        updatesToCreate.push(message);
        await onDelete({
          mutation: deleteMaterialPartRelation,
          input: { id: deletedItem.relationID },
          client,
        });
      },
      funcCheckUpdate: async function funcCheckUpdate({
        currentItem,
        initialValues,
        updatesToCreate,
        // changedProperties,
      }) {
        const arrayItemInInitialValue = initialValues[this.field].find(
          (y) => y.part?.id === currentItem?.part?.id
        );
        const changedProperties = comparePropertyBetweenObjects({
          properties: ["statusCode"],
          newObj: currentItem,
          oldObj: arrayItemInInitialValue,
        });

        if (Object.keys(changedProperties)?.length > 0) {
          const input = { id: currentItem.relationID, ...changedProperties };
          // console.log({ input });
          // debugger;
          await client.mutate({
            mutation: updateMaterialPartRelation,
            variables: { input },
          });

          Object.keys(changedProperties).forEach((key) => {
            const subField = this.checkPropertiesForUpdate.find(
              (x) => x.field === key
            );
            if (subField?.createUpdate) {
              const message = `${key} has been updated from ${arrayItemInInitialValue[key]} to ${currentItem[key]}`;
              updatesToCreate.push(message);
            }
          });
        }
      },
      checkPropertiesForUpdate: [{ field: "statusCode", createUpdate: true }],
      checkIsItemInNewArrayExisting: ({ item, originalArr }) => {
        return (
          item.relationID &&
          originalArr.map((y) => y?.part?.id).includes(item?.part.id)
        );
      },
      checkIsItemInOldArrayRemaining: ({ item, newArr }) => {
        return newArr.some((y) => y.relationID && y?.part?.id === item.partID);
      },
    },
  ];

  async function getInitialValue() {
    const { data } = await client.query({
      query: getMaterial,
      variables: { id },
    });

    let details = data?.getMaterial || {};

    details = {
      ...details,
      parts: details?.parts?.items?.map((x) => ({ ...x, relationID: x.id })),
    };

    return details;
  }

  await onUpdate({
    type: "Material",
    values,
    mutation: updateMaterial,
    // query,
    getInitialValue,
    fieldsToUpdate,
    client,
    refetchQueries: ["GetMaterial"],
  });
}
