import React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import Drawer from "../Drawer";
import getSideNav from "../../function/getSideNav";

export default function AdminLayout() {
  const menus = getSideNav({
    entityType: "systemAdmin",
    userRole: "systemAdmin",
    isAuthenticated: true,
  });



  return (
    <Box
      sx={{
        // bgcolor: "red",
        display: "flex",
        width: "100%",
        height: "100%",
        // flexDirection: "column",
        // justifyContent: "center",
        // alignItems: "center",
        // gap: 2,
        // mt: 5,
      }}
    >
      <Drawer menusArray={menus} />
      <Outlet />
    </Box>
  );
}
