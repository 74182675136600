import { gql } from "@apollo/client";
import { API } from "aws-amplify";

import mergeArrayAndCombineQty from "../../lib/mergeArrayAndCombineQty";

const getPO = /* GraphQL */ `
  query GetPO($id: ID!) {
    getPO(id: $id) {
      id
      openQty
      __typename
    }
  }
`;

const updatePO = /* GraphQL */ `
  mutation UpdatePO($input: UpdatePOInput!, $condition: ModelPOConditionInput) {
    updatePO(input: $input, condition: $condition) {
      id
      number
      itemNumber
      date
      offerID
      customerID
      vendorID
      definerID
      materialID
      material {
        id
        definerID
        mpn
        mfr
        __typename
      }
      materialEntityRelationID
      qty
      openQty
      __typename
    }
  }
`;

export default async function updatePOOpenQtyFromArray({ client, arr, type }) {
  try {
    // console.log({ arr });
    // debugger;
    if (type !== "deduct" && type !== "add") {
      alert("type must be deduct or add");
      throw new Error("type must be deduct or add");
    }

    if (arr.some((x) => !x.qty || !x.poID)) {
      alert("please make sure all items have qty and poID");
      throw new Error("poID or qty is missing");
    }

    const congregatedArr = mergeArrayAndCombineQty(arr, ["poID"], ["qty"]);

    await Promise.all(
      congregatedArr.map(async (x) => {
        const { poID, po, qty } = x;

        const { data } = await client.query({
          query: gql(getPO),
          variables: { id: poID },
        });

        const { openQty } = data?.getPO || 0;
        const newOpenQty =
          type === "deduct" ? openQty * 1 - qty * 1 : openQty * 1 + qty * 1;

        console.log({ newOpenQty, openQty, qty, type });
        // debugger;
        const res = await client.mutate({
          mutation: gql(updatePO),
          variables: {
            input: {
              id: poID,
              openQty: newOpenQty,
            },
          },
        });
        // console.log({ res: res.data.updatePO });
        // debugger;

        if (po?.pi?.poID) {
          const { data: originalPOData } = await client.query({
            query: gql(getPO),
            variables: { id: po?.pi?.poID },
          });

          const { openQty: originalOpenQty } = originalPOData?.getPO;
          const newOriginalOpenQty =
            type === "deduct" ? originalOpenQty - qty : originalOpenQty + qty;
          const res = await client.mutate({
            mutation: gql(updatePO),
            variables: {
              input: {
                id: po?.pi?.poID,
                openQty: newOriginalOpenQty,
              },
            },
          });
        }
      })
    );
  } catch (e) {
    // console.log("updating po qty error", e);
    await API.post("iSupplyAPI", "/restricted", {
      body: {
        operation: "SEND_EMAIL",
        data: {
          subject: e.message,
          to: "steven.lu@hankeno.com",
          // cc: "antrons@gmail.com",
          bcc: null,
          senderCompanyName: "HANKENO INC",
          recipientName: "Steven Lu",
          // items: ["item1", "item2", "item3"],
          // btnURL: "www.google.com",
          // btnLabel: "confirm",
          text:
            "Error occured when updating PO openQty, error message: " +
            e.message,
        },
      },
    });
    // throw new Error(e);
  }
}
