// import { ErrorMessage } from "formik";
import { Typography } from "@mui/material";

export default function FieldErrorMessage({ name, meta }) {

  // console.log({ name4444444444444444444: name, meta });

  if (meta.touched && meta.error) {
    if (typeof meta.error === "object") return null;
    return <Typography color="red">{meta.error}</Typography>;
  }

  return null;

  // return (
  //   <ErrorMessage
  //     name={name}
  //     render={(msg) => {
  //       console.log('2222222222222222222222');

  //       console.log({ name, msg });
  //       // debugger

  //       if (isArray(msg)) {
  //         // return null
  //         return <Typography color="red">{msg.join(", ")}</Typography>;
  //       }
  //       // if (name === "role") {
  //       //   console.log({ msg });
  //       //   debugger
  //       // }
  //       return <Typography color="red">{msg}</Typography>;
  //     }}
  //   />
  // );
}
