import { Box } from "@mui/material";

export default function ImageOverlay({
  // width,
  // height,
  hero,
  img,
  children,
  color1 = "#111111",
  color2 = "#11111150",
  noOverlay,
}) {
  const height = hero ? 440 : "100%";
  // const width = "100vw";

  return (
    <Box
      sx={{
        display: "block",
        position: "relative",
        height,
        backgroundImage: `url("${img}")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        // backgroundRepeat: "no-repeat",
      }}
    >
      <Box
        sx={{
          ...(!noOverlay && {
            background: `linear-gradient(to right bottom,${color1}, ${color2})`,
          }),
          // backgroundColor: "rgba(0, 0, 0, 0.7)", }),

          potision: "absolute",
          height: "100%",
          padding: 3,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
