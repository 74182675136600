import { gql, useQuery } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";
// import { NewForm } from "../../../component/Views/CreateForm/index";
// import DetailsView from "../../../component/Views/ModuleView";
import FormikForm, {
  FormikField,
  FormikRadioGroup,
} from "../../../component/formikForm";
import DataGridComp from "../../../component/DataGrid";
import Notes from "../../../component/Notes";
import Updates from "../../../component/Updates";
import { entityTypeSelection } from "../../../settings/formFieldEnum";
import getFilteredItemsByUserRole from "../../../function/getFilteredItemsByUserRole";
import { getEntity, listEntities } from "./query";
import onDelete from "./onDelete";
import onUpdate from "./onUpdate";

const validationSchema = Yup.object().shape({
  name: Yup.string().min(2, "Too Short").required("Please enter company name"),
  alias: Yup.string()
    .min(2, "Too Short")
    .required("Please enter company alias"),
  type: Yup.string().required("Please select type"),
});

// const FormArrayForCreate = [
//   {
//     Item: ContactItemForEntityForm,
//     name: "contacts",
//   },
// ];
const FormArrayForEdit = null;

function CreateFields(props) {
  return (
    <Stack spacing={5}>
      <Stack direction="row" spacing={5} width={1200}>
        <FormikField name="name" label="Company Name" required />
        <FormikField name="alias" label="Company Alias" required />
      </Stack>
      <FormikRadioGroup
        name="type"
        label="Type"
        selections={entityTypeSelection.map((x) => ({
          ...x,
          label: `to ${x.label}`,
        }))}
      />
    </Stack>
  );
}

function CreateForm() {
  // const { entityID } = useParams();
  const initialValues = {};

  return (
    <Typography variant="h3">YOU MAY NEED TO REBUILD THIS FORM</Typography>
    // <NewForm
    //   initialValues={initialValues}
    //   validationSchema={validationSchema}
    //   FormMainFields={CreateFields}
    //   onCreate={onCreate}
    //   // ArrayField={FormArrayForCreate}
    // />
  );
}

function EditFields({ values }) {
  return (
    <Stack
      width="100%"
      spacing={3}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <FormikField name="name" label="Name" required />
      <FormikField name="alias" label="Alias" required />
      <FormikRadioGroup
        name="type"
        label="Type"
        selections={entityTypeSelection.map((x) => ({
          ...x,
          label: `to ${x.label}`,
        }))}
      />
    </Stack>
  );
}

function EditForm({ data, handleCancel, setIsEditing, onClose }) {
  return (
    <FormikForm
      initialValues={data}
      validationSchema={validationSchema}
      FormMainFields={EditFields}
      FormArray={FormArrayForEdit}
      type="edit"
      handleCancel={handleCancel}
      saveButtonLabel="save"
      onClose={onClose}
      setIsEditing={setIsEditing}
      onSubmit={onUpdate}
      onDelete={onDelete}
    />
  );
}

function Item({ data }) {
  const { id, alias, name, label } = data;
  return (
    <Stack spacing={5}>
      <Stack>
        <Typography> alias: {alias}</Typography>
        <Typography> name: {name}</Typography>
        <Typography> Type Label: {label}</Typography>
      </Stack>
      <Notes itemID={id} />
      <Updates itemID={id} />
    </Stack>
  );
}

function ItemView({ id, onClose }) {
  const nagivate = useNavigate();
  const entityID = useParams().entityID || id;

  const { data } = useQuery(gql(getEntity), {
    variables: { id: entityID },
  });

  if (!data) return null;

  const details = data?.getEntity || {};
  const defaultOnClose = () => {
    nagivate(-1);
  };
  return (
    <p>please rebuild this component</p>
    // <DetailsView
    //   onClose={onClose || defaultOnClose}
    //   Details={Item}
    //   data={details}
    //   EditForm={EditForm}
    //   allowEdit
    // />
  );
}

function Comp({ entityType, userRole, isAuthenticated, entityID }) {
  const columns = [
    {
      field: "name",
      headerName: "name",
      width: 300,
      userRole: ["systemAdmin"],
    },
    {
      field: "alias",
      headerName: "alias",
      width: 300,
      userRole: ["systemAdmin"],
    },
    {
      field: "label",
      headerName: "label",
      width: 300,
      userRole: ["systemAdmin"],
    },
  ];
  const dropdownMenu = [
    {
      label: "Delete",
      userRole: ["systemAdmin"],
      onClick: async ({ client, selectionModel, setSelectionModel }) => {
        await onDelete({
          selectionModel,
          setSelectionModel,
          client,
        });
      },
    },
  ];

  const filteredColumns = getFilteredItemsByUserRole(columns, userRole);

  const filteredDropdownMenu = getFilteredItemsByUserRole(
    dropdownMenu,
    userRole
  );

  return (
    <DataGridComp
      query={listEntities}
      // queryVariables={queryVariables}
      onDelete={onDelete}
      onClickAddRoute="new"
      columns={filteredColumns}
      DetailsDialog={ItemView}
      userRole={userRole}
      checkboxSelection
      getDropdownMenu={filteredDropdownMenu}
      // prepareItemsFunc={prepareItemsFunc}
    />
  );
}

const entities = {
  path: "entities",
  sideNav: {
    group: 4,
    order: 50,
    label: "Entities",
    entityType: ["systemAdmin"],
    allowedRole: ["systemAdmin"],
    auth: ["authenticated"],
  },
  route: {
    index: {
      element: null,
      Comp,
      allowedRole: "all",
      entityType: "all",
      auth: ["authenticated"],
    },
    new: {
      element: <CreateForm />,
      allowedRole: ["systemAdmin"],
      entityType: ["systemAdmin"],
      auth: ["authenticated"],
    },
  },
};

export default entities;
