import { useFormikContext } from 'formik'
import { Stack, Typography, MenuItem } from '@mui/material'
import * as Yup from 'yup'
import FormikForm, {
  FormikField,
  FormikArrayRemoveItemButton,
} from '../../../../component/formikForm'
import onUpdate from '../onUpdate'


const validationSchemaForCreateRelation = Yup.lazy((values) => {
  console.log({ valuesinSchema: values })
  return Yup.object().shape({

  })
})

export default function Specifications({
  materialID,
  specifications,
  onClose,
}) {


  async function onSubmit({ values, client }) {

    await onUpdate({
      values: { id: materialID, specifications: values.specifications },
      client,
    })
  }

  const initialValues = {
    specifications,
    disableAddButton: false,
    isEditing: false,
  }

  const FormArray = [
    {
      Item: SpecLine,
      name: 'specifications',
      width: '95%',
      bgcolor: '#f3e5f5',
      btnColor: 'purple',
      size: 'md',
      narrow: true,
    },
  ]

  return (
    <Stack
      spacing={2}
      justifyContent='space-between'
      sx={{ minHeight: 120 }}
      bgcolor='#f1f1f1'
      borderRadius={1}
      padding={2}>
      <Stack spacing={3}>
        <Typography variant='h6' sx={{ alignSelf: 'center' }}>
          Specifications
        </Typography>
        <FormikForm
          initialValues={initialValues}
          validationSchema={null}
          onSubmit={onSubmit}
          FormMainFields={() => null}
          FormArray={FormArray}
          saveButtonLabel='submit'
          handleCancel={({ setFieldValue }) => {
            setFieldValue('specifications', specifications)
            setFieldValue('isEditing', false)
          }}
        />
      </Stack>
    </Stack>
  )
}

const chi = ['BETWEEN', 'EQUAL'].map((option) => (
  <MenuItem key={option} value={option}>
    {option.label}
  </MenuItem>
))

const SpecLine = ({ index, remove, arrayFieldName, removeBtnColor, size }) => {
  const { values } = useFormikContext()
  const item = values.specifications[index] || {}
  const isEditing = values.isEditing

  console.log({ item, isEditing })

  return (
    <Stack
      bgcolor='#fff'
      direction='row'
      spacing={2}
      minWidth={1100}
      // alignItems="center"
      justifyContent='space-between'>
      <Stack direction='row' spacing={2}>
        <FormikField
          width={80}
          name={`[${arrayFieldName}][${index}][order]`}
          label='Order'
          type='number'
          disabled={!isEditing}
        />
        <FormikField
          width={180}
          name={`[${arrayFieldName}][${index}][attr]`}
          label='attr'
          disabled={!isEditing}
        />
        <FormikField
          width={100}
          name={`[${arrayFieldName}][${index}][predicator]`}
          label='Predicator'
          disabled={!isEditing}
          select
          children={['BETWEEN', 'EQUAL'].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        />

        {item.predicator === 'BETWEEN' && (
          <>
            <FormikField
              width={80}
              name={`[${arrayFieldName}][${index}][specValueFrom]`}
              label='specValueFrom'
              disabled={!isEditing}
            />
            <FormikField
              width={80}
              name={`[${arrayFieldName}][${index}][specValueTo]`}
              label='specValueTo'
              disabled={!isEditing}
            />
            <FormikField
              width={80}
              name={`[${arrayFieldName}][${index}][testValueFrom]`}
              label='testValueFrom'
              disabled={!isEditing}
            />
            <FormikField
              width={80}
              name={`[${arrayFieldName}][${index}][testValueTo]`}
              label='testValueTo'
              disabled={!isEditing}
            />
          </>
        )}

        {item.predicator === 'EQUAL' && (
          <>
            <FormikField
              width={80}
              name={`[${arrayFieldName}][${index}][specValueEqual]`}
              label='specValueEqual'
              disabled={!isEditing}
            />
            <FormikField
              width={80}
              name={`[${arrayFieldName}][${index}][testValueEqual]`}
              label='testValueEqual'
              disabled={!isEditing}
            />
          </>
        )}

        <FormikField
          width={50}
          name={`[${arrayFieldName}][${index}][unit]`}
          label='unit'
          disabled={!isEditing}
        />
        <FormikField
          width={50}
          name={`[${arrayFieldName}][${index}][digits]`}
          label='digits'
          disabled={!isEditing}
        />
      </Stack>

      {isEditing && (
        <FormikArrayRemoveItemButton
          onClick={() => remove(index)}
          name={arrayFieldName}
          removeBtnColor={removeBtnColor}
          size={size}
          disabled={!isEditing}
        />
      )}
    </Stack>
  )
}
