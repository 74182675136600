const index = {
  path: "",
  route: {
    index: {
      element: <p>system admin index</p>,
      allowedRole: ["systemAdmin"],
      entityType: ["systemAdmin"],
      auth: ["authenticated"],
    },
  },
};

export default index;
