import { useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import SimpleTable from "../../../../../component/SimpleTable";
import mergeArrayAndCombineQty from "../../../../../lib/mergeArrayAndCombineQty";
import { useEffect } from "react";

export default function Step1Review({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  setErrors,
  setStatus,
}) {
  const {
    date,
    number,
    customer,
    vendor,
    incoTerms,
    shippingItems,
    aggregatedShippingItems,
  } = values;

  const { terms, entityName, contactName, phoneNumber, email, address } =
    incoTerms || {};

  const tableRows = useMemo(() => {
    let rows = shippingItems?.map((item) => {
      const { po, qty, coo } = item || {};
      const {
        id,
        number,
        itemNumber,
        materialID,
        materialEntityRelationID,
        materialEntityRelation,
        material,
      } = po || {};

      return {
        PONumber: `${number}-${itemNumber}`,
        poID: id,
        GCAS: materialEntityRelation?.code,
        MFR: material?.mfr,
        MPN: material?.mpn,
        materialID: materialID,
        materialEntityRelationID: materialEntityRelationID,
        qty,
        coo: coo?.toUpperCase(),
      };
    });

    rows = mergeArrayAndCombineQty(rows, ["poID"], ["qty"])
      .sort((a, b) => (a.PONumber > b.PONumber ? -1 : 1))
      .sort((a, b) => (a.GCAS > b.GCAS ? 1 : -1))
      ?.map((x, i) => ({
        ...x,
        itemNumber: i + 1,
      }));

    return rows;
  }, [shippingItems]);

  useEffect(() => {
    setFieldValue("aggregatedShippingItems", tableRows);
  }, [tableRows, setFieldValue]);

  const tableHeaders = [
    "itemNumber",
    "PONumber",
    "GCAS",
    "MFR",
    "MPN",
    "qty",
    "coo",
  ];

  return (
    <Stack spacing={5}>
      <Stack direction="row" spacing={3}>
        <Typography variant="h6">Shipping Date: {date}</Typography>
        <Typography variant="h6">Number: {number}</Typography>
      </Stack>
      <Stack direction="row" spacing={3}>
        <Typography variant="h6">Customer: {customer?.name}</Typography>
        <Typography variant="h6">Vendor: {vendor?.name}</Typography>
      </Stack>
      <Stack direction="row" spacing={3}>
        <Typography variant="h6">IncoTerms: {terms}</Typography>
        <Typography variant="h6">
          {entityName}
          {contactName}
          {address}
          {phoneNumber}
          {email}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={3}>
        <Typography variant="h6">
          Total Qty: {tableRows?.reduce((a, v) => a + v.qty * 1, 0)}
        </Typography>
      </Stack>

      <SimpleTable headers={tableHeaders} rows={aggregatedShippingItems} />
    </Stack>
  );
}
