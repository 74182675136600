import { gql, useReactiveVar } from "@apollo/client";

import { useState } from "react";
import { Storage } from "aws-amplify";
import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import createContact from "../../contacts/onCreate";
import onCreateRelation from "../../contacts/onCreateRelation";
import { EntitybyAlias, userByEmail } from "../query";
import { userEntityRelationVar } from "../../../../client/cache";

export const TemplateLabel = ({ rows }) => {
  const { entityID } = useReactiveVar(userEntityRelationVar);

  const [isLoading, setIsloading] = useState(false);
  const filename = "contacts.csv";
  const path = `${entityID}/template/${filename}`;

  console.log({ path });

  return (
    <Stack
      direction="row"
      spacing={2}
      // display="flex"
      // justifyContent="center"
      alignItems="center"
      // sx={{ alignItems: "center" }}
    >
      <Typography variant="h6">Template: </Typography>
      <Typography variant="p">{filename}</Typography>

      <LoadingButton
        loading={isLoading}
        size="small"
        onClick={async () => {
          setIsloading(true);
          try {
            const fetchedURL = await Storage.get(path, {
              download: false,
              validateObjectExistence: true,
              // level: 'protected',
            });
            window.location.href = fetchedURL;

            // console.log({window})

            // const downloadFolder = window.downloads.getDownloadFolder();

            // console.log({ downloadFolder });
            // debugger;
          } catch (err) {
            console.log({ err444: err });
          }
          setIsloading(false);
        }}
        variant="contained"
      >
        download
      </LoadingButton>
    </Stack>
  );
};

export const headerIdentifier = [
  { label: "ALIAS", isRequired: true },
  { label: "NAME", isRequired: true },
  { label: "TITLE", isRequired: true },
  { label: "ROLE", isRequired: false },
  { label: "entityID", isRequired: false },
  { label: "fn", isRequired: false },
  { label: "ln", isRequired: false },
  { label: "EMAIL", isRequired: true },
  { label: "PHONENUMBER", isRequired: false },
  { label: "ADDRESS", isRequired: false },
  { label: "userID", isRequired: false },
];

export async function onCreate({ rows, definerID, client }) {
  await Promise.all(
    rows.map(async (row) => {
      let {
        fn,
        ln,
        EMAIL,
        PHONENUMBER,
        ADDRESS,
        entityID,
        userID,
        ROLE,
        TITLE,
      } = row;

      let id;
      //CREATE CONTACT
      if (!userID) {
        const inputForCreateContact = {
          fn,
          ln,
          definerID,
          email: EMAIL,
          phoneNumber: PHONENUMBER,
          address: ADDRESS,
        };

        id = await createContact({
          client,
          values: inputForCreateContact,
        });
        // console.log({ id });
        // console.log({ inputForCreateContact });
        // debugger;
        if (!id) return alert("error creating contact");
      }

      const inputForCreateRelation = {
        userID: userID || id,
        role: ROLE,
        title: TITLE,
        entityID,
        definerID,
      };
      await onCreateRelation({ client, values: inputForCreateRelation });

      // debugger;
    })
  );
}

export async function validator({ rows, definerID, client }) {
  console.log({ rows });
  let newarr = await Promise.all(
    rows?.map(async (row) => {
      let ERROR = [];
      const { NAME, ALIAS, TITLE, ROLE, EMAIL } = row;
      let userID;
      let { data: entitybyAliasData } = await client.query({
        query: gql(EntitybyAlias),
        variables: { alias: ALIAS },
      });
      entitybyAliasData = entitybyAliasData?.EntitybyAlias?.items?.[0];

      if (!entitybyAliasData) {
        ERROR.push({
          attr: "ALIAS",
          isMajor: true,
          msg: "Entity not found",
        });
        return { ...row, ERROR };
      }

      const { id } = entitybyAliasData;

      let { data: userByEmailData } = await client.query({
        query: gql(userByEmail),
        variables: { email: EMAIL.trim() },
      });
      userByEmailData = userByEmailData?.userByEmail?.items?.[0];

      if (userByEmailData) {
        userID = userByEmailData?.id;
      }

      let [ln, fn] = NAME?.trim()?.split(",");
      fn = fn?.trim();
      ln = ln?.trim();

      row.entityID = id;
      row.fn = fn;
      row.ln = ln;
      row.TITLE = TITLE?.trim();
      row.ROLE = ROLE?.trim();
      row.userID = userID;
      return { ...row, ERROR };
    })
  );

  // newarr = mergeArrayAndCombineQty(newarr, ["poID"], ["qty"])
  //   .sort((a, b) => (a.PONumber > b.PONumber ? -1 : 1))
  //   .sort((a, b) => (a.MATERIAL > b.MATERIAL ? 1 : -1))
  //   ?.map((x, i) => ({
  //     ...x,
  //     itemNumber: ((i + 1) * 10).toString().padStart(5, "0"),
  //   }));

  return newarr;
}
