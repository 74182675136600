import { useEffect } from "react";
import {
  Container,
  List,
  ListItemText,
  ListItem,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import Template from "../../../component/Template";
import award from "../../../assets/images/award.jpg";
import ConfettiOverlay from "../../../component/ConfettiOverlay";

const milestones = [
  {
    year: 1999,
    events: [
      {
        label: "Founded",
      },
    ],
  },
  {
    year: 2001,
    events: [
      {
        label: "ISO18001 certified",
      },
    ],
  },
  {
    year: 2002,
    events: [
      { label: 'Awarded "Supplier of the Year" by Motorola' },
      { label: "Qualifed as a Tier-1 supplier by UTStarcom" },
    ],
  },
  { year: 2003, events: [{ label: "ISO9001 certified" }] },
  {
    year: 2004,
    events: [{ label: "Winner of Nokia Diamond Awards" }],
  },
  {
    year: 2006,
    events: [{ label: "Qualified by GE Medical(GE Health Care)" }],
  },

  {
    year: 2010,
    events: [{ label: "Fabrication services launched" }],
  },
  {
    year: 2012,
    events: [{ label: "ISO14001 certified" }],
  },
  {
    year: 2016,
    events: [{ label: "Qualified by TSMC" }],
  },
  {
    year: 2017,
    events: [
      { label: "Started partership with industry leading EMS providers" },
    ],
  },
  {
    year: 2019,
    events: [{ label: "IT services launched" }],
  },
  {
    year: 2022,
    events: [{ label: "Qualified by Tesla" }],
  },
  {
    year: 2023,
    events: [
      { label: 'Ranked among "PLATINUM TOP 1%" by EcoVadis ESG rating' },
    ],
  },
  {
    year: 2024,
    events: [
      {
        label:
          "We've evolved! Now we have a new name - Hankeno Inc - reflecting a new chapter in our journey!",
      },
    ],
  },
];

function ImageOverlayOverwite() {
  return (
    <>
      <ConfettiOverlay height={400} />
      <Box
        sx={{
          height: "100%",
          display: "flex",
          // bgcolor: "red",
          // flexDirection: "column",
          // gap: 5,
          // justifyContent: "center",
          alignItems: "center",
          color: "#fff",
        }}
      >
        <Typography
          sx={{
            position: "relative",
            top: -30,
            left: 40,
            fontStyle: "italic",
            fontWeight: "light",
            fontFamily: "cursive",
            fontSize: 72,
            // " -webkit-text-stroke": "1px #ff3b3e80",
            textShadow: "0px 0px 24px #ff3b3e80 ",
          }}
        >
          25 years of excellence!
        </Typography>
      </Box>
    </>
  );
}

function Content() {
  return (
    <Container id="history" sx={{ mt: 5 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
        <Divider color="#888888" />
        <Box sx={{ alignSelf: "center" }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              mb: 2,
              textAlign: "center",
              color: "#666666",
            }}
          >
            Milestones
          </Typography>

          {milestones.map(({ year, events }) => (
            <Box
              key={year}
              sx={{
                display: "flex",
                gap: 3,
                alignItems: "flex-start",
                color: "#666666",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "light",
                  top: 15,
                  position: "relative",
                  fontSize: 24,
                  fontStyle: "italic",
                }}
              >
                {year}
              </Typography>
              <List sx={{}}>
                {events.map(({ label }) => (
                  <ListItem key={label}>
                    <ListItemText
                      primary={label}
                      primaryTypographyProps={{
                        fontSize: 18,
                        fontStyle: "italic",
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </Box>
        <Divider color="#888888" />

        <Box
          id="contact"
          sx={{
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            color: "#666666",
            // bgcolor: "#f1f1f1",
          }}
        >
          <Typography sx={{ fontSize: 18 }}>
            Address: ONE AMERICA PLAZA, 600 W BROADWAY, #175-180, SAN DIEGO, CA
            92101
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            Phone Number: +1-951-297-7788
          </Typography>

          <Typography sx={{ fontSize: 18 }}>
            Email: contact@hankeno.com
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}
export default function Company() {
  const headPicture = award;
  const headTitle = "Company";
  const headDescription = "25 years of excellence";
  const headMessage = `Since 1999, Hankeno has been a reliable partner to prominent companies across diverse industries. We've built a reputation for delivering exceptional service quality and efficiency, empowering our clients to achieve unparalleled success.`;

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <Template
      headPicture={headPicture}
      headTitle={headTitle}
      confetti
      headDescription={headDescription}
      headMessage={headMessage}
      Content={Content}
      ImageOverlayOverwite={ImageOverlayOverwite}
    />
  );
}
